import React, { useRef, useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Card from "../../../../../shared/components/Card/Card";
import { Button, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Filledbluebutton from "../../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import LocationPicker from "react-location-picker";
import uploadFileService from "../../../../../core/services/Data/uploadFileService";
import { laboratoryoutRegistrationValidation } from "../../../../../core/Validationschemas/laboratoryValidation";
import Toggledays from "../../../../../shared/components/Toggledays/Toggledays";
import laboratoryService from "../../../../../core/services/Data/laboratoryService";
import { toast } from "react-toastify";
import { UserContext } from "../../../../../App";
import LocationService from "../../../../../core/services/Data/LocationService";
import SearchBox from "./googleSearch";

const Laboratoryouthouseform = () => {
  const params = useParams();
  const navigate = useNavigate();
  const pharmcyImageInputref = useRef();
  const pharmcyPermitInputref = useRef();
  const [formPrefillData, setFormPrefillData] = useState();
  const [workingDays, setWorkingDays] = useState(!params.id && []);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  const [laboratoryImageUrl, setPharmacyImageUrl] = useState("");
  const [laboratoryPermitUrl, setPharmacyPermitUrl] = useState("");
  const { setShowLoader } = useContext(UserContext);

  const defaultPosition = {
    lat: 29.378586,
    lng: 47.990341,
  };

  const getLAboratoryOuthouse = () => {
    laboratoryService.getLaboratoryOutById(params.id).then((res) => {
      console.log("AKASH", res.data);
      setFormPrefillData(res.data);
      setPosition({
        lat: res.data?.location?.coordinates[0],
        lng: res.data?.location?.coordinates[1],
      });
      setPharmacyImageUrl(res.data.picture);
      setPharmacyPermitUrl(res.data.permit);
    });
  };
  const [position, setPosition] = useState({ ...defaultPosition });
  useEffect(() => {
    const currentLocation = async () => {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (err) => console.log(err)
      );
      // console.log(this.state.latitude)
    };
    currentLocation();
  }, []);

  const formik = useFormik({
    initialValues: {
      laboratoryName: formPrefillData ? formPrefillData.name : "",
      // BranchName: formPrefillData ? formPrefillData.branchName : "",
      laboratoryLogo: formPrefillData ? formPrefillData.picture : "",
      laboratoryPermit: formPrefillData ? formPrefillData.permit : "",
      phoneNumber: formPrefillData ? formPrefillData.phone : "",
      email: formPrefillData ? formPrefillData.email : "",
      block:formPrefillData ? formPrefillData?.addressBlock?.block || "" : 'B-',
        blockNumber:formPrefillData ? formPrefillData?.addressBlock?.number || "" : '',
      alternatePhoneNumber: formPrefillData
        ? formPrefillData.alternatePhone
        : "",
      address: formPrefillData ? formPrefillData.address : "",
      city: formPrefillData ? formPrefillData.city : "",
      state: formPrefillData ? formPrefillData.state : "",
      country: formPrefillData ? formPrefillData.country : "",
      startTime: formPrefillData
        ? new Date(parseInt(formPrefillData.shift.startTime))
        : null,
      endTime: formPrefillData
        ? new Date(parseInt(formPrefillData.shift.endTime))
        : null,
    },
    validationSchema: laboratoryoutRegistrationValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("Working Days", workingDays);
      console.log("values", values);
      setShowLoader(true);
      if (!workingDaysError) {
        Promise.all([
          new Promise((resolve, reject) => {
            if (typeof values.laboratoryLogo !== "string") {
              const response = uploadFileService.fileUpload(
                values.laboratoryLogo
              );
              console.log(1);
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            } else {
              resolve();
            }
          }),

          new Promise((resolve, reject) => {
            if (typeof values.laboratoryPermit !== "string") {
              const response = uploadFileService.fileUpload(
                values.laboratoryPermit
              );
              // console.log(2);
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            } else {
              resolve();
            }
          }),
        ]).then(async ([res1, res2]) => {
          console.log("wokring", res1, values);
          if (params.id) {
            console.log(3);
            const payload = {
              ...values,
              name: values.laboratoryName,
              branchName: values.BranchName,
              picture: res1 ? res1.fileUrl : values.laboratoryLogo,
              permit: res2 ? res2.fileUrl : values.laboratoryPermit,
              phone: values.phoneNumber,
              alternatePhone: values.alternatePhoneNumber,
              workingDays: workingDays,
              location: {
                coordinates: [position.lat, position.lng],
              },
              addressBlock:{
                block:"B-",
                number:values.blockNumber
            },
            };
            laboratoryService
              .updateLaboratoryOut(params.id, payload)
              .then((res) => {
                if (res) {
                  navigate("/dashboard/laboratory/out-house");
                  toast.success(res.data.message);
                }
                setShowLoader((prevState) => {
                  return false;
                });
              });
          } else {
            const payload = {
              ...values,
              name: values.laboratoryName,
              branchName: values.BranchName,
              picture: res1 ? res1.fileUrl : values.laboratoryLogo,
              permit: res2 ? res2.fileUrl : values.laboratoryPermit,
              phone: values.phoneNumber,
              alternatePhone: values.alternatePhoneNumber,
              workingDays: workingDays,
              location: {
                coordinates: [position.lat, position.lng],
              },
              addressBlock:{
                block:"B-",
                number:values.blockNumber
            },
            };
            laboratoryService.createLaboratoryOuthouse(payload).then((res) => {
              if (res) {
                navigate("/dashboard/laboratory/out-house");
                toast.success(res.data.message);
              }
              setShowLoader((prevState) => {
                return false;
              });
            });
          }
        });
      }
    },
  });

  const laboratoryImageInputChangeHandler = (event) => {
    console.log("event files", event.target.files[0]);
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        formik.setErrors({
          laboratoryLogo: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        formik.handleChange(event);
        formik.setFieldValue(
          "laboratoryLogo",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setPharmacyImageUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      formik.setErrors({ laboratoryLogo: "Laboratory Logo is required" });
    }
  };

  const handleLocationChange = ({ position, address, places }) => {
    // Set new location
    setPosition({
      lat: position.lat,
      lng: position.lng,
    });
  };

  const PharmacyResetImageInput = () => {
    formik.setFieldValue("laboratoryLogo", "");
    console.log("imageInputref", pharmcyImageInputref);
    // pharmcyImageInputref.current.value = null;
    setPharmacyImageUrl("");
  };

  const laboratoryPermitInputChangeHandler = (event) => {
    console.log("event files", event.target.files);
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        formik.setErrors({
          laboratoryPermit: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        formik.handleChange(event);
        formik.setFieldValue(
          "laboratoryPermit",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );

        setPharmacyPermitUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      formik.setErrors({
        laboratoryPermit: "Laboratory Permit is Required!",
      });
    }
  };

  const PharmacyPermitResetImageInput = () => {
    console.log("imageInputref", pharmcyPermitInputref);
    pharmcyPermitInputref.current.value = null;
    setPharmacyPermitUrl("");
  };

  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };
  const [locatons, setLocations] = useState({
    country: [],
    city: [],
    state: [],
  });
  useEffect(() => {
    const apiData = async () => {
      const { data } = await LocationService.getLocations(
        formik.values.country,
        formik.values.state
      );
      // console.log({data})
      let cities = [...data[0].city];
      let states = [...data[0].state];
      let country = [...data[0].country];
      // for await (let element of data) {
      //   if (!cities.includes(element.city)) {
      //     cities.push(element.city);
      //   }
      //   if (!states.includes(element.state)) {
      //     states.push(element.state);
      //   }
      //   if (!country.includes(element.country)) {
      //     country.push(element.country);
      //   }
      // }
      const locations = { ...locatons };
      locations.city = cities;
      locations.state = states;
      locations.country = country;
      setLocations(locations);
    };
    apiData();
  }, [formik.values.country, formik.values.state]);
  useEffect(() => {
    if (params.id) {
      getLAboratoryOuthouse();
    }
  }, []);
  return (
    <Card cname="px-10">
      <div className="my-10">
        <h1 className="text-3xl font-bold">
          {params.id ? "Update" : "Register"}
        </h1>
        <p className="text-xl font-semibold text-gray-500 mt-2">
          {params.id ? "Update" : "Register"} as out house laboratory.
        </p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        {/*Registration Type input field*/}
        <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
          <TextField
            fullWidth
            id="branchInput"
            label="Laboratory"
            name="registerType"
            disabled
            select
            helperText={
              formik.touched.registerType && Boolean(formik.errors.registerType)
                ? "Branch name is required"
                : ""
            }
            value="Laboratory"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.registerType && Boolean(formik.errors.registerType)
            }
          >
            <MenuItem value="Laboratory">Laboratory</MenuItem>
          </TextField>
        </div>

        {/*Basic Details Input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Basic Details</h1>
          {/*Pharmacy name input field*/}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="laboratoryName"
                label="Enter laboratory name"
                helperText={
                  formik.touched.laboratoryName &&
                  Boolean(formik.errors.laboratoryName)
                    ? "laboratoryName is required"
                    : ""
                }
                value={formik.values.laboratoryName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.laboratoryName &&
                  Boolean(formik.errors.laboratoryName)
                }
              />
            </div>

            {/* <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="BranchName"
                label="Enter laboratory Branch name"
                helperText={
                  formik.touched.BranchName && Boolean(formik.errors.BranchName)
                    ? "BranchName is required"
                    : ""
                }
                value={formik.values.BranchName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.BranchName && Boolean(formik.errors.BranchName)
                }
              />
            </div> */}
          </div>

          {/*Pharmacy permit and logo input field*/}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-5">
            {/*Pharmacy logo input field */}
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col justify-center items-center max-w-full">
                <label
                  className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${
                                   formik.errors.laboratoryLogo
                                     ? "border-[#d32f2f]"
                                     : "border-gray-300"
                                 }
                         border-dashed cursor-pointer`}
                >
                  <input
                    ref={pharmcyImageInputref}
                    id="dropzone-file"
                    name="laboratoryLogo"
                    disabled={laboratoryImageUrl !== ""}
                    accept="image/png, image/jpeg, image/jpg"
                    // value={formik.values.laboratoryLogo}
                    onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    onChange={laboratoryImageInputChangeHandler}
                    type="file"
                    className="hidden"
                  />
                  {laboratoryImageUrl !== "" ? (
                    <div className="relative w-24 h-24">
                      <img
                        id="blah"
                        src={laboratoryImageUrl}
                        className="shadow w-full object-fill"
                        alt="your"
                      />
                      <CloseIcon
                        onClick={PharmacyResetImageInput}
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload laboratory logo
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`text-[#d32f2f] my-2 text-sm ml-4 ${
                  formik.errors.laboratoryLogo ? "" : "hidden"
                }`}
              >
                {formik.errors.laboratoryLogo}
              </div>
            </div>

            {/*Pharmacy permit input field*/}
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col justify-center items-center w-full">
                <label
                  className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2
                  ${
                    formik.errors.laboratoryPermit
                      ? "border-[#d32f2f]"
                      : "border-gray-300"
                  }
                  border-dashed cursor-pointer`}
                >
                  <input
                    ref={pharmcyPermitInputref}
                    id="dropzone-file"
                    name="laboratoryPermit"
                    disabled={laboratoryPermitUrl !== ""}
                    accept="image/png, image/jpeg, image/jpg"
                    // value={formik.values.laboratoryPermit}
                    onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    onChange={laboratoryPermitInputChangeHandler}
                    type="file"
                    className="hidden"
                  />
                  {laboratoryPermitUrl !== "" ? (
                    <div className="relative w-24 h-24">
                      <img
                        id="blah"
                        src={laboratoryPermitUrl}
                        className="shadow w-full object-fill"
                        alt="your"
                      />
                      <CloseIcon
                        onClick={PharmacyPermitResetImageInput}
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload laboratory permit document
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`text-[#d32f2f] my-2 text-sm ml-4 ${
                  formik.errors.laboratoryPermit ? "" : "hidden"
                }`}
              >
                {formik.errors.laboratoryPermit}
              </div>
            </div>
          </div>
        </div>

        {/*Contact Details input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Contact Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="phoneNumber"
                name="phoneNumber"
                label="Enter phone number"
                helperText={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                    ? "Phone Number is required"
                    : ""
                }
                value={formik.values.phoneNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="alternatePhoneNumber"
                name="alternatePhoneNumber"
                label="Enter alternate phone number"
                helperText={
                  formik.touched.alternatePhoneNumber &&
                  Boolean(formik.errors.alternatePhoneNumber)
                    ? "Alternate Phone Number is required"
                    : ""
                }
                value={formik.values.alternatePhoneNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.alternatePhoneNumber &&
                  Boolean(formik.errors.alternatePhoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="email"
                label="Enter your email"
                helperText={
                  formik.touched.email && Boolean(formik.errors.email)
                    ? "Email is required"
                    : ""
                }
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3 md:mt-8">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                name="address"
                label="Enter address"
                helperText={
                  formik.touched.address && Boolean(formik.errors.address)
                    ? "Address is required"
                    : ""
                }
                value={formik.values.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
              />
            </div>

            <div className="col-span-6 md:col-span-3 ">
                            {/* <InputLabel>B-</InputLabel> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="blockNumber"
                                name="blockNumber"
                                label="Enter Block Number"
                                helperText={formik.touched.blockNumber && Boolean(formik.errors.blockNumber) ? formik.errors.blockNumber : ''}
                                value={`${formik.values.block || "B-"}${formik.values.blockNumber}`}
                                onBlur={formik.handleBlur}
                                onChange={(e)=>{
                                    const values = e.target.value.split("-");
                                    e.target.value = values[1] || "";
                                    formik.handleChange(e)
                                }}
                                // InputProps={{
                                //     inputComponent: blockElement,
                                // }}
                                error={formik.touched.blockNumber && Boolean(formik.errors.blockNumber)}
                            />
                        </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="country"
                label="Enter Country"
                name="country"
                helperText={
                  formik.touched.country && Boolean(formik.errors.country)
                    ? formik.errors.country
                    : ""
                }
                value={formik.values.country}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                select
              >
                {locatons.country.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="state"
                label="Enter State"
                name="state"
                helperText={
                  formik.touched.state && Boolean(formik.errors.state)
                    ? formik.errors.state
                    : ""
                }
                value={formik.values.state}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                select
              >
                {locatons.state.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="city"
                label="Enter City"
                name="city"
                helperText={
                  formik.touched.city && Boolean(formik.errors.city)
                    ? formik.errors.city
                    : ""
                }
                value={formik.values.city}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                select
              >
                {locatons.city.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>
            {/* <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="city"
                name="city"
                label="Enter City"
                helperText={
                  formik.touched.city && Boolean(formik.errors.city)
                    ? "city is required"
                    : ""
                }
                value={formik.values.city}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="country"
                name="country"
                label="Enter Country"
                helperText={
                  formik.touched.country && Boolean(formik.errors.country)
                    ? "country is required"
                    : ""
                }
                value={formik.values.country}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
              />
            </div> */}
          </div>
        </div>

        {/*Laboratory Location Details Input map field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Laboratory location</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 relative">
              <div className="absolute left-56 top-[10px] z-50">
                <SearchBox setPosition={setPosition} />
              </div>
              <LocationPicker
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "400px" }} />}
                defaultPosition={position}
                onChange={handleLocationChange}
              />
            </div>
          </div>
        </div>

        {/*Laboratory shift Details Input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Shift Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  name="startTime"
                  value={formik.values.startTime}
                  helperText={
                    formik.touched.startTime && Boolean(formik.errors.startTime)
                      ? formik.errors.startTime
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.startTime && Boolean(formik.errors.startTime)
                  }
                  onChange={(value) => {
                    formik.setFieldValue("startTime", Date.parse(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        formik.touched.startTime &&
                        Boolean(formik.errors.startTime)
                          ? "Start Time is required"
                          : ""
                      }
                      value={formik.values.startTime}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.startTime &&
                        Boolean(formik.errors.startTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  value={formik.values.endTime}
                  onChange={(value) => {
                    formik.setFieldValue("endTime", Date.parse(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        formik.touched.endTime && Boolean(formik.errors.endTime)
                          ? "End Time is required"
                          : ""
                      }
                      value={formik.values.endTime}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.endTime && Boolean(formik.errors.endTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-6">
              {workingDays && (
                <Toggledays
                  handleWorkingDays={getWorkingDays}
                  workingDays={workingDays}
                />
              )}
              {workingDaysError && (
                <div className="text-red-500">Working days is required</div>
              )}
            </div>
          </div>
        </div>

        {/*Submit button*/}
        <Button color="primary" variant="contained" fullWidth type="submit">
          {params.id ? "Update" : "Submit"}
        </Button>
        {params.id && (
          <Button
            color="warning"
            variant=""
            fullWidth
            type="submit"
            onClick={() => {
              navigate("/dashboard/laboratory/out-house");
            }}
          >
            {params.id ? "Cancel" : ""}
          </Button>
        )}
      </form>
    </Card>
  );
};



export default Laboratoryouthouseform;

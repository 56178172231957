import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function sendForgotPasswordMail(email) {
    try {
        const {data: response} = await httpservice.post("forgot-password", {
            email,
        });
        return response;
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

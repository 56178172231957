import React, { useContext, useState, useEffect } from 'react';
import { useFormik } from "formik";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { MenuItem } from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { createSpecialitywithDoctor } from "../../../../core/services/Data/speciality"
import { UserContext } from "../../../../App";
import { toast } from "react-toastify";
import { doctors } from "../../../../core/services/Data/doctorsService"
import { getSpecialist } from "../../../../core/services/Data/speciality"
import * as yup from 'yup';
import { getSingleSpecialist } from "../../../../core/services/Data/speciality"

const Specialityassignform = () => {
    const { setShowLoader } = useContext(UserContext);
    const params = useParams()
    const [doctorsData, setDoctorsData] = useState()
    const [formPrefillData, setFormPrefillData] = useState();
    const [specialityData, setSpecialityData] = useState()
    const navigate = useNavigate();
    const getDoctors = () => {
        setShowLoader(true)
        doctors().then((res) => {
            setDoctorsData(res);
            setShowLoader(false)
        })
    }


    const getSpecialistCheck = () => {
        setShowLoader(true)
        getSpecialist().then((res) => {
            setSpecialityData(res.data)
        })
    }

    const validateAssign = yup.object({
        specialityName: yup.string("").required("Speciality Name is required"),
        doctorName: yup.string("").required("doctorName is required")
    })
    const assignSpecialityWithDoctor = useFormik({
        initialValues: {
            specialityName: formPrefillData ? formPrefillData.specialityName : '',
            doctorName: ''
        },
        enableReinitialize: true,
        validationSchema: validateAssign,
        onSubmit: (values) => {
            setShowLoader((prevState) => {
                return true
            });
            const payload = {
                specialityId:values.specialityName,
                doctorId:values.doctorName
            }

            createSpecialitywithDoctor(payload).then((res) => {
                toast.success(res.message)
                navigate('/dashboard/speciality');
                setShowLoader(false);
            })
        }

    });
    useEffect(() => {
        getDoctors()
        getSpecialistCheck()
        if (params.id) {
            setShowLoader(true)
            getSingleSpecialist(params.id).then((res) => {
                setShowLoader(false)
                setFormPrefillData(res.data);
            })
        }
    }, [])
    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">{params.id ? "Update" : "Add"} Speciality</h1>
            <form onSubmit={assignSpecialityWithDoctor.handleSubmit}>
                <TextField
                    fullWidth
                    id="specialityName"
                    label="Speciality Name"
                    name="specialityName"
                    select
                    value={assignSpecialityWithDoctor.values.insurance}
                    onChange={assignSpecialityWithDoctor.handleChange}
                    onBlur={assignSpecialityWithDoctor.handleBlur}
                    helperText={assignSpecialityWithDoctor.touched.specialityName && Boolean(assignSpecialityWithDoctor.errors.specialityName) ? 'Speciality name is required' : ''}
                    error={assignSpecialityWithDoctor.touched.specialityName && Boolean(assignSpecialityWithDoctor.errors.specialityName)}>
                    {specialityData && specialityData.map((ele) => {
                        return (

                            <MenuItem value={ele._id}>{ele.specialityName}</MenuItem>
                        )

                    })}

                </TextField>
                <div className="my-5">
                    <TextField
                        fullWidth
                        id="doctorName"
                        label="Doctor name"
                        name="doctorName"
                        select
                        value={assignSpecialityWithDoctor.values.doctorName}
                        onChange={assignSpecialityWithDoctor.handleChange}
                        onBlur={assignSpecialityWithDoctor.handleBlur}
                        helperText={assignSpecialityWithDoctor.touched.doctorName && Boolean(assignSpecialityWithDoctor.errors.doctorName) ? 'Doctor name is required' : ''}
                        error={assignSpecialityWithDoctor.touched.doctorName && Boolean(assignSpecialityWithDoctor.errors.doctorName)}>
                        {doctorsData && doctorsData.map((ele) => {
                            return (

                                <MenuItem value={ele._id}>{ele.name.first} {ele.name.last}</MenuItem>
                            )

                        })}

                    </TextField>
                </div>
                <Filledbluebutton cname="w-full" btnType="submit" >{params.id ? "Update" : "Add"}</Filledbluebutton>
            </form>
        </Card>
    );
};

export default Specialityassignform;

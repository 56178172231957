import {hospitalService} from "./hospitalService";

const getHospitalsList = async () => {
    const result = await hospitalService();
    console.log({result})
    const hospitalsListData = result.data.map((ele) => {
        return {
            hospital: ele.name,
            branch: ele.branchs,
            id: ele._id,
            city:ele.city,
            country:ele.country,
            state:ele.state,
            address:ele.address
        }
    });
    return hospitalsListData
}

export default {
    getHospitalsList
}

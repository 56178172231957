import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Laboratoryinhouse from "./Laboratoryinhouse/Laboratoryinhouse";
import Laboratoryouthouse from "./Laboratoryouthouse/Laboratoryouthouse";
import Laboratoryinhouseform from "./Laboratoryinhouse/Laboratoryinhouseform/Laboratoryinhouseform";
import Laboratoryouthouseform from "./Laboratoryouthouse/Laboratoryouthouseform/Laboratoryouthouseform";
import LabTestForm from "./Laboratoryinhouse/addLabTest/labtest";

const Laboratory = () => {
  return (
    <Routes>
      <Route path="/in-house" element={<Laboratoryinhouse />} />
      <Route path="/in-house/test/add" element={<LabTestForm />} />
      <Route path="/in-house/add-form" element={<Laboratoryinhouseform />} />
      <Route
        path="/in-house/add-form/:id"
        element={<Laboratoryinhouseform />}
      />
      <Route path="/out-house" element={<Laboratoryouthouse />} />
      <Route path="/out-house/add-form" element={<Laboratoryouthouseform />} />
      <Route
        path="/out-house/add-form/:id"
        element={<Laboratoryouthouseform />}
      />
      <Route
        path=""
        element={<Navigate to="/dashboard/laboratory/in-house" />}
      />
      <Route
        path="*"
        element={<Navigate to="/dashboard/laboratory/in-house" />}
      />
    </Routes>
  );
};

export default Laboratory;

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function hospitalService() {
    try {
        const {data: response} = await httpservice.get('hospitals');
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function getHospitalById(id) {
    try {
        const {data: response} = await httpservice.get('hospitals/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function getHospitalByIdWithServices(id) {
    try {
        const {data: response} = await httpservice.get('hospitals/app/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function deleteHospitalById(id) {
    try {
        const {data: response} = await httpservice.delete('hospitals/delete/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function allHospitalAdmin() {
    try {
        const {data: response} = await httpservice.get('hospitals/users/all');
        return response
    } catch
        (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function deleteallHospitalAdmin(id){
    try {
       const {data : response} = await httpservice.delete('hospitals/users/delete/' +id) 
       return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}

import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import SearchIcon from '@mui/icons-material/Search';
export default class SearchBox extends React.Component {

  static propTypes = {
    placeholder: PropTypes.string,
    onPlacesChanged: PropTypes.func
  }
  render() {
    
    return <div className='bg-white  rounded-lg flex pl-4 justify-center items-center'><SearchIcon className='' /><input className='p-2 px-3 rounded-lg' onKeyDown={e=>{
      console.log(e)
      if(e.key === "Enter"){
        e.preventDefault();
        e.stopPropagation()
      }
     
    }} ref="input" placeholder={this.props.placeholder} type="search"/></div>
  }
  onPlacesChanged = () => {
    if (this.props.onPlacesChanged) {
      this.props.onPlacesChanged(this.searchBox.getPlaces());
    }
    const lat = this.searchBox.getPlaces()[0].geometry.location.lat();
    const lng = this.searchBox.getPlaces()[0].geometry.location.lng();
    this.props.setPosition({lat,lng})
  }
  componentDidMount() { 
    var input = ReactDOM.findDOMNode(this.refs.input);
    // eslint-disable-next-line no-undef
    this.searchBox = new window.google.maps.places.SearchBox(input);
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
  }
  componentWillUnmount() {
    // this.searchBox.removeListener('places_changed', this.onPlacesChanged);
  }

}

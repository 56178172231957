import React, {useEffect,useState,useRef,useCallback} from 'react';
import Filterbutton from "../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../shared/components/Card/Card";
import Customtable from "../../../shared/components/Customtable/Customtable";
import EditButton from "../../../shared/components/EditButton/EditButton";
import {subscribersDetailsTableData} from "../../../core/constants/appconstants";
import Outlinedbluebutton from "../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import {getSubscription} from "../../../core/services/Data/subscriptionService";
import { AgGridReact } from "ag-grid-react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const Subscription = () => {
    const subscriptionDetailsData = subscribersDetailsTableData;
    const [subscriptionTable, setSubscriptionTable] = useState()
    const [subscription, setSubscription] = useState()
    const gridRef = useRef(null);
    const subscriptionDetails = ()=>{
        getSubscription().then((res)=>{
            // console.log(
            //     "aara hai kya -->",res.data
            // );
            setSubscription(res.data)
            setSubscriptionTable(res.data.subscriber);
        })
    }
    const editButton = (props) => {
        return (
          <ModeEditIcon
            className={`text-[#0098C4] border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`}
            onClick={() => {
              props.editClicked(props.data._id);
            }}
          />
        );
      };

    const [columnDefs] = useState([
   
        { headerName: "Subscriber Name", field: "name", resizable: true, }, 
        { headerName: "Subscribed Plan", field: "subscriptions.name", resizable: true, }, 
        { headerName: "Plan Start Date", field: "subscriptions.startDate", resizable: true, valueGetter: (e) => {
            const date = new Date(e.data.subscriptions.startDate);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })}}, 
        { headerName: "Plan End Date", field: "subscriptions.expiryDate", resizable: true, valueGetter: (e) => {
            const date = new Date(e.data.subscriptions.expiryDate);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })}}, 
        
    
        // {
        //   headerName: "Action",
        //   cellRenderer: editButton,
        //   sortable: false, 
        //   filter: false,
        //   resizable: true,
        //   cellRendererParams: {
        //     data: gridRef && gridRef,  
           
        //     editClicked: (id) => {
        //     //   handleOpen();
        //     //   setIdToDelete((prevState) => {
        //     //     return id;
        //     //   });
        //       // console.log('id', id)
        //     },
        //   },
        // },
        // { headerName: "Description", field: "description", resizable: true },
      ])

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
      };
    console.log("Akash is Here", subscriptionTable?.subscriber);
    useEffect(()=>{
        subscriptionDetails()
    },[])
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
      }, []);

    return (
        <>
            <div className="flex justify-between mb-4">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Subscription</h1>
                </div>
                {/* <div className="order-last">
                    <Filterbutton text="Filter by plans"/>
                </div> */}
            </div>
            <Card>
                <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10">
                    <div
                        className="col-span-12 md:col-span-4 shadow pt-12 flex flex-col bg-orange-400 rounded-xl text-white p-3 px-10">
                        <h1 className="text-4xl font-bold">Silver plan</h1>
                        <p className="text-xl">{subscription ? subscription.silverUser : null} users</p>
                    </div>
                    <div
                        className="col-span-12 md:col-span-4 shadow pt-12 flex flex-col bg-green-400 rounded-xl text-white p-3 px-10">
                        <h1 className="text-4xl font-bold">Gold plan</h1>
                        <p className="text-xl">{subscription ? subscription.goldUser : null} users</p>
                    </div>
                    <div
                        className="col-span-12 md:col-span-4 shadow pt-12 flex flex-col bg-teal-400 rounded-xl text-white p-3 px-10">
                        <h1 className="text-4xl font-bold">Platinum plan</h1>
                        <p className="text-xl">{subscription ? subscription.platinumUser : null} users</p>
                    </div>
                </div>
                <div className="my-16">
                    <div className="my-4 flex justify-between">
                        <div className="order-first">
                            <h1 className="text-xl font-semibold">Subscribers</h1>
                        </div>
                        <div className="order-last">
                            <Outlinedbluebutton download={onBtnExport} />
                        </div>
                    </div>
                    <div className="mt-5">
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={subscriptionTable} 
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowSelection="multiple"
            ></AgGridReact>
          </div>
        </div>
                    {/* <Customtable
                        heading={['Subscriber Name', 'Subscribed Plan', 'Plan Start Date', 'Plan End Date', 'Action']}>
                        {subscriptionTable &&  subscriptionTable.subscriber.map((ele) => {
                            return (
                                <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.subscriptions.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.subscriptions.startDate.split("T")[0]}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.subscriptions.expiryDate.split("T")[0]}</td>
                                    <td>
                                       <div><EditButton/></div>
                                    </td>
                                </tr>
                            )
                        })}
                    </Customtable> */}
                </div>
            </Card>
        </>
    );
};

export default Subscription;

export const sideNavList = [
    'Dashboard'
]


/**
 * Requests dummy data
 */

export const requestsReceived = [
    {
        id: '1',
        imgLink: 'https://i.postimg.cc/nzQpNyFJ/max-Hospital.png',
        hospitalName: 'Max Hospital',
        requestedAt: '2:38 PM',
        status: 'PENDING',
        bannerLink: 'https://i.postimg.cc/vmHWtkst/max-banner.png',
        contactDetails: {
            phone: '9876543211',
            address: 'H- 12 , Global hospital , saket -110021',
            city: 'noida',
            state: 'UP',
            country: 'India',
            workingDays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            workingHours: '24hrs'
        },
        activePlanDetails: {
            subscriptionName: 'platinum',
            startDate: '10 JAN 2021',
            endDate: '10 JAN 2023'
        }
    },
    {
        id: '2',
        imgLink: 'https://i.postimg.cc/HnM5sWms/pathlab.png',
        hospitalName: 'Lal path labs',
        requestedAt: '2:38 PM',
        status: 'PENDING',
        bannerLink: 'https://i.postimg.cc/vmHWtkst/max-banner.png',
        contactDetails: {
            phone: '9876543211',
            address: 'H- 12 , Global hospital , saket -110021',
            city: 'noida',
            state: 'UP',
            country: 'India',
            workingDays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            workingHours: '24hrs'
        },
        activePlanDetails: {
            subscriptionName: 'platinum',
            startDate: '10 JAN 2021',
            endDate: '10 JAN 2023'
        }
    }
]

/**
 * Hospitals page dummy data
 */

export const hospitalDetailsTableData = [
    {
        id: '1',
        hospital: 'Medanta',
        branch: 'gurgaon',
        subscriptionType: 'platinum',
        sponsored: 'Yes',
        startDate: '22 July 2021',
        expiryDate: '22 July 2023',
        city: 'Gurgaon',
        state: 'Haryana',
        email: 'medanta@medanta.co.in',
        phone: '9876543211',
        description: `
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!`,
        treatments: [
            {
                url: 'https://i.postimg.cc/xdBsmVzj/Frame-1000004535.png',
                name: 'Allergy'
            },
            {
                url: 'https://i.postimg.cc/W3r56YGG/Frame-1000004536.png',
                name: 'Coronavirus'
            },
            {
                url: 'https://i.postimg.cc/GtCqMPKZ/Frame-1000004537.png',
                name: 'Anemia'
            },
            {
                url: 'https://i.postimg.cc/RC7PW8V9/Frame-1000004538.png',
                name: 'ALS'
            },
            {
                url: 'https://i.postimg.cc/NFkbZyPL/Frame-1000004539.png',
                name: 'Asthma'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
            {
                url: 'https://i.postimg.cc/GtCqMPKZ/Frame-1000004537.png',
                name: 'Anemia'
            },
            {
                url: 'https://i.postimg.cc/RC7PW8V9/Frame-1000004538.png',
                name: 'ALS'
            },
            {
                url: 'https://i.postimg.cc/NFkbZyPL/Frame-1000004539.png',
                name: 'Asthma'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
        ]
    },
    {
        id: '2',
        hospital: 'Fortis',
        branch: 'gurgaon',
        subscriptionType: 'platinum',
        sponsored: 'Yes',
        startDate: '22 July 2021',
        expiryDate: '22 July 2023',
        city: 'Gurgaon',
        state: 'Haryana',
        email: 'fotis@fortis.com',
        phone: '9876543211',
        description: `
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!`,
        treatments: [
            {
                url: 'https://i.postimg.cc/xdBsmVzj/Frame-1000004535.png',
                name: 'Allergy'
            },
            {
                url: 'https://i.postimg.cc/W3r56YGG/Frame-1000004536.png',
                name: 'Coronavirus'
            },
            {
                url: 'https://i.postimg.cc/GtCqMPKZ/Frame-1000004537.png',
                name: 'Anemia'
            },
            {
                url: 'https://i.postimg.cc/RC7PW8V9/Frame-1000004538.png',
                name: 'ALS'
            },
            {
                url: 'https://i.postimg.cc/NFkbZyPL/Frame-1000004539.png',
                name: 'Asthma'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
            {
                url: 'https://i.postimg.cc/GtCqMPKZ/Frame-1000004537.png',
                name: 'Anemia'
            },
            {
                url: 'https://i.postimg.cc/RC7PW8V9/Frame-1000004538.png',
                name: 'ALS'
            },
            {
                url: 'https://i.postimg.cc/NFkbZyPL/Frame-1000004539.png',
                name: 'Asthma'
            },
            {
                url: 'https://i.postimg.cc/RVGXRHHD/Frame-1000004540.png',
                name: 'Stroke'
            },
        ]
    }
]

/**
 * Pharmacy page dummy data
 */
export const pharmacyDetailsTableData = [
    {
        id: '1',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        chemist: 'Kicker Nicil',
        email: 'demo@email.com',
        password: 'admin@123',
        branch: 'New York'
    },
    {
        id: '2',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        chemist: 'Kicker Nicil',
        email: 'demo@email.com',
        password: 'admin@123',
        branch: 'New York'
    },
    {
        id: '3',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        chemist: 'Kicker Nicil',
        email: 'demo@email.com',
        password: 'admin@123',
        branch: 'New York'
    }
]

export const pharmacyOutHouseDetailsTableData = [
    {
        id: '1',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        chemist: 'Kicker Nicil',
        company: 'Medica',
        hospital: 'fortis',
        branch: 'New York',
        sponsored: 'yes',
    },
    {
        id: '2',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        chemist: 'Kicker Nicil',
        company: 'Medica',
        hospital: 'fortis',
        branch: 'New York',
        sponsored: 'yes',
    },
    {
        id: '3',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        chemist: 'Kicker Nicil',
        company: 'Medica',
        hospital: 'fortis',
        branch: 'New York',
        sponsored: 'yes',
    }
]

/**
 * Medicines dummy data
 */
export const medicineDetailsTableData = [
    {
        id: '1',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        medicineName: 'Paracetamol',
        company: 'tata'
    },
    {
        id: '2',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        medicineName: 'Paracetamol',
        company: 'tata'
    },
    {
        id: '3',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        medicineName: 'Paracetamol',
        company: 'tata'
    }
]

/**
 * Coupons dummy data
 */
export const couponsDetailsTableData = [
    {
        id: '1',
        code: 'NEW501',
        description: '60% off on fee for new user',
        timesUsed: '615',
        expire: '32 July 2023',
        promote: 'promoted'
    },
    {
        id: '2',
        code: 'NEW501',
        description: '60% off on fee for new user',
        timesUsed: '615',
        expire: '32 July 2023',
        promote: 'promoted'
    },
    {
        id: '3',
        code: 'NEW501',
        description: '60% off on fee for new user',
        timesUsed: '615',
        expire: '32 July 2023',
        promote: 'promoted'
    }
]

/**
 * Leaves page dummy data
 */
export const leavesDetailsTableData = [
    {
        id: '1',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        employeeName: 'John carter',
        hospitalName: 'Fortis',
        branchName: 'paris',
        fromDate: '22 July 2022',
        toDate: '5 August 2023',
        leaveType: 'Sick',
        status: 'approved'
    },
    {
        id: '2',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        employeeName: 'John carter',
        hospitalName: 'Fortis',
        branchName: 'paris',
        fromDate: '22 July 2022',
        toDate: '5 August 2023',
        leaveType: 'Sick',
        status: 'approved'
    },
    {
        id: '3',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        employeeName: 'John carter',
        hospitalName: 'Fortis',
        branchName: 'paris',
        fromDate: '22 July 2022',
        toDate: '5 August 2023',
        leaveType: 'Sick',
        status: 'approved'
    }
]

/**
 * Laboratory outHouse dummy data
 */
export const laboratoryOutHouseDetailsTableData = [
    {
        id: '1',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        labName: 'xyx',
        hospital: 'medanta',
        branch: 'noida',
        sponsored: 'Yes'
    },
    {
        id: '2',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        labName: 'xyx',
        hospital: 'medanta',
        branch: 'noida',
        sponsored: 'Yes'
    }
]

/**
 * Blog posts dummy data
 */
export const blogDetailsTableData = [
    {
        id: '1',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        title: 'Angular components',
        author: 'John wick',
        commentsCount: 15,
        postDate: '22 July 2021',
        description: `
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!`,
    },
    {
        id: '2',
        imageUrl: 'https://i.postimg.cc/Kj4kTKmZ/table-img.png',
        title: 'Angular environments and environment variables',
        author: 'Devanshu verma',
        commentsCount: 15,
        postDate: '22 July 2021',
        description: `
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque debitis error iste nemo neque
                        reiciendis voluptas voluptatibus. Aliquid, beatae dicta, est impedit magnam modi, nihil nisi
                        obcaecati quidem quos sit!`,
    }
]

/**
 * Promotions - Promotion table dummy data
 */
export const promotionDetailsTableData = [
    {
        rank: 1,
        category: 'lab',
        entityName: 'Dhruv lab',
        whatsappNo: '+919876543211',
        description: 'lorem ipsum ....',
        promote: 'promoted',
        status: false,
        startDate: '12 July 2021',
        endDate: '23 August 2021'
    },
    {
        rank: 1,
        category: 'lab',
        entityName: 'Dhruv lab',
        whatsappNo: '+919876543211',
        description: 'lorem ipsum ....',
        promote: 'promoted',
        status: true,
        startDate: '12 July 2021',
        endDate: '23 August 2021'
    }
]

/**
 * Location page dummy data
 */
export const locationDetailsTableData = [
    {
        state: 'haryana',
        city: 'Gurgaon',
        country: 'India'
    },
    {
        state: 'haryana',
        city: 'Gurgaon',
        country: 'India'
    }
]

/**
 * Insurance page dummy data
 */
export const InsuranceDetailsTableData = [
    {
        name: 'ICICI Life Insurance'
    },
    {
        name: 'ICICI Life Insurance'
    }
]

export const InsuranceDetailsWithDoctor = [
    {
        name: 'Group health insurance',
        doctors: [
            'https://i.postimg.cc/9XBsvjVV/doctor1.png',
            'https://i.postimg.cc/9XBsvjVV/doctor1.png',
            'https://i.postimg.cc/9XBsvjVV/doctor1.png',
            'https://i.postimg.cc/9XBsvjVV/doctor1.png'
        ]
    },
    {
        name: 'Critical illness insurance',
        doctors: [
            'https://i.postimg.cc/9XBsvjVV/doctor1.png',
            'https://i.postimg.cc/9XBsvjVV/doctor1.png',
            'https://i.postimg.cc/9XBsvjVV/doctor1.png',
            'https://i.postimg.cc/9XBsvjVV/doctor1.png'
        ]
    }
]

// Dummy super admin credential
export const credentials = {
    email: 'admin@admin.com',
    password: 'admin'
}

export const subscribersDetailsTableData = [
    {
        id: 1,
        subscriberName: 'Alen Mark',
        subscribedPlan: 'Gold Plan',
        startDate: '22 Jan 2021',
        endDate: '22 Jan 2022'
    },
    {
        id: 2,
        subscriberName: 'Alen Mark',
        subscribedPlan: 'Gold Plan',
        startDate: '22 Jan 2021',
        endDate: '22 Jan 2022'
    },
    {
        id: 3,
        subscriberName: 'Alen Mark',
        subscribedPlan: 'Gold Plan',
        startDate: '22 Jan 2021',
        endDate: '22 Jan 2022'
    }
]

/**
 * Sponsors page dummy data
 */
export const SponsorsDetailsTableData = [
    {
        entityName: 'Laboratory',
        name: 'ICICI Life Insurance'
    },
    {
        entityName: 'Laboratory',
        name: 'ICICI Life Insurance'
    }
]


/**
 * Holidays page page dummy data
 */
export const holidaysDetailsTableData = [
    {
        date: '22 Aug 2022',
        holidayName: 'Gandhi Jayanti',
        day: 'Monday'
    },
    {
        date: '22 Aug 2022',
        holidayName: 'Gandhi Jayanti',
        day: 'Monday'
    }
]

/**
 * Regex for validations
 */
export const phoneNumberRegex = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$';

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Receptionisthome from "./Receptionisthome/Receptionisthome";
import Receptionistaddform from "./Receptionistaddform/Receptionistaddform";

const Receptionist = () => {
    return (
        <Routes>
            <Route path="" element={<Receptionisthome/>}/>
            <Route path="/registration" element={<Receptionistaddform/>}/>
            <Route path="/registration/:id" element={<Receptionistaddform/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

export default Receptionist;

import React, {useRef,useContext, useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {couponsDetailsTableData} from "../../../core/constants/appconstants";
import Card from "../../../shared/components/Card/Card";
import EditButton from "../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../shared/components/Deletebutton/Deletebutton";
import Couponform from "./Couponform/Couponform";
import {AgGridReact} from "ag-grid-react";
import {UserContext} from "../../../App";
import Editdeletebutton from "../../../shared/components/Editdeletebutton/Editdeletebutton";
import {getCouponsData, coupDelete, updateStatusCoupon, getAllCoupons} from "../../../core/services/Data/couponService";
import BasicSwitches from "../../../shared/components/statusButton/StatusButton";
import Deletepopup from "../../../shared/components/Deletepopup/Deletepopup";
import {toast} from "react-toastify";

const Coupons = () => {
    const navigate = useNavigate();
    const couponTableData = couponsDetailsTableData;
    const gridRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const {setShowLoader} = useContext(UserContext);
    const [idToDelete, setIdToDelete] = useState('');
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [columnDefs] = useState([
        {headerName: "CODE", field: "code"},
        {headerName: "Description", field: "description"},
        {headerName: "Times Used", field: "totalApplied"},
        {headerName: "Expires", field: "expires", valueGetter: (e) => {
            const date = new Date(e.data.expires);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })
        }
        },
        
        {
            headerName: 'Action', cellRenderer: Deletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
               
                deleteClicked: (id) => {
                    handleOpen();
                    
                    setIdToDelete((prevState) => {
                        return id
                    })
                }
            }
        },
        {headerName: "Status", cellRenderer: BasicSwitches,sortable: false, filter: false , cellRendererParams: {
            data: gridRef && gridRef,
            statusChanged: (data) => {
                // navigate('/dashboard/receptionist/registration/' + id);
            //   console.log("id", data._id, data.status );
            setShowLoader(true)
            const payload = {
                status : !data.status
            }
            updateStatusCoupon(data._id, payload).then((res)=>{
                toast.success("Status Updated Successfully")
                setShowLoader(false)
            })
           
            }
        }},
    ]);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    const getAllCouponsData=()=>{
    getAllCoupons().then((res)=>{
            setRowData(res);
        })
    }
    
const deleteCoupons=()=>{
    coupDelete(idToDelete).then((res)=>{
        if(res){
            toast.success(res.message)
            getAllCouponsData()
        }
    })
}

    useEffect(()=>{
        getAllCouponsData()
    },[])

    return (
        <>
            <div className="flex justify-between mb-3">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Ads data</h1>
                </div>
                <div className="order-last">
                    {/* <button type="button"
                            className="inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by date
                    </button>
                    <button type="button"
                            className="mx-4 inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by category
                    </button>
                    <button type="button"
                            className="inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by rank
                    </button> */}
                </div>
            </div>
            <div className="mt-10">
                <Card>
                <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteCoupons}/>
                    <div className="mt-5">

                    <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                    <AgGridReact 
                        ref={gridRef}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowSelection="multiple">
                    </AgGridReact>
                </div>


                        {/* <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full text-center">
                                            <thead className="border-b bg-gray-50 font-bold">
                                            <tr>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    CODE
                                                </th>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    Description
                                                </th>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    Times Used
                                                </th>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    Expires
                                                </th>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    Actions
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                                    promote
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {couponTableData.map((ele) => {
                                                return (
                                                    <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.code}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.description}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.timesUsed}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.expire}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                                            <EditButton/>
                                                            <Deletebutton cname="ml-3"/>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.promote}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Card>
                <div className="mt-10">
                    <Couponform/>
                </div>
            </div>
        </>
    );
};

export default Coupons;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import Toggledays from "../../../../shared/components/Toggledays/Toggledays";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import LocationPicker from "react-location-picker";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
import { toast } from "react-toastify";
import commonAPICallService from "../../../../core/services/Data/commonAPICallService";
import { branchRegistrationValidationSchema } from "../../../../core/Validationschemas/branchRegistrationValidation";
import {
  branchRegistration,
  branchUpdate,
  getBranchById,
} from "../../../../core/services/Data/branchService";
import { UserContext } from "../../../../App";
import { getHospitalById } from "../../../../core/services/Data/hospitalService";
import LocationService from "../../../../core/services/Data/LocationService";
import { getSpecialist } from "../../../../core/services/Data/speciality";
import SearchBox from "./googleSearch";

const Branchesregister = () => {
  const { userDetails } = useContext(UserContext);
  const [currentUsersHospital, setCurrentUsersHospital] = useState();
  const [inputValue, setInputValue] = useState("");

  const params = useParams();
  const [formPrefillData, setFormPrefillData] = useState();
  const [workingDays, setWorkingDays] = useState(!params.id && []);
  const [hospitalList, setHospitalsList] = useState(null);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  const [speciality, setSpeciality] = useState([])

  const defaultPosition = {
    lat: 29.378586,
    lng: 47.990341
  };
  const { setShowLoader } = useContext(UserContext);
  const navigate = useNavigate();
  const branchPermitInputref = useRef();
  const [branchPermitUrl, setBranchPermitUrl] = useState("");
  const [position, setPosition] = useState({ ...defaultPosition })
  useEffect(() => {
    const currentLocation = async () => {
      await navigator.geolocation.getCurrentPosition(
        position => setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }),
        err => console.log(err)
      );
      // console.log(this.state.latitude)
    }
    currentLocation()
  }, [])

  const branchRegistrationForm = useFormik({
    initialValues: {
      hospitalName: formPrefillData
        ? formPrefillData.hospital.hospitalName
        : userDetails.role === "Hospital"
          ? currentUsersHospital
          : "",
      branchName: formPrefillData ? formPrefillData.name : "",
      services: formPrefillData?.services ? formPrefillData.services : [],
      speciality: formPrefillData?.speciality ? formPrefillData.speciality : [],
      phoneNumber: formPrefillData ? formPrefillData.phone : "",
      alternatePhoneNumber: formPrefillData
        ? formPrefillData.alternatePhone
        : "",
      email: formPrefillData ? formPrefillData.email : "",
      address: formPrefillData ? formPrefillData.address : "",
      city: formPrefillData ? formPrefillData.city : "",
      state: formPrefillData ? formPrefillData.state : '',
      block:formPrefillData ? formPrefillData?.addressBlock?.block || "" : 'B-',
      blockNumber:formPrefillData ? formPrefillData?.addressBlock?.number || "" : '',
      country: formPrefillData ? formPrefillData.country : "",
      subscriptionPlans: formPrefillData
        ? formPrefillData.subscription.name
        : "",
      startDate: formPrefillData
        ? new Date(formPrefillData.subscription.startDate)
        : null,
      expiryDate: formPrefillData
        ? new Date(formPrefillData.subscription.expiryDate)
        : null,
      branchPermit: formPrefillData ? formPrefillData.branchPermit : "",
      startTime: formPrefillData
        ? new Date(formPrefillData.shift.startTime)
        : null,
      endTime: formPrefillData ? new Date(formPrefillData.shift.endTime) : null,
    },
    validationSchema: () =>
      branchRegistrationValidationSchema(userDetails.role),
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("working days length", workingDays);
      console.log("values", values);
      setShowLoader((prevState) => {
        return true;
      });
      if (!workingDaysError) {
        Promise.all([
          new Promise((resolve, reject) => {
            if (typeof values.hospitalPermit !== "string") {
              const response = uploadFileService.fileUpload(
                values.branchPermit
              );
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            } else {
              resolve();
            }
          }),
        ]).then(async ([res1]) => {
          console.log('first', { params })
          if (params.id) {
            const payload = {
              ...values,
              branchPermit: res1 ? res1.fileUrl : values.branchPermit,
              workingDays: workingDays,
              addressBlock:{
                block:"B-",
                number:values.blockNumber
            },
              location: {
                coordinates: [position.lat, position.lng]
              }
            };
            console.log("first", payload);
            branchUpdate(payload, params.id).then((res) => {
              if (res) {
                console.log("response of branch registration is", res);
                navigate("/dashboard/branches");

                toast.success("Branch details updated successfully");
              } else {
                setShowLoader((prevState) => {
                  return false;
                });
              }
            });
          } else {
            const payload = {
              ...values,
              branchPermit: res1.fileUrl,
              workingDays: workingDays,
              addressBlock:{
                block:"B-",
                number:values.blockNumber
            },
              location: {
                coordinates: [position.lat, position.lng]
              }
            };
            console.log("branch permit payload", payload);
            branchRegistration(payload).then((res) => {
              if (res) {
                console.log("response of branch registration is", res);
                navigate("/dashboard/branches");
                toast.success("Branch registered successfully and mail sent");
              } else {
                setShowLoader((prevState) => {
                  return false;
                });
              }
            });
          }
        });
      } else {
        setShowLoader((prevState) => {
          return false;
        });
      }
    },
  });
  console.log("Hospital Name ---====-=-=-=-=-==->", currentUsersHospital);

  const branchPermitInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file })
      if (file.size > 1024000) {
        branchRegistrationForm.setErrors({
          branchPermit: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        branchRegistrationForm.setFieldValue(
          "branchPermit",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setBranchPermitUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }

    } else {
      branchRegistrationForm.setErrors({
        branchPermit: "Branch Permit is required",
      });
    }
  };

  const HospitalResetPermitInput = () => {
    branchRegistrationForm.setFieldValue("branchPermit", "");
    setBranchPermitUrl((prevState) => {
      return "";
    });
  };

  const handleLocationChange = ({ position, address, places }) => {
    setPosition({
      lat: position.lat,
      lng: position.lng,
    });
  };

  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };
  const [locatons, setLocations] = useState({
    country: [],
    city: [],
    state: [],
  });
  useEffect(() => {
    getSpecialist().then(res => {
      console.log({ res })
      setSpeciality(res.data)
    })
  }, [])
  function handleChange(event) {
    const trimmedValue = event.target.value.trim();

    if (trimmedValue) {
      const newSelectedItem = [...branchRegistrationForm.values.services.selectedItem];
      const values = trimmedValue.split(" ");

      values.forEach((value) => {
        if (newSelectedItem.indexOf(value) === -1) {
          newSelectedItem.push(value);
        }
      });

      branchRegistrationForm.setFieldValue("services", newSelectedItem);
    }

    setInputValue("");
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = branchRegistrationForm.values.services.filter((service) => service !== item);
    branchRegistrationForm.setFieldValue("services", newSelectedItem);
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }
  function handleKeyDown(event) {
    // ;
    // event.stopPropagation()
    const { setFieldValue } = branchRegistrationForm
    if (event.key === "Enter") {
      event.preventDefault();
      const newSelectedItem = [...branchRegistrationForm.values.services];
      const trimmedValue = event.target.value.trim();

      if (trimmedValue && newSelectedItem.indexOf(trimmedValue) === -1) {
        newSelectedItem.push(trimmedValue);
      }

      setFieldValue("services", newSelectedItem);
      setInputValue("");
    }

    if (
      branchRegistrationForm.values.services.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      const newSelectedItem = branchRegistrationForm.values.services.slice(0, branchRegistrationForm.values.services.length - 1);
      setFieldValue("services", newSelectedItem);
    }
  }
  useEffect(() => {
    const apiData = async () => {
      const { data } = await LocationService.getLocations(branchRegistrationForm.values.country, branchRegistrationForm.values.state);
      // console.log({data})
      let cities = [...data[0].city];
      let states = [...data[0].state];
      let country = [...data[0].country];
      // for await (let element of data) {
      //   if (!cities.includes(element.city)) {
      //     cities.push(element.city);
      //   }
      //   if (!states.includes(element.state)) {
      //     states.push(element.state);
      //   }
      //   if (!country.includes(element.country)) {
      //     country.push(element.country);
      //   }
      // }
      const locations = { ...locatons };
      locations.city = cities;
      locations.state = states;
      locations.country = country;
      setLocations(locations);
    };
    apiData();
  }, [branchRegistrationForm.values.country, branchRegistrationForm.values.state]);
  useEffect(() => {
    setShowLoader(true);
    if (userDetails.role === "Hospital") {
      console.log("role is hospital", userDetails.entityId);
      getHospitalById(userDetails.entityId).then(async (res) => {
        // console.log('res', res.data.name);
        await setCurrentUsersHospital(res.data.name);
        console.log(currentUsersHospital, "currentUserHospital");
        setShowLoader(false);
      });
    }
    if (userDetails.role === "SuperAdmin") {
      commonAPICallService.getHospitalsList().then(async (res) => {
        if (res) {
          console.log("res from hospital list", res);
          await setHospitalsList(res);
        }
      });
    }
    if (params.id) {
      setShowLoader(true);
      getBranchById(params.id).then((res) => {
        console.log("res by id ===>", res);
        setBranchPermitUrl((prevState) => {
          return res.data.branchPermit;
        });
        setWorkingDays((prevState) => {
          console.log("res.data.workingDays", res.data.workingDays);
          return res.data.workingDays;
        });
        setFormPrefillData((prevState) => {
          setShowLoader(false);
          return res.data;
        });
        if (res.data.location?.coordinates) {
          setPosition((prevState) => {
            return { lng: res?.data?.location?.coordinates[1], lat: res?.data?.location?.coordinates[0] }
          })
        }
      });
    } else {
      setShowLoader(false);
    }
  }, []);

  return (
    <Card cname="px-10">
      <div className="my-10">
        <h1 className="text-3xl font-bold">
          {params.id ? "Update" : "Register"}
        </h1>
        <p className="text-xl font-semibold text-gray-500 mt-2">
          You can {params.id ? "update" : "register"} hospitals, branches,
          clinics
        </p>
      </div>
      <form
        onSubmit={branchRegistrationForm.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        {/*Registration Type input field*/}
        <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
          <TextField
            fullWidth
            id="registerType"
            label="Branch"
            name="registerType"
            value="Branch"
            disabled
            select
          >
            <MenuItem value="Branch">Branch</MenuItem>
          </TextField>
        </div>

        {/*Basic Details Input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Basic Details</h1>
          {/*Hospital name input field*/}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div
              className={`col-span-12 md:col-span-6 ${userDetails.role === "Hospital" ? "hidden" : ""
                }`}
            >
              <TextField
                fullWidth
                id="hospitalName"
                label="Hospital name"
                name="hospitalName"
                select
                disabled={userDetails.role === "Hospital"}
                helperText={
                  branchRegistrationForm.touched.hospitalName &&
                    Boolean(branchRegistrationForm.errors.hospitalName)
                    ? branchRegistrationForm.errors.hospitalName
                    : ""
                }
                value={branchRegistrationForm.values.hospitalName}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.hospitalName &&
                  Boolean(branchRegistrationForm.errors.hospitalName)
                }
              >
                {userDetails.role === "SuperAdmin"
                  ? hospitalList &&
                  hospitalList.map((ele) => {
                    return (
                      <MenuItem value={ele.hospital} key={ele.id}>
                        {ele.hospital}
                      </MenuItem>
                    );
                  })
                  : currentUsersHospital && (
                    <MenuItem value={currentUsersHospital}>
                      {currentUsersHospital}
                    </MenuItem>
                  )}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="branchName"
                name="branchName"
                label="Enter Branch Name"
                helperText={
                  branchRegistrationForm.touched.branchName &&
                    Boolean(branchRegistrationForm.errors.branchName)
                    ? branchRegistrationForm.errors.branchName
                    : ""
                }
                value={branchRegistrationForm.values.branchName}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.branchName &&
                  Boolean(branchRegistrationForm.errors.branchName)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <FormControl className="w-full">
                <InputLabel id="hospitalType" className="bg-white">
                  Enter Branch Speciality
                </InputLabel>

                <Select
                  fullWidth
                  labelId="speciality"
                  id="speciality"
                  multiple
                  className="w-full"
                  name="speciality"
                  helperText={
                    branchRegistrationForm.touched.speciality &&
                      Boolean(branchRegistrationForm.errors.speciality)
                      ? branchRegistrationForm.errors.speciality
                      : ""
                  }
                  value={branchRegistrationForm.values.speciality}
                  onBlur={branchRegistrationForm.handleBlur}
                  onChange={branchRegistrationForm.handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  error={
                    branchRegistrationForm.touched.speciality &&
                    Boolean(branchRegistrationForm.errors.speciality)
                  }

                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {speciality.map(ele => <MenuItem key={ele._id} value={ele.specialityName}>
                    {ele.specialityName}
                  </MenuItem>)}


                </Select>
              </FormControl>
            </div>
            <div className="col-span-12 md:col-span-6">

              <FormControl className="w-full">
                <TextField
                  label="Services"
                  InputProps={{
                    // startAdornment: branchRegistrationForm.values.services.map((item) => (
                    //   <Chip
                    //     key={item}
                    //     tabIndex={-1}
                    //     label={item}
                    //     onDelete={handleDelete(item)}
                    //   />
                    // )),

                    onChange: (event) => {
                      // event.preventDefault()
                      handleInputChange(event);
                      handleChange(event.target.value);
                    },
                  }}
                  onKeyDown={handleKeyDown}
                  value={inputValue}
                  fullWidth
                  variant="outlined"
                  id="services"
                  name="services"
                  helperText={
                    branchRegistrationForm.touched.services && Boolean(branchRegistrationForm.errors.services)
                      ? branchRegistrationForm.errors.services
                      : ""
                  }
                  error={
                    branchRegistrationForm.touched.services &&
                    Boolean(branchRegistrationForm.errors.services)
                  }
                  onBlur={branchRegistrationForm.handleBlur}
                />
                  <div className="my-2 flex gap-2 flex-wrap">
                {branchRegistrationForm.values.services.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        onDelete={handleDelete(item)}
                      />
                    ))}
                </div>
              </FormControl>
            </div>
          </div>
        </div>

        {/*Contact Details input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Contact Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="phoneNumber"
                name="phoneNumber"
                label="Enter phone number"
                helperText={
                  branchRegistrationForm.touched.phoneNumber &&
                    Boolean(branchRegistrationForm.errors.phoneNumber)
                    ? branchRegistrationForm.errors.phoneNumber
                    : ""
                }
                value={branchRegistrationForm.values.phoneNumber}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.phoneNumber &&
                  Boolean(branchRegistrationForm.errors.phoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="alternatePhoneNumber"
                name="alternatePhoneNumber"
                label="Enter alternate phone number"
                helperText={
                  branchRegistrationForm.touched.alternatePhoneNumber &&
                    Boolean(branchRegistrationForm.errors.alternatePhoneNumber)
                    ? branchRegistrationForm.errors.alternatePhoneNumber
                    : ""
                }
                value={branchRegistrationForm.values.alternatePhoneNumber}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.alternatePhoneNumber &&
                  Boolean(branchRegistrationForm.errors.alternatePhoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="email"
                label="Enter your email"
                helperText={
                  branchRegistrationForm.touched.email &&
                    Boolean(branchRegistrationForm.errors.email)
                    ? branchRegistrationForm.errors.email
                    : ""
                }
                value={branchRegistrationForm.values.email}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.email &&
                  Boolean(branchRegistrationForm.errors.email)
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-2 md:mt-8">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                name="address"
                label="Enter address"
                helperText={
                  branchRegistrationForm.touched.address &&
                    Boolean(branchRegistrationForm.errors.address)
                    ? branchRegistrationForm.errors.address
                    : ""
                }
                value={branchRegistrationForm.values.address}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.address &&
                  Boolean(branchRegistrationForm.errors.address)
                }
              />
            </div>
            <div className="col-span-6 md:col-span-3 ">
                            {/* <InputLabel>B-</InputLabel> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="blockNumber"
                                name="blockNumber"
                                label="Enter Block Number"
                                helperText={branchRegistrationForm.touched.blockNumber && Boolean(branchRegistrationForm.errors.blockNumber) ? branchRegistrationForm.errors.blockNumber : ''}
                                value={`${branchRegistrationForm.values.block || "B-"}${branchRegistrationForm.values.blockNumber}`}
                                onBlur={branchRegistrationForm.handleBlur}
                                onChange={(e)=>{
                                    const values = e.target.value.split("-");
                                    e.target.value = values[1] || "";
                                    branchRegistrationForm.handleChange(e)
                                }}
                                // InputProps={{
                                //     inputComponent: blockElement,
                                // }}
                                error={branchRegistrationForm.touched.blockNumber && Boolean(branchRegistrationForm.errors.blockNumber)}
                            />
                        </div>


            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="country"
                label="Enter Country"
                name="country"
                helperText={
                  branchRegistrationForm.touched.country &&
                    Boolean(branchRegistrationForm.errors.country)
                    ? branchRegistrationForm.errors.country
                    : ""
                }
                value={branchRegistrationForm.values.country}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.country &&
                  Boolean(branchRegistrationForm.errors.country)
                }
                select
              >
                {locatons.country.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="state"
                label="Enter State"
                name="state"
                helperText={
                  branchRegistrationForm.touched.state &&
                    Boolean(branchRegistrationForm.errors.state)
                    ? branchRegistrationForm.errors.state
                    : ""
                }
                value={branchRegistrationForm.values.state}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.state &&
                  Boolean(branchRegistrationForm.errors.state)
                }
                select
              >
                {locatons.state.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>


            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="city"
                label="Enter City"
                name="city"
                helperText={
                  branchRegistrationForm.touched.city &&
                    Boolean(branchRegistrationForm.errors.city)
                    ? branchRegistrationForm.errors.city
                    : ""
                }
                value={branchRegistrationForm.values.city}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.city &&
                  Boolean(branchRegistrationForm.errors.city)
                }
                select
              >
                {locatons.city.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>
            

            {/* <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="city"
                                name="city"
                                label="Enter City"
                                helperText={branchRegistrationForm.touched.city && Boolean(branchRegistrationForm.errors.city) ? branchRegistrationForm.errors.city : ''}
                                value={branchRegistrationForm.values.city}
                                onBlur={branchRegistrationForm.handleBlur}
                                onChange={branchRegistrationForm.handleChange}
                                error={branchRegistrationForm.touched.city && Boolean(branchRegistrationForm.errors.city)}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="country"
                                name="country"
                                label="Enter Country"
                                helperText={branchRegistrationForm.touched.country && Boolean(branchRegistrationForm.errors.country) ? branchRegistrationForm.errors.country : ''}
                                value={branchRegistrationForm.values.country}
                                onBlur={branchRegistrationForm.handleBlur}
                                onChange={branchRegistrationForm.handleChange}
                                error={branchRegistrationForm.touched.country && Boolean(branchRegistrationForm.errors.country)}
                            />
                        </div> */}
          </div>
        </div>

        {/*Hospital Location Details Input map field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Hospital location</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 relative">
              <div className='absolute left-56 top-[10px] z-50'>

                <SearchBox
                  setPosition={setPosition}
                />
              </div>
              {/*<img src="https://i.postimg.cc/qRF5QCjR/map-register.png" alt="map"*/}
              {/*     className="w-full-object-fill"/>*/}
              <LocationPicker
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "400px" }} />}
                defaultPosition={position}
                onChange={handleLocationChange}
              />
            </div>
          </div>
        </div>

        {/*Plans & Permits Details Input map field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Plans & Permits</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                id="subscriptionPlans"
                label="Subscription Plans"
                name="subscriptionPlans"
                helperText={
                  branchRegistrationForm.touched.subscriptionPlans &&
                    Boolean(branchRegistrationForm.errors.subscriptionPlans)
                    ? branchRegistrationForm.errors.subscriptionPlans
                    : ""
                }
                value={branchRegistrationForm.values.subscriptionPlans}
                onBlur={branchRegistrationForm.handleBlur}
                onChange={branchRegistrationForm.handleChange}
                error={
                  branchRegistrationForm.touched.subscriptionPlans &&
                  Boolean(branchRegistrationForm.errors.subscriptionPlans)
                }
                select
              >
                <MenuItem value="Gold">Gold</MenuItem>
                <MenuItem value="Silver">Silver</MenuItem>
                <MenuItem value="Platinum">Platinum</MenuItem>
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  name="startDate"
                  helperText={
                    branchRegistrationForm.touched.startDate &&
                      Boolean(branchRegistrationForm.errors.startDate)
                      ? branchRegistrationForm.errors.startDate
                      : ""
                  }
                  value={branchRegistrationForm.values.startDate}
                  onBlur={branchRegistrationForm.handleBlur}
                  onChange={(value) => {
                    branchRegistrationForm.setFieldValue(
                      "startDate",
                      Date.parse(value)
                    );
                  }}
                  error={
                    branchRegistrationForm.touched.startDate &&
                    Boolean(branchRegistrationForm.errors.startDate)
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="startDate"
                      error={
                        branchRegistrationForm.touched.startDate &&
                        Boolean(branchRegistrationForm.errors.startDate)
                      }
                      helperText={
                        branchRegistrationForm.touched.startDate &&
                          Boolean(branchRegistrationForm.errors.startDate)
                          ? "Start Date is required"
                          : ""
                      }
                      onBlur={branchRegistrationForm.handleBlur}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expiry Date"
                  name="expiryDate"
                  minDate={new Date(branchRegistrationForm.values.startDate)}
                  helperText={
                    branchRegistrationForm.touched.expiryDate &&
                      Boolean(branchRegistrationForm.errors.expiryDate)
                      ? branchRegistrationForm.errors.expiryDate
                      : ""
                  }
                  value={branchRegistrationForm.values.expiryDate}
                  onBlur={branchRegistrationForm.handleBlur}
                  error={
                    branchRegistrationForm.touched.expiryDate &&
                    Boolean(branchRegistrationForm.errors.expiryDate)
                  }
                  onChange={(value) => {
                    branchRegistrationForm.setFieldValue(
                      "expiryDate",
                      Date.parse(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        branchRegistrationForm.touched.expiryDate &&
                          Boolean(branchRegistrationForm.errors.expiryDate)
                          ? "Expiry date is required"
                          : ""
                      }
                      value={branchRegistrationForm.values.expiryDate}
                      onBlur={branchRegistrationForm.handleBlur}
                      error={
                        branchRegistrationForm.touched.expiryDate &&
                        Boolean(branchRegistrationForm.errors.expiryDate)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12">
              {/*Hospital permit file upload*/}
              <div className="col-span-12">
                {/*Hospital permit image upload*/}
                <div className="flex flex-col justify-center items-center max-w-full">
                  <label
                    className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${branchRegistrationForm.errors
                        .branchPermit
                        ? "border-[#d32f2f]"
                        : "border-gray-300"
                      }
                                        border-dashed cursor-pointer`}
                  >
                    <input
                      ref={branchPermitInputref}
                      id="dropzone-file"
                      name="branchPermit"
                      disabled={branchPermitUrl !== ""}
                      accept="image/png, image/jpeg, image/jpg"
                      onBlur={branchRegistrationForm.handleBlur}
                      onChange={branchPermitInputChangeHandler}
                      type="file"
                      className="hidden"
                    />
                    {branchPermitUrl !== "" ? (
                      <div className="relative w-24 h-24">
                        <img
                          id="blah"
                          src={branchPermitUrl}
                          className="shadow w-full object-fill"
                          alt="your"
                        />
                        <CloseIcon
                          onClick={HospitalResetPermitInput}
                          className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="mb-3 w-10 h-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">
                            Click to upload Branch permit
                          </span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          SVG, PNG, JPG or GIF (MAX. 800x400px)
                        </p>
                      </div>
                    )}
                  </label>
                </div>
                <div
                  className={`text-[#d32f2f] my-2 text-sm ml-4 ${branchRegistrationForm.errors.branchPermit ? "" : "hidden"
                    }`}
                >
                  {branchRegistrationForm.errors.branchPermit}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Shift details*/}
        <div className="col-span-12">
          <h1 className="text-xl font-semibold my-5">Shift details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  name="startTime"
                  helperText={
                    branchRegistrationForm.touched.startTime &&
                      Boolean(branchRegistrationForm.errors.startTime)
                      ? branchRegistrationForm.errors.startTime
                      : ""
                  }
                  value={branchRegistrationForm.values.startTime}
                  onBlur={branchRegistrationForm.handleBlur}
                  error={
                    branchRegistrationForm.touched.startTime &&
                    Boolean(branchRegistrationForm.errors.startTime)
                  }
                  onChange={(value) => {
                    branchRegistrationForm.setFieldValue(
                      "startTime",
                      Date.parse(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        branchRegistrationForm.touched.startTime &&
                          Boolean(branchRegistrationForm.errors.startTime)
                          ? "Start Time is required"
                          : ""
                      }
                      value={branchRegistrationForm.values.startTime}
                      onBlur={branchRegistrationForm.handleBlur}
                      error={
                        branchRegistrationForm.touched.startTime &&
                        Boolean(branchRegistrationForm.errors.startTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  name="endTime"
                  helperText={
                    branchRegistrationForm.touched.endTime &&
                      Boolean(branchRegistrationForm.errors.endTime)
                      ? branchRegistrationForm.errors.endTime
                      : ""
                  }
                  value={branchRegistrationForm.values.endTime}
                  onBlur={branchRegistrationForm.handleBlur}
                  error={
                    branchRegistrationForm.touched.endTime &&
                    Boolean(branchRegistrationForm.errors.endTime)
                  }
                  onChange={(value) => {
                    branchRegistrationForm.setFieldValue(
                      "endTime",
                      Date.parse(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        branchRegistrationForm.touched.endTime &&
                          Boolean(branchRegistrationForm.errors.endTime)
                          ? "End Time is required"
                          : ""
                      }
                      value={branchRegistrationForm.values.endTime}
                      onBlur={branchRegistrationForm.handleBlur}
                      error={
                        branchRegistrationForm.touched.endTime &&
                        Boolean(branchRegistrationForm.errors.endTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-6">
              {workingDays && (
                <Toggledays
                  handleWorkingDays={getWorkingDays}
                  workingDays={workingDays}
                />
              )}
              {workingDaysError && (
                <div className="text-red-500">Working days is required</div>
              )}
            </div>
          </div>
        </div>

        {/*Submit button*/}
        <Filledbluebutton
          cname="w-32"
          btnType="submit"
          disabledBtn={!branchRegistrationForm.isValid}
        >
          {params.id ? "Update" : "Submit"}
        </Filledbluebutton>

      </form>
    </Card>
  );
};

export default Branchesregister;

const UtilService = {};
const days = ["Su","M","T","W","Th","F","Sa"]

UtilService.getDaysWithDaysIndex = (daysIndexArray = []) => {
    const result = [];

    daysIndexArray.forEach(day => {
        result.push(days[day]);
    });

    return result
}


export default UtilService;
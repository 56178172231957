import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {UserContext} from "../../../../App";
import {useDownloadExcel} from "react-export-table-to-excel";
import {AgGridReact} from "ag-grid-react";
import {branchService, deleteBranchById} from "../../../../core/services/Data/branchService";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {deleteHospitalById} from "../../../../core/services/Data/hospitalService";
import {toast} from "react-toastify";

const Brancheshome = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [idToDelete, setIdToDelete] = useState('');

    const gridRef = useRef(null);
    const [hospitalDetails, setHospitalDetails] = useState([]);
    const [hospitalToDisplay, setHospitalToDisplay] = useState(null);
    const {setShowLoader} = useContext(UserContext);
    const [rowData, setRowData] = useState([]);
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'hospitals',
        sheet: 'Users'
    });

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const getAllBranchesData = () => {
        setShowLoader(true);
        branchService().then(async (res) => {
            await setHospitalDetails((prevState) => {
                setRowData(res.data)
                return res.data
            });
            setShowLoader(false)
        })
    }

   

    useEffect(() => {
        getAllBranchesData();
    }, []);

    const deleteHospital = () => {
        deleteBranchById(idToDelete).then((res) => {
            if(res){
                toast.success('Branch Deleted successfully');
                getAllBranchesData();
            }
        })
    }

    const [columnDefs] = useState([
        {headerName: "Branch name", field: "name"},
        {headerName: "Hospital name", field: "hospital.hospitalName"},
        {headerName: "City", field: "city"},
        {headerName: "Contact", field: "phone"},
        {headerName: "Subscription Type", field: "subscription.name"},
        {
            headerName: "StartDate", field: "subscription.startDate", valueGetter: (e) => {
                const date = new Date(e.data.subscription.startDate);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })
            }
        },
        {
            headerName: "expiryDate", field: "subscription.expiryDate", valueGetter: (e) => {
                const date = new Date(e.data.subscription.expiryDate);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })
            }
        },
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/branches/registration/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen();
                    setIdToDelete((prevState) => {
                        return id
                    })
                }
            }
        }
    ]);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }
    return (<Card>
        <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteHospital}/>
        <div className="flex justify-between mb-3">
            <div className="order-first">
                <h1 className="text-2xl font-bold tracking-wider">Branch table</h1>
            </div>
            <div className="order-last">
                <Outlinedbluebutton download={onBtnExport}/>
                <Filledbluebutton
                    clicked={() => {
                        navigate('/dashboard/branches/registration')
                    }}
                    cname="ml-4"/>
            </div>
        </div>
        <div className="mt-5">
            <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowSelection="multiple">
                </AgGridReact>
            </div>

        </div>
    </Card>);
};

export default Brancheshome;

import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as csc from 'country-state-city';
import Select from "react-select";
import Card from "../../../../shared/components/Card/Card";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { useNavigate, useParams } from "react-router-dom";
import LocationService from "../../../../core/services/Data/LocationService";
import { UserContext } from "../../../../App";
import { toast } from "react-toastify";
import { TextField } from '@mui/material';

const Addlocationform = () => {
    const params = useParams();
    const [formPrefillData, setFormPrefillData] = useState();
    const navigate = useNavigate();
    const { setShowLoader } = useContext(UserContext);

    const addLocationForm = useFormik({
        initialValues: {
            country: {
                "label": "Kuwait",
                "name": "Kuwait",
                "isoCode": "KW",
                "flag": "🇰🇼",
                "phonecode": "965",
                "currency": "KWD",
                "latitude": "29.50000000",
                "longitude": "45.75000000",
                "timezones": [
                    {
                        "zoneName": "Asia/Kuwait",
                        "gmtOffset": 10800,
                        "gmtOffsetName": "UTC+03:00",
                        "abbreviation": "AST",
                        "tzName": "Arabia Standard Time"
                    }
                ]
            },
            state: formPrefillData ? formPrefillData.state : null,
            city: formPrefillData ? formPrefillData.city : null
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setShowLoader(true);
            const payload = {
                city: values.city,
                state: values.state?.name,
                country: values.country?.name,
            }
            console.log("WORKING", values)
            LocationService.addLocation(payload).then((res) => {
                if (res) {
                    navigate('/dashboard/locations');
                    toast.success('Location successfully added');
                }
                setShowLoader(false)
            })
        }
    });
    const updateCountries = csc.Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.id,
        ...country
    }));
    const updatedStates = (countryId) =>
        csc.State.getStatesOfCountry(countryId)
            ?.map((state) => ({ label: state.name, value: state.id, ...state }));


    const updatedCities = (stateId) =>
        csc.City.getCitiesOfState("IN", stateId)
            ?.map((city) => ({ label: city.name, value: city.id, ...city }));


    const { values, handleSubmit, setFieldValue, setValues } = addLocationForm;

    useEffect(() => {
        setShowLoader(true)
        if (params.id) {
            LocationService.getLocationById(params.id).then((res) => {
                if (res) {
                    setFormPrefillData((prevState) => {
                        setShowLoader(false)
                        console.log("Akash", res.data);
                        return res.data

                    })
                }
            })
        }
        else {
            setShowLoader(false)
        }
    }, []);


    console.log(updatedStates(values.country ? values.country.isoCode : null), "checkCities");

    return (
        <Card cname="max-w-3xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">{params.id ? "Update Location" : "Add location"}</h1>
            <form onSubmit={addLocationForm.handleSubmit}>
                <Select
                    id="country"
                    name="country"
                    placeholder="Select country"
                    isDisabled
                    className=''
                    
                    // options={updateCountries}
                    value={addLocationForm.values.country}
                    onChange={(value) => {
                        console.log(value)
                        setValues({ country: value, state: null, city: null }, false);
                    }}
                />

                <div className="my-10">
                    {updatedStates(values.country ? values.country.isoCode : null).length > 0 && <Select
                        id="state"
                        name="state"
                        placeholder="Select state"
                        options={updatedStates(values.country ? values.country.isoCode : null)}
                        value={addLocationForm.values.state}
                        onChange={(value) => {
                            setValues({ ...values, state: value, city: null }, false);
                        }}
                    />}
                </div>

                {updatedCities(values.state ? values.state.isoCode : null).length > 0 && <Select
                    id="city"
                    name="city"
                    placeholder="Select city"
                    options={updatedCities(values.state ? values.state.isoCode : null)}
                    value={addLocationForm.values.city}
                    onChange={(value) => setValues({
                        ...values,
                        city: value
                    })}
                />}
                {values.state?.name && values.country.name === "Kuwait" && <TextField
                    id="city"
                    name="city"
                    placeholder="Add city"
                    className='!w-full '
                    // options={updatedCities(values.state ? values.state.isoCode : null)}
                    value={addLocationForm.values.city}
                    onChange={(value) => {

                        setValues({
                            ...values,
                            city:value.target.value
                        })
                    }}
                />}
                {/*Submit button*/}
                <Filledbluebutton cname="w-32 mt-5" btnType="submit" disabledBtn={!addLocationForm.isValid}>
                    {params.id ? 'Update' : 'Submit'}
                </Filledbluebutton>
            </form>
        </Card>
    );
};

export default Addlocationform;

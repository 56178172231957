import React, { useState, useRef, useContext, useEffect } from "react";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { blogDetailsTableData } from "../../../../core/constants/appconstants";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";

import { AgGridReact } from "ag-grid-react";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import { UserContext } from "../../../../App";
import {
  getBlog,
  trashBlog,
  updateBlog,
  getBlogById,
  deleteBlog,
} from "../../../../core/services/Data/blogService";
import ApproveDeleteButton from "../../../../shared/components/Editdeletebutton/ApproveDeleteButton";

const Bloghome = () => {
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();
  let gridRef = useRef(null);
  let approvedRef = useRef(null);
  let trashRef = useRef(null);
  const { setShowLoader } = useContext(UserContext);
  const [blogpendingTableData, setBlogpendingTableData] = useState([]);
  const [blogapprovedTableData, setBlogapprovedTableData] = useState([]);
  const [blogtrashTableData, setBlogtrashTableData] = useState([]);
  const [refresh, setRefresh] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const blogTableData = blogDetailsTableData;
  const handleNavigate = (id) => {
    navigate("/dashboard/blog/" + id);
  };

  const getAllBlogData = () => {
    // setShowLoader(true)
    getBlog().then(async (res) => {
      let pending = res.data.filter((el) => el.status === "Pending");
      let approved = res.data.filter((el) => el.status === "Approved");
      //    console.log("Approved by akash", approved);
      setBlogapprovedTableData(approved);
      setBlogpendingTableData(pending);
      // setShowLoader(false)
    });
    trashBlog().then(async (res) => {
      setBlogtrashTableData(res.data);
      // console.log("Trash", res.data);
    });
  };

  function fullNameGetter(params) {
    return (
      params.data.author.name.title || "" +
      " " +
      params.data.author.name.first || "" +
      " " +
      params.data.author.name.last || ""
    );
  }
  const gettingDate = (params) => {
    return params.data.createdAt.split("T")[0];
  };

  useEffect(() => {}, []);
  useEffect(() => {
    setShowLoader(true);
    console.log("WORKING");
    getBlog().then(async (res) => {
      let pending = res.data.filter((el) => el.status === "Pending");
      let approved = res.data.filter((el) => el.status === "Approved");
      //    console.log("Approved by akash", approved);
      setBlogapprovedTableData(approved);
      setBlogpendingTableData(pending);
      setShowLoader(false);
    });
    trashBlog().then(async (res) => {
      setBlogtrashTableData(res.data);
      // console.log("Trash", res.data);
    });
  }, [refresh]);

  const [columnDefs] = useState([
    { headerName: "Image", field: "picture", cellRenderer: Imagerenderer },
    { headerName: "Title", field: "title" },
    {
      headerName: "Author",
      field: "title&first&last",
      valueGetter: fullNameGetter,
    },
    { headerName: "Post Date", field: "createdAt", valueGetter: gettingDate },

    {
      headerName: "Options",
      cellRenderer: ApproveDeleteButton,
      cellRendererParams: {
        data: gridRef ?? gridRef,
        approveClicked: async (id) => {
          console.log("Id", id);
          updateBlog(id, { status: "Approved" }).then((res) => {
            console.log("WORKING", refresh);
            getAllBlogData();
          });
        },
        deleteClicked: (id) => {
          console.log("id", id);
          updateBlog(id, { status: "Rejected", active: false }).then((res) => {
            console.log("WORKING", refresh);
            getAllBlogData();
          });
        },
      },
    },
  ]);
  const [ApprovedColumnDefs] = useState([
    { headerName: "Image", field: "picture", cellRenderer: Imagerenderer },
    { headerName: "Title", field: "title" },
    {
      headerName: "Author",
      field: "title&first&last",
      valueGetter: fullNameGetter,
    },
    { headerName: "Post Date", field: "createdAt", valueGetter: gettingDate },
    {
      headerName: "Options",
      cellRenderer: Editdeletebutton,
      cellRendererParams: {
        data: approvedRef ?? approvedRef,
        approveClicked: async (id) => {},
        deleteClicked: (id) => {
          console.log("id", id);
          updateBlog(id, { status: "Rejected", active: false }).then((res) => {
            console.log("WORKING", refresh);
            getAllBlogData();
          });
        },
      },
    },
  ]);

  const [trashColumnDefs] = useState([
    { headerName: "Image", field: "picture", cellRenderer: Imagerenderer },
    { headerName: "Title", field: "title" },
    {
      headerName: "Author",
      field: "title&first&last",
      valueGetter: fullNameGetter,
    },
    { headerName: "Post Date", field: "createdAt", valueGetter: gettingDate },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  useEffect(()=>{},[gridRef,approvedRef,trashRef])
  return (
    <>
      <div className="flex justify-between mb-3">
        <div className="order-first">
          <h1 className="text-2xl font-semibold">Blog Posts</h1>
        </div>
        {/* <div className="order-last">
                    <Filterbutton text="Filter by latest"/>
                    <Filterbutton text="Filter by lab author" cname="mx-4"/>
                </div> */}
      </div>
      <div className="mt-5">
        <TabContext value={value}>
          <div>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="lab API tabs example"
            >
              <Tab label="Approved" value="1" onClick={(e)=>{
                e.preventDefault();
                // gridRef = null;
                // approvedRef = null;
                // trashRef = null;
              }}/>
              <Tab label="Pending" value="2" onClick={(e)=>{
                e.preventDefault();
                // gridRef = null;
                // approvedRef = null;
                // trashRef = null;
              }} />
              <Tab label="Trash" value="3" onClick={(e)=>{
                e.preventDefault();
                // gridRef = null;
                // approvedRef = null;
                // trashRef = null;
              }} />
            </TabList>
          </div>
          <Card>
            <TabPanel value="1">
              <div className="mt-5">
                <div
                  className="ag-theme-alpine"
                  style={{ height: 400, width: "100%" }}
                >
                  <AgGridReact
                    ref={!approvedRef ? approvedRef : undefined}
                    rowData={blogapprovedTableData}
                    defaultColDef={defaultColDef}
                    columnDefs={ApprovedColumnDefs}
                    pagination={true}
                    onRowClicked={(e) =>{
                        handleNavigate(e.data._id)
                    }}

                    paginationPageSize={10}
                    rowSelection="multiple"
                  ></AgGridReact>
                </div>
              </div>
              {/* <Customtable heading={['Image', 'Title', 'Author', 'Comments', 'Post Date', 'Actions']}>
                                {blogTableData.map((ele) => {
                                    return (
                                        <tr className="bg-white border-b cursor-pointer" key={ele.id} onClick={() => {
                                            handleNavigate(ele.id)
                                        }}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900 flex justify-center">
                                                <img src={ele.imageUrl}
                                                     className="bg-white shadow rounded-full" alt=""/>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.title}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.author}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.commentsCount}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.postDate}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                                <EditButton/>
                                                <Deletebutton cname="ml-3"/>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Customtable> */}
            </TabPanel>
            <TabPanel value="2">
              <div className="mt-5">
                <div
                  className="ag-theme-alpine"
                  style={{ height: 400, width: "100%" }}
                >
                  <AgGridReact
                    ref={!gridRef ? gridRef : undefined }
                    rowData={blogpendingTableData}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    pagination={true}
                    onRowClicked={(e) => {
                      handleNavigate(e.data._id);
                    }}
                    paginationPageSize={10}
                    rowSelection="multiple"
                  ></AgGridReact>
                </div>
              </div>

              {/* <Customtable heading={['Image', 'Title', 'Author', 'Comments', 'Post Date', 'Actions']}>
                                {blogTableData.map((ele) => {
                                    return (
                                        <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900 flex justify-center">
                                                <img src={ele.imageUrl}
                                                     className="bg-white shadow rounded-full" alt=""/>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.title}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.author}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.commentsCount}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.postDate}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                                <EditButton/>
                                                <Deletebutton cname="ml-3"/>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Customtable> */}
            </TabPanel>
            <TabPanel value="3">
              <div className="mt-5">
                <div
                  className="ag-theme-alpine"
                  style={{ height: 400, width: "100%" }}
                >
                  <AgGridReact
                    // ref={trashRef}
                    rowData={blogtrashTableData}
                    defaultColDef={defaultColDef}
                    columnDefs={trashColumnDefs}
                    pagination={true}
                    onRowClicked={(e) => {
                      handleNavigate(e.data._id);
                    }}
                    paginationPageSize={10}
                    rowSelection="multiple"
                  ></AgGridReact>
                </div>
              </div>
              {/* <Customtable heading={['Image', 'Title', 'Author', 'Comments', 'Post Date', 'Actions']}>
                                {blogTableData.map((ele) => {
                                    return (
                                        <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900 flex justify-center">
                                                <img src={ele.imageUrl}
                                                     className="bg-white shadow rounded-full" alt=""/>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.title}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.author}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.commentsCount}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.postDate}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                                <EditButton/>
                                                <Deletebutton cname="ml-3"/>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Customtable> */}
            </TabPanel>
          </Card>
        </TabContext>
      </div>
    </>
  );
};

export default Bloghome;

import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

async function fileUpload(file) {
    try {
        const formData = new FormData();
        // Store form name as "file" with file data
        formData.append("file", file, file.name);
        const {data: response} = await httpservice.post('file/upload', formData);
        
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
            return false;
        }
    }
}

export default {
    fileUpload
}

import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

async function getAllReceptionist() {
    const url = 'receptionists';
    try {
        
        const {data: doctors} = await httpservice.get(url);
        return doctors
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function createReceptionist(payload) {
    const url = 'receptionists/create';
    try {
        const {data: doctors} = await httpservice.post(url, payload);
        return doctors
    } catch (ex) {
        console.log({ex})
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}

async function getReceptionistById(id) {
    try {
        const {data: doctors} = await httpservice.get('receptionists/' + id);
        return doctors
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function updateReceptionistDetails(payload,id) {
    try {
        console.log("Id is ",id)
        console.log("payload is ",payload)
        const {data: response} = await httpservice.put('receptionists/edit/' + id, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
async function deleteReceptionistById(id) {
    try {
        const {data: response} = await httpservice.delete('receptionists/delete/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export default {
    getAllReceptionist,
    createReceptionist,
    getReceptionistById,
    updateReceptionistDetails,
    deleteReceptionistById
}

import React, { useRef, useState, useContext, useEffect,useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  InsuranceDetailsTableData,
  InsuranceDetailsWithDoctor,
} from "../../../../core/constants/appconstants";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import { AgGridReact } from "ag-grid-react";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import { UserContext } from "../../../../App";
import symptomsService from "../../../../core/services/Data/symptomsService";
import { toast } from "react-toastify";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const Symptomshome = () => {
  const navigate = useNavigate();
  const gridRef = useRef(null);
  
  const [insuranceTableData, setInsuranceTableData] = useState();
  const insuranceDetailsData = InsuranceDetailsTableData;
  const insuranceDetailsDataWithDoctor = InsuranceDetailsWithDoctor;
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const { setShowLoader } = useContext(UserContext);
  const [open1, setOpen1] = useState()
  const handleNavigate = (id) => {
  
    navigate('/dashboard/Symptoms/assign-doctor/' + id); 
   }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const editButton = (props)=>{
    return (
      <ModeEditIcon
      className={`text-[#0098C4] border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`} onClick={() => {props.editClicked(props.data._id)}}/>
    )
  }
  const getInsuranceData = () => {
    setShowLoader(true);
    symptomsService.getSymptoms().then((res) => {
      if (res) {
        console.log("Symptoms", res.data);
        setInsuranceTableData(res.data);
      }
      setShowLoader(false);
    });
  };
  const mulitipleImageRenderer = (props) => {
    console.log('lol',props.getValue())
      let number = 0;
    return <div className="flex gap-2" >
        {
            props.getValue().map((el,i)=>{
                console.log('working',el)
                if(i<3){

                  return <img src={el} className="h-8 w-8 " alt="photo" />;
                }else{
                  number++
                 return 
                }
            })

        }
        <h2 style ={{cursor : "pointer", marginTop : "10px" }}>{number>0 ? "+" +number+" more" : null} </h2>
    </div>
  };
  const [columnDefs] = useState([
    { headerName: "Image", field: "icon", resizable: true, cellRenderer: Imagerenderer, },
    { headerName: "Symptom Name", field: "name", resizable: true },

    {
      headerName: "Action",
      cellRenderer: Editdeletebutton,
      sortable: false,
      filter: false,
      resizable: true,
      cellRendererParams: {
        data: gridRef && gridRef,  
        editClicked: (id) => {
          navigate('/dashboard/Symptoms/add-symptom/' + id);
          // console.log("id", id);
        },
        deleteClicked: (id) => {
          handleOpen();
          setIdToDelete((prevState) => {
            return id;
          });
          // console.log('id', id)
        },
      },
    },
    // { headerName: "Description", field: "description", resizable: true },
  ]);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
}, []);


  const [assigncolumnDefs] = useState([
    { headerName: "Symptoms Name", field: "name", resizable: true },
    {
      headerName: "Doctors",
      field: "doctors",
      resizable: true,
      cellRenderer: mulitipleImageRenderer,
      valueGetter: (e) => {
        console.log("Checking-->", e.data);

        return e.data?.doctors?.map((el) => el.picture);
      },
    },

    {
      headerName: "Action",
      cellRenderer: editButton,
      sortable: false,
      filter: false,
      resizable: true,
      cellRendererParams: {
        data: gridRef && gridRef,
        editClicked: (id) => {
          navigate('/dashboard/Symptoms/assign-doctor/' + id);
          // console.log("id", id);
        },
        // deleteClicked: (id) => {
        //   handleOpen();
        //   setIdToDelete((prevState) => {
        //     return id;
        //   });
        //   // console.log('id', id)
        // },
      },
    },
  ]);
  const deleteInsurance = () => {
    symptomsService.deleteSymptoms(idToDelete).then((res) => {
      if (res) {
        toast.success(res.message);
        getInsuranceData(); 
      }
    });
    console.log("Id to be deleted", idToDelete);
  };

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  useEffect(() => {
    getInsuranceData();
  }, []);
  return (
    <>
      <div className="flex justify-between mb-4">
        {/* <div className="order-first">
          <h1 className="text-3xl font-semibold">Insurance</h1>
        </div>
        <div className="order-last">
          <Filterbutton text="Filter by name" />
        </div> */}
      </div>
      <Card>
        <Deletepopup
          handleCloseModel={handleClose}
          isOpen={open}
          id={idToDelete}
          handleDelete={deleteInsurance}
        />

        <div className="flex justify-between mb-3">
          <div className="order-first">
            <p className="text-2xl font-semibold">Symptom</p>
          </div>
          <div className="order-last">
            <Outlinedbluebutton download={onBtnExport} />
            <Filledbluebutton
              cname="ml-4"
              clicked={() => {
                navigate("/dashboard/Symptoms/add-symptom");
              }}
            />
          </div>
        </div>
        <div className="mt-5">
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={insuranceTableData}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowSelection="multiple"
            ></AgGridReact>
          </div>
        </div>
      </Card>
      {/* <div className="flex justify-between my-4">
       
      </div>
      <Card>
        <div className="flex justify-between mb-3">
          <div className="order-first">
            <p className="text-2xl font-semibold">
            Assign Doctors
            </p>
          </div>
          <div className="order-last">
            <Outlinedbluebutton download={onBtnExport}/>
            <Filledbluebutton
              cname="ml-4"
              clicked={() => {
                navigate("/dashboard/Symptoms/assign-doctor");
              }}
            />
          </div>
        </div>
        <div className="mt-5">
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={insuranceTableData}
              defaultColDef={defaultColDef}
              columnDefs={assigncolumnDefs}
              rowSelection="multiple"
              onRowClicked={(e) =>{
                 
                handleNavigate(e.data._id)
            }}
            ></AgGridReact>
          </div>
        </div>
      </Card> */}
    </>
  );
};

export default Symptomshome;

import * as yup from 'yup';
import {phoneNumberRegex} from "../constants/appconstants";

export const hospitalRegistrationValidationSchema = yup.object({
    hospitalName: yup.string('Please enter a valid Hospital Name').required('Hospital Name is required').trim(),
    hospitalType: yup.array().required('Hospital type is required').min(1,"Please Select Speaciality"),
    hospitalLogo: yup.string().required('Hospital Logo is required').trim(),
    phoneNumber: yup.string('Enter your Email').required('Phone number is required').trim(),
    alternatePhoneNumber: yup.string('Enter your Email').trim(),
    email: yup.string('Enter your Email').required('Email is required').email('Please enter a valid email').trim(),
    address: yup.string('Enter your Email').required('Address is required').trim(),
    city: yup.string('Enter your Email').required('City is required').trim(),
    state:yup.string("Enter Your State").required("State is required").trim(),
    country: yup.string('Enter your Email').required('Country is required').trim(),
    blockNumber:yup.string("Enter your Email").trim(),
    block:yup.string("Enter your Email").trim(),
    hospitalPermit: yup.string().required('Hospital Permit is required').trim(),
    subscriptionPlans: yup.string('Enter your Email').required('Subscription Plans is required').trim(),
    startDate: yup.string('Enter your Email').required('Start Date is required').trim(),
    expiryDate: yup.string('Enter your Email').required('Expiry Date is required').trim(),
    startTime: yup.string('Enter your Email').required('Start Time is required').trim(),
    endTime: yup.string('Enter your Email').required('Expiry Time is required').trim(),
    services:yup.array().required('Services is required').min(1,"Please Add Service"),
});
// const x = {
//     "hospitalName": "Hospital (1)",
//     "hospitalType": [
//         "Bones-&-Joints",
//         "Kidney-Issues"
//     ],
//     "hospitalLogo": "https://careplus-bucket.s3.ap-south-1.amazonaws.com/27d0835223c1d43cd6f62e6a7608f26dc600bcad81929be343c34a3082fd44ec-Hospital%20%281%29.jpg",
//     "phoneNumber": 1234567890,
//     "alternatePhoneNumber": 1234567890,
//     "email": "111111@gmail.com",
//     "address": "111111@gmail.com",
//     "city": "Hawalli Governorate",
//     "state": "Hawalli Governorate",
//     "country": "Kuwait",
//     "subscriptionPlans": "Silver",
//     "startDate": "2023-06-14T00:00:00.000Z",
//     "expiryDate": "2023-06-29T00:00:00.000Z",
//     "hospitalPermit": "https://careplus-bucket.s3.ap-south-1.amazonaws.com/f33b5759069430a2c14ab4546bb4bea073917d32398e4da2e327002d60d1508c-Hospital%20%281%29.jpg",
//     "startTime": "2023-06-15T03:00:00.000Z",
//     "endTime": "2023-06-15T14:00:00.000Z",
//     "services": [
//         "dsaf"
//     ],
// }

export const loginValidationSchema = yup.object({
    email: yup.string('Email is required').required('Email is required').trim(),
    password: yup.string('password is required').required('password is required').trim()
})

// hospitalName: '',
//     hospitalType: '',
//     hospitalLogo: '',
//     phoneNumber: '',
//     alternatePhoneNumber: '',
//     email: '',
//     address: '',
//     city: '',
//     hospitalPermit: '',
//     country: '',
//     description: '',
//     subscriptionPlans: '',
//     startDate: null,
//     expiryDate : null,
//     startTime: null,
//     endTime: null

import React, {useContext,useState,useEffect} from 'react';
import Card from "../../../../shared/components/Card/Card";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {UserContext} from "../../../../App";
import {toast} from "react-toastify";
import { insuranceRegistrationValidation } from "../../../../core/Validationschemas/insuranceValidation";
import insuranceService  from "../../../../core/services/Data/insuranceService"

const Addinsuranceform = () => {
    const {setShowLoader} = useContext(UserContext);
    const params = useParams()
    const navigate = useNavigate();
    const [formPrefillData, setFormPrefillData] = useState();
    const addInsurance = useFormik({
        initialValues: {
            insurance: formPrefillData ? formPrefillData.name : null,
            description: formPrefillData ? formPrefillData.description :''
        }, 
        validationSchema: insuranceRegistrationValidation,
        enableReinitialize: true,
        onSubmit: (values) => {
            setShowLoader((prevState) => {
                return true
            });
            const payload = {
                ...values,
                name : values.insurance
            }
            if(params.id){
                insuranceService.updateInsurance(params.id,payload).then((res)=>{
                    if(res){
                        navigate('/dashboard/insurance');
                        toast.success(res.message)  
                    }else{
                        setShowLoader(false)
                    }
                })
            }
            else{

                insuranceService.createInsurance(payload).then((res)=>{
                    if(res){
    
                        navigate('/dashboard/insurance');
                        toast.success(res.message)
                    }else{
                        setShowLoader(false)
                    }
                })
            }
            
        }
    }); 
    useEffect(()=>{
        if(params.id){
            setShowLoader(true)
          
            insuranceService.getSingleInsurance(params.id).then((res)=>{
                console.log("AKASH____>",res.data);
                setFormPrefillData(res.data);
                setShowLoader(false)
            })
        }
    },[])
    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">{params.id ?  "Update" : "Add"} Insurance</h1>
            <form onSubmit={addInsurance.handleSubmit}>
                <TextField
                    fullWidth
                    id="insurance"
                    label="Insurance"
                    name="insurance"
                    value={addInsurance.values.insurance}
                    onChange={addInsurance.handleChange}
                    onBlur={addInsurance.handleBlur}
                    helperText={addInsurance.touched.insurance && Boolean(addInsurance.errors.insurance) ? 'Insurance name is required' : ''}
                    error={addInsurance.touched.insurance && Boolean(addInsurance.errors.insurance)}>
                </TextField>
                <div className="my-5">
                    <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        value={addInsurance.values.description}
                        onChange={addInsurance.handleChange}
                        onBlur={addInsurance.handleBlur}
                        helperText={addInsurance.touched.description && Boolean(addInsurance.errors.description) ? 'Insurance description is required' : ''}
                        error={addInsurance.touched.description && Boolean(addInsurance.errors.description)}
                        multiline
                        rows={5}
                    />
                </div>
                <Filledbluebutton cname="w-full" btnType="submit" > {params.id ? 'Update' : 'Add'}</Filledbluebutton>
            </form>
        </Card>
    );
};

export default Addinsuranceform;

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDownloadExcel} from "react-export-table-to-excel";
import {deleteHospitalById, getHospitalById, hospitalService} from "../../../../core/services/Data/hospitalService";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {UserContext} from "../../../../App";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from "react-toastify";

const Hospitalhome = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [idToDelete, setIdToDelete] = useState('');
    const gridRef = useRef(null);
    const [hospitalDetails, setHospitalDetails] = useState([]);
    const [hospitalToDisplay, setHospitalToDisplay] = useState(null);
    const {setShowLoader} = useContext(UserContext);
    const [rowData, setRowData] = useState([]);
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'hospitals',
        sheet: 'Users'
    });
    
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const getAllHospitalsData = () => {
        setShowLoader(true);
        hospitalService().then(async (res) => {
            await setHospitalDetails((prevState) => {
                setRowData(res.data)
                return res.data
            });
            setShowLoader(false)
        })
    }

    useEffect(() => {
        getAllHospitalsData();
    }, []);

    const [columnDefs] = useState([
        {headerName: "Hospital", field: "name"},
        {headerName: "Branch", field: "address"},
        {headerName: "Subscription Type", field: "subscription.name"},
        {headerName: "StartDate", field: "subscription.startDate", valueGetter: (e) => {
            const date = new Date(e.data.subscription.startDate);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })}},
        {headerName: "expiryDate", field: "subscription.expiryDate",  valueGetter: (e) => {
                const date = new Date(e.data.subscription.expiryDate);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}},
        {headerName: "city", field: "city"},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/hospitals/registration/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    
                    handleOpen();
                    // console.log({e})
                    setIdToDelete((prevState) => {
                        return id
                    })
                }
            }
        }
    ]);

     const deleteHospital = () => {
         deleteHospitalById(idToDelete).then((res) => {
             if(res){
                 console.log('res', res);
                 toast.success('Hospital Deleted successfully');
                 getAllHospitalsData()
             }
         })
     }

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    return (
        <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteHospital}/>
            <div className="flex justify-between mb-3">
                <div className="order-first">
                    <h1 className="text-2xl font-bold tracking-wider">Hospital table</h1>
                </div>
                <div className="order-last">
                    <Outlinedbluebutton download={onBtnExport}/>
                    <Filledbluebutton cname="ml-4" clicked={() => {
                        navigate('/dashboard/hospitals/registration')
                    }}/>
                </div>
            </div>
            <div className="mt-5">
                <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                    <AgGridReact 
                        ref={gridRef}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowSelection="multiple"
                        onRowClicked={(e) => navigate(`/dashboard/hospitals/${rowData[e.rowIndex]._id}`,{state:{
                            name:rowData[e.rowIndex].name,
                            email:rowData[e.rowIndex].email,
                            description:rowData[e.rowIndex].description,
                            phone:rowData[e.rowIndex].phone
                        }})}
                        >
                    </AgGridReact>
                </div>
            </div>
        </Card>
    );
};

export default Hospitalhome;

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

    export async function getBlog(){
    try {
        const url = 'blogs'
        const {data : blogs} = await httpservice.get(url)
        return blogs
        
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
    }


    export async function updateBlog(id,payload){
        try {
            const url = 'blogs/edit/'
            const {data : blogs} = await httpservice.put(url + id, payload)
            return blogs
            
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data)
            }
        }
    }
    export async function getBlogById(id){
        try {
            const url = "blogs/"
            const {data : blog} = await httpservice.get(url + id)
            return blog 
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data)
            }
        }
    }

    export async function deleteBlog(id){
        try {
            const url = "blogs/delete/"
            const {data : blog} = await httpservice.delete(url + id)
            return blog 
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data)
            }
        }
    }

    export async function trashBlog(){
        try {
            const url = "blogs?active=false"
            const {data : blog} = await httpservice.get(url)
            return blog
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data)
            }
        }
    }
import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Deletebutton = (props) => {
   console.log("props.data", props.data);
    return (
        <DeleteOutlineOutlinedIcon
        onClick = {()=>{props.deleteClicked(props.data._id)}}
            className={`text-red-500 ${props.cname} border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`}/>
    );
};

export default Deletebutton;

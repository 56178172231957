import React, {useContext} from 'react';
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {mapHospitalFormSchema} from "../../../../core/Validationschemas/Schedule";
import createMapUserService from "../../../../core/services/User/createMapUserService";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {UserContext} from "../../../../App";
import { toast } from "react-toastify";

const Mapuserhospitalform = () => {
    const navigate = useNavigate();
    const {setShowLoader} = useContext(UserContext);

    const loginForm = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        enableReinitialize: true,
        validationSchema: mapHospitalFormSchema,
        onSubmit: (values) => {
            const apiData = async () => {
                setShowLoader((prevState) => {
                    return true
                });
                const res = await createMapUserService.createmapuser(values.name, values.email)
                if(res){
                    navigate("/dashboard/addHospitalUser")
                    // toast.success(res.message)
                    // console.log('response', res);
                }
                setShowLoader((prevState) => {
                    return false
                });

            }
            apiData();
            
                
        }
    })
    return (
        <Card cname="max-w-4xl mx-auto">
            <div className="py-5">
                <h3 className="text-2xl text-center font-bold text-gray-800">Create User</h3>
            </div>
            <form onSubmit={loginForm.handleSubmit}>
                <div className="my-5">
                    <TextField
                        className="w-full"
                        variant="outlined"
                        name="name"
                        id="name"
                        type="name"
                        label="Enter name"
                        value={loginForm.values.name}
                        onChange={loginForm.handleChange}
                        onBlur={loginForm.handleBlur}
                        helperText={loginForm.touched.name && Boolean(loginForm.errors.name) ? loginForm.errors.name : ''}
                        error={loginForm.touched.name && Boolean(loginForm.errors.name)}
                    />
                </div>
                <div className="my-5">
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="email"
                        name="email"
                        label="Enter email"
                        value={loginForm.values.email}
                        onChange={loginForm.handleChange}
                        onBlur={loginForm.handleBlur}
                        helperText={loginForm.touched.email && Boolean(loginForm.errors.email) ? loginForm.errors.email : ''}
                        error={loginForm.touched.email && Boolean(loginForm.errors.email)}
                    />
                </div>

                {/*Submit button*/}
                <Filledbluebutton cname="w-32" btnType="submit" disabledBtn={!loginForm.isValid}>
                    Submit
                </Filledbluebutton>
            </form>
        </Card>
    );
};

export default Mapuserhospitalform;

import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Card from "../../../../shared/components/Card/Card";
import { useFormik } from "formik";
import { validationSchemaSchedule } from "../../../../core/Validationschemas/Schedule";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import commonAPICallService from "../../../../core/services/Data/commonAPICallService";
import { UserContext } from "../../../../App";
import { doctorbyBranch } from "../../../../core/services/Data/doctorsService";
import { createMultipleScheduleData } from "../../../../core/services/Data/scheduleService";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

// new
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers";
import doctorRegistrationService from "../../../../core/services/Data/doctorRegistrationService";
const localizer = momentLocalizer(moment);
// import 'react-big-calendar/lib/sass/styles';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles';

const MultipleScheduler = ({ setCalenderData, calendarData, refresh, setRefresh, handleClose, hospital, branch, doctor }) => {
  const { userDetails } = useContext(UserContext);
  const [hospitalList, setHospitalsList] = useState(null);
  const [branchList, setbranchList] = useState(null);
  const [docList, setdocList] = useState(null);

  const [showCalendarLoader, setShowCalendarLoader] = useState(false)

  const navigate = useNavigate();
  const [branchId, setBranchID] = useState();
  // const { setShowCalendarLoader } = useContext(UserContext);
  const endDate = new Date(
    Date.UTC(
      new Date().getFullYear() + 1,
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  const startDate = new Date(
    Date.UTC(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  const formik = useFormik({
    initialValues: {
      hospital: "",
      branch: "",
      doctor: "",
      startDate: null,
      endDate: null,
      workingDay: [],
      slot: 1,
      slots: [
        {
          startTime: null,
          endTime: null,
          slotDuration: 15,
        },
      ],
    },
    // validationSchema: validationSchemaSchedule,
    onSubmit: (values) => {

      // console.log("values", values);
      const payload = {
        startDate: values.startDate,
        endDate: values.endDate,
        doctorId: values.doctor,
        workingDays: values.workingDay,
        slots: values.slots
      };
      const apiCall = async () => {

        // console.log({refresh});

        let slots = [];
        const startDateDemo = new Date(payload.startDate);
        const endDateDemo = new Date(payload.endDate);
        if (payload.slots) {
          slots = [...payload.slots];
        }
        console.log({payload})
        for (let slot of slots) {
          slot.startTime = new Date(slot.startTime);
          slot.endTime = new Date(slot.endTime);
          slot.startTime.setFullYear(startDateDemo.getFullYear());
          slot.startTime.setMonth(startDateDemo.getMonth());
          slot.startTime.setDate(startDateDemo.getDate());
          slot.endTime.setFullYear(endDateDemo.getFullYear());
          slot.endTime.setMonth(endDateDemo.getMonth());
          slot.endTime.setDate(endDateDemo.getDate());          
        }

       

        const realStartDate = new Date(payload.startDate);
        const realEndDate = new Date(payload.endDate);

        const dayDifference = date_diff_indays(realStartDate, realEndDate);
        function isWorkingDay(date, workingDays, currentDate) {
          const daysList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const workingDaysInIndex = [];
          workingDays.forEach(day => {
            workingDaysInIndex.push(daysList.indexOf(day))
          })
          // console.log({ date: date.getDay(), workingDaysInIndex, date2: date, currentDate });
          return workingDaysInIndex.includes(date.getDay());
        }
        // console.log({ dayDifference });
        let promises = [];
        for (let i = 0; i <= dayDifference; i++) {
          const slotApis = await createSlotsOfARange(
            slots,
            realStartDate,
            realEndDate,
            payload,
            isWorkingDay
          );
          promises = [...promises, ...slotApis];

          realStartDate.setDate(realStartDate.getDate() + 1);
          realEndDate.setDate(realEndDate.getDate() + 1);
        }
        // console.log({realSlots:promises})


        //api Call
             setShowCalendarLoader(true)
        payload.slots = [...promises];
        const data = await createMultipleScheduleData(payload);
        setShowCalendarLoader(false)
        // if (data) {
          // console.log({ data });
          handleClose(false);   
          setRefresh(!refresh)
        // }
      }
      // console.log({ payload })
      apiCall()




    },
  });

  //   props.func(formik.values.doctor);
  //   props.cops(list);

  useEffect(() => {
    // doctorRegistrationService.getDoctorById(formik)
    setShowCalendarLoader(true);
    const apiCall = async () => {
      if (
        userDetails.role === "Branch" ||
        userDetails.role === "Hospital" ||
        userDetails.role === "SuperAdmin"
      ) {
        const res = await commonAPICallService.getHospitalsList();
        if (res) {
          // console.log('res from hospital list', res);
          await setHospitalsList(res);
          const data = await doctorRegistrationService.getDoctorById(doctor);
          if (data) {
            setbranchList((prevState) => {
              return res.filter((ele) => {
                return ele.id === data.data.professional.hospital._id;
              })[0].branch
            });
            const doctors = await doctorbyBranch(data.data.professional.branch._id)
            setdocList(doctors.data)
            formik.setFieldValue('hospital', data.data.professional.hospital.hospitalName);
            formik.setFieldValue('branch', data.data.professional.branch._id);
            formik.setFieldValue('doctor', doctor);
            // setShowCalendarLoader(true);
          }
          setShowCalendarLoader(false);

        }


      }
    }
    apiCall()
    setShowCalendarLoader(false);
  }, [])
  const callGetDoctors = () => {
    // console.log("BRANCHID", branchId);
    setShowCalendarLoader(true);
    doctorbyBranch(branchId ? branchId : null).then((res) => {
      setShowCalendarLoader(false);
      setdocList(res.data);
    });
  };

  const hospitalChange = (event) => {
    setbranchList((prevState) => {
      return hospitalList.filter((ele) => {
        return ele.hospital === event.target.value;
      })[0].branch;
    });
  };
  const handleBranch = (e) => {
    // console.log("===>target", e.target);
    e.preventDefault();
    setBranchID(e.target.value);
  };

  useEffect(() => {
    setShowCalendarLoader(true);
    if (
      userDetails.role === "Branch" ||
      userDetails.role === "Hospital" ||
      userDetails.role === "SuperAdmin"
    ) {
      commonAPICallService.getHospitalsList().then(async (res) => {
        if (res) {
          // console.log('res from hospital list', res);
          await setHospitalsList(res);
          // console.log("res", res);
          setShowCalendarLoader(false);
        }
      });
    }
    callGetDoctors();
  }, [branchId]);
  const daysList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const slots = [1, 2, 3, 4, 5, 6, 7, 8];
  // const [slot, setSlot] = useState();

  const slotChange = (e) => {
    // setSlot(e.target.value);
    e.preventDefault();

    // console.log(slot);
  };

  const daysChange = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex md:min-w-[40vw] flex-1 flex-col gap-4">

      <Card>
        <h1 className="text-xl font-bold text-center text-gray-700">
          Create Slots
        </h1>

        <form onSubmit={formik.handleSubmit} className="mt-4 ">
          <div className="h-96 overflow-y-scroll pt-4 px-2">

            {
              (showCalendarLoader) && <div className="w-full h-full flex justify-center items-center">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#0098C4"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            }
            {!showCalendarLoader && <>
              <div className="flex justify-between gap-4">
                {userDetails.role === "SuperAdmin" && <div className="flex-1 mt-0">
                  <TextField
                    style={{ width: "100%" }}
                    id="hospitalInput"
                    label="Hospital"
                    name="hospital"
                    select
                    value={formik.values.hospital}
                    onChange={(e) => {
                      hospitalChange(e);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.hospital && Boolean(formik.errors.hospital)
                        ? "Hospital name is required"
                        : ""
                    }
                    error={
                      formik.touched.hospital && Boolean(formik.errors.hospital)
                    }
                  >
                    {hospitalList &&
                      hospitalList.map((el) => {
                        return (
                          <MenuItem value={el.hospital} key={el.id}>
                            {el.hospital}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>}
                {((userDetails.role === "SuperAdmin") || (userDetails.role === "Hospital")) && <div className="flex-1 mt-0">
                  <TextField
                    style={{ width: "100%" }}
                    id="branchInput"
                    label="Branch"
                    name="branch"
                    select
                    helperText={
                      formik.touched.branch && Boolean(formik.errors.branch)
                        ? "Branch name is required"
                        : ""
                    }
                    value={formik.values.branch}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      // console.log("abs", branchList);
                      // console.log("currentTarget", e.target);
                      handleBranch(e);
                      formik.handleChange(e);
                    }}
                    error={
                      formik.touched.branch && Boolean(formik.errors.branch)
                    }
                  >
                    {branchList &&
                      branchList.map((ele) => {
                        return (
                          <MenuItem value={ele._id} id={ele._id} key={ele._id}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>}
                <div className="flex-1 mt-0">
                  <TextField
                    style={{ width: "100%" }}
                    id="doctorInput"
                    label="Doctor"
                    name="doctor"
                    select
                    helperText={
                      formik.touched.doctor && Boolean(formik.errors.doctor)
                        ? "Doctor name is required"
                        : ""
                    }
                    value={formik.values.doctor}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.doctor && Boolean(formik.errors.doctor)
                    }
                  >
                    {docList &&
                      docList.map((ele) => {
                        return (
                          <MenuItem value={ele._id} key={ele._id}>
                            {ele.name.first} {ele.name.last}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
              </div>

              <br />

              <div className="flex justify-content gap-4 mt-6">
                <div className="flex-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                    <DatePicker
                      label="Start Date"
                      minDate={startDate}
                      maxDate={endDate}
                      value={formik.values.startDate}
                      onChange={(value) => {
                        // console.log();
                        formik.setFieldValue(
                          "startDate",
                          new Date(Date.parse(value)).toISOString()
                        );
                      }}
                      error={
                        formik.touched.startDate &&
                        Boolean(formik.errors.startDate)
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          name="startDate"
                          error={
                            formik.touched.startDate &&
                            Boolean(formik.errors.startDate)
                          }
                          helperText={
                            formik.touched.startDate &&
                              Boolean(formik.errors.startDate)
                              ? "Start Date is required"
                              : ""
                          }
                          onBlur={formik.handleBlur}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                    <DatePicker
                      label="End Date"
                      // disableFuture
                      value={formik.values.endDate}
                      minDate={formik.values.startDate}
                      maxDate={endDate}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "endDate",
                          new Date(Date.parse(value)).toISOString()
                        );
                      }}
                      error={
                        formik.touched.endDate && Boolean(formik.errors.endDate)
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          name="endDate"
                          error={
                            formik.touched.endDate &&
                            Boolean(formik.errors.endDate)
                          }
                          helperText={
                            formik.touched.endDate &&
                              Boolean(formik.errors.endDate)
                              ? "End Date is required"
                              : ""
                          }
                          onBlur={formik.handleBlur}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex-1">
                  <FormControl className="w-full">
                    <InputLabel id="workingDay" className="bg-white">
                      Select Working Days
                    </InputLabel>

                    <Select
                      style={{ width: "100%" }}
                      id="workingDay"
                      label="Select Working Days"
                      name="workingDay"
                      multiple
                      select
                      value={formik.values.workingDay}
                      onChange={(e) => {
                        daysChange(e);
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.workingDay &&
                          Boolean(formik.errors.workingDay)
                          ? "Day is required.."
                          : ""
                      }
                      error={
                        formik.touched.workingDay &&
                        Boolean(formik.errors.workingDay)
                      }
                    >
                      {daysList &&
                        daysList.map((el) => {
                          return (
                            <MenuItem key={el} value={el}>
                              {el}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="flex mt-6">
                <div className="flex-1">
                  <TextField
                    style={{ width: "32%" }}
                    id="slot"
                    label="Select Number of Slots"
                    name="slot"
                    select
                    value={formik.values.slot}
                    onChange={(e) => {
                      slotChange(e);
                      let currentLength = formik.values.slots.length;
                      // console.log(currentLength, "----Deepak");
                      let arr1 = [];
                      for (let i = 0; i < parseInt(e.target.value); ++i) {
                        arr1.push({
                          startTime: null,
                          endTime: null,
                          slotDuration: 15,
                        });
                      }
                      formik.setFieldValue("slots", arr1);

                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.slot && Boolean(formik.errors.slot)
                        ? "Slot is required.."
                        : ""
                    }
                    error={formik.touched.slot && Boolean(formik.errors.slot)}
                  >
                    {slots &&
                      slots.map((el) => {
                        return (
                          <MenuItem key={el} value={el}>
                            {el}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
              </div>

              {formik.values.slots.map((slot, i) => (
                <div className="flex justify-between gap-4 mt-6">
                  <div className="flex-1 mt-0">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Shift Start Time"
                        name="startTime"
                        value={slot.startTime}
                        // onBlur={formik.handleBlur}
                        onChange={(value) => {
                          let slots = [...formik.values.slots];
                          slots[i].startTime = Date.parse(value);
                          formik.setFieldValue("slots", slots);
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%" }}
                            value={slot.startTime}
                            // onBlur={formik.handleBlur}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="flex-1 mt-0">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Shift End Time"
                        name="endTime"
                        value={slot.endTime}
                        onChange={(value) => {
                          let slots = [...formik.values.slots];
                          slots[i].endTime = Date.parse(value);
                          formik.setFieldValue("slots", slots);
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%" }}
                            value={formik.values.slots[i].endTime}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="flex-1 mt-0">
                    <TextField
                      style={{ width: "100%" }}
                      id="slotDurration"
                      label="Slot Durration"
                      name="slotDurration"
                      select
                      value={slot.slotDurration}
                      onChange={(e) => {
                        let slots = [...formik.values.slots];
                        slots[i].slotDuration = e.target.value;
                        formik.setFieldValue("slots", slots);
                      }}
                    >
                      <MenuItem value={15}>15 Min</MenuItem>
                      <MenuItem value={30}>30 Min</MenuItem>
                      <MenuItem value={45}>45 Min</MenuItem>
                      <MenuItem value={60}>60 Min</MenuItem>
                    </TextField>
                  </div>
                </div>
              ))}</>}

          </div>
          <div className="flex justify-between items-center mt-10 px-5 py-2 font-bold">
            <button onClick={() => handleClose(false)} class="w-[190px] bg-white text-blue-500 rounded-lg border border-blue-500 px-6 py-3">
              Discard
            </button>
            <button type="submit" class="w-[190px] bg-blue-500 text-white rounded-lg border border-blue-500  px-6 py-3">
              Add Schedule
            </button>
          </div>
        </form>
      </Card>
    </div>
  )
}

function diff_minutes(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}
const date_diff_indays = function (date1, date2) {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    (1000 * 60 * 60 * 24)
  );
};
const  createSlotsOfARange = async (slots, realStartDate, realEndDate, body, isWorkingDay) => {
  let slotApis = [];

  const fakeStartDate = new Date(realStartDate);
  const fakeEndDateDemo = new Date(realStartDate);
  // fakeStartDate.setDate(fakeStartDate.getDate() - 1);
  // fakeEndDateDemo.setDate(fakeEndDateDemo.getDate() - 1);
  let currentDate = new Date(fakeEndDateDemo);
  for (let slot of slots) {
    const startTime = new Date(slot.startTime);
    const endTime = new Date(slot.endTime);
    const quad = Math.round(startTime.getMinutes() / 15);

    startTime.setMinutes(quad * 15);
    endTime.setMinutes(quad * 15);
    fakeStartDate.setHours(startTime.getHours());
    fakeEndDateDemo.setHours(endTime.getHours());
    fakeStartDate.setMinutes(startTime.getMinutes());
    fakeEndDateDemo.setMinutes(endTime.getMinutes());

    startTime.setFullYear(fakeStartDate.getFullYear());
    startTime.setMonth(fakeStartDate.getMonth());
    startTime.setDate(fakeStartDate.getDate());

    // endTime.setFullYear(startTime.getFullYear());
    // endTime.setMonth(startTime.getMonth());
    // endTime.setDate(startTime.getDate());
    const endTimeDemo = new Date(startTime);
    endTimeDemo.setHours(endTime.getHours());
    endTimeDemo.setMinutes(endTime.getMinutes())
    // if (startTime.getTime() < fakeEndDateDemo.getTime()) {
    //   startTime.setDate(startTime.getDate() + 1);
    //   endTimeDemo.setDate(endTimeDemo.getDate() + 1);
    //   fakeStartDate.setDate(fakeStartDate.getDate() + 1);
    //   // currentDate.setDate(startTime.getDate());
    //   fakeEndDateDemo.setDate(fakeEndDateDemo.getDate() + 1);
    // }
    // if (endTimeDemo.getTime() < startTime.getTime()) {
    //   endTimeDemo.setDate(endTimeDemo.getDate() + 1);
    // }


    // console.log("working new => ", { startTime, endTimeDemo });
    const difference_in_minutes = diff_minutes(endTimeDemo, startTime);
    const numberOfSlots = difference_in_minutes / slot.slotDuration;
    //loop on particular slot duration in slots
    // console.log({ numberOfSlots });
    if (isWorkingDay(startTime, body.workingDays, currentDate)) {
      const slotsArr = [];

      for (let i = 1; i <= numberOfSlots; i++) {
        const fakeEndTime = new Date(startTime);
        fakeEndTime.setTime(fakeEndTime.getTime() + slot.slotDuration * 60000);
        const appointment = {
          startDate: new Date(startTime),
          endDate: new Date(fakeEndTime),
          startTime: new Date(startTime),
          endTime: new Date(fakeEndTime),
          slotDuration: slot.slotDuration,
          workingDays: body.workingDays,
        };
        slotApis.push(appointment)


        startTime.setTime(startTime.getTime() + slot.slotDuration * 60000);
      }
    }
  }
  return slotApis;
};


export default MultipleScheduler
import React from 'react';

const Imagecircular = (props) => {
    return (
        <div className="flex flex-col justify-center items-center">
            <div className="relative w-20 h-20">
                <img className="rounded-full border-2 border-[#0098C4] shadow-sm p-1 h-20 w-20"
                     src={`${props.doctor.picture}`} alt="user image"/>
            </div>
            <h1 className="text-md text-gray-500">{props.doctor.name.first} {props.doctor.name.last}</h1>
            <h1 className="text-sm text-gray-500 font-bold tracking-widest">{props.doctor.professional.hospital.hospitalName}</h1>
            <h1 className="text-xs text-gray-500 italic font-bold">{props.doctor.professional.speciality?.slice(0,20)}...</h1>
        </div>
    );
};

export default Imagecircular;

import React, { useContext, useEffect, useRef, useState } from "react";
import Card from "../../../../shared/components/Card/Card";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Button, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import Toggledays from "../../../../shared/components/Toggledays/Toggledays";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {createScheduleData} from "../../../../core/services/Data/scheduleService";
import {UserContext} from "../../../../App";
import {toast} from "react-toastify";
import { validationCreateSchemaSchedule } from "../../../../core/Validationschemas/Schedule";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";

const CreateSchedule = (state) => {
  const params = useParams();
  const {setShowLoader} = useContext(UserContext);
  const [workingDays, setWorkingDays] = useState(!params.id && []);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  const navigate = useNavigate();
  // console.log("AKASH MANAGE",state);
  const formik = useFormik({
    initialValues: {
      duration : null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    },
    validationSchema: validationCreateSchemaSchedule,
    onSubmit: (values) => {
      console.log("values", values);
      console.log("Working Days",workingDays )
      setShowLoader((prevState)=>{
        return true
      })
      if(!workingDaysError){
        const payload = {
          ...values,
          slotDuration : values.duration,
          workingDays : workingDays,
          doctorId : state.state //6388ab780628b25a4bf92ca0
        }
        createScheduleData(payload).then((res)=>{
          setShowLoader(false)
          toast.success(res.message)
          navigate('/dashboard/schedules')
        })
      }
    },
  });
  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };

  return (
    <Card cname="px-10 md:flex gap-5 ">
      <div className="md:w-[35rem] ">
        <h1 className="text-xl font-bold text-center">
          Create Doctor Schedule
        </h1>

    

        <div className="col-span-12 mt-4 ">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={formik.values.startDate}
              onChange={(value) => {
                formik.setFieldValue("startDate", Date.parse(value));
              }}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              renderInput={(params) => (
                <TextField 
                
                fullWidth
                name="startDate"
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                    ? "Start Date is required"
                    : ""
                }
                onBlur={formik.handleBlur}
                {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-span-12 mt-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              // disableFuture
              value={formik.values.endDate}
              onChange={(value) => {
                formik.setFieldValue("endDate", Date.parse(value));
              }}
              error={
                formik.touched.endDate && Boolean(formik.errors.endDate)
              }
              renderInput={(params) => (
                <TextField 
                fullWidth
                name="endDate"
                error={
                  formik.touched.endDate && Boolean(formik.errors.endDate)
                }
                helperText={
                  formik.touched.endDate && Boolean(formik.errors.endDate)
                    ? "End Date is required"
                    : ""
                }
                onBlur={formik.handleBlur}
                {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit} >
          <div className="col-span-12">
            <h1 className="text-xl font-bold text-center">Shift details</h1>
            <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
              <div className="col-span-12 md:col-span-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Start Time"
                    name="startTime"
                    helperText={
                      formik.touched.startTime &&
                      Boolean(formik.errors.startTime)
                        ? formik.errors.startTime
                        : ""
                    }
                    value={formik.values.startTime}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.startTime &&
                      Boolean(formik.errors.startTime)
                    }
                    onChange={(value) => {
                      formik.setFieldValue("startTime", Date.parse(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        helperText={
                          formik.touched.startTime &&
                          Boolean(formik.errors.startTime)
                            ? "Start Time is required"
                            : ""
                        }
                        value={formik.values.startTime}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.startTime &&
                          Boolean(formik.errors.startTime)
                        }
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-span-12 md:col-span-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="End Time"
                    name="endTime"
                    helperText={
                      formik.touched.endTime && Boolean(formik.errors.endTime)
                        ? formik.errors.endTime
                        : ""
                    }
                    value={formik.values.endTime}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.endTime && Boolean(formik.errors.endTime)
                    }
                    onChange={(value) => {
                      formik.setFieldValue("endTime", Date.parse(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        helperText={
                          formik.touched.endTime &&
                          Boolean(formik.errors.endTime)
                            ? "End Time is required"
                            : ""
                        }
                        value={formik.values.endTime}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.endTime &&
                          Boolean(formik.errors.endTime)
                        }
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-span-12 md:col-span-6">
                {/* {workingDays && <Toggledays handleWorkingDays={getWorkingDays} workingDays={workingDays}/>}
                            {workingDaysError && (
                                <div className="text-red-500">
                                    Working days is required
                                </div>
                            )} */}
                {/* <TextField
                  fullWidth
                  variant="outlined"
                  id="duration"
                  name="duration"
                  label="Slot Duration"
                  helperText={
                    formik.touched.duration &&
                    Boolean(formik.errors.duration)
                      ? formik.errors.duration
                      : ""
                  }
                  value={formik.values.duration}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.duration &&
                    Boolean(formik.errors.duration)
                  }
                /> */}
                 {<div className="col-span-12 md:col-span-4">
              <FormControl className="w-full">
                <InputLabel id="speciality" className="bg-white">
                  Slot Duration (mins)
                </InputLabel>

                <Select
                 fullWidth
                 variant="outlined"
                 id="duration"
                 name="duration"
                 label="Slot Duration"
                 helperText={
                   formik.touched.duration &&
                   Boolean(formik.errors.duration)
                     ? formik.errors.duration
                     : ""
                 }
                 value={formik.values.duration}
                 onBlur={formik.handleBlur}
                 onChange={formik.handleChange}
                 error={
                   formik.touched.duration &&
                   Boolean(formik.errors.duration)
                 }
                 
                >
                  {[15,30,45,60].map(ele => <MenuItem key={ele} value={ele}>
                    {ele}
                  </MenuItem>)}
                </Select>
              </FormControl>
            </div>}
              </div>
            </div>
          </div>
          <div className="w-2/3">
            <div className="grid grid-cols-12 gap-2 md:grid-cols-7 md:gap-5 lg:gap-8 xl:gap-10 mt-8">
              <div className="col-span-12 md:col-span-6">
                {workingDays && (
                  <Toggledays
                    handleWorkingDays={getWorkingDays}
                    workingDays={workingDays}
                  />
                )}
                {workingDaysError && (
                  <div className="text-red-500">Working days is required</div>
                )}
              </div>
            </div>
          </div>
          <div className = "mt-6 w-64">
          <Button  color="primary" variant="contained" fullWidth type="submit">
            Add
          </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};
export default CreateSchedule;

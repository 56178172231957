import React, {useRef,useContext,useEffect, useState} from 'react';
import {laboratoryOutHouseDetailsTableData} from "../../../../core/constants/appconstants";
import {useNavigate} from "react-router-dom";
import Card from "../../../../shared/components/Card/Card";
import Imagecircular from "../../../../shared/components/Imagecircular/Imagecircular";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import {AgGridReact} from "ag-grid-react";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from 'react-toastify'
import {UserContext} from "../../../../App";
import laboratoryService from "../../../../core/services/Data/laboratoryService"

const Laboratoryouthouse = () => {
    const laboratoryTableData = laboratoryOutHouseDetailsTableData;
    const navigate = useNavigate();
    const gridRef = useRef(null);
    const [LaboratoryTableData, setLaboratoryTableData] = useState()
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const {setShowLoader} = useContext(UserContext);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
       
    };
    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    const getLaboratoryData =()=>{
        setShowLoader(true)
        laboratoryService.getAllLaboratoryOuthouse().then((res)=>{
            if(res){
                setLaboratoryTableData(res.data)
            }
            setShowLoader(false)
        })

    }
    useEffect(()=>{
        getLaboratoryData()
    },[])
    



    const [columnDefs] = useState([
        {headerName: "Image", field: "picture", cellRenderer: Imagerenderer},
        {headerName: "Lab Name", field: "name"},
     
        // {headerName: "Hospital", field: "hospital.hospitalName"},
        // {headerName: "Branch", field: "branchName"},
        // {headerName: "Sponsored", field: "sponsored"},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/laboratory/out-house/add-form/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    setIdToDelete((prevState) => {
                        return id
                    })
                    // console.log('id', id)
                }
            }
        }
    ]);

    

    const deleteInLab = ()=>{
        laboratoryService.deleteOuthouseLaboratory(idToDelete).then((res)=>{
            if(res){
                toast.success(res.message);
                getLaboratoryData()
            }
        })
        // console.log("ID", idToDelete);
    }
    return (
        <>
            {/* <Card> */}
                {/* <div className="flex justify-between mt-3 mb-5"> */}
                    {/* <div className="order-first">
                                <span className="font-bold text-xl tracking-wider">
                                    Laboratories
                                </span>
                    </div>
                    <div className="order-last cursor-pointer">
                                    <span
                                        className="tracking-wider font-bold hover:underline text-sky-600">View all</span>
                    </div> */}
                {/* </div> */}
                {/* <div className="grid grid-cols-12 gap-y-10"> */}
                    {/* <div
                        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                        <Imagecircular></Imagecircular>
                    </div>
                    <div
                        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                        <Imagecircular></Imagecircular>
                    </div>
                    <div
                        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                        <Imagecircular></Imagecircular>
                    </div>
                    <div
                        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                        <Imagecircular></Imagecircular>
                    </div>
                    <div
                        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                        <Imagecircular></Imagecircular>
                    </div>
                    <div
                        className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                        <Imagecircular></Imagecircular>
                    </div> */}
                {/* </div> */}
            {/* </Card> */}

            <div className="mt-10">
                <Card>
                <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteInLab}/>
                    <div className="flex justify-between mb-3">
                        <div className="order-first">
                            <p className="text-2xl font-semibold">
                                Laboratory Outhouse
                            </p>
                        </div>
                        <div className="order-last">
                            <Outlinedbluebutton/>
                            <Filledbluebutton cname="ml-4" clicked={() => {
                                navigate('/dashboard/laboratory/out-house/add-form')
                            }}>
                                Add New
                            </Filledbluebutton>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={LaboratoryTableData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Laboratoryouthouse;

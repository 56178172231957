import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

async function doctorRegister(payload) {
    try {
        const {data: response} = await httpservice.post('dashboard/doctors/create', payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            // console.log({ex})
            toast.error(ex.response.data.message)
        }
    }
}

async function getDoctorById(id) {
    try {
        const {data: response} = await httpservice.get('dashboard/doctors/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function updateDoctorDetails(payload,id) {
    try {
        const {data: response} = await httpservice.put('dashboard/doctors/edit/' + id, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
async function updateSponsored(payload,id){
    try {
        const {data: response} = await httpservice.patch('dashboard/doctors/edit/sponsored/' + id, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    } 
}
async function doctorDeleteData(id){
    try {
        const {data : response} = await httpservice.delete("/dashboard/doctors/delete/" + id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export default {
    doctorRegister,
    getDoctorById,
    updateDoctorDetails, 
    doctorDeleteData,
    updateSponsored
}

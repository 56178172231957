import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";


async function getAllMedicine(){
    const url = 'medicines'
    try {
      const {data : medicines} = await httpservice.get(url);
      return medicines
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}


async function getMedicineById(id) {
    try {
        const {data: response} = await httpservice.get('medicines/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function createMedicine(payload){
    const url = 'medicines/create'
    try {
        const {data : medicines} = await httpservice.post(url, payload)
        return medicines
    } catch (ex) {
        
        if (ex.response && ex.response.status === 400) {
            
            toast.error(ex.response.data.message)
        }
    }
}

async function updateMedicineDetails(payload, id){
    try {
        const {data: response} = await httpservice.put('medicine/delete/' + id, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

 async function UpdateMedicine(id,payload){
    try {
        const url = "medicines/edit/"
        const {data : response} = await httpservice.patch(url+id,payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}

async function deleteMedicineDetails( id){
    try {
        const {data: response} = await httpservice.delete('medicines/delete/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export default {
    getAllMedicine,
    createMedicine,
    getMedicineById,
    deleteMedicineDetails,
    UpdateMedicine
}

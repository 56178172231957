import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {
  createLanguage,
  SingleLanguage,
  Updatelanguage,
} from "../../../../core/services/Data/languageService";
import * as yup from "yup";
import { UserContext } from "../../../../App";
import { toast } from "react-toastify";

const Addlanguageform = () => {
  const { setShowLoader } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const [formPrefillData, setFormPrefillData] = useState();
  const validation = yup.object({
    language: yup.string().required("Language is required"),
  });
  const addLanguage = useFormik({
    initialValues: {
      language: formPrefillData ? formPrefillData.name : "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      setShowLoader(true);
      const payload = {
        name: values.language,
      };
      if (params.id) {
        Updatelanguage(params.id, payload).then((res) => {
          if (res) {
            navigate("/dashboard/language");
            toast.success(res.message);
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        });
      } else {
        createLanguage(payload).then((res) => {
          if (res) {
            navigate("/dashboard/language");
            setShowLoader(false);

            toast.success(res.message);
          } else {
            setShowLoader(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (params.id) {
      setShowLoader(true);
      SingleLanguage(params.id).then((res) => {
        setShowLoader(false);
        setFormPrefillData(res.data);
      });
      setShowLoader(false);

    }
  }, []);
  return (
    <Card cname="max-w-2xl mx-auto">
      <h1 className="text-center text-2xl my-6 font-semibold">
        {params.id ? "Update" : "Add"} Language
      </h1>
      <form onSubmit={addLanguage.handleSubmit}>
        <TextField
          fullWidth
          id="hospitalInput"
          label="Language"
          name="language"
          // select
          value={addLanguage.values.language}
          onChange={addLanguage.handleChange}
          onBlur={addLanguage.handleBlur}
          helperText={
            addLanguage.touched.language && Boolean(addLanguage.errors.language)
              ? "Language is required"
              : ""
          }
          error={
            addLanguage.touched.language && Boolean(addLanguage.errors.language)
          }
        >
          {/* <MenuItem value="Health Insurance">Health Insurance</MenuItem>
                    <MenuItem value="Cipla">Cipla</MenuItem> */}
        </TextField>
        <div className="my-5">
          <Filledbluebutton cname="w-full" btnType="submit">
            {params.id ? "Update" : "Add"}
          </Filledbluebutton>
        </div>
      </form>
    </Card>
  );
};

export default Addlanguageform;

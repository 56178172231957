import React from 'react';
import {Route, Routes} from "react-router-dom";
import Medicinehome from "./Medicinehome/Medicinehome";
import Medicineform from "./Medicineform/Medicineform";

const Medicines = () => {
    return (
        <Routes>
            <Route path="" element={<Medicinehome/>}/>
            <Route path="add-form" element={<Medicineform/>}/>
            <Route path="/:id" element={<Medicineform/>}/>
        </Routes>
    )
};

export default Medicines;

import React,{useEffect,useState,useContext} from 'react';
import {useFormik} from "formik";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {UserContext} from "../../../../App";
import {useNavigate, useParams} from "react-router-dom";
import {getLanguage,createAssign,SingleLanguage}  from "../../../../core/services/Data/languageService"
import {toast} from "react-toastify";
import {doctors} from "../../../../core/services/Data/doctorsService";
import * as yup from 'yup';

const Assigndoctorform = () => {
    const {setShowLoader} = useContext(UserContext);
    const [doctorsArray, setDoctorsArray] = useState(null);
    const navigate = useNavigate();
    const [formPrefillData, setFormPrefillData] = useState();
    const params = useParams()
    const [language, setLanguage] = useState()
    const initialValidation = yup.object({
        language :   yup.string("").required("Insurance Name is required"),
        doctorName :  yup.string("").required("Doctor Name is required")
    })
    const assignDoctorWithDoctor = useFormik({
        initialValues: {
            language: formPrefillData ? formPrefillData.name : '',
            doctorName: ''
        },
        validationSchema : initialValidation,
        enableReinitialize: true,
        onSubmit: (values) => {
            setShowLoader(true)
            const payload = {
                ...values,
                languageName : values.language,
                doctor : {_id : values.doctorName}
            }
            delete payload.language
           
                createAssign(payload).then((res)=>{
                    if(res){
                        toast.success(res.message)
                        navigate('/dashboard/language')
                        setShowLoader(false)
                    }else{
                        setShowLoader(false)
                    }
                }) 
        }
    });
    const getlanguagesData = ()=>{
        setShowLoader(true)
        getLanguage().then((res)=>{
            setLanguage(res.data)
            setShowLoader(false)
        })
    }
    const callGetDoctors = () => {
        setShowLoader(true)
        doctors().then((res) => {
            setShowLoader(false)
            // console.log("DOCTORS", res);
            setDoctorsArray(res);
        })
    }
    useEffect(()=>{
        getlanguagesData()
        callGetDoctors()
        if(params.id){
            setShowLoader(true)
            SingleLanguage(params.id).then((res)=>{
                setShowLoader(false)
                setFormPrefillData(res.data);
            })
        }
    },[])
    console.log("FORmprefill", formPrefillData?.name);
    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">Assign a doctor</h1>
            <form onSubmit={assignDoctorWithDoctor.handleSubmit}>
                <TextField
                    fullWidth
                    id="language"
                    label="Language Name"
                    name="language"
                    select
                    value={assignDoctorWithDoctor.values.language}
                    onChange={assignDoctorWithDoctor.handleChange}
                    onBlur={assignDoctorWithDoctor.handleBlur}
                    helperText={assignDoctorWithDoctor.touched.language && Boolean(assignDoctorWithDoctor.errors.language) ? 'Insurance name is required' : ''}
                    error={assignDoctorWithDoctor.touched.language && Boolean(assignDoctorWithDoctor.errors.language)}>
                    {/* <MenuItem value="Health Insurance">Health Insurance</MenuItem>
                    <MenuItem value="Cipla">Cipla</MenuItem> */}
                    {language && language.map((el)=>{
                           
                           return (<MenuItem value={el.name}>{el.name}</MenuItem>)
                         })}
                </TextField>
                <div className="my-5">
                    <TextField
                        fullWidth
                        id="doctorName"
                        label="Doctor name"
                        select
                        name="doctorName"
                        value={assignDoctorWithDoctor.values.doctorName}
                        onChange={assignDoctorWithDoctor.handleChange}
                        onBlur={assignDoctorWithDoctor.handleBlur}
                        helperText={assignDoctorWithDoctor.touched.doctorName && Boolean(assignDoctorWithDoctor.errors.doctorName) ? 'Doctor name is required' : ''}
                        error={assignDoctorWithDoctor.touched.doctorName && Boolean(assignDoctorWithDoctor.errors.doctorName)}
                    >
                      {doctorsArray && doctorsArray.map((el)=>{
                           
                           return (<MenuItem value={el._id}>{el.name.first} {el.name.last}</MenuItem>)
                         })}  
                    </TextField>
                </div>
                <Filledbluebutton cname="w-full" btnType="submit">{params.id ? "Update" : "add"}</Filledbluebutton>
            </form>
        </Card>
    );
};

export default Assigndoctorform;

import React, { useState, useCallback,useRef, useContext, useEffect } from "react";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../App";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import { promotionDetailsTableData } from "../../../../core/constants/appconstants";
import { AgGridReact } from "ag-grid-react";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import BasicSwitches from "../../../../shared/components/statusButton/StatusButton";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import {
  getAdvertising,
  getbannerTable,
  deleteData,
  getPermotions,
  updatePermotions,
} from "../../../../core/services/Data/permotionService";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import { toast } from "react-toastify";

const Promotionhome = () => {
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const promotionTableData = promotionDetailsTableData;
  const [status, setStatus] = useState();
  const { setShowLoader } = useContext(UserContext);
  const [advertisementArray, setAdvertisementArray] = useState();
  const [bannerArray, setBannerArray] = useState();
  const [promotionsArray, setPromotionsArray] = useState();
  const [idToDelete, setIdToDelete] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
}, []);

  const getPromotionsTable = () => {
    getPermotions().then((res) => {
      setPromotionsArray(res.data);
    });
  };

  const getadvertisement = () => {
    getAdvertising().then((res) => {
      setAdvertisementArray(res.data);
    });
  };

  const getBanner = () => {
    getbannerTable().then((res) => {
      setBannerArray(res.data);
    });
  };

  const GetingDate = (type) => (params) => {
    if (type === "startDate") {
      return params.data.startDate.split("T")[0];
    } else {
      return params.data.endDate.split("T")[0];
    }
  };

  

  const [columnDefs] = useState([
    {
      headerName: "Rank",
      valueGetter: "node.rowIndex + 1"
    },
    {
      headerName: "Category",
      field: "category",
    },
    { headerName: "Entity name", field: "entityName" },
    { headerName: "Whatsapp no.", field: "whatsapp" },
    { headerName: "Description", field: "desc" },

    {
      headerName: "Action",
      cellRenderer: Editdeletebutton,
      sortable: false,
      filter: false,
      cellRendererParams: {
        data: gridRef && gridRef,
        editClicked: (id) => {
          navigate("/dashboard/promotions/add-promotion/update/" + id);
        },
        deleteClicked: (id) => {
          handleOpen();
          setIdToDelete((prevState) => {
            return id;
          });
        },
      },
    },
    {
      headerName: "Status",
      cellRenderer: BasicSwitches,
      sortable: false,
      filter: false,
      cellRendererParams: {
        data: gridRef && gridRef,
        statusChanged: (data) => {
          // navigate('/dashboard/receptionist/registration/' + id);
          // console.log('id', data._id)
          setShowLoader(true);
          updatePermotions(data._id, { status: !data.status }).then((res) => {
            if (res) {
              console.log(data.status,"statusCheck");
              toast.success("Status Updated Successfully");
              setShowLoader(false);
            } else {
              setShowLoader(false);
              toast.error("Something Goes wrong");
            }
          });
        },
      },
    },
    {
      headerName: "Start Date",
      field: "startDate",
      valueGetter: GetingDate("startDate"),
    },
    {
      headerName: "End Date",
      field: "endDate",
      valueGetter: GetingDate("endDate"),
    },
  ]);


  const [columnDefsAdd] = useState([
    {
      headerName: "Rank",
      valueGetter: "node.rowIndex + 1"
    },
    {
      headerName: "Category",
      field: "category",
    },
    { headerName: "Entity name", field: "entityName" },
    { headerName: "Whatsapp no.", field: "whatsapp" },
    { headerName: "Description", field: "desc" },

    {
      headerName: "Action",
      cellRenderer: Editdeletebutton,
      sortable: false,
      filter: false,
      cellRendererParams: {
        data: gridRef && gridRef,
        editClicked: (id) => {
          navigate("/dashboard/promotions/add-advertisement/update/" + id);
        },
        deleteClicked: (id) => {
          handleOpen();
          setIdToDelete((prevState) => {
            return id;
          });
        },
      },
    },
    {
      headerName: "Status",
      cellRenderer: BasicSwitches,
      sortable: false,
      filter: false,
      cellRendererParams: {
        data: gridRef && gridRef,
        statusChanged: (data) => {
          // navigate('/dashboard/receptionist/registration/' + id);
          // console.log('id', data._id)
          setShowLoader(true);
          updatePermotions(data._id, { status: !data.status }).then((res) => {
            if (res) {
              toast.success("Status Updated Successfully");
              setShowLoader(false);
            } else {
              setShowLoader(false);
              toast.error("Something Goes wrong");
            }
          });
        },
      },
    },
    {
      headerName: "Start Date",
      field: "startDate",
      valueGetter: GetingDate("startDate"),
    },
    {
      headerName: "End Date",
      field: "endDate",
      valueGetter: GetingDate("endDate"),
    },
  ]);


  const [columnDefsban] = useState([
    {
      headerName: "Rank",
      valueGetter: "node.rowIndex + 1"
    },
    {
      headerName: "Category",
      field: "category",
    },
    { headerName: "Entity name", field: "entityName" },
    { headerName: "Whatsapp no.", field: "whatsapp" },
    { headerName: "Description", field: "desc" },

    {
      headerName: "Action",
      cellRenderer: Editdeletebutton,
      sortable: false,
      filter: false,
      cellRendererParams: {
        data: gridRef && gridRef,
        editClicked: (id) => {
          navigate("/dashboard/promotions/add-banner/update/" + id);
        },
        deleteClicked: (id) => {
          handleOpen();
          setIdToDelete((prevState) => {
            return id;
          });
        },
      },
    },
    {
      headerName: "Status",
      cellRenderer: BasicSwitches,
      sortable: false,
      filter: false,
      cellRendererParams: {
        data: gridRef && gridRef,
        statusChanged: (data) => {
          // navigate('/dashboard/receptionist/registration/' + id);
          // console.log('id', data._id)
          setShowLoader(true);
          updatePermotions(data._id, { status: !data.status }).then((res) => {
            if (res) {
             
              toast.success("Status Updated Successfully");
              setShowLoader(false);
            } else {
              setShowLoader(false);
              toast.error("Something Goes wrong");
            }
          });
        },
      },
    },
    {
      headerName: "Start Date",
      field: "startDate",
      valueGetter: GetingDate("startDate"),
    },
    {
      headerName: "End Date",
      field: "endDate",
      valueGetter: GetingDate("endDate"),
    },
  ]);


  const deleteOneAdvert = () => {
    deleteData(idToDelete).then((res) => {
      toast.success(res.message);
      getadvertisement();
      getBanner();
      getPromotionsTable();
    });
    // console.log("id", idToDelete);
  };

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  useEffect(() => {
    getadvertisement();
    getBanner();
    getPromotionsTable();
  }, []);
  return (
    <>
      {/*Prommotions section table*/}
      <section>
        <div className="flex justify-between mb-3">
          <div className="order-first">
            <h1 className="text-2xl font-bold tracking-wider">Promotions</h1>
          </div>
          {/* <div className="order-last">
                        <Filterbutton text="Filter by date"></Filterbutton>
                        <Filterbutton cname="mx-4" text="Filter by category"></Filterbutton>
                        <Filterbutton text="Filter by rank"></Filterbutton>
                    </div> */}
        </div>
        {/*Promotions table card*/}
        <Card>
          '{" "}
          <div className="flex justify-between mb-3">
            <div className="order-first">
              <p className="text-2xl font-semibold">Promotions table</p>
            </div>
            <div className="order-last">
              <Outlinedbluebutton download={onBtnExport} />
              <Filledbluebutton
                cname="ml-4"
                clicked={() => {
                  navigate("/dashboard/promotions/add-promotion");
                }}
              />
            </div>
          </div>
          <div className="mt-5">
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                // style={{ width: '100%', height: '100%;' }}
                ref={gridRef}
                rowData={promotionsArray}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowSelection="multiple"
              ></AgGridReact>
            </div>
          </div>
          {/* <Customtable
                        heading={['Rank', 'Category', 'Entity name', 'Whatsapp no.', 'Description', 'Promote', 'Actions', 'Status', 'Start Date', 'End Date']}>
                        {promotionTableData.map((ele) => {
                            return (
                                <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.rank}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.category}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.entityName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.whatsappNo}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.description}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.promote}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                        <EditButton/>
                                        <Deletebutton cname="ml-3"/>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                        <label htmlFor="default-toggle"
                                               className="inline-flex relative items-center cursor-pointer">
                                            <input type="checkbox" value="" id="default-toggle"
                                                   className="sr-only peer"/>
                                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer
                                                      dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                                                       after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                                                        after:bg-white after:border-gray-300 after:border after:rounded-full
                                                         after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        </label>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.startDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.endDate}</td>
                                </tr>
                            )
                        })}
                    </Customtable> */}
        </Card>
      </section>

      {/*Advertisement section table*/}
      <section className="my-10">
        <div className="flex justify-between mb-3">
          <div className="order-first">
            <h1 className="text-2xl font-bold tracking-wider">
              Advertisements
            </h1>
          </div>
          {/* <div className="order-last">
                        <Filterbutton text="Filter by date"></Filterbutton>
                        <Filterbutton cname="mx-4" text="Filter by category"></Filterbutton>
                        <Filterbutton text="Filter by rank"></Filterbutton>
                    </div> */}
        </div>
        <Card>
          <Deletepopup
            handleCloseModel={handleClose}
            isOpen={open}
            id={idToDelete}
            handleDelete={deleteOneAdvert}
          />
          <div className="flex justify-between mb-3">
            <div className="order-first">
              <p className="text-2xl font-semibold">Advertisements Table</p>
            </div>
            <div className="order-last">
              <Outlinedbluebutton download={onBtnExport} />
              <Filledbluebutton
                cname="ml-4"
                clicked={() => {
                  navigate("/dashboard/promotions/add-advertisement");
                }}
              />
            </div>
          </div>

          <div className="mt-5">
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                // style={{ width: '100%', height: '100%;' }}
                ref={gridRef}
                rowData={advertisementArray}
                defaultColDef={defaultColDef}
                columnDefs={columnDefsAdd}
                rowSelection="multiple"
              ></AgGridReact>
            </div>
          </div>

          {/* <Customtable
                        heading={['Rank', 'Category', 'Entity name', 'Whatsapp no.', 'Description', 'Promote', 'Actions', 'State', 'Start Date', 'End Date']}>
                        {promotionTableData.map((ele) => {
                            return (
                                <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.rank}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.category}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.entityName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.whatsappNo}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.description}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.promote}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                        <EditButton/>
                                        <Deletebutton cname="ml-3"/>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                        <label htmlFor="default-toggle"
                                               className="inline-flex relative items-center cursor-pointer">
                                            <input type="checkbox" value="" id="default-toggle"
                                                   className="sr-only peer" checked={ele.status}/>
                                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer
                                                      dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                                                       after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                                                        after:bg-white after:border-gray-300 after:border after:rounded-full
                                                         after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        </label>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.startDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.endDate}</td>
                                </tr>
                            )
                        })}
                    </Customtable> */}
        </Card>
      </section>

      {/*Banner section table*/}
      <section>
        <div className="flex justify-between mb-3">
          <div className="order-first">
            <h1 className="text-2xl font-bold tracking-wider">Banners</h1>
          </div>
          {/* <div className="order-last">
                        <Filterbutton text="Filter by date"></Filterbutton>
                        <Filterbutton cname="mx-4" text="Filter by category"></Filterbutton>
                        <Filterbutton text="Filter by rank"></Filterbutton>
                    </div> */}
        </div>
        <Card>
          <div className="flex justify-between mb-3">
            <div className="order-first">
              <p className="text-2xl font-semibold">Banners Table</p>
            </div>
            <div className="order-last">
              <Outlinedbluebutton download={onBtnExport} />
              <Filledbluebutton
                cname="ml-4"
                clicked={() => {
                  navigate("/dashboard/promotions/add-banner");
                }}
              />
            </div>
          </div>
          {/* <Customtable
                        heading={['Rank', 'Category', 'Entity name', 'Whatsapp no.', 'Description', 'Promote', 'Actions', 'State', 'Start Date', 'End Date']}>
                        {promotionTableData.map((ele) => {
                            return (
                                <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.rank}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.category}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.entityName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.whatsappNo}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.description}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.promote}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                        <EditButton/>
                                        <Deletebutton cname="ml-3"/>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">
                                        <label htmlFor="default-toggle"
                                               className="inline-flex relative items-center cursor-pointer">
                                            <input type="checkbox" value="" id="default-toggle"
                                                   className="sr-only peer" checked={ele.status}/>
                                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer
                                                      dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                                                       after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                                                        after:bg-white after:border-gray-300 after:border after:rounded-full
                                                         after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        </label>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.startDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.endDate}</td>
                                </tr>
                            )
                        })}
                    </Customtable> */}

          <div className="mt-5">
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                // style={{ width: '100%', height: '100%;' }}
                ref={gridRef}
                rowData={bannerArray}
                defaultColDef={defaultColDef}
                columnDefs={columnDefsban}
                rowSelection="multiple"
              ></AgGridReact>
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};

export default Promotionhome;

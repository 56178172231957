import React, {useRef,useContext, useState, useEffect,useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import {holidaysDetailsTableData} from "../../../../core/constants/appconstants";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {AgGridReact} from "ag-grid-react";
import {UserContext} from "../../../../App";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import {getHolidays, deleteHoliday}  from "../../../../core/services/Data/holidayService"
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from "react-toastify";

const Holidayhome = () => {
    const navigate = useNavigate();
    const holidaysDetailsData = holidaysDetailsTableData;
    const gridRef = useRef(null);
    const [holidayData, setHolidayData] = useState()

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const {setShowLoader} = useContext(UserContext);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
       
    };

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    };


    const getHolidaydetails = () =>{
        getHolidays().then((res)=>{
            setHolidayData(res.data);
        })
    }

    useEffect(()=>{
        getHolidaydetails()
    },[])

    const [columnDefs] = useState([
        {headerName: "Date", field: "date", valueGetter : (e)=>{
            return new Date(e.data.date).toDateString()
        }},
        {headerName: "holiday Name", field: "name",},
        {headerName: "Day", field: "day",},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/holidays/add-form/' + id);
                    // console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    setIdToDelete((prevState) => {
                        return id
                    })
                    // console.log('id', id)
                }
            }
        }
    ]);

    const deleteOne = ()=>{
        setShowLoader(true)
        deleteHoliday(idToDelete).then((res)=>{
            toast.success(res.message);
            getHolidaydetails()
            setShowLoader(false)
        }) 
       
    }
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    return (
        <>
            <div className="flex justify-between mb-4">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Holidays</h1>
                </div>
                {/* <div className="order-last">
                    <Filterbutton text="Filter by date"/>
                    <Filterbutton text="Filter by type" cname="ml-4"/>
                </div> */}
            </div>
            <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteOne}/>
                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            List of holidays
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport}/>
                        <Filledbluebutton cname="ml-4" clicked={() => {
                            navigate('/dashboard/holidays/add-form')
                        }}/>
                    </div>
                </div>



                <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={holidayData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>

                {/* <Customtable heading={['Date', 'Holiday name', 'Day', 'Action']}>
                    {holidaysDetailsData.map((ele) => {
                        return (
                            <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.date}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.holidayName}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.day}</td>
                                <td>
                                    <EditButton/>
                                    <Deletebutton cname="ml-3"/>
                                </td>
                            </tr>
                        )
                    })}
                </Customtable> */}
            </Card>
        </>
    );
};

export default Holidayhome;

import {toast} from 'react-toastify'
import jwtDecode from 'jwt-decode';
import httpservice from "../../Httpservice/httpservice,";

const tokenkey = 'token';

export async function login(email, password) {
    try {
        const {data: token} = await httpservice.post("login", {
            email,
            password,
        });
        localStorage.setItem(tokenkey, JSON.stringify(token));
        httpservice.setJWT(`Bearer ${JSON.parse(localStorage.getItem(tokenkey))}`);
        return token;
    } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            toast.error(ex.response.data)
        }
    }
}

httpservice.setJWT(`Bearer ${JSON.parse(localStorage.getItem(tokenkey))}`);

export function logout() {
    localStorage.removeItem(tokenkey);
}

export async function findUser(id) {
    try {
        const result = await httpservice.get(`/users/${id}`)
        return result.data;
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log(ex.response.data)
        }
    }
}

export function getCurrentUser() {
    const token = localStorage.getItem(tokenkey);
    const result = jwtDecode(token, 'Password6654yrht4764');
    return result.id
}

import * as yup from 'yup';
import {phoneNumberRegex} from "../constants/appconstants";


export const advertisementSchema = yup.object({
    category: yup.string('This field is required').required('This field is required').trim(),
    hyperLink: yup.string('This field is required').required('This field is required').trim(),
    whatsappNo: yup.string('This field is required').trim(),
    description: yup.string('This field is required').required('This field is required').trim(),
    image: yup.string('This field is required').required('This field is required').trim(),
    startDate: yup.string('This field is required').required('This field is required').trim(),
    endDate: yup.string('This field is required').required('This field is required').trim(),
    entityName: yup.string('This field is required').required('This field is required').trim(),
   
});



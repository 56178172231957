import React, {useContext, useState,useEffect} from 'react';
import Card from "../../../../shared/components/Card/Card";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {UserContext} from "../../../../App";
import {toast} from "react-toastify";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
// import { insuranceRegistrationValidation } from "../../../../core/Validationschemas/insuranceValidation";
import insuranceService  from "../../../../core/services/Data/insuranceService"
import CloseIcon from "@mui/icons-material/Close";
import symptomsService from "../../../../core/services/Data/symptomsService";
import * as yup from 'yup';

const AddSymptomform = () => {
    const {setShowLoader} = useContext(UserContext);
    const params = useParams()
    const navigate = useNavigate();
    const [formPrefillData, setFormPrefillData] = useState();
    const [branchPermitUrl, setBranchPermitUrl] = useState('');
    const validation = yup.object({
        name: yup.string().required("Symptom Name is required") ,
        // icon : yup.string().required("Symptom icon is required") 
    })
    const formik = useFormik({
        initialValues: {
            name: formPrefillData? formPrefillData.name : null,
            icon :formPrefillData? formPrefillData.icon : null
        }, 
        validationSchema: validation,
        enableReinitialize: true,
        onSubmit: (values) => {
            
            console.log("values", values);
            if(!values?.icon){
       toast.error("Sympton icon is required")
       return
            }
          
            setShowLoader((prevState) => {
                return true
            });
            new Promise((resolve,reject)=>{

            
            if (typeof values.icon !== "string"){
                const response = uploadFileService.fileUpload(values.icon)
                if(response){
                    resolve(response)
                }  else{
                    reject()
                }
            } else{
                resolve() 
            } 
        }).then((res)=>{
                // console.log("Icons Images here", res.data);
                 
                const payload = {
                    ...values,
                    symtomName : values.name,
                symtomIcon : res? res.fileUrl : values.icon
                }
                delete payload.name
                delete payload.icon 
                    if(params.id){
                        symptomsService.updateSymptom(params.id,payload).then((res)=>{
                            if(res){

                                navigate('/dashboard/Symptoms');
                                toast.success(res.message)
                            }else{
                                setShowLoader(false)
                            }
                        })
                    }
                    else{   
                    symptomsService.createSymptoms(payload).then((res)=>{
                        if(res){
                               navigate('/dashboard/Symptoms');
                               toast.success(res.message)
                           }else{
                               setShowLoader(false)
                           }
                    })
                    }
            })
           
           
            
        }
    }); 
    const branchPermitInputChangeHandler = (event) => {
        if (event.currentTarget.files.length !== 0) {
            formik.setFieldValue("icon", event.currentTarget.files[0] ? event.currentTarget.files[0] : '')
            setBranchPermitUrl((prevState) => {
                return URL.createObjectURL(event.target.files[0])
            });
        } else {
            formik.setErrors({branchPermit: 'Feature icon is required'});
        }
    }

    const HospitalResetPermitInput = () => {
        formik.setFieldValue('icon', '');
        setBranchPermitUrl((prevState) => {
            return ''
        });
    }

    useEffect(()=>{
        if(params.id){
            setShowLoader(true)
            symptomsService.Singlegetsymptom(params.id).then((res)=>{
                setShowLoader(false)
                setFormPrefillData(res.data);
                setBranchPermitUrl(res.data.icon)
            })
        }
    },[])
    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">Add Symptom</h1>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="name"
                    label="Symptom Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.name && Boolean(formik.errors.name) ? 'Symptom name is required' : ''}
                    error={formik.touched.name && Boolean(formik.errors.name)}>
                </TextField>
                {/* <div className="my-5">
                    <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={addInsurance.handleBlur}
                        helperText={addInsurance.touched.description && Boolean(addInsurance.errors.description) ? 'Insurance description is required' : ''}
                        error={addInsurance.touched.description && Boolean(addInsurance.errors.description)}
                        multiline
                        rows={5}
                    />
                </div> */}

<div className="flex flex-col justify-center items-center max-w-full mb-5 mt-5">
                                    <label
                                        className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${formik.errors.branchPermit ? 'border-[#d32f2f]' : 'border-gray-300'}
                                        border-dashed cursor-pointer`}>
                                        <input
                                            // ref={branchPermitInputref}
                                            id="dropzone-file"
                                            name="Symptom icon"
                                            disabled={branchPermitUrl !== ''}
                                            accept="image/png, image/jpeg, image/jpg"
                                            onBlur={formik.handleBlur}
                                            onChange={branchPermitInputChangeHandler}
                                            type="file"
                                            className="hidden"/>
                                        {branchPermitUrl !== '' ? (
                                            <div className="relative w-24 h-24">
                                                <img id="blah" src={branchPermitUrl}
                                                     className="shadow w-full object-fill"
                                                     alt="your"/>
                                                <CloseIcon onClick={HospitalResetPermitInput}
                                                           className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"/>
                                            </div>
                                        ) : (
                                            <div
                                                className="flex flex-col justify-center items-center pt-5 pb-6">
                                                <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400"
                                                     fill="none"
                                                     stroke="currentColor" viewBox="0 0 24 24"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                    className="font-semibold">Click to upload symptom icon</span> or
                                                    drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG,
                                                    PNG, JPG or
                                                    GIF
                                                    (MAX.
                                                    800x400px)</p>
                                            </div>
                                        )}
                                    </label>
                                </div>
                <Filledbluebutton cname="w-full" btnType="submit" />
            </form>
        </Card>
    );
};

export default AddSymptomform;

import * as yup from 'yup';
import {phoneNumberRegex} from "../constants/appconstants";

export const laboratoryRegistrationValidation = yup.object({
    hospitalName : yup.string("Please enter a valid hospital name").required("Hospital Name is required").trim(),
    branch : yup.string("Please enter a valid branch Name").required("Branch Name is required").trim(),
    labAssistantName : yup.string("Lab Assistant Name is required").required("Lab Assistant Name is required").trim(),
    email: yup.string('Enter your Email').required('email is required').email('Please enter a valid email').trim(),
    image: yup.string().required('Insurance is required'),
    shopLocation:yup.string('Address is required').required('Address is required')
});

export const laboratoryoutRegistrationValidation = yup.object({
    
    laboratoryName : yup.string("").required("laboratoryName is required").trim(),
    // BranchName : yup.string("").required("BranchName is required").trim(),
    laboratoryLogo : yup.string("").required("laboratoryLogo is required").trim(),
    laboratoryPermit : yup.string("").required("laboratoryPermit is required").trim(),
    address : yup.string("").required("Address is required").trim(),
    startTime: yup.string('Enter your Email').required('Start Time is required').trim(),
    endTime: yup.string('Enter your Email').required('Expiry Time is required').trim(),
    email: yup.string('Enter your Email').required('Email is required').email('Please enter a valid email').trim(),
    phoneNumber: yup.string('Enter your Email').required('Phone number is required').trim(),
    alternatePhoneNumber: yup.string('Enter your Email').nullable("").trim(),
    city: yup.string('Enter your Email').required('City is required').trim(),
    state: yup.string('Enter your State').required('State is required').trim(),
    country: yup.string('Enter your Email').required('Country is required').trim(),
})
import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Locationhome from "./Locationhome/Locationhome";
import Addlocationform from "./Addlocationform/Addlocationform";

const Locations = () => {
    return (
        <Routes>
            <Route path="" element={<Locationhome/>}/>
            <Route path="/add-location" element={<Addlocationform/>}/>
            <Route path="/add-location/:id" element={<Addlocationform/>}/>
            <Route path="*" element={<Navigate to="/dashboard/laboratory/in-house"/>}/>
        </Routes>
    );
};

export default Locations;

import React, {useEffect, useState,useContext, useRef,useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import {InsuranceDetailsTableData, InsuranceDetailsWithDoctor} from "../../../../core/constants/appconstants";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {AgGridReact} from "ag-grid-react";
import {UserContext} from "../../../../App";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import {getSpecialist, deleteSpecialist}  from "../../../../core/services/Data/speciality"
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from "react-toastify";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const Specialityhome = () => {
    const navigate = useNavigate();
    const gridRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const {setShowLoader} = useContext(UserContext);
    const [specialistData, setSpecialistData] = useState()

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
       
    };
    const insuranceDetailsData = InsuranceDetailsTableData;
    const insuranceDetailsDataWithDoctor = InsuranceDetailsWithDoctor;
   
     const gettingSpeciality = ()=>{
        setShowLoader(true)
        getSpecialist().then((res)=>{
            setSpecialistData(res.data)
            setShowLoader(false)
           
        })
     }
     const handleNavigate = (id) => {
        navigate("/dashboard/speciality/assign-speciality/" + id);
      };
     useEffect(()=>{
        gettingSpeciality()
     },[])

     const mulitipleImageRenderer = (props) => {
        console.log("lol", props.getValue());
        let number = 0;
        return (
          <div className="flex gap-2">
            {props.getValue().map((el, i) => {
              if (i < 2) {
                return <img src={el} className="h-8 w-8 " alt="photo" />;
              } else {
                number++;
                return;
              }
            })}
            <p style={{ cursor: "pointer", marginTop: "10px" }}>
              {number ? "+" + number + " more" : null}{" "}
            </p>
          </div>
        );
      };

const editButton = (props) => {
    return (
      <ModeEditIcon
        className={`text-[#0098C4] border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`}
        onClick={() => {
          props.editClicked(props.data._id);
        }}
      />
    );
  };
    const [columnDefs] = useState([
        {headerName: "Speciality", field: "specialityName",resizable: true },
        
        {
            headerName: 'Action', cellRenderer: Editdeletebutton,resizable: true , sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/speciality/add-speciality/' + id);
                    // console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    setIdToDelete((prevState) => {
                        return id
                    })
                    // console.log('id', id)
                }
            }
        }
    ]);

    const [columnDefsDoctor] = useState([
        {headerName: "Speciality", field: "specialityName",resizable: true },
        {
            headerName: "Doctors",
            field: "doctors",
            resizable: true,
            cellRenderer: mulitipleImageRenderer,
            valueGetter: (e) => {
              console.log("Checking-->", e.data);
      
              return e.data.doctors.map((el) => el.picture);
            },
          },
        
        {
            headerName: 'Action', cellRenderer: editButton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/speciality/assign-speciality/' + id);
                    console.log('id', id)
                },
                
            }
        }
    ]);
    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }
    const deleteSpeciality = ()=>{
        setShowLoader(true)
        deleteSpecialist(idToDelete).then((res)=>{
            if(res){
                toast.success(res.message)
                gettingSpeciality()
            }
            setShowLoader(false)
        })
    }
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
      }, []);
    return (
        <>
            <div className="flex justify-between mb-4">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Doctor Speciality</h1>
                </div>
                {/* <div className="order-last">
                    <Filterbutton text="Filter by hospital"/>
                    <Filterbutton tex t="Filter by branch" cname="mx-4"/>
                    <Filterbutton text="Filter by city"/>
                </div> */}
            </div>
            <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteSpeciality}/>

                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            Speciality Table
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport}/>
                        <Filledbluebutton cname="ml-4" clicked={() => {
                            navigate('/dashboard/speciality/add-speciality')
                        }}/>
                    </div>
                </div>
                <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={specialistData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
            </Card>
            {/* <div className="flex justify-between my-4">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Assign Doctors</h1>
                </div>
               
            </div>
            <Card>
                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            Speciality Data with doctors list
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport}/>
                        <Filledbluebutton cname="ml-4" clicked={() => {
                            navigate('/dashboard/speciality/assign-speciality')
                        }}/>
                    </div>
                </div>
             
                 <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={specialistData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefsDoctor}
                                onRowClicked={(e) => {
                                    handleNavigate(e.data._id);
                                  }}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
            </Card> */}
        </>
    );
};

export default Specialityhome;

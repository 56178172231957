import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Brancheshome from "./Brancheshome/Brancheshome";
import Branchesregister from "./Branchesregister/Branchesregister";

const Branches = () => {
    return (
        <Routes>
            <Route path="" element={<Brancheshome/>}/>
            <Route path="/registration" element={<Branchesregister/>}/>
            <Route path="/registration/:id" element={<Branchesregister/>}/>
            <Route path="*" element={<Navigate to=""/>}/>
        </Routes>);
};

export default Branches;

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {hospitalDetailsTableData} from "../../../../core/constants/appconstants";
import {UserContext} from "../../../../App";
import {useNavigate} from "react-router-dom";
import {doctors} from "../../../../core/services/Data/doctorsService";
import doctorRegistrationService from "../../../../core/services/Data/doctorRegistrationService";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Card from "../../../../shared/components/Card/Card";
import Imagecircular from "../../../../shared/components/Imagecircular/Imagecircular";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {AgGridReact} from "ag-grid-react";
import {BasicSponsored} from "../../../../shared/components/statusButton/StatusButton";
import {toast} from "react-toastify";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
// import doctorRegistrationService from "../../../../core/services/Data/doctorRegistrationService";

const Doctorshome = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [idToDelete, setIdToDelete] = useState('');
    const gridRef = useRef(null);
    const [doctorsArray, setDoctorsArray] = useState(null);
    const {setShowLoader} = useContext(UserContext);
    const navigate = useNavigate();
    const callGetDoctors = () => {
        setShowLoader(true)
        doctors(false).then((res) => {
            setShowLoader(false)
            setDoctorsArray(res);
        })
    }

    const [columnDefs] = useState([
        {headerName: "Image", field: "picture", cellRenderer: Imagerenderer},
        {headerName: "Doctor name", field: "name.first", valueGetter : (e) => {return e.data.name.first + ' ' + e.data.name.last}},
        {headerName: "Hospital", field: "professional.hospital.hospitalName"},
        {headerName: "Branch", field: "professional.branch.name"},
        {headerName: "Speciality", field: "professional.speciality"},
        {
            headerName: "Feature",
            cellRenderer: BasicSponsored ,
            sortable: false,
            filter: false,
            cellRendererParams: {
              data: gridRef && gridRef,
              statusChanged: (data) => {
                // navigate('/dashboard/receptionist/registration/' + id);
                console.log('id', data)
                setShowLoader(true);
                doctorRegistrationService.updateSponsored({ sponsored: !data.professional?.sponsored },data._id).then((res) => {
                  if (res) {
                    toast.success("Status Updated Successfully");
                    setShowLoader(false);
                  } else {
                    setShowLoader(false);
                    toast.error("Something Goes wrong");
                  }
                });
              },
            },
          },
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/doctors/registration/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen();
                    setIdToDelete((prevState) => {
                        return id
                    })
                }
            }
        }
    ]);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);
    const deleteDoctor = ()=>{
        setShowLoader(true)
        doctorRegistrationService.doctorDeleteData(idToDelete).then((res)=>{
            if(res){

                toast.success("Doctor Deleted Successfully")
                setShowLoader(false)
                callGetDoctors()
            }
           
        })
        console.log("id", idToDelete);
    }

    useEffect(() => {
        callGetDoctors();
    }, [])
    return (
        <>
            <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteDoctor}/>

                <div className="flex justify-between mt-3 mb-5">
                    <div className="order-first">
                                <span className="font-bold text-xl tracking-wider">
                                    Top Doctors
                                </span>
                    </div>
                    <div className="order-last cursor-pointer">
                                    {/* <span
                                        className="tracking-wider font-bold hover:underline text-sky-600">View all</span> */}
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-y-10">
                    {doctorsArray && doctorsArray.slice(0,6).map((doctor) => {
                        return (
                            <div
                                className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                                <Imagecircular doctor={doctor}/>
                            </div>
                        )
                    })}
                </div>
            </Card>

            <div className="mt-10">
                <Card>
                    <div className="flex justify-between mb-3">
                        <div className="order-first">
                            <p className="text-2xl font-semibold">
                                Doctors list
                            </p>
                        </div>
                        <div className="order-last">
                            <Outlinedbluebutton download={onBtnExport}/>
                            <Filledbluebutton cname="ml-4" clicked={() => {
                                navigate('/dashboard/doctors/registration')
                            }}/>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={doctorsArray}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Doctorshome;

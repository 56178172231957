import * as React from "react";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function BasicSwitches(props) {
  console.log("props", props.data);
  return (
    <div>
      <Switch
        {...label}
        defaultChecked={props.data.status ? true : false}
        onClick={() => props.statusChanged(props.data)}
      />
    </div>
  );
}

export function BasicSponsored(props) {
  console.log("props", props.data);
  return (
    <div>
      <Switch
        {...label}
        defaultChecked={props.data.professional?.sponsored ? true : false}
        onClick={() => props.statusChanged(props.data)}
      />
    </div>
  );
}
export function BasicLeaves(props) {
  console.log("props", props.data.status);
  return (
    <div>
      <Switch
        {...label}
        defaultChecked={
          props.data.status == "Pending" || props.data.status == "Rejected"
            ? false
            : true
        }
        onClick={() => props.statusChanged(props.data)}
      />
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../App";
import LocationPicker from "react-location-picker";
import { useFormik } from "formik";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
import hospitalRegistrationService from "../../../../core/services/Data/hospitalRegistrationService";
import { toast } from "react-toastify";
import { getHospitalById } from "../../../../core/services/Data/hospitalService";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import Toggledays from "../../../../shared/components/Toggledays/Toggledays";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import commonAPICallService from "../../../../core/services/Data/commonAPICallService";
import { doctorRegistrationValidationSchema } from "../../../../core/Validationschemas/doctorRegistrationValidationSchema";
import doctorRegistrationService from "../../../../core/services/Data/doctorRegistrationService";
import LocationService from "../../../../core/services/Data/LocationService";
import { getSpecialist } from "../../../../core/services/Data/speciality";
import { getLanguage } from "../../../../core/services/Data/languageService";
import SearchBox from "./googleSearch";
import insuranceService from "../../../../core/services/Data/insuranceService";
import symptomsService from "../../../../core/services/Data/symptomsService";

const Doctorsregistration = () => {
  const params = useParams();
  const [formPrefillData, setFormPrefillData] = useState();
  const [workingDays, setWorkingDays] = useState(!params.id && []);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  const defaultPosition = {
    lat: 29.378586,
    lng: 47.990341
  };
  const { setShowLoader } = useContext(UserContext);
  const [hospitalsList, setHospitalsList] = useState(null);
  const [branchList, setbranchList] = useState(null);
  const [services, setServices] = useState(["X ray", "Blood test", " MRI"]);
  const [speciality, setSpeciality] = useState([]);
  const [symptoms,setSymptoms] = useState([]);
  const [doctorOtherCertificateUrlError, setOtherCertificateUrlError] =
    useState(false);
  const navigate = useNavigate();
  const doctorOtherInputRef = useRef();
  const [doctorCertificateUrl, setDoctorCertificateUrl] = useState("");
  const [doctorOtherCertificateUrl, setOtherCertificateUrl] = useState("");
  const [doctorAwardsCertificateUrl, setAwardsCertificateUrl] = useState("");

  const [position, setPosition] = useState({ ...defaultPosition });
  useEffect(() => {
    const currentLocation = async () => {
      await navigator.geolocation.getCurrentPosition(
        (position) =>
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (err) => console.log(err)
      );
      // console.log(this.state.latitude)
    };
    currentLocation();
  }, []);
  const doctorsRegistrationForm = useFormik({
    initialValues: {
      firstName: formPrefillData ? formPrefillData.name.first : "",
      lastName: formPrefillData ? formPrefillData.name.last : "",
      email: formPrefillData ? formPrefillData.email : "",
      hospitalName: formPrefillData
        ? formPrefillData.professional.hospital._id
        : "",
      price: formPrefillData ? formPrefillData.price : "",

      branchName: formPrefillData
        ? formPrefillData.professional.branch._id
        : "",
      educationDegree: formPrefillData
        ? formPrefillData.professional.educationDegree
        : "",
      yearsExperience: formPrefillData
        ? formPrefillData.professional.yearsOfExp
        : "",
      specialist: formPrefillData
        ? formPrefillData.professional.speciality.split(",")
        : [],
      symptoms: formPrefillData?.symptoms
        ? formPrefillData.symptoms
        : [],
      language: formPrefillData ? formPrefillData.professional.language.split(",") : [],
      insurance: formPrefillData ? formPrefillData.professional.insurance : "",
      doctorCertificate: formPrefillData
        ? formPrefillData.professional.certificate
        : "",
      otherCertificate: formPrefillData
        ? formPrefillData.professional.otherDocs
        : "",
      awards: formPrefillData ? formPrefillData.professional.awards : "",
      services: formPrefillData
        ? formPrefillData.professional.services.split(",")
        : [],
      phoneNumber: formPrefillData ? formPrefillData.phone : "",
      alternatePhoneNumber: formPrefillData
        ? formPrefillData.alternatePhone
        : "",
      alternateEmail: formPrefillData ? formPrefillData.alternateEmail : "",
      block:formPrefillData ? formPrefillData?.addressBlock?.block || "" : 'B-',
      blockNumber:formPrefillData ? formPrefillData?.addressBlock?.number || "" : '',
      address: formPrefillData ? formPrefillData.address : "",
      city: formPrefillData ? formPrefillData.city : "",
      state: formPrefillData ? formPrefillData.state : "",
      country: formPrefillData ? formPrefillData.country : "",
      startTime: formPrefillData
        ? new Date(formPrefillData.shift.startTime).getTime()
        : null,
      endTime: formPrefillData
        ? new Date(formPrefillData.shift.endTime).getTime()
        : null,
    },
    enableReinitialize: true,
    validationSchema: doctorRegistrationValidationSchema,
    onSubmit: (values) => {
      setShowLoader((prevState) => {
        return true;
      });
      if (!workingDaysError) {
        Promise.all([
          new Promise((resolve, reject) => {
            if (typeof values.doctorCertificate !== "string") {
              const response = uploadFileService.fileUpload(
                values.doctorCertificate
              );
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            } else {
              resolve();
            }
          }),
          new Promise((resolve, reject) => {
            if (typeof values.otherCertificate !== "string") {
              const response = uploadFileService.fileUpload(
                values.otherCertificate
              );
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            } else {
              resolve();
            }
          }),
          new Promise((resolve, reject) => {
            if (typeof values.awards !== "string") {
              const response = uploadFileService.fileUpload(values.awards);
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            } else {
              resolve();
            }
          }),
        ]).then(async ([res1, res2, res3]) => {
          const payload = {
            ...values,
            doctorCertificate: res1 ? res1.fileUrl : values.doctorCertificate,
            otherCertificate: res2 ? res2.fileUrl : values.otherCertificate,
            awards: res3 ? res3.fileUrl : values.awards,
            specialist: values.specialist.join(","),
            services: values.services.join(","),
            language: values.language.join(","),
            workingDays: workingDays,
            location: {
              coordinates: [position.lat, position.lng],
            },
            addressBlock:{
              block:"B-",
              number:values.blockNumber
          },
          };

          if (params.id) {
            doctorRegistrationService
              .updateDoctorDetails(payload, params.id)
              .then((res) => {
                if (res) {
                  navigate("/dashboard/doctors");
                  toast.success("Doctor details updated successfully.");
                }
                setShowLoader((prevState) => {
                  return false;
                });
              });
          } else {
            doctorRegistrationService.doctorRegister(payload).then((res) => {
              if (res) {
                console.log("response of hospital registration is", res);
                navigate("/dashboard/doctors");
                toast.success("Doctor registered successfully and mail sent");
              }

              setShowLoader((prevState) => {
                return false;
              });
            });
          }
        });
      } else {
        setShowLoader((prevState) => {
          return false;
        });
      }
    },
  });
  const doctorCertificateInputHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        doctorsRegistrationForm.setErrors({
          doctorCertificate: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        doctorsRegistrationForm.handleChange(event);
        doctorsRegistrationForm.setFieldValue(
          "doctorCertificate",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setDoctorCertificateUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      doctorsRegistrationForm.setErrors({
        doctorCertificate: "Doctor Certificate is Required!",
      });
    }
  };
  console.log({ err: doctorsRegistrationForm.errors })

  const DoctorCertificateResetInput = () => {
    doctorsRegistrationForm.setFieldValue("doctorCertificate", "");
    setDoctorCertificateUrl((prevState) => {
      return "";
    });
  };

  const DoctorOtherInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        doctorsRegistrationForm.setErrors({
          otherCertificate: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        doctorsRegistrationForm.setFieldValue(
          "otherCertificate",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setOtherCertificateUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      doctorsRegistrationForm.setErrors({
        otherCertificate: "Other Certificate is Required!",
      });
    }
  };

  const DoctorResetOtherInput = () => {
    doctorsRegistrationForm.setFieldValue("otherCertificate", "");
    setOtherCertificateUrl((prevState) => {
      return "";
    });
  };

  const DoctorAwardsInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        doctorsRegistrationForm.setErrors({
          awards: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        doctorsRegistrationForm.setFieldValue(
          "awards",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setAwardsCertificateUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      doctorsRegistrationForm.setErrors({
        awards: "Awards is required!",
      });
    }
  };

  const DoctorResetAwardsInput = () => {
    doctorsRegistrationForm.setFieldValue("awards", "");
    setAwardsCertificateUrl((prevState) => {
      return "";
    });
  };

  const handleLocationChange = ({ position, address, places }) => {
    setPosition({
      lat: position.lat,
      lng: position.lng,
    });
  };

  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };

  const [locatons, setLocations] = useState({
    country: [],
    city: [],
    state: [],
  });
  useEffect(() => {
    const apiData = async () => {
      const { data } = await LocationService.getLocations(doctorsRegistrationForm.values.country, doctorsRegistrationForm.values.state);
      console.log({ data })
      let cities = [...data[0].city];
      let states = [...data[0].state];
      let country = [...data[0].country];
      // for await (let element of data) {
      //   if (!cities.includes(element.city)) {
      //     cities.push(element.city);
      //   }
      //   if (!states.includes(element.state)) {
      //     states.push(element.state);
      //   }
      //   if (!country.includes(element.country)) {
      //     country.push(element.country);
      //   }
      // }
      const locations = { ...locatons };
      locations.city = cities;
      locations.state = states;
      locations.country = country;
      setLocations(locations);
    };
    apiData();
  }, [doctorsRegistrationForm.values.country, doctorsRegistrationForm.values.state]);
  useEffect(() => { }, [hospitalsList, branchList]);
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    const apiData = async () => {
      const { data } = await getLanguage();

      setLanguages(data);
    };
    apiData();
  }, []);
  useEffect(() => {
    const apiData = async () => {
      const { data } = await symptomsService.getSymptoms();
      setSymptoms(data);
    };
    apiData();
  }, []);
  const [insurance, setInsurance] = useState([]);

  useEffect(() => {
    const apiData = async () => {
      const { data } = await insuranceService.getInsuranceData();

      setInsurance(data);
    };
    apiData();
  }, []);


  useEffect(() => {
    setShowLoader(true);
    if (params.id) {
      doctorRegistrationService.getDoctorById(params.id).then((res) => {
        console.log("res from get doctor by id", res);
        commonAPICallService.getHospitalsList().then(async (response) => {
          if (response) {
            await setHospitalsList(response);
            setbranchList((prevState) => {
              return response.filter(
                (ele) => ele.id === res.data.professional.hospital._id
              )[0].branch;
            });
          }
        });
        setAwardsCertificateUrl((prevState) => {
          return res.data.professional.awards;
        });
        setDoctorCertificateUrl((prevState) => {
          return res.data.professional?.certificate;
        });
        setOtherCertificateUrl((prevState) => {
          return res.data.professional.otherDocs;
        });
        setWorkingDays((prevState) => {
          return res.data.workingDays;
        });
        setFormPrefillData((prevState) => {
          setShowLoader(false);
          return res.data;
        });
        setPosition((prevState) => {
          return {
            lng: res?.data?.location?.coordinates[1],
            lat: res?.data?.location?.coordinates[0],
          };
        });
      });
    } else {
      commonAPICallService.getHospitalsList().then(async (res) => {
        console.log("res for hospital list", res);
        if (res) {
          await setHospitalsList(res);
        }
      });
      setShowLoader(false);
    }
  }, []);
  useEffect(() => {
    getSpecialist().then((res) => {
      console.log({ res });
      setSpeciality(res.data);
    });
  }, []);
  const hospitalChange = (event) => {
    doctorsRegistrationForm.setFieldValue("branchName", "")
    const ele = hospitalsList.filter((ele) => {
      return ele.id === event.target.value;
    })[0];
    if(ele){
      doctorsRegistrationForm.setFieldValue("city", ele.city);
      doctorsRegistrationForm.setFieldValue("country", ele.country);
      doctorsRegistrationForm.setFieldValue("state", ele.state);
      doctorsRegistrationForm.setFieldValue("address", ele.address);
    }
    setbranchList((prevState) => {
      console.log({hospitalsList,sle:event.target.value})
      return hospitalsList.filter((ele) => {
        return ele.id === event.target.value;
      })[0].branch;
    });
    
  };
  const branchChange = (event) => {
    const ele =  branchList.filter((ele) => {
      return ele._id === event.target.value;
    })[0];
    if(ele){
      doctorsRegistrationForm.setFieldValue("city", ele.city);
      doctorsRegistrationForm.setFieldValue("country", ele.country);
      doctorsRegistrationForm.setFieldValue("state", ele.state);
      doctorsRegistrationForm.setFieldValue("address", ele.address);
    }
    
  }

  // adding the service MUIcode below

  // adding the service MUI code below

  const { values, setFieldValue, touched, errors, handleBlur } =
    doctorsRegistrationForm;
  const [inputValue, setInputValue] = useState("");
  const selectedItem = values.services || [];

  useEffect(() => {
    console.log(selectedItem);
  }, [selectedItem]);

  function handleKeyDown(event) {
    // ;
    // event.stopPropagation()
    if (event.key === "Enter") {
      event.preventDefault();
      const newSelectedItem = [...selectedItem];
      const trimmedValue = event.target.value.trim();

      if (trimmedValue && newSelectedItem.indexOf(trimmedValue) === -1) {
        newSelectedItem.push(trimmedValue);
      }

      setFieldValue("services", newSelectedItem);
      setInputValue("");
    }

    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1);
      setFieldValue("services", newSelectedItem);
    }
  }

  function handleChange(event) {
    const trimmedValue = event.target.value.trim();

    if (trimmedValue) {
      const newSelectedItem = [...selectedItem];
      const values = trimmedValue.split(" ");

      values.forEach((value) => {
        if (newSelectedItem.indexOf(value) === -1) {
          newSelectedItem.push(value);
        }
      });

      setFieldValue("services", newSelectedItem);
    }

    setInputValue("");
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = selectedItem.filter((service) => service !== item);
    setFieldValue("services", newSelectedItem);
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  const isServicesEmpty = selectedItem.length === 0;
  return (
    <Card cname="px-10">
      <div className="my-10">
        <h1 className="text-3xl font-bold">
          {params.id ? "Update" : "Register"}
        </h1>
        <p className="text-xl font-semibold text-gray-500 mt-2">
          You can {params.id ? "update" : "register"} hospitals, branches,
          clinics
        </p>
      </div>
      <form
        onSubmit={doctorsRegistrationForm.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        {/*Registration Type input field*/}
        <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
          <TextField
            fullWidth
            id="registerType"
            label="Register As"
            name="registerType"
            value="Doctor"
            disabled
            select
          >
            <MenuItem value="Doctor">Doctor</MenuItem>
          </TextField>
        </div>

        {/*Basic Details Input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Basic Details</h1>
          {/*Hospital name input field*/}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="firstName"
                name="firstName"
                label="Enter first name"
                helperText={
                  doctorsRegistrationForm.touched.firstName &&
                    Boolean(doctorsRegistrationForm.errors.firstName)
                    ? doctorsRegistrationForm.errors.firstName
                    : ""
                }
                value={doctorsRegistrationForm.values.firstName}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.firstName &&
                  Boolean(doctorsRegistrationForm.errors.firstName)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="lastName"
                name="lastName"
                label="Enter Last Name"
                helperText={
                  doctorsRegistrationForm.touched.lastName &&
                    Boolean(doctorsRegistrationForm.errors.lastName)
                    ? doctorsRegistrationForm.errors.lastName
                    : ""
                }
                value={doctorsRegistrationForm.values.lastName}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.lastName &&
                  Boolean(doctorsRegistrationForm.errors.lastName)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="email"
                label="Enter your email"
                helperText={
                  doctorsRegistrationForm.touched.email &&
                    Boolean(doctorsRegistrationForm.errors.email)
                    ? doctorsRegistrationForm.errors.email
                    : ""
                }
                value={doctorsRegistrationForm.values.email}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.email &&
                  Boolean(doctorsRegistrationForm.errors.email)
                }
              />
            </div>
          </div>
        </div>

        {/*Professional details*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Professional Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                id="hospitalName"
                label="Hospital name"
                name="hospitalName"
                select
                helperText={
                  doctorsRegistrationForm.touched.hospitalName &&
                    Boolean(doctorsRegistrationForm.errors.hospitalName)
                    ? doctorsRegistrationForm.errors.hospitalName
                    : ""
                }
                value={doctorsRegistrationForm.values.hospitalName}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={(e) => {
                  hospitalChange(e);
                  doctorsRegistrationForm.handleChange(e);
                }}
                error={
                  doctorsRegistrationForm.touched.hospitalName &&
                  Boolean(doctorsRegistrationForm.errors.hospitalName)
                }
              >
                {hospitalsList &&
                  hospitalsList.map((ele) => {
                    return (
                      <MenuItem value={ele.id} key={ele.id}>
                        {ele.hospital}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                id="branch"
                label="Branch name"
                name="branchName"
                select
                helperText={
                  doctorsRegistrationForm.touched.branchName &&
                    Boolean(doctorsRegistrationForm.errors.branchName)
                    ? doctorsRegistrationForm.errors.branchName
                    : ""
                }
                value={doctorsRegistrationForm.values.branchName}
                onChange={(e) => {
                  branchChange(e);
                  doctorsRegistrationForm.handleChange(e);
                }}
                onBlur={doctorsRegistrationForm.handleBlur}
                // onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.branchName &&
                  Boolean(doctorsRegistrationForm.errors.branchName)
                }
              >
                {console.log({ branchList })}
                {/* <MenuItem value="Main Branch">Main Branch</MenuItem> */}
                {branchList &&
                  branchList.map((ele) => {
                    // console.log({ values: doctorsRegistrationForm.values });
                    return (
                      <MenuItem value={ele._id} key={ele._id}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="educationDegree"
                name="educationDegree"
                label="Enter your Education Degree"
                helperText={
                  doctorsRegistrationForm.touched.educationDegree &&
                    Boolean(doctorsRegistrationForm.errors.educationDegree)
                    ? doctorsRegistrationForm.errors.educationDegree
                    : ""
                }
                value={doctorsRegistrationForm.values.educationDegree}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.educationDegree &&
                  Boolean(doctorsRegistrationForm.errors.educationDegree)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="yearsExperience"
                name="yearsExperience"
                label="Enter years of experience"
                helperText={
                  doctorsRegistrationForm.touched.yearsExperience &&
                    Boolean(doctorsRegistrationForm.errors.yearsExperience)
                    ? doctorsRegistrationForm.errors.yearsExperience
                    : ""
                }
                value={doctorsRegistrationForm.values.yearsExperience}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.yearsExperience &&
                  Boolean(doctorsRegistrationForm.errors.yearsExperience)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              {/*Image upload hospital logo*/}
              <div className="flex flex-col justify-center items-center max-w-full">
                <label
                  className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${doctorsRegistrationForm.errors
                      .doctorCertificate
                      ? "border-[#d32f2f]"
                      : "border-gray-300"
                    }
                         border-dashed cursor-pointer`}
                >
                  <input
                    id="dropzone-file"
                    name="doctorCertificate"
                    disabled={doctorCertificateUrl !== ""}
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    onBlur={doctorsRegistrationForm.handleBlur}
                    onChange={doctorCertificateInputHandler}
                    type="file"
                    className="hidden"
                  />
                  {doctorCertificateUrl !== "" ? (
                    <div className="relative w-24 h-24">
                      <img
                        id="blah"
                        src={doctorCertificateUrl}
                        className="shadow w-full object-fill"
                        alt="your"
                      />
                      <CloseIcon
                        onClick={DoctorCertificateResetInput}
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload Doctor Certificate
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`text-[#d32f2f] my-2 text-sm ml-4 ${doctorsRegistrationForm.errors.doctorCertificate
                    ? ""
                    : "hidden"
                  }`}
              >
                {doctorsRegistrationForm.errors.doctorCertificate}
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              {/*Hospital permit image upload*/}
              <div className="flex flex-col justify-center items-center max-w-full">
                <label
                  className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${doctorsRegistrationForm.errors
                      .otherCertificate
                      ? "border-[#d32f2f]"
                      : "border-gray-300"
                    }
                                        border-dashed cursor-pointer`}
                >
                  <input
                    ref={doctorOtherInputRef}
                    id="dropzone-file"
                    name="otherCertificate"
                    disabled={doctorOtherCertificateUrl !== ""}
                    accept="image/png, image/jpeg, image/jpg"
                    onBlur={doctorsRegistrationForm.handleBlur}
                    onChange={DoctorOtherInputChangeHandler}
                    type="file"
                    className="hidden"
                  />
                  {doctorOtherCertificateUrl !== "" ? (
                    <div className="relative w-24 h-24">
                      <img
                        id="blah"
                        src={doctorOtherCertificateUrl}
                        className="shadow w-full object-fill"
                        alt="your"
                      />
                      <CloseIcon
                        onClick={DoctorResetOtherInput}
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload Other Certificates
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`text-[#d32f2f] my-2 text-sm ml-4 ${doctorsRegistrationForm.errors.otherCertificate
                    ? ""
                    : "hidden"
                  }`}
              >
                {doctorsRegistrationForm.errors.otherCertificate}
              </div>
            </div>
            <div className="col-span-12 md:col-span-4">
              <FormControl className="w-full">
                <InputLabel id="speciality" className="bg-white">
                  Speciality
                </InputLabel>

                <Select
                  fullWidth
                  labelId="speciality"
                  id="specialist"
                  className="w-full"
                  multiple
                  label="Enter Speciality"
                  name="specialist"
                  helperText={
                    doctorsRegistrationForm.touched.specialist &&
                      Boolean(doctorsRegistrationForm.errors.specialist)
                      ? doctorsRegistrationForm.errors.specialist
                      : ""
                  }
                  value={doctorsRegistrationForm.values.specialist}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  onChange={doctorsRegistrationForm.handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  error={
                    doctorsRegistrationForm.touched.specialist &&
                    Boolean(doctorsRegistrationForm.errors.specialist)
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {speciality.map((ele) => (
                    <MenuItem key={ele._id} value={ele.specialityName}>
                      {ele.specialityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>


            {/* <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="specialist"
                name="specialist"
                label="Enter Speciality"
                helperText={
                  doctorsRegistrationForm.touched.specialist &&
                  Boolean(doctorsRegistrationForm.errors.specialist)
                    ? doctorsRegistrationForm.errors.specialist
                    : ""
                }
                value={doctorsRegistrationForm.values.specialist}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.specialist &&
                  Boolean(doctorsRegistrationForm.errors.specialist)
                }
              />
            </div> */}
            {/* <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="language"
                name="language"
                label="Enter language"
                helperText={
                  doctorsRegistrationForm.touched.language &&
                  Boolean(doctorsRegistrationForm.errors.language)
                    ? doctorsRegistrationForm.errors.language
                    : ""
                }
                value={doctorsRegistrationForm.values.language}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.language &&
                  Boolean(doctorsRegistrationForm.errors.language)
                }
              />
            </div> */}
            <div className="col-span-12 md:col-span-4">
              <FormControl className="w-full">
                <InputLabel id="language" className="bg-white">
                  Enter language
                </InputLabel>

                <Select
                  fullWidth
                  labelId="language"
                  className="w-full"
                  multiple
                  id="language"
                  name="language"
                  label="Enter language"
                  helperText={
                    doctorsRegistrationForm.touched.language &&
                      Boolean(doctorsRegistrationForm.errors.language)
                      ? doctorsRegistrationForm.errors.language
                      : ""
                  }
                  value={doctorsRegistrationForm.values.language}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  onChange={doctorsRegistrationForm.handleChange}
                  error={
                    doctorsRegistrationForm.touched.language &&
                    Boolean(doctorsRegistrationForm.errors.language)
                  }
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }

                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {languages.map((ele) => (
                    <MenuItem key={ele._id} value={ele.name}>
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-12 md:col-span-4">
              <FormControl className="w-full">
                <InputLabel id="speciality" className="bg-white">
                  Insurance
                </InputLabel>

                <Select
                  fullWidth
                  labelId="speciality"
                  id="insurance"
                  className="w-full"
                  // multiple
                  label="Enter Speciality"
                  name="insurance"
                  helperText={
                    doctorsRegistrationForm.touched.insurance &&
                      Boolean(doctorsRegistrationForm.errors.insurance)
                      ? doctorsRegistrationForm.errors.insurance
                      : ""
                  }
                  value={doctorsRegistrationForm.values.insurance}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  onChange={doctorsRegistrationForm.handleChange}
                  // input={
                  //   <OutlinedInput id="select-multiple-chip" label="Chip" />
                  // }
                  // renderValue={(selected) => (
                  //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  //     {selected.map((value) => (
                  //       <Chip key={value} label={value} />
                  //     ))}
                  //   </Box>
                  // )}
                >
                  {insurance.map((ele) => (
                    <MenuItem key={ele._id} value={ele.name}>
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="insurance"
                name="insurance"
                label="Enter Insurance"
                helperText={
                  doctorsRegistrationForm.touched.insurance &&
                    Boolean(doctorsRegistrationForm.errors.insurance)
                    ? doctorsRegistrationForm.errors.insurance
                    : ""
                }
                value={doctorsRegistrationForm.values.insurance}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.insurance &&
                  Boolean(doctorsRegistrationForm.errors.insurance)
                }
              />
            </div> */}
            <div className="col-span-12 md:col-span-6">
              <div className="col-span-12">
                {/*Hospital permit image upload*/}
                <div className="flex flex-col justify-center items-center max-w-full">
                  <label
                    className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${doctorsRegistrationForm.errors.awards
                        ? "border-[#d32f2f]"
                        : "border-gray-300"
                      }
                                        border-dashed cursor-pointer`}
                  >
                    <input
                      ref={doctorOtherInputRef}
                      id="dropzone-file"
                      name="awards"
                      disabled={doctorAwardsCertificateUrl !== ""}
                      accept="image/png, image/jpeg, image/jpg"
                      onBlur={doctorsRegistrationForm.handleBlur}
                      onChange={DoctorAwardsInputChangeHandler}
                      type="file"
                      className="hidden"
                    />
                    {doctorAwardsCertificateUrl !== "" ? (
                      <div className="relative w-24 h-24">
                        <img
                          id="blah"
                          src={doctorAwardsCertificateUrl}
                          className="shadow w-full object-fill"
                          alt="your"
                        />
                        <CloseIcon
                          onClick={DoctorResetAwardsInput}
                          className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="mb-3 w-10 h-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">
                            Click to upload Awards
                          </span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          SVG, PNG, JPG or GIF (MAX. 800x400px)
                        </p>
                      </div>
                    )}
                  </label>
                </div>
                <div
                  className={`text-[#d32f2f] my-2 text-sm ml-4 ${doctorsRegistrationForm.errors.awards ? "" : "hidden"
                    }`}
                >
                  {doctorsRegistrationForm.errors.awards}
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 flex flex-col gap-8">
              {/* <FormControl className="w-full">
                <InputLabel id="speciality" className="bg-white">
                  Services
                </InputLabel>

                <Select
                  className="w-full"
                  multiple
                  id="services"
                  name="services"
                  label="Enter Services"
                  helperText={
                    doctorsRegistrationForm.touched.services &&
                    Boolean(doctorsRegistrationForm.errors.services)
                      ? doctorsRegistrationForm.errors.services
                      : ""
                  }
                  value={doctorsRegistrationForm.values.services}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  onChange={doctorsRegistrationForm.handleChange}
                  // error={
                  //   doctorsRegistrationForm.touched.services &&
                  //   Boolean(doctorsRegistrationForm.errors.services)
                  // }
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {services.map((serv) => (
                    <MenuItem key={serv} value={serv}>
                      {serv}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              {/* Adding new service input code below */}
              {/* Adding new service input code below */}
              <FormControl className="w-full">
                <TextField
                  label="Services"
                  placeholder="Add Services"
                  InputProps={{
                    // startAdornment: selectedItem.map((item) => (
                    //   <Chip
                    //     key={item}
                    //     tabIndex={-1}
                    //     label={item}
                    //     onDelete={handleDelete(item)}
                    //   />
                    // )),

                    onChange: (event) => {
                      // event.preventDefault()
                      handleInputChange(event);
                      handleChange(event.target.value);
                    },
                  }}
                  onKeyDown={handleKeyDown}
                  value={inputValue}
                  fullWidth
                  variant="outlined"
                  id="services"
                  name="services"
                  error={
                    doctorsRegistrationForm.touched.services &&
                    Boolean(doctorsRegistrationForm.errors.services)
                  }
                  helperText={
                    touched.services && Boolean(errors.services)
                      ? errors.services
                      : ""
                  }
                  onBlur={handleBlur}
                />
                <div className="my-2 flex gap-2 flex-wrap">
                {selectedItem.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        onDelete={handleDelete(item)}
                      />
                    ))}
                </div>
              </FormControl>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                id="price"
                name="price"
                label="Price"
                helperText={
                  doctorsRegistrationForm.touched.price &&
                    Boolean(doctorsRegistrationForm.errors.price)
                    ? doctorsRegistrationForm.errors.price
                    : ""
                }
                value={doctorsRegistrationForm.values.price}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.price &&
                  Boolean(doctorsRegistrationForm.errors.price)
                }
              />
<div className="col-span-12 md:col-span-4">
              <FormControl className="w-full">
                <InputLabel id="speciality" className="bg-white">
                  Symptoms
                </InputLabel>

                <Select
                  fullWidth
                  labelId="symptom"
                  id="symptom"
                  className="w-full"
                  multiple
                  label="Enter symptom"
                  name="symptoms"
                  helperText={
                    doctorsRegistrationForm.touched.symptoms &&
                      Boolean(doctorsRegistrationForm.errors.symptoms)
                      ? doctorsRegistrationForm.errors.symptoms
                      : ""
                  }
                  error={
                    doctorsRegistrationForm.touched.symptoms &&
                    Boolean(doctorsRegistrationForm.errors.symptoms)
                  }
                  value={doctorsRegistrationForm.values.symptoms}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  onChange={doctorsRegistrationForm.handleChange}
                  // input={
                  //   <OutlinedInput id="select-multiple-chip" label="Chip" />
                  // }
                  // renderValue={(selected) => (
                  //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  //     {selected.map((value) => (
                  //       <Chip key={value} label={value} />
                  //     ))}
                  //   </Box>
                  // )}
                >
                  {symptoms.map((ele) => (
                    <MenuItem key={ele._id} value={ele._id}>
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            </div>

          </div>
        </div>

        {/*Contact Details input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Contact Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="phoneNumber"
                name="phoneNumber"
                label="Enter phone number"
                helperText={
                  doctorsRegistrationForm.touched.phoneNumber &&
                    Boolean(doctorsRegistrationForm.errors.phoneNumber)
                    ? doctorsRegistrationForm.errors.phoneNumber
                    : ""
                }
                value={doctorsRegistrationForm.values.phoneNumber}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.phoneNumber &&
                  Boolean(doctorsRegistrationForm.errors.phoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="alternatePhoneNumber"
                name="alternatePhoneNumber"
                label="Enter alternate phone number"
                helperText={
                  doctorsRegistrationForm.touched.alternatePhoneNumber &&
                    Boolean(doctorsRegistrationForm.errors.alternatePhoneNumber)
                    ? doctorsRegistrationForm.errors.alternatePhoneNumber
                    : ""
                }
                value={doctorsRegistrationForm.values.alternatePhoneNumber}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.alternatePhoneNumber &&
                  Boolean(doctorsRegistrationForm.errors.alternatePhoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="alternateEmail"
                name="alternateEmail"
                label="Enter your Alternate email"
                helperText={
                  doctorsRegistrationForm.touched.alternateEmail &&
                    Boolean(doctorsRegistrationForm.errors.alternateEmail)
                    ? doctorsRegistrationForm.errors.alternateEmail
                    : ""
                }
                value={doctorsRegistrationForm.values.alternateEmail}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.alternateEmail &&
                  Boolean(doctorsRegistrationForm.errors.alternateEmail)
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-2 md:mt-8">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                // disabled
                name="address"
                label="Enter address"
                helperText={
                  doctorsRegistrationForm.touched.address &&
                    Boolean(doctorsRegistrationForm.errors.address)
                    ? doctorsRegistrationForm.errors.address
                    : ""
                }
                value={doctorsRegistrationForm.values.address}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.address &&
                  Boolean(doctorsRegistrationForm.errors.address)
                }
              />
            </div>
            <div className="col-span-6 md:col-span-3 ">
                            {/* <InputLabel>B-</InputLabel> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="blockNumber"
                                name="blockNumber"
                                label="Enter Block Number"
                                helperText={doctorsRegistrationForm.touched.blockNumber && Boolean(doctorsRegistrationForm.errors.blockNumber) ? doctorsRegistrationForm.errors.blockNumber : ''}
                                value={`${doctorsRegistrationForm.values.block || "B-"}${doctorsRegistrationForm.values.blockNumber}`}
                                onBlur={doctorsRegistrationForm.handleBlur}
                                onChange={(e)=>{
                                    const values = e.target.value.split("-");
                                    e.target.value = values[1] || "";
                                    doctorsRegistrationForm.handleChange(e)
                                }}
                                // InputProps={{
                                //     inputComponent: blockElement,
                                // }}
                                error={doctorsRegistrationForm.touched.blockNumber && Boolean(doctorsRegistrationForm.errors.blockNumber)}
                            />
                        </div>
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                disabled
                id="country"
                label="Enter Country"
                name="country"
                helperText={
                  doctorsRegistrationForm.touched.country &&
                    Boolean(doctorsRegistrationForm.errors.country)
                    ? doctorsRegistrationForm.errors.country
                    : ""
                }
                value={doctorsRegistrationForm.values.country}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.country &&
                  Boolean(doctorsRegistrationForm.errors.country)
                }
                select
              >
                {locatons.country.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-3">
              <TextField
                disabled
                fullWidth
                id="state"
                label="Enter State"
                name="state"
                helperText={
                  doctorsRegistrationForm.touched.state &&
                    Boolean(doctorsRegistrationForm.errors.state)
                    ? doctorsRegistrationForm.errors.state
                    : ""
                }
                value={doctorsRegistrationForm.values.state}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.state &&
                  Boolean(doctorsRegistrationForm.errors.state)
                }
                select
              >
                {locatons.state.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>

           
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="city"
                disabled
                label="Enter City"
                name="city"
                helperText={
                  doctorsRegistrationForm.touched.city &&
                    Boolean(doctorsRegistrationForm.errors.city)
                    ? doctorsRegistrationForm.errors.city
                    : ""
                }
                value={doctorsRegistrationForm.values.city}
                onBlur={doctorsRegistrationForm.handleBlur}
                onChange={doctorsRegistrationForm.handleChange}
                error={
                  doctorsRegistrationForm.touched.city &&
                  Boolean(doctorsRegistrationForm.errors.city)
                }
                select
              >
                {locatons.city.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>

            {/* <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="city"
                                name="city"
                                label="Enter City"
                                helperText={doctorsRegistrationForm.touched.city && Boolean(doctorsRegistrationForm.errors.city) ? doctorsRegistrationForm.errors.city : ''}
                                value={doctorsRegistrationForm.values.city}
                                onBlur={doctorsRegistrationForm.handleBlur}
                                onChange={doctorsRegistrationForm.handleChange}
                                error={doctorsRegistrationForm.touched.city && Boolean(doctorsRegistrationForm.errors.city)}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="country"
                                name="country"
                                label="Enter Country"
                                helperText={doctorsRegistrationForm.touched.country && Boolean(doctorsRegistrationForm.errors.country) ? doctorsRegistrationForm.errors.country : ''}
                                value={doctorsRegistrationForm.values.country}
                                onBlur={doctorsRegistrationForm.handleBlur}
                                onChange={doctorsRegistrationForm.handleChange}
                                error={doctorsRegistrationForm.touched.country && Boolean(doctorsRegistrationForm.errors.country)}
                            />
                        </div> */}
          </div>
        </div>

        {/*Hospital Location Details Input map field*/}
        <div className="col-span-12 mt-2">
          <h1 className="text-xl font-semibold">Hospital location</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 relative">
              <div className='absolute left-56 top-[10px] z-50'>

                <SearchBox
                  setPosition={setPosition}
                />
              </div>
              {/*<img src="https://i.postimg.cc/qRF5QCjR/map-register.png" alt="map"*/}
              {/*     className="w-full-object-fill"/>*/}
              <LocationPicker
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "400px" }} />}
                defaultPosition={position}
                onChange={handleLocationChange}
              />
            </div>
          </div>
        </div>

        {/*Shift details*/}
        <div className="col-span-12 mt-2">
          <h1 className="text-xl font-semibold my-4">Shift details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  name="startTime"
                  helperText={
                    doctorsRegistrationForm.touched.startTime &&
                      Boolean(doctorsRegistrationForm.errors.startTime)
                      ? doctorsRegistrationForm.errors.startTime
                      : ""
                  }
                  value={doctorsRegistrationForm.values.startTime}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  error={
                    doctorsRegistrationForm.touched.startTime &&
                    Boolean(doctorsRegistrationForm.errors.startTime)
                  }
                  onChange={(value) => {
                    doctorsRegistrationForm.setFieldValue(
                      "startTime",
                      Date.parse(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        doctorsRegistrationForm.touched.startTime &&
                          Boolean(doctorsRegistrationForm.errors.startTime)
                          ? "Start Time is required"
                          : ""
                      }
                      value={doctorsRegistrationForm.values.startTime}
                      onBlur={doctorsRegistrationForm.handleBlur}
                      error={
                        doctorsRegistrationForm.touched.startTime &&
                        Boolean(doctorsRegistrationForm.errors.startTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  name="endTime"
                  helperText={
                    doctorsRegistrationForm.touched.endTime &&
                      Boolean(doctorsRegistrationForm.errors.endTime)
                      ? doctorsRegistrationForm.errors.endTime
                      : ""
                  }
                  value={doctorsRegistrationForm.values.endTime}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  error={
                    doctorsRegistrationForm.touched.endTime &&
                    Boolean(doctorsRegistrationForm.errors.endTime)
                  }
                  onChange={(value) => {
                    doctorsRegistrationForm.setFieldValue(
                      "endTime",
                      Date.parse(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        doctorsRegistrationForm.touched.endTime &&
                          Boolean(doctorsRegistrationForm.errors.endTime)
                          ? "End Time is required"
                          : ""
                      }
                      value={doctorsRegistrationForm.values.endTime}
                      onBlur={doctorsRegistrationForm.handleBlur}
                      error={
                        doctorsRegistrationForm.touched.endTime &&
                        Boolean(doctorsRegistrationForm.errors.endTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-6">
              {workingDays && (
                <Toggledays
                  handleWorkingDays={getWorkingDays}
                  workingDays={workingDays}
                />
              )}
              {workingDaysError && (
                <div className="text-red-500">Working days is required</div>
              )}
            </div>
          </div>
        </div>

        {/*Submit button*/}
        <Filledbluebutton
          cname="w-32"
          btnType="submit"
          disabledBtn={!doctorsRegistrationForm.isValid}
        >
          {params.id ? "Update" : "Submit"}
        </Filledbluebutton>
      </form>
    </Card>
  );
};

export default Doctorsregistration;

import React, {Fragment} from 'react';

const Resetmailsent = () => {
    return (
        <Fragment>
            <div className="py-5">
                <h1 className="text-4xl font-bold">Check your mail</h1>
                <h3 className="text-lg my-3 text-start text-gray-500 max-w-sm">
                    We’ve sent a password recovery instructions to your email.
                </h3>
                <div className="flex justify-center items-center">
                    <img src="https://i.postimg.cc/g2pVCsSq/check-circle.gif" alt=""/>
                </div>
                <div className="mt-5 text-center text-gray-400">
                    Do not receive this email, check your
                    <span className="text-red-500 font-bold"> spam</span> folder
                </div>
            </div>
        </Fragment>
    );
};

export default Resetmailsent;

import React from 'react';

const Imagerenderer = (props) => {
    console.log("=====>",props)
    return (
        <img src={props.getValue()} className="h-8 w-8" alt="photo" />
    );
};




export default Imagerenderer;

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const Leftbutton = (props) => {
    return (
        <button
            onClick={props.clicked}
            className={`border border-red-500 hover:border-red-700 shadow p-2 flex
         justify-evenly items-center ${props.big ? 'rounded-lg w-32 text-white bg-red-500 hover:bg-red-700'
            : 'h-7 w-7 rounded-full text-red-500 hover:text-red-700'}`}>
            {props.big ? (<span>Decline</span>) : (<span></span>)}
            <CloseIcon/>
        </button>
    );
};

export default Leftbutton;

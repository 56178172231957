import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

export async function getPerformanceData() {
    try {
        const {data: analytics} = await httpservice.get('dashboard/performance');
        return analytics
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

import React, {useEffect, useState, useContext} from 'react';
import {useFormik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import * as yup from 'yup';
import symptomsService from "../../../../core/services/Data/symptomsService";
import {UserContext} from "../../../../App";
import {toast} from "react-toastify";
import {doctors} from "../../../../core/services/Data/doctorsService";


const Assigndoctorform = () => {
    const [insuranceData, setInsuranceData] = useState();
    const [doctorsArray, setDoctorsArray] = useState(null);
    const [formPrefillData, setFormPrefillData] = useState();
    const navigate = useNavigate();
    const {setShowLoader} = useContext(UserContext);
    const params = useParams()
    const initialValidation = yup.object({
        insuranceName :   yup.string("").required("Insurance Name is required"),
        doctorName :  yup.string("").required("Doctor Name is required")
    })


    const getInsurance = ()=>{
        setShowLoader(true)
        symptomsService.getSymptoms().then((res)=>{
            setInsuranceData(res.data);
            setShowLoader(false)
        })
    }
    const callGetDoctors = () => {
        setShowLoader(true)
        doctors().then((res) => {
            setShowLoader(false)
            // console.log("DOCTORS", res);
            setDoctorsArray(res);
        })
    }


    const assignDoctor = useFormik({
        initialValues: {
            insuranceName: formPrefillData ? formPrefillData.name : '',
            doctorName: ''
        },
        validationSchema : initialValidation,
        enableReinitialize: true,
        onSubmit: (values) => {
            setShowLoader(true)
            const payload = {
                ...values,
                symtomName : values.insuranceName,
                doctor:{_id: values.doctorName}
            }
            delete payload.insuranceName
            symptomsService.createassignSymptom(payload).then((res)=>{
                if(res){
                    toast.success(res.message)
                    navigate('/dashboard/Symptoms')
                }else{
                    setShowLoader(false)
                }
            })
        }
    });

    useEffect(()=>{
        getInsurance()
        callGetDoctors()
        if(params.id){
            setShowLoader(true)
            symptomsService.Singleassignsymptom(params.id).then((res)=>{
                setShowLoader(false)
                setFormPrefillData(res.data.symtom);
            })
        }
    },[])
    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">Assign a doctor</h1>
            <form onSubmit={assignDoctor.handleSubmit}>
                <TextField
                    fullWidth
                    id="insuranceName"
                    label="Symptom Name"
                    name="insuranceName"
                    select
                    value={assignDoctor.values.insuranceName}
                    onChange={assignDoctor.handleChange}
                    onBlur={assignDoctor.handleBlur}
                    helperText={assignDoctor.touched.insuranceName && Boolean(assignDoctor.errors.insuranceName) ? 'Symptom name is required' : ''}
                    error={assignDoctor.touched.insuranceName && Boolean(assignDoctor.errors.insuranceName)}>
                        {insuranceData && insuranceData.map((el)=>{
                           
                          return (<MenuItem value={el.name}>{el.name}</MenuItem>)
                        })}
                    {/* <MenuItem value="Health Insurance">Health Insurance</MenuItem>
                    <MenuItem value="Cipla">Cipla</MenuItem> */}
                </TextField>
                <div className="my-5">
                    <TextField
                        fullWidth
                        id="doctorName"
                        label="Doctor name"
                        name="doctorName"
                        select
                        value={assignDoctor.values.doctorName}
                        onChange={assignDoctor.handleChange}
                        onBlur={assignDoctor.handleBlur}
                        helperText={assignDoctor.touched.doctorName && Boolean(assignDoctor.errors.doctorName) ? 'Doctor name is required' : ''}
                        error={assignDoctor.touched.doctorName && Boolean(assignDoctor.errors.doctorName)}
                        
                    >

                        {doctorsArray && doctorsArray.map((el)=>{
                           
                          return (<MenuItem value={el._id}>{el.name.first} {el.name.last}</MenuItem>)
                        })}
                    </TextField>
                </div>
                <Filledbluebutton cname="w-full" btnType="submit" />
            </form>
        </Card>
    );
};

export default Assigndoctorform;

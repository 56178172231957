import React, {useState, useEffect, useRef,useCallback, useContext} from 'react';
import {useNavigate} from "react-router-dom";
import {InsuranceDetailsTableData, InsuranceDetailsWithDoctor} from "../../../../core/constants/appconstants";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {getLanguage, deleteLanguage}  from "../../../../core/services/Data/languageService"
import {AgGridReact} from "ag-grid-react";
import {UserContext} from "../../../../App";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from "react-toastify";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const Languagehome = () => {
    const navigate = useNavigate();
    const insuranceDetailsData = InsuranceDetailsTableData;
    const insuranceDetailsDataWithDoctor = InsuranceDetailsWithDoctor;
    const [languageData, setLanguageData] = useState();
    const gridRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const {setShowLoader} = useContext(UserContext);
    const editButton = (props)=>{
        return (
          <ModeEditIcon
          className={`text-[#0098C4] border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`} onClick={() => {props.editClicked(props.data._id)}}/>
        )
      }
      const handleNavigate = (id) => {
  
        navigate('/dashboard/language/assign-doctor/' + id); 
       }

    const mulitipleImageRenderer = (props) => {
        console.log('lol',props.getValue())
          let number = 0;
        return <div className="flex gap-2" >
            {
                props.getValue().map((el,i)=>{
                    console.log('working',el)
                    if(i<3){
    
                      return <img src={el} className="h-8 w-8 " alt="photo" />;
                    }else{
                      number++
                     return 
                    }
                })
    
            }
            <h2 style ={{cursor : "pointer", marginTop : "10px" }}>{number>0 ? "+" +number+" more" : null} </h2>
        </div>
      };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
       
    };

    const getlanguagedetails=()=>{
        getLanguage().then((res)=>{
            setLanguageData(res.data);
        })
        
    }

    const [columnDefs] = useState([
        {headerName: "Language", field: "name",},
        
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/language/add-language/' + id);
                    // console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    setIdToDelete((prevState) => {
                        return id
                    })
                    // console.log('id', id)
                }
            }
        }
    ]);
    const [assigncolumnDefs] = useState([
        {headerName: "Language", field: "name",},
        {
            headerName: "Doctors",
            field: "doctors",
            resizable: true,
            cellRenderer: mulitipleImageRenderer,
            valueGetter: (e) => {
              console.log("Checking-->", e.data);
      
              return e.data?.doctors?.map((el) => el.picture);
            },
          },
        
        {
            headerName: 'Action', cellRenderer: editButton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/language/assign-doctor/adddoctor/' + id);
                    // console.log('id', id)
                },
                
            }
        }
    ]);
    const deleteLanguageshai = ()=>{
        // setShowLoader(true)
        deleteLanguage(idToDelete).then((res)=>{
            if(res){
                toast.success(res.message)
                getlanguagedetails()
                // setShowLoader(false)
            }
        })
        // console.log("id", idToDelete);
    }
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    useEffect(()=>{
        getlanguagedetails()
    },[])
    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    return (
        <>
            <div className="flex justify-between mb-4">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Languages</h1>
                </div>
                <div className="order-last">
                    {/* <Filterbutton text="Filter by language"/> */}
                </div>
            </div>
            <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteLanguageshai}/>
                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            Language Table
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport}/>
                        <Filledbluebutton cname="ml-4" clicked={() => {
                            navigate('/dashboard/language/add-language')
                        }}/>
                    </div>
                </div>
                
                 <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={languageData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
            </Card>
            {/* <div className="flex justify-between my-4">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Assign Doctors</h1>
                </div>
                
            </div>
            <Card>
                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            Language Data with doctors list
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport}/>
                        <Filledbluebutton cname="ml-4" clicked={() => {
                            navigate('/dashboard/language/assign-doctor')
                        }}/>
                    </div>
                </div>
                <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={languageData}
                                defaultColDef={defaultColDef}
                                columnDefs={assigncolumnDefs}
                                onRowClicked={(e) =>{
                 
                                    handleNavigate(e.data._id)
                                }}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
            </Card> */}
        </>
    );
};

export default Languagehome;

import React from 'react';

const Card = (props) => {
    return (
        <div className={`bg-white shadow rounded ${props.cname} p-2 sm:p-4 md:p-6 lg:p-10`}>
            {props.children}
        </div>
    );
};

export default Card;

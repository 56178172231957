import React, { useContext, useEffect, useState } from "react";
import Card from "../../../shared/components/Card/Card";
import Rightbutton from "../../../shared/components/Rightbutton/Rightbutton";
import Leftbutton from "../../../shared/components/Leftbutton/Leftbutton";
import Calendar from "react-calendar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "react-calendar/dist/Calendar.css";
import Imagecircular from "../../../shared/components/Imagecircular/Imagecircular";
import Doughnutchart from "../../../shared/components/Doughnutchart/Doughnutchart";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { analytics } from "../../../core/services/Data/dashboardService";
import { doctors } from "../../../core/services/Data/doctorsService";
import requestService, {
  requests,
} from "../../../core/services/Data/requestService";
import { coupons } from "../../../core/services/Data/couponService";
import { getPerformanceData } from "../../../core/services/Data/performanceAnalyticsService";
import { UserContext } from "../../../App";

const Home = () => {
  const [date, setDate] = useState(new Date());
  const [analyticData, setAnalyticData] = useState(null);
  const [doctorsArray, setDoctorsArray] = useState(null);
  const [receivedRequests, setReceivedRequests] = useState(null);
  const [couponsData, setCouponsData] = useState(null);
  const [calendarLooader, setCalenderLoader] = useState(false)
  const { setShowLoader, userDetails } = useContext(UserContext);
  const { role } = userDetails;
  const handleDateChange = (event) => {
    setDate(event)
    const payload =
      event.getFullYear() +
      "-" +
      (event.getMonth() + 1) +
      "-" +
      event.getDate();
      setCalenderLoader(true)
    // setShowLoader(true);
    analytics(payload).then(async (res) => {
      if (res) {
        await setAnalyticData(res);
        // setShowLoader(false);

        setCalenderLoader(false)
      }
    });
  };
  const handleRespondToRequest = (id, status) => {
    setShowLoader(true);
    requestService.respondRequest(id, status).then((res) => {
      if (res) {
        console.log("response", res);
        setShowLoader(false);
      }
    });
  };
  const handleSelectChange = (e) => {
    // setDate(e)
    e.preventDefault()
    console.log("Date", e);
  };
  const dashboardAPIcalls = (datePayload) => {
    setShowLoader(true);
    Promise.all([
      analytics(datePayload),
      doctors(5),
      requestService.requests(2),
      coupons(2),
      getPerformanceData(),
    ]).then(async ([res1, res2, res3, res4, res5]) => {
      console.log(res3.data);
      await setAnalyticData(res1);
      await setDoctorsArray(res2);
      await setReceivedRequests(
        res3.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
      await setCouponsData(res4);
      setShowLoader(false);
    });
  };

  useEffect(() => {
    const payload =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    dashboardAPIcalls(payload);
  }, []);
  return (
    <div>
      <div className="flex justify-between mb-3">
        <div className="order-first">
          <h1 className="text-2xl font-bold tracking-wider">Dashboard</h1>
        </div>
        {/* <div className="order-last">
                    <button type="button"
                            className="inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by State
                    </button>
                </div> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card>
          {!calendarLooader && <div id="calender-tab" className="grid grid-cols-12 2xl:place-items-stretch place-items-center gap-5">
            <div className="col-span-12 2xl:col-span-6 my-auto">
              <Calendar
                maxDate={new Date()}
                onChange={handleDateChange}
                // value={date}
                value={date}
                // date={date}
                onSelect={(e) => {
                  handleSelectChange(e)
                }}
              />
            </div>
            <div className="col-span-12 2xl:col-span-6 my-auto">
              {analyticData && (
                <div className="grid grid-cols-2 gap-5">
                  <div className="shadow pt-12 flex flex-col bg-sky-400 rounded-xl text-white p-3">
                    <h1 className="text-xl font-bold">
                      {analyticData.data.totalClinics}
                    </h1>
                    <p className="text-sm">Total clinics</p>
                  </div>
                  <div className="shadow pt-12 flex flex-col bg-orange-400 rounded-xl text-white p-3">
                    <h1 className="text-xl font-bold">
                      {analyticData.data.totalLaboratorys}
                    </h1>
                    <p className="text-sm">Total laboratory</p>
                  </div>
                  <div className="shadow pt-12 flex flex-col bg-green-400 rounded-xl text-white p-3">
                    <h1 className="text-xl font-bold">
                      {analyticData.data.totalPharmacys}
                    </h1>
                    <p className="text-sm">Total Pharmacy</p>
                  </div>
                  <div className="shadow pt-12 flex flex-col bg-yellow-400 rounded-xl text-white p-3">
                    <h1 className="text-xl font-bold">
                      {analyticData.data.totalDocters}
                    </h1>
                    <p className="text-sm">Total Doctors</p>
                  </div>
                </div>
              )}
            </div>
          </div>}
                {
                  calendarLooader && <div className="flex w-full h-full justify-center items-center">
                            <div
  class="inline-block h-12 w-12 text-blue-500 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
  role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>
                  </div>
                }
        </Card>

        {role === "SuperAdmin" && (
          <Card>
            <div className="flex flex-col tracking-wider">
              <h1 className="text-xl font-bold">Requests</h1>
              <p className="text-gray-500 text-sm">
                You can approve/deny requests of hospitals, pharmacies <br />&
                more.
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <div className="order-first">
                <span className="font-bold tracking-wider">Today</span>
              </div>
              <div className="order-last cursor-pointer">
                <span className="tracking-wider font-bold hover:underline text-sky-600">
                  <NavLink to="/dashboard/requests">View all</NavLink>
                </span>
              </div>
            </div>
            {receivedRequests &&
              receivedRequests.map((request) => {
                return (
                  <>
                    <div
                      className="grid grid-cols-12 place-content-center gap-6 my-4"
                      key={request.id}
                    >
                      <div className="col-span-3 flex justify-center items-center">
                        <img
                          src={request.requestedUser.picture}
                          className="border border-sky-400 p-1 h-16 w-16 rounded-full"
                          alt=""
                        />
                      </div>
                      <div className="col-span-6 flex flex-col my-auto">
                        <h3 className="text-sky-500 text-md font-semibold">
                          {request.requestedUser.name}
                        </h3>
                        <p className="text-xs text-gray-500">
                          Approval for adding new {request.requestType}
                        </p>
                      </div>
                      <div className="col-span-3 flex flex-col justify-center items-center">
                        <div className="flex flex-row">
                          {request.status === "rejected" ||
                          request.status === "approved" ? (
                            <div
                              className={`font-semibold rounded-md px-2 py-1 ${
                                request.status === "rejected"
                                  ? "text-red-500 bg-red-200"
                                  : "text-green-500 bg-green-200"
                              }`}
                            >
                              {request.status.toUpperCase()}
                            </div>
                          ) : (
                            <>
                              <Rightbutton
                                clicked={() => {
                                  handleRespondToRequest(
                                    request._id,
                                    "approved"
                                  );
                                }}
                              />
                              <div className="ml-2">
                                <Leftbutton
                                  clicked={() => {
                                    handleRespondToRequest(
                                      request._id,
                                      "rejected"
                                    );
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {request.status === "rejected" &&
                          request.status === "approved" && (
                            <div className="text-center">
                              <span className="text-xs text-gray-500 font-semibold">
                                Today, {request.requestedAt}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })}
          </Card>
        )}
        <div>
          <div>
            <Card>
              <div className="flex justify-between mt-3">
                <div className="order-first">
                  <span className="font-bold text-xl tracking-wider">
                    Top Doctors
                  </span>
                </div>
                <div className="order-last cursor-pointer">
                  <NavLink
                    to="/dashboard/doctors"
                    className="tracking-wider font-bold hover:underline text-sky-600"
                  >
                    View all
                  </NavLink>
                </div>
              </div>
              <div className="flex flex-wrap justify-evenly mt-4">
                {doctorsArray &&
                  doctorsArray.map((doctor) => {
                    return <Imagecircular doctor={doctor} />;
                  })}
              </div>
            </Card>
          </div>
        </div>
        {role == "SuperAdmin" && <div>
          <div className="mt-11">
            <Card>
              <div className="flex justify-between mt-3">
                <div className="order-first">
                  <span className="font-bold text-xl tracking-wider">
                    Top Coupons
                  </span>
                </div>
                <div className="order-last cursor-pointer">
                  <NavLink
                    to="/dashboard/coupons"
                    className="tracking-wider font-bold hover:underline text-sky-600"
                  >
                    View all
                  </NavLink>
                </div>
              </div>
              <div className="mt-4">
                <table className="table-auto min-w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="text-gray-900 px-6 py-4 text-left"
                      >
                        CODE
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900 px-6 py-4 text-left"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900 px-6 py-4 text-left"
                      >
                        Applied
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {couponsData &&
                      couponsData.map((data) => {
                        return (
                          <tr key={data._id}>
                            <td className="text-gray-900 px-6 text-left">
                              {data.code}
                            </td>
                            <td className="text-gray-900 px-6 text-left">
                              {data.description}
                            </td>
                            <td className="text-gray-900 px-6 text-left">
                              {data.totalApplied}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </div>}
        {/* <Card>
                    <div className="flex justify-between mt-3">
                        <div className="order-first">
                                <span className="font-bold text-xl tracking-wider">
                                    Performance
                                </span>
                        </div>
                        <div className="order-last cursor-pointer">
                            <div
                                className="tracking-wider font-bold hover:underline text-sky-800 flex justify-center items-center">
                                <ArrowBackIosIcon/>
                                <span className="mx-6 bg-sky-200 px-6 py-1">
                                May
                            </span>
                                <ArrowForwardIosIcon/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="grid grid-cols-12 gap-10">
                            <div className="col-span-6">
                                <Doughnutchart/>
                            </div>
                            <div className="col-span-6 flex flex-col justify-center my-auto">
                                <div>
                                    <div className="flex flex-row items-center">
                                        <div className="bg-[#1247FF] rounded-full h-2 w-2">&nbsp;</div>
                                        <h1 className="text-sm ml-5">No. of patients</h1>
                                    </div>
                                    <div className="flex flex-row items-center ml-7 text-xs text-gray-500">
                                        90%
                                    </div>
                                </div>
                                <div className="my-4">
                                    <div className="flex flex-row items-center">
                                        <div className="bg-[#F54484] rounded-full h-2 w-2">&nbsp;</div>
                                        <h1 className="text-sm ml-5">Pending reports</h1>
                                    </div>
                                    <div className="flex flex-row items-center ml-7 text-xs text-gray-500">
                                        90%
                                    </div>
                                </div>
                                <div>
                                    <div className="flex flex-row items-center">
                                        <div className="bg-[#37C93D] rounded-full h-2 w-2">&nbsp;</div>
                                        <h1 className="text-sm ml-5">Completed reports</h1>
                                    </div>
                                    <div className="flex flex-row items-center ml-7 text-xs text-gray-500">
                                        90%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-1 xl:gap-10 mt-4">
                        <div className="col-span-12 xl:col-span-4">
                            <div className="shadow-2xl py-2 px-4 flex flex-col">
                                <span className="text-lg">Patients</span>
                                <div>
                                    <span className="text-xl font-semibold">232</span>
                                    <span className="text-xs text-green-500">(300%)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 xl:col-span-4">
                            <div className="shadow-2xl py-2 px-4 flex flex-col">
                                <span className="text-lg">Patients</span>
                                <div>
                                    <span className="text-xl font-semibold">232</span>
                                    <span className="text-xs text-red-500">(300%)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 xl:col-span-4">
                            <div className="shadow-2xl py-2 px-4 flex flex-col">
                                <span className="text-lg">Patients</span>
                                <div>
                                    <span className="text-xl font-semibold">232</span>
                                    <span className="text-xs text-green-500">(300%)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card> */}
      </div>
    </div>
  );
};

export default Home;

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function createScheduleData(payload){
    const url = "/schedules/slots/create"
    try {
        const {data : response} = await httpservice.post(url, payload)
        return response   
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function getAllSlotsOfADoctor(doctorId){
    const url = "/schedules/slots/all/"+doctorId
    try {
        const {data : response} = await httpservice.get(url)
        return response   
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function createMultipleScheduleData(payload){
    const url = "/schedules/slots/create/multiple"
    try {
        const {data : response} = await httpservice.post(url, payload)
        return response   
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log({error:ex})     
            toast.error(ex.response.data.error)
        }
    }
}

export async function getScheduleData(id,payload){
    const url = "/schedules/admin/doctor/"
    // if(id === undefined){
    //     return 
    // }
    try {
        const {data : response} = await httpservice.post(url+id,payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function getScheduleSlotsData(id,payload){
    const url = "/schedules/admin/v2/doctor/"
    // if(id === undefined){
    //     return 
    // }
    try {
        const {data : response} = await httpservice.post(url+id,payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function deleteSlot(id){
    const url = "/schedules/delete/"
    // if(id === undefined){
    //     return 
    // }
    try {
        const {data : response} = await httpservice.delete(url+id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

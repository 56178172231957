import React from 'react';
import {Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Deletepopup = (props) => {
    return (
        <Modal
            open={props.isOpen}
            onClose={props.handleCloseModel}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <div className="absolute top-1/2 left-1/2" style={{transform: 'translate(-50%, -50%)'}}>
                <div className="p-10 bg-white rounded-lg relative">
                    <CloseIcon
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold cursor-pointer"
                        onClick={props.handleCloseModel}/>
                    <h1 className="text-lg">Are you sure you want to delete ?</h1>
                    <div className="flex justify-evenly text-white mt-6">
                        <div className="order-first">
                            <button className="bg-green-500 w-16 shadow-xl hover:bg-green-700 rounded text-lg" onClick={() => {
                                props.handleDelete();
                                props.handleCloseModel();
                            }}>Yes</button>
                        </div>
                        <div className="order-last">
                            <button className="bg-red-500 w-16 shadow-xl hover:bg-red-700 rounded text-lg" onClick={props.handleCloseModel}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Deletepopup;

import axios from "axios";
import { toast } from "react-toastify";

// axios.defaults.baseURL = "http://localhost:9300/";
//axios.defaults.baseURL = "https://carepluz.brihatinfotech.com/";

axios.defaults.baseURL = "https://backend.thecarepluz.com/";

const setJWT = (jwt) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("token")
  )}`;
  // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
};
axios.interceptors.response.use(null, (err) => {
  const expectedError =
    err.response && err.response.status >= 400 && err.response.status < 500;
  if (!expectedError) {
    toast.error("Unexpected error occured, please Try again");
  }
  return Promise.reject(err);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  setJWT,
};

import React, { useRef, useContext, useState, useEffect } from "react";
import Card from "../../../../../shared/components/Card/Card";
import { Button, MenuItem, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Filledbluebutton from "../../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { medicineFormSchema } from "../../../../../core/services/Data/medicinesSchema";
import { UserContext } from "../../../../../App";
import uploadFileService from "../../../../../core/services/Data/uploadFileService";
import pharmacyService from "../../../../../core/services/Data/pharmacyService";
import { toast } from "react-toastify";
import LocationPicker from "react-location-picker";

import LocationService from "../../../../../core/services/Data/LocationService";
import SearchBox from "./googleSearch";
// import pharmacyService from "../../../../core/services/Data/pharmacyService";

const Pharmacyouthouseform = () => {
  const navigate = useNavigate();
  const params = useParams();
  const pharmcyImageInputref = useRef();
  const pharmcyPermitInputref = useRef();
  const [formPrefillData, setFormPrefillData] = useState();
  const [pharmacyImageUrl, setPharmacyImageUrl] = useState("");
  const [pharmacyPermitUrl, setPharmacyPermitUrl] = useState("");
  const { setShowLoader } = useContext(UserContext);
  const [position, setPosition] = useState({
    lat: 29.378586,
    lng: 47.990341
  });

  const pharmacyAddForm = useFormik({
    initialValues: {
      pharmacyName: formPrefillData ? formPrefillData.name : "",
      pharmacyLogo: formPrefillData ? formPrefillData.picture : "",
      pharmacyPermit: formPrefillData ? formPrefillData.permit : "",
      phoneNumber: formPrefillData ? formPrefillData.phone : "",
      alternatePhoneNumber: formPrefillData
        ? formPrefillData.alternatePhone
        : "",
        block:formPrefillData ? formPrefillData?.addressBlock?.block || "" : 'B-',
        blockNumber:formPrefillData ? formPrefillData?.addressBlock?.number || "" : '',
      email: formPrefillData ? formPrefillData.email : "",
      address: formPrefillData ? formPrefillData.address : "",
      city: formPrefillData ? formPrefillData.city : "",
      state: formPrefillData ? formPrefillData.state : "",
      country: formPrefillData ? formPrefillData.country : "",
    },
    validationSchema: medicineFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setShowLoader((prevState) => {
        return true;
      });
      Promise.all([
        new Promise((resolve, reject) => {
          if (typeof values.pharmacyLogo !== "string") {
            const response = uploadFileService.fileUpload(values.pharmacyLogo);
            if (response) {
              resolve(response);
            } else {
              reject();
            }
          } else {
            resolve();
          }
        }),
        new Promise((resolve, reject) => {
          if (typeof values.pharmacyPermit !== "string") {
            const response = uploadFileService.fileUpload(
              values.pharmacyPermit
            );
            if (response) {
              resolve(response);
            } else {
              reject();
            }
          } else {
            resolve();
          }
        }),
      ]).then(async ([res1, res2]) => {
        const payload = {
          ...values,
          picture: res1 ? res1.fileUrl : values.pharmacyLogo,
          permit: res2 ? res2.fileUrl : values.pharmacyPermit,
          name: values.pharmacyName,
          phone: values.phoneNumber,
          location: {
            coordinates: [position.lat, position.lng],
          },
          alternatePhone: values.alternatePhoneNumber,
          branchName: "Saket",
          addressBlock:{
            block:"B-",
            number:values.blockNumber
        },
        };
        if (params.id) {
          pharmacyService
            .updateSingleOuthouse(params.id, payload)
            .then((res) => {
              if (res) {
                navigate("/dashboard/pharmacy/out-house", { replace: true });
                toast.success(res.message);
              }
              setShowLoader(false);
            });
        } else {
          pharmacyService.addOuthousePharmacy(payload).then((res) => {
            // toast.success("Pharmacy Successfully Credit")
            if (res) {
              navigate("/dashboard/pharmacy/out-house", { replace: true });
              toast.success(res.message);
            }
            setShowLoader(false);
          });
        }
      });
    },
  });
  useEffect(() => {
    const currentLocation = async () => {
      await navigator.geolocation.getCurrentPosition(
        (position) =>
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (err) => console.log(err)
      );
      console.log(this.state.latitude);
    };
    currentLocation();
  }, []);
  console.log({ pharmacyAddForm: pharmacyAddForm.values });
  const handleLocationChange = ({ position, address, places }) => {
    // Set new location
    setPosition({
      lat: position.lat,
      lng: position.lng,
    });
  };
  const pharmacyImageInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        pharmacyAddForm.setErrors({
          pharmacyLogo: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        pharmacyAddForm.handleChange(event);
        pharmacyAddForm.setFieldValue(
          "pharmacyLogo",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setPharmacyImageUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      pharmacyAddForm.setErrors({
        pharmacyLogo: "Pharmacy Logo is Required!",
      });
    }
  };

  const PharmacyResetImageInput = () => {
    pharmacyAddForm.setFieldValue("pharmacyLogo", "");
    setPharmacyImageUrl((prevState) => {
      return "";
    });
  };

  const pharmacyPermitInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        pharmacyAddForm.setErrors({
          pharmacyPermit: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        pharmacyAddForm.handleChange(event);
        pharmacyAddForm.setFieldValue(
          "pharmacyPermit",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setPharmacyPermitUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      pharmacyAddForm.setErrors({
        pharmacyPermit: "Pharmacy Permit is Required!",
      });
    }
  };

  const PharmacyPermitResetImageInput = () => {
    pharmacyAddForm.setFieldValue("pharmacyPermit", "");
    setPharmacyPermitUrl((prevState) => {
      return "";
    });
  };
  // useEffect(() => {
  //   if (params.id) {
  //     setShowLoader(true);
  //     pharmacyService.getSingleOuthousePharmacy(params.id).then((res) => {
  //       console.log({res})
  //       setPharmacyPermitUrl(res.data[0]?.permit);
  //       setPharmacyImageUrl(res.data[0]?.picture);
  //       setFormPrefillData((prevState) => {
  //         setShowLoader(false);
  //         return res.data[0];
  //       });
  //     });
  //   }
  // },[]);
  const [locatons, setLocations] = useState({
    country: [],
    city: [],
    state: [],
  });
  useEffect(() => {
    const apiData = async () => {
      const { data } = await LocationService.getLocations(pharmacyAddForm.values.country, pharmacyAddForm.values.state);
      // console.log({data})
      let cities = [...data[0].city];
      let states = [...data[0].state];
      let country = [...data[0].country];
      // for await (let element of data) {
      //   if (!cities.includes(element.city)) {
      //     cities.push(element.city);
      //   }
      //   if (!states.includes(element.state)) {
      //     states.push(element.state);
      //   }
      //   if (!country.includes(element.country)) {
      //     country.push(element.country);
      //   }
      // }
      const locations = { ...locatons };
      locations.city = cities;
      locations.state = states;
      locations.country = country;
      setLocations(locations);
    };
    apiData();
  }, [pharmacyAddForm.values.country, pharmacyAddForm.values.state]);
  useEffect(() => {
    if (params.id) {
      setShowLoader(true);
      pharmacyService.getSingleOuthousePharmacy(params.id).then((res) => {
        setPharmacyPermitUrl((prevState) => {
          return res.data[0]?.permit;
        });
        setPharmacyImageUrl((prevState) => {
          return res.data[0]?.picture;
        });
        console.log({ response: res.data });
        setPosition({
          lat: res.data[0]?.location?.coordinates[0],
          lng: res.data[0]?.location?.coordinates[1],
        });
        setFormPrefillData((prevState) => {
          setShowLoader(false);
          return res.data[0];
        });
      });
    }
  }, []);

  console.log({ pharmacyImageUrl, pharmacyPermitUrl });
  return (
    <Card cname="px-10">
      <div className="my-10">
        <h1 className="text-3xl font-bold">Register</h1>
        <p className="text-xl font-semibold text-gray-500 mt-2">
          Register as out house pharmacy.
        </p>
      </div>
      <form
        onSubmit={pharmacyAddForm.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        {/*Registration Type input field*/}
        <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
          <TextField
            fullWidth
            id="branchInput"
            label="Pharmacy"
            name="registerType"
            disabled
            select
            helperText={
              pharmacyAddForm.touched.registerType &&
                Boolean(pharmacyAddForm.errors.registerType)
                ? "Branch name is required"
                : ""
            }
            value="Pharmacy"
            onBlur={pharmacyAddForm.handleBlur}
            onChange={pharmacyAddForm.handleChange}
            error={
              pharmacyAddForm.touched.registerType &&
              Boolean(pharmacyAddForm.errors.registerType)
            }
          >
            <MenuItem value="Pharmacy">Pharmacy</MenuItem>
          </TextField>
        </div>

        {/*Basic Details Input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Basic Details</h1>
          {/*Pharmacy name input field*/}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="pharmacyName"
                label="Enter pharmacy name"
                helperText={
                  pharmacyAddForm.touched.pharmacyName &&
                    Boolean(pharmacyAddForm.errors.pharmacyName)
                    ? pharmacyAddForm.errors.pharmacyName
                    : ""
                }
                value={pharmacyAddForm.values.pharmacyName}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.pharmacyName &&
                  Boolean(pharmacyAddForm.errors.pharmacyName)
                }
              />
            </div>
          </div>
          {/*Pharmacy permit and logo input field*/}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-5">
            {/*Pharmacy logo input field */}
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col justify-center items-center max-w-full">
                <label
                  className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${pharmacyAddForm.touched.pharmacyLogo &&
                      Boolean(
                        pharmacyAddForm.errors.pharmacyLogo
                      ) &&
                      pharmacyAddForm.errors.pharmacyLogo
                      ? "border-[#d32f2f]"
                      : "border-gray-300"
                    }
                         border-dashed cursor-pointer`}
                >
                  <input
                    ref={pharmcyImageInputref}
                    id="dropzone-file"
                    name="pharmacyLogo"
                    disabled={pharmacyImageUrl !== ""}
                    accept="image/png, image/jpeg, image/jpg"
                    onBlur={pharmacyAddForm.handleBlur}
                    onChange={pharmacyImageInputChangeHandler}
                    type="file"
                    className="hidden"
                  />
                  {pharmacyImageUrl !== "" ? (
                    <div className="relative w-24 h-24">
                      <img
                        id="blah"
                        src={pharmacyImageUrl}
                        className="shadow w-full object-fill"
                        alt="your"
                      />
                      <CloseIcon
                        onClick={PharmacyResetImageInput}
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload pharmacy logo
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`text-[#d32f2f] my-2 text-sm ml-4 ${pharmacyAddForm.errors.pharmacyLogo
                    ? ""
                    : "hidden"
                  }`}
              >
                {pharmacyAddForm.errors.pharmacyLogo}
              </div>
            </div>

            {/*Pharmacy permit input field*/}
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col justify-center items-center w-full">
                <label
                  className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${pharmacyAddForm.errors.pharmacyPermit
                      ? "border-[#d32f2f]"
                      : "border-gray-300"
                    }
                         border-dashed cursor-pointer`}
                >
                  <input
                    ref={pharmcyPermitInputref}
                    id="dropzone-file"
                    name="pharmacyPermit"
                    disabled={pharmacyPermitUrl !== ""}
                    accept="image/png, image/jpeg, image/jpg"
                    onBlur={pharmacyAddForm.handleBlur}
                    onChange={pharmacyPermitInputChangeHandler}
                    type="file"
                    className="hidden"
                  />
                  {pharmacyPermitUrl !== "" ? (
                    <div className="relative w-24 h-24">
                      <img
                        id="blah"
                        src={pharmacyPermitUrl}
                        className="shadow w-full object-fill"
                        alt="your"
                      />
                      <CloseIcon
                        onClick={PharmacyPermitResetImageInput}
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload pharmacy permit
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`text-[#d32f2f] my-2 text-sm ml-4 ${pharmacyAddForm.errors.pharmacyPermit
                    ? ""
                    : "hidden"
                  }`}
              >
                {pharmacyAddForm.errors.pharmacyPermit}
              </div>
            </div>
          </div>
        </div>

        {/*Contact Details input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Contact Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="phoneNumber"
                name="phoneNumber"
                label="Enter phone number"
                helperText={
                  pharmacyAddForm.touched.phoneNumber &&
                    Boolean(pharmacyAddForm.errors.phoneNumber)
                    ? pharmacyAddForm.errors.phoneNumber
                    : ""
                }
                value={pharmacyAddForm.values.phoneNumber}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.phoneNumber &&
                  Boolean(pharmacyAddForm.errors.phoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="alternatePhoneNumber"
                name="alternatePhoneNumber"
                label="Enter alternate phone number"
                helperText={
                  pharmacyAddForm.touched.alternatePhoneNumber &&
                    Boolean(pharmacyAddForm.errors.alternatePhoneNumber)
                    ? pharmacyAddForm.errors.alternatePhoneNumber
                    : ""
                }
                value={pharmacyAddForm.values.alternatePhoneNumber}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.alternatePhoneNumber &&
                  Boolean(pharmacyAddForm.errors.alternatePhoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="email"
                label="Enter your email"
                helperText={
                  pharmacyAddForm.touched.email &&
                    Boolean(pharmacyAddForm.errors.email)
                    ? pharmacyAddForm.errors.email
                    : ""
                }
                value={pharmacyAddForm.values.email}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.email &&
                  Boolean(pharmacyAddForm.errors.email)
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-2 md:mt-8">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                name="address"
                label="Enter address"
                helperText={
                  pharmacyAddForm.touched.address &&
                    Boolean(pharmacyAddForm.errors.address)
                    ? pharmacyAddForm.errors.address
                    : ""
                }
                value={pharmacyAddForm.values.address}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.address &&
                  Boolean(pharmacyAddForm.errors.address)
                }
              />
            </div>
            <div className="col-span-6 md:col-span-3 ">
                            {/* <InputLabel>B-</InputLabel> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="blockNumber"
                                name="blockNumber"
                                label="Enter Block Number"
                                helperText={pharmacyAddForm.touched.blockNumber && Boolean(pharmacyAddForm.errors.blockNumber) ? pharmacyAddForm.errors.blockNumber : ''}
                                value={`${pharmacyAddForm.values.block || "B-"}${pharmacyAddForm.values.blockNumber}`}
                                onBlur={pharmacyAddForm.handleBlur}
                                onChange={(e)=>{
                                    const values = e.target.value.split("-");
                                    e.target.value = values[1] || "";
                                    pharmacyAddForm.handleChange(e)
                                }}
                                // InputProps={{
                                //     inputComponent: blockElement,
                                // }}
                                error={pharmacyAddForm.touched.blockNumber && Boolean(pharmacyAddForm.errors.blockNumber)}
                            />
                        </div>
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="country"
                label="Enter Country"
                name="country"
                helperText={
                  pharmacyAddForm.touched.country &&
                    Boolean(pharmacyAddForm.errors.country)
                    ? pharmacyAddForm.errors.country
                    : ""
                }
                value={pharmacyAddForm.values.country}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.country &&
                  Boolean(pharmacyAddForm.errors.country)
                }
                select
              >
                {locatons.country.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="state"
                label="Enter State"
                name="state"
                helperText={
                  pharmacyAddForm.touched.state &&
                    Boolean(pharmacyAddForm.errors.state)
                    ? pharmacyAddForm.errors.state
                    : ""
                }
                value={pharmacyAddForm.values.state}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.state &&
                  Boolean(pharmacyAddForm.errors.state)
                }
                select
              >
                {locatons.state.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="city"
                label="Enter City"
                name="city"
                helperText={
                  pharmacyAddForm.touched.city &&
                    Boolean(pharmacyAddForm.errors.city)
                    ? pharmacyAddForm.errors.city
                    : ""
                }
                value={pharmacyAddForm.values.city}
                onBlur={pharmacyAddForm.handleBlur}
                onChange={pharmacyAddForm.handleChange}
                error={
                  pharmacyAddForm.touched.city &&
                  Boolean(pharmacyAddForm.errors.city)
                }
                select
              >
                {locatons.city.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>
            
          </div>
        </div>

        {/*Pharmacy Location Details Input map field*/}
        {/* <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Pharmacy location</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12">
              <img
                src="https://i.postimg.cc/qRF5QCjR/map-register.png"
                alt="map"
                className="w-full-object-fill"
              />
            </div>
          </div>
        </div> */}

        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Laboratory location</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 relative">
              <div className='absolute left-56 top-[10px] z-50'>

                <SearchBox
                  setPosition={setPosition}
                />
              </div>
              <LocationPicker
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "400px" }} />}
                defaultPosition={position}
                onChange={handleLocationChange}
              />
            </div>
          </div>
        </div>

        {/*Submit button*/}

        <div className="flex gap-2">
          <Filledbluebutton
            cname="w-32"
            btnType="submit"
            disabledBtn={!pharmacyAddForm.isValid}
          >
            {params.id ? "Update" : "Submit"}
          </Filledbluebutton>

          {params.id && (
            <Button
              color="warning"
              variant=""
              fullWidth
              type="submit"
              onClick={() => {
                navigate("/dashboard/pharmacy/out-house");
              }}
            >
              {params.id ? "Cancel" : ""}
            </Button>
          )}
        </div>
      </form>
    </Card>
  );
};

export default Pharmacyouthouseform;

import React, {useContext, useEffect, useRef, useState,useCallback} from 'react';
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {useNavigate} from "react-router-dom";
import pharmacyService from "../../../../core/services/Data/pharmacyService";
import {UserContext} from "../../../../App";
import {AgGridReact} from "ag-grid-react";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from 'react-toastify'

const Pharmacyhome = () => {
    const navigate = useNavigate();
    const gridRef = useRef(null);
    const [pharmacyTableDate, setPharmacyTableDate] = useState(null);
    const {setShowLoader} = useContext(UserContext);

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const getChemistsData = () => {
        setShowLoader(true)
        pharmacyService.getAllChemist().then((res) => {
            if (res) {
                setPharmacyTableDate(res.data);
                console.log('res for chemists table data', res);
            }
            setShowLoader(false);
        });
    };

    const [columnDefs] = useState([
        {headerName: "Image", field: "picture", cellRenderer: Imagerenderer},
        {headerName: "Receiptionist Name", field: "name"},
        {headerName: "Email", field: "email"},
        {headerName: "Password", field: "password"},
        {headerName: "Hospital", field: "hospital.hospitalName"},
        {headerName: "Branch", field: "branch.branchName"},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/pharmacy/in-house/add-form/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    setIdToDelete((prevState) => {
                        return id
                    })
                }
            }
        }
    ]);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    useEffect(() => {
        getChemistsData();
    }, []);

    const deletePharmacy = ()=>{
        pharmacyService.deleteInpharmacy(idToDelete).then((res) => {
            if(res){
                toast.success(res.message);
                getChemistsData()
            }
        })
        // console.log('working delete')
    }
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    return (
        <>
            <div className="mt-10">
                <Card>
                <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deletePharmacy}/>
                    <div className="flex justify-between mb-3">
                        <div className="order-first">
                            <p className="text-2xl font-semibold">
                                Pharmacy InHouse
                            </p>
                        </div>
                        <div className="order-last">
                            <Outlinedbluebutton  download={onBtnExport}/>
                            <Filledbluebutton cname="ml-4" clicked={() => {
                                navigate('/dashboard/pharmacy/in-house/add-form')
                            }}/> 
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={pharmacyTableDate}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Pharmacyhome;

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";


async function getSymptoms(){
    try {
        const url = "/symtoms"
        const {data : insurance} = await httpservice.get(url)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function createSymptoms(payload){
    try {
        const url = "/symtoms/create"
        const {data : insurance} = await httpservice.post(url,payload)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message|| ex.response.data.error)
        }
    }
}
async function deleteSymptoms(id){
    try {
        const url = "/symtoms/delete/"
        const {data : insurance} = await httpservice.delete(url + id)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message|| ex.response.data.error)
        }  
    }
}

async function createassignSymptom(payload){
    const url = "symtoms/assign/doctor"
    try {
        const {data : insurance} = await httpservice.post(url, payload)
        return insurance
    } catch (ex) {
       
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message|| ex.response.data.error)        } 
    }
}
async function Singleassignsymptom(id){
    try {
        const {data : response} = await httpservice.get(`symtoms/${id}/doctors`)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message|| ex.response.data.error)
        }
    }
}

async function Singlegetsymptom(id){
    try {
        const url = "/symtoms/"
        const {data : response} = await httpservice.get(url +id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message|| ex.response.data.error)
        } 
    }
}
async function updateSymptom(id,payload){
    try {
        const url = "/symtoms/edit/"
        const {data : response} = await httpservice.put(url +id, payload)
        return response
    } catch (ex) { 
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message|| ex.response.data.error)
        } 
    }
}
async function unassignDelete(id){
    try {
        const url = "symtoms/unassign/doctor/"
        const {data : response} = await httpservice.patch(url +id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message|| ex.response.data.error)        } 
    }
}

export default {
    getSymptoms,
    createSymptoms,
    deleteSymptoms,
    createassignSymptom,
    Singlegetsymptom,
    updateSymptom,
    Singleassignsymptom,
    unassignDelete
}
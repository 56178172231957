import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";



export async function getLanguage(){
    try {
        const url = "languages"
        const {data : language} = await httpservice.get(url)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function createLanguage(payload){
   
    try {
        const url = "languages/create"
        const {data : language} = await httpservice.post(url,payload)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}
export async function deleteLanguage(id){
    try {
        const {data : language} = await httpservice.delete("languages/delete/" + id)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function SingleLanguage(id){
    try {
        const url = "languages/"
        const {data : language} = await httpservice.get(url+id)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}
export async function Updatelanguage(id,payload){
    try {
        const url = "languages/edit/"
        const {data : language} = await httpservice.put(url+id,payload)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}
export async function createAssign(payload){
    try {
        const url = "languages/assign/doctor/"
        const {data : language} = await httpservice.put(url,payload)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.error)
        }
    }
}
export async function getAssignlanguage(id){
    try {
        const url = "languages/assign/doctor/"
        const {data : language} = await httpservice.get(url +id)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.error)
        }  
    }
}
export async function deleteAssignlanguage(id){
    try {
        const url = "languages/unassign/doctor/"
        const {data : language} = await httpservice.delete(url +id)
        return language
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.error)
        }
    }
}
import * as yup from 'yup';
import {phoneNumberRegex} from "../constants/appconstants";

export const receptionalistRegistrationValidationSchema = yup.object({
    firstName: yup.string('Please enter a valid Hospital Name').required('First Name is required').trim(),
    lastName: yup.string('Please enter a valid Hospital Name').required('Last Name is required').trim(),

    hospitalName: yup.string('Please enter a valid Hospital Name').required('Hospital Name is required').trim(),
    branchName: yup.string('Please enter a valid Branch Name').required('Branch name is required').trim(),
    phoneNumber: yup.string('Enter your Email').required('Phone number is required').trim(),
    alternatePhoneNumber: yup.string('Enter your Email').trim(),
    educationDegree:yup.string().matches(/^[a-zA-Z ]*$/, "Please Enter Valid Degree").required("Education degree is required").trim(),
    email: yup.string('Enter your Email').required('Email is required').email('Please enter a valid email').trim(),
    address: yup.string('Enter your Email').required('Address is required').trim(),
    block:yup.string("Enter your Block").trim(),
    blockNumber:yup.string("Enter your Block").trim(),
    city: yup.string('Enter your Email').required('City is required').trim(),
    state: yup.string('Enter your Email').required('State is required').trim(),
    yearsExperience:yup.number().required("Experience is required"),
    country: yup.string('Enter your Email').required('Country is required').trim(),
    // subscriptionPlans: yup.string('Enter your Email').required('Subscription Plans is required').trim(),
    // startDate: yup.string('Enter your Email').required('Start Date is required').trim(),
    // expiryDate: yup.string('Enter your Email').required('Expiry Date is required').trim(),
    // // branchPermit: yup.string().required('Branch Permit is required').trim(),
    startTime: yup.string('Enter your Email').required('Start Time is required').trim(),
    endTime: yup.string('Enter your Email').required('Expiry Time is required').trim(),
    
});

import React, { useContext, useEffect, useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import Home from "../../components/Home/Home";
import Requests from "../../components/Requests/Requests";
import RequestDetail from "../../components/Requests/RequestDetail/RequestDetail";
import Hospitals from "../../components/Hospitals/Hospitals";
import Branches from "../../components/Branches/Branches";
import Schedules from "../../components/Schedules/Schedules";
import Doctors from "../../components/Doctors/Doctors";
import Receptionist from "../../components/Receptionist/Receptionist";
import Pharmacy from "../../components/Pharmacy/Pharmacy";
import Medicines from "../../components/Medicines/Medicines";
import Coupons from "../../components/Coupons/Coupons";
import Leaves from "../../components/Leaves/Leaves";
import Laboratory from "../../components/Laboratory/Laboratory";
import Blog from "../../components/Blog/Blog";
import Symptoms from "../../components/symtoms/Symtoms";
import Promotions from "../../components/Promotions/Promotions";
import Locations from "../../components/Locations/Locations.";
import Insurance from "../../components/Insurance/Insurance";
import Languagepage from "../../components/Languagepage/Languagepage";
import Speciality from "../../components/Speciality/Speciality";
import Subscription from "../../components/Subscription/Subscription";
import Sponsors from "../../components/Sponsors/Sponsors";
import Holidays from "../../components/Holidays/Holidays";
import Pushnotifications from "../../components/Pushnotifications/Pushnotifications";
import { UserContext } from "../../../App";
import HospitalGuard from "../../../core/Guards/HospitalGuard/HospitalGuard";
import Mapuserhospital from "../../components/Mapuserhospital/Mapuserhospital";
import Userprofile from "../../components/Userprofile/Userprofile";
import { useMediaQuery } from 'react-responsive'

function Dashboard(props) {
  const { userDetails } = useContext(UserContext);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  const [refresh,setRefresh] = useState(false);
  console.log("Userdetails", userDetails);
  const location = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState(isDesktopOrLaptop ? true : false);
  const handleSetSideBar = () => {
    setSidebarOpen(!sidebarOpen);
  };

 

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={setSidebarOpen} setSidebarOpen={handleSetSideBar} refresh={refresh} setRefresh={setRefresh}/>

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          logoutUser={props.handleLogoutOfUser}
          setSidebarOpen={setSidebarOpen}
        />

        <main className="bg-gray-100">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <Routes>
              <Route path="home" element={<Home />} />
              <Route path="profile" element={<Userprofile />} />
              <Route
                element={
                  <HospitalGuard
                    currentUserRole={userDetails.role}
                    compareToRole={["SuperAdmin"]}
                  />
                }
              >
                <Route path="hospitals/*" element={<Hospitals />} />
                <Route path="promotions/*" element={<Promotions />} />
                <Route path="locations/*" element={<Locations />} />
                <Route path="insurance/*" element={<Insurance />} />
                <Route path="language/*" element={<Languagepage />} />
                <Route path="speciality/*" element={<Speciality />} />
                <Route path="subscription/*" element={<Subscription />} />
                <Route path="medicines/*" element={<Medicines />} />
                <Route path="sponsors/*" element={<Sponsors />} />
                <Route path="holidays/*" element={<Holidays />} />
                <Route
                  path="push-notifications/*"
                  element={<Pushnotifications />}
                />
                <Route path="coupons" element={<Coupons />} />
                <Route path="requests" element={<Requests />} />
              </Route>
              <Route
                element={
                  <HospitalGuard
                    currentUserRole={userDetails.role}
                    compareToRole={["SuperAdmin", "Hospital"]}
                  />
                }
              >
                {/* <Route path="requests" element={<Requests/>}/> */}
                <Route path="requests/*" element={<RequestDetail />} />
                <Route path="branches/*" element={<Branches />} />
              </Route>
              <Route
                element={
                  <HospitalGuard
                    currentUserRole={userDetails.role}
                    compareToRole={["SuperAdmin", "Hospital", "Branch"]}
                  />
                }
              >
                <Route path="schedules/*" element={<Schedules currentUserRole={userDetails.role} setSidebarOpen={setSidebarOpen} refresh={refresh} setRefresh={setRefresh}/>} />
                <Route path="doctors/*" element={<Doctors />} />
                <Route path="receptionist/*" element={<Receptionist />} />
                <Route path="pharmacy/*" element={<Pharmacy />} />
                <Route path="laboratory/*" element={<Laboratory />} />
                <Route path="blog/*" element={<Blog />} />
                <Route path="Symptoms/*" element={<Symptoms />} />
                <Route path="Leaves" element={<Leaves />} />
              </Route>
              <Route
                element={
                  <HospitalGuard
                    currentUserRole={userDetails.role}
                    compareToRole={["Hospital"]}
                  />
                }
              >
                <Route
                  path="addHospitalUser/*"
                  element={<Mapuserhospital />}
                ></Route>
              </Route>
              <Route path="" element={<Navigate to="/dashboard/home" />} />
              <Route path="*" element={<Navigate to="/dashboard/home" />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;

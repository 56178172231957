import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function getSpecialist(){
    try {
        const url = "speciality"
        const {data : speciality} = await httpservice.get(url)
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function createSpecialist(payload){
    try {
        const url = "speciality/create"
        const {data : speciality} = await httpservice.post(url, payload)
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.error)
        }
    }
}

export async function deleteSpecialist(id){
    try {
        const url = "speciality/delete/"
        const {data : speciality} = await httpservice.delete(url + id)
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function updateSpecialist(id,payload){
    try {
        const url = "speciality/edit/"
        const {data : speciality} = await httpservice.put(url + id,payload)
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.error)
        }
    }
}
export async function createSpecialitywithDoctor(payload){
    try {
        const url = 'speciality/assign/doctor'
        const {data : speciality} = await httpservice.put(url,payload)
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function updateSpecialityDoctor(id,payload){
    try {
        const url = "speciality/assign/doctor/edit/"
        const {data : speciality} = await httpservice.put(url+id,payload)
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.error)
        }
    }
}

export async function getSingleSpecialist(id){
    try {
        const url = "speciality/"
        const {data : speciality} = await httpservice.get(url + id)
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function unassigndoctor(id,specialityId){
    try {
        const url = "speciality/unassign/doctor/"
        const {data : speciality} = await httpservice.put(url + id,{speciality:specialityId})
        return speciality
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
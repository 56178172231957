import React, {useRef, useState, useContext, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import Card from "../../../../shared/components/Card/Card";
import {Button, MenuItem, TextField} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {createAdvertise ,singlegetAdvertise,updatePermotions, UpdateBanner,SinglegetPromotions, createBanner,SinglegetBanner, createPermotions,updateAdvertiseng} from "../../../../core/services/Data/permotionService";
import LocationPicker from 'react-location-picker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {UserContext} from "../../../../App";
import {toast} from "react-toastify";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
import { advertisementSchema } from '../../../../core/Validationschemas/promotionValidation';


const Addform = () => {
    const navigate = useNavigate();
    const s = useParams()
    const history = useLocation();
    const {setShowLoader} = useContext(UserContext);
    const [formPrefillData, setFormPrefillData] = useState();
    const labelToShow = history.pathname.split('/')[3];
    const imageInputref = useRef();
    
    const [imageUrl, setImageUrl] = useState('');
    const formik = useFormik({
        initialValues: {
            category: formPrefillData ? formPrefillData.category :  '',
            hyperLink: formPrefillData ? formPrefillData.hyperLink : '',
            whatsappNo:  formPrefillData ? formPrefillData.whatsapp :'',
            description:  formPrefillData ? formPrefillData.desc :'',
            image:  formPrefillData ? formPrefillData.image :'',
            startDate :  formPrefillData ? new Date(formPrefillData.startDate) : null,
            endDate : formPrefillData ? new Date(formPrefillData.endDate) : null,
            entityName :  formPrefillData ? formPrefillData.entityName :''
        },
        enableReinitialize: true,
        validationSchema: advertisementSchema,
        onSubmit: (values) => {
            setShowLoader(true)  
            new Promise((resolve,reject)=>{
                if(typeof values.image !== "string"){
                    const response = uploadFileService.fileUpload(values.image)
                    if(response){
                        resolve(response)
                    } else{
                        reject()
                    }
                } else{
                    resolve() 
                }
              }).then(async (res)=>{
            // if(s["*"] === 'add-advertisement'){
            //   new Promise((resolve,reject)=>{
            //     if(typeof values.image !== "string"){
            //         const response = uploadFileService.fileUpload(values.image)
            //         if(response){
            //             resolve(response)
            //         } else{
            //             reject()
            //         }
            //     } else{
            //         resolve() 
            //     }
            //   }).then(async (res)=>{
              if(s["*"] === `add-advertisement/update/${s.id}`){
                // console.log(1);
                const payload = {
                    ...values,
                    whatsapp : values.whatsappNo,
                    desc : values.description,
                    image : res ? res.fileUrl : values.image
                } 
                delete payload.whatsappNo 
                delete payload.description
                // console.log(2);
                updateAdvertiseng(s.id,payload).then((res)=>{
                    console.log(3);
                    if(res){
                        toast.success(res.message)
                        navigate('/dashboard/promotions')
                        setShowLoader(false)
                    }else{
                        setShowLoader(false)
                    }
                })
              }
                else if(s["*"] === 'add-advertisement'){
                    const payload = {
                        ...values,
                        whatsapp : values.whatsappNo,
                        desc : values.description,
                        image : res.fileUrl
                    }
                    delete payload.whatsappNo 
                    delete payload.description

                    createAdvertise(payload).then((res)=>{
                        if(res){
                            toast.success(res.message);
                            navigate('/dashboard/promotions')
                            setShowLoader(false)
                        }
                        else{
                            setShowLoader(false)
                        }
                    })
                    console.log("Values", values);
                }
                // })
              
            // }
           else if(s["*"] === "add-banner"){
                setShowLoader(true)
                uploadFileService.fileUpload(values.image).then(async (res)=>{
                    
                    
                            const payload = {
                                ...values,
                                whatsapp : values.whatsappNo,
                                desc : values.description,
                                image : res.fileUrl
                            }
                            delete payload.whatsappNo 
                            delete payload.description
                            createBanner(payload).then((res)=>{
                                if(res){
                                    toast.success(res.message);
                                    navigate('/dashboard/promotions')
                                    setShowLoader(false)
                                }
                                else{
                                    setShowLoader(false)
                                }
                            })
                            console.log("Values", values);
                        })
            }

            else if(s["*"] === `add-banner/update/${s.id}`){
                const payload = {
                    ...values,
                    whatsapp : values.whatsappNo,
                    desc : values.description,
                    image : res ? res.fileUrl : values.image
                } 
                delete payload.whatsappNo 
                delete payload.description
                // console.log(2);
                UpdateBanner(s.id,payload).then((res)=>{
                    console.log(3);
                    if(res){
                        toast.success(res.message)
                        navigate('/dashboard/promotions')
                        setShowLoader(false)
                    }else{
                        setShowLoader(false)
                    }
                })
            }
            else if(s["*"] === "add-promotion"){
                setShowLoader(true)
                uploadFileService.fileUpload(values.image).then(async (res)=>{
                    
                    
                    const payload = {
                        ...values,
                        whatsapp : values.whatsappNo,
                        desc : values.description,
                        image : res.fileUrl
                    }
                    delete payload.whatsappNo 
                    delete payload.description
                    createPermotions(payload).then((res)=>{
                        if(res){
                            toast.success(res.message);
                            navigate('/dashboard/promotions')
                            setShowLoader(false)
                        }
                        else{
                            setShowLoader(false)
                        }
                    })
                    console.log("Values", values);
                })
            }
            else if(s["*"] === `add-promotion/update/${s.id}`){
                const payload = {
                    ...values,
                    whatsapp : values.whatsappNo,
                    desc : values.description,
                    image : res ? res.fileUrl : values.image
                } 
                delete payload.whatsappNo 
                delete payload.description
                // console.log(2);
                updatePermotions(s.id,payload).then((res)=>{
                    console.log(3);
                    if(res){
                        toast.success(res.message)
                        navigate('/dashboard/promotions')
                        setShowLoader(false)
                    }else{
                        setShowLoader(false)
                    }
                })
            }
        })
        },
    });
    
    const ImageInputChangeHandler = (event) => {
        console.log("Image given--", event.currentTarget.files[0] );
        if (event.currentTarget.files.length !== 0) {
            formik.setFieldValue("image", event.currentTarget.files[0] ? event.currentTarget.files[0] : '')
            setImageUrl((prevState) => {
                return URL.createObjectURL(event.target.files[0])
            });
        } else {
            formik.setErrors({image: 'Image is required'});
        }
        console.log("height",document.getElementById("resolutionCheck").offsetHeight)
    }

    const ImageResetImageInput = () => {
        formik.setFieldValue('image', '');  
        setImageUrl((prevState) => {
            return ''
        });
    }
    console.log("+++++++=====>", formPrefillData);
            useEffect(()=>{
                if(s["*"] === `add-advertisement/update/${s.id}`){
                        setShowLoader(true)
                        singlegetAdvertise(s.id).then((res)=>{
                            setFormPrefillData((prevState) => {
                                setShowLoader(false)
                                return res.data
                            });
                            setImageUrl(res.data.image)
                        })
                    }
                    else if(s["*"] === `add-banner/update/${s.id}`){
                        setShowLoader(true)
                        SinglegetBanner(s.id).then((res)=>{
                            setFormPrefillData((prevState) => {
                                setShowLoader(false)
                                return res.data
                            });
                            setImageUrl(res.data.image) 

                        })

                    }
                    else if(s["*"] === `add-promotion/update/${s.id}`){
                        setShowLoader(true)
                        SinglegetPromotions(s.id).then((res)=>{
                            setFormPrefillData((prevState) => {
                                setShowLoader(false)
                                return res.data
                            });
                            setImageUrl(res.data.image) 
                        })
                    }

            },[])

            console.log(formik.errors,"formikpro");

    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-xl font-bold text-center capitalize">{s.id ? "Update" : "Add"}</h1>
            <form onSubmit={formik.handleSubmit} className="grid grid-cols-12 gap-1 sm:gap-5">
                <div className="col-span-12 mt-2">
                    <TextField
                        style={{width: '100%'}}
                        id="categoryInput"
                        label="Category name"
                        name="category"
                        select
                        helperText={formik.touched.category && Boolean(formik.errors.category) ? 'Category name is required' : ''}
                        value={formik.values.category}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.category && Boolean(formik.errors.category)}>
                        <MenuItem value="Lab">Lab</MenuItem> 
                        <MenuItem value="Pharmacy">Pharmacy</MenuItem>
                        <MenuItem value="Hospital">Hospital</MenuItem>
                        {/* <MenuItem value="Noida">Pharmacy</MenuItem> */}
                    </TextField>
                </div>

                <div className="col-span-12 mt-2">
                    <TextField
                        id="entityName"
                        fullWidth
                        helperText={formik.touched.entityName && Boolean(formik.errors.entityName) ? 'Title is required' : ''}
                        value={formik.values.entityName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.entityName && Boolean(formik.errors.entityName)}
                        label="Title"
                        name="entityName"
                        variant="outlined"/>
                </div>


                <div className="col-span-12 mt-2">
                    <TextField
                        id="hyperLink"
                        fullWidth
                        helperText={formik.touched.hyperLink && Boolean(formik.errors.hyperLink) ? 'hyperLink is required' : ''}
                        value={formik.values.hyperLink}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.hyperLink && Boolean(formik.errors.hyperLink)}
                        label="hyperLink"
                        name="hyperLink"
                        variant="outlined"/>
                </div>
                <div className="col-span-12 mt-2">
                    <TextField
                        id="whatsappNo"
                        fullWidth
                        helperText={formik.touched.whatsappNo && formik.errors.whatsappNo}
                        value={formik.values.whatsappNo}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.whatsappNo && Boolean(formik.errors.whatsappNo)}
                        label="Whatsapp No"
                        name="whatsappNo"
                        variant="outlined"/>
                </div>
                <div className="col-span-12 mt-2">
                    <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        multiline
                        rows={6}
                        helperText={formik.touched.description && Boolean(formik.errors.description) ? 'Description is required' : ''}
                        value={formik.values.description}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                    />
                </div>
                <div className="col-span-12 mt-2">
                    
                <div className="flex flex-col justify-center items-center max-w-full">
                                    <label
                                        className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${formik.errors.image ? 'border-[#d32f2f]' : 'border-gray-300'}
                                        border-dashed cursor-pointer`}>
                                        <input
                                            ref={imageInputref}
                                            id="dropzone-file"
                                            name="hospitalPermit"
                                            disabled={imageUrl !== ''}
                                            accept="image/png, image/jpeg, image/jpg"
                                            onBlur={formik.handleBlur}
                                            onChange={ImageInputChangeHandler}
                                            type="file"
                                            className="hidden"/>
                                        {imageUrl !== '' ? (
                                            <div className="relative w-24 h-24">
                                                <img id="blah" src={imageUrl}
                                                     className="shadow w-full object-fill"
                                                     alt="your"/>
                                                <CloseIcon onClick={ImageResetImageInput}
                                                           className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"/>
                                            </div>
                                        ) : (
                                            <div
                                                className="flex flex-col justify-center items-center pt-5 pb-6">
                                                <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400"
                                                     fill="none"
                                                     stroke="currentColor" viewBox="0 0 24 24"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                    className="font-semibold">Click to upload Image</span> or
                                                    drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG,
                                                    PNG, JPG or
                                                    GIF
                                                    (Aspect Ratio 2:1, e.g., 500x250, 1000x500)</p>
                                            </div>
                                        )}
                                    </label>
                                </div>
                                <div
                        className={`text-[#d32f2f] my-2 text-sm ml-4 ${formik.errors.image ? '' : 'hidden'}`}>
                         {formik.errors.image}
                    </div>
                </div>
               
                <div className="col-span-12 mt-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date"
                            minDate={new Date()}
                            value={formik.values.startDate}
                            onChange={(value) => {
                                formik.setFieldValue('startDate', Date.parse(value))
                            }}
                            error={
                                formik.touched.startDate && Boolean(formik.errors.startDate)
                              }
                            renderInput={(params) => <TextField 
                                fullWidth
                                error={
                                    formik.touched.startDate && Boolean(formik.errors.startDate)
                                  }
                                  helperText={
                                    formik.touched.startDate && Boolean(formik.errors.startDate)
                                      ? "Start Date is required"
                                      : ""
                                  } 
                                {...params} 
                                />}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-span-12 mt-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="End Date"
                            minDate={formik.values.startDate || new Date()}
                            error={
                                formik.touched.endDate && Boolean(formik.errors.endDate)
                              }
                            // disableFuture
                            value={formik.values.endDate}
                            onChange={(value) => {
                                formik.setFieldValue('endDate', Date.parse(value))
                            }}
                            renderInput={(params) => <TextField 
                                fullWidth
                                error={
                                    formik.touched.endDate && Boolean(formik.errors.endDate)
                                  }
                                  helperText={
                                    formik.touched.endDate && Boolean(formik.errors.endDate)
                                      ? "End Date is required"
                                      : ""
                                  } 
                                {...params} 
                                />}
                        />
                    </LocalizationProvider>
              
                        </div> <br/>
                <Button color="primary" variant="contained" fullWidth type="submit">
                    {s.id ? "Update" : "Submit"} 
                </Button>

                <img id='resolutionCheck' onLoadCapture={(e)=>{
                    const width = e.target.offsetWidth;
                    const height = e.target.offsetHeight;
                    if(!(width/2 === height)){
                        formik.setFieldValue('image', '');  
                        setImageUrl("")
                        toast.error("Aspect Ratio of Image should be 2:1.(e.g., 500x250,300x150...")
                    }  
                }} src={imageUrl} alt="" className='absolute invisible' />
            </form>
        </Card>
    );
};

export default Addform;

import React from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Doughnut} from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    datasets: [
        {
            label: '# of Votes',
            data: [3, 12, 19],
            backgroundColor: [
                '#F54484',
                '#37C93D',
                '#1247FF',
            ],
            borderColor: [
                '#F54484',
                '#37C93D',
                '#1247FF',
            ],
            borderWidth: 1,
            responsive: true,
        },
    ],
};

const Doughnutchart = () => {
    return (
        <Doughnut data={data}/>
    );
};

export default Doughnutchart;

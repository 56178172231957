import React, { useState, useContext } from "react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../../shared/components/Card/Card";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UserContext } from "../../../../App";
import { createCoupons } from "../../../../core/services/Data/couponService";

const Couponform = () => {
  const { setShowLoader } = useContext(UserContext);
  const navigate = useNavigate();
  const validationOncoupons = yup.object({
    code: yup.string().required("Code is required"),
    validFrom: yup.string().required("Valid From is required"),
    validTo: yup.string().required("Valid Till is required"),
    type: yup.string().required("Type is required"),
    maxDiscount: yup.string().required("Discount is required"),
    description: yup.string().required("Description required"),
  });
  const formik = useFormik({
    initialValues: {
      code: "",
      validFrom: null,
      validTo: null,
      description: "",
      type: "",
      maxDiscount: "",
    },
    validationSchema: validationOncoupons,
    enableReinitialize: true,
    onSubmit: (values) => {
      setShowLoader(true);
      console.log("Values", values);
      const payload = {
        ...values,
      };
      createCoupons(payload).then((res) => {
        if (res) {
          console.log("res.data", res);
          // navigate('/dashboard/coupons')
          window.location.reload();
          setShowLoader(false);
        }
      });
    },
  });
  return (
    <Card>
      <h1 className="mb-4 text-2xl font-semibold">Generate promo code</h1>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10"
      >
        <div className="col-span-12 md:col-span-4">
          <TextField
            fullWidth
            variant="outlined"
            id="code"
            name="code"
            label="Code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.code && Boolean(formik.errors.code)
                ? "Code is required"
                : ""
            }
            error={formik.touched.code && Boolean(formik.errors.code)}
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Valid from"
              name="validFrom"
              value={formik.values.validFrom}
              onChange={(value) => {
                formik.setFieldValue("validFrom", Date.parse(value));
              }}
              error={
                formik.touched.validFrom && Boolean(formik.errors.validFrom)
              }
              renderInput={(params) => (
                <TextField
                  fullWidth
                  name="validFrom"
                  error={
                    formik.touched.validFrom && Boolean(formik.errors.validFrom)
                  }
                  helperText={
                    formik.touched.validFrom && Boolean(formik.errors.validFrom)
                      ? "Valid from is required"
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-span-12 md:col-span-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Valid till"
              name="validTo"
              value={formik.values.validTo}
              onChange={(value) => {
                formik.setFieldValue("validTo", Date.parse(value));
              }}
              error={formik.touched.validTo && Boolean(formik.errors.validTo)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  name="validTo"
                  error={
                    formik.touched.validTo && Boolean(formik.errors.validTo)
                  }
                  helperText={
                    formik.touched.validTo && Boolean(formik.errors.validTo)
                      ? "Valid Till is required"
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-span-12 md:col-span-8">
          <TextField
            fullWidth
            id="hospitalInput"
            label="Description"
            name="description"
            multiline
            rows={5}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.description && Boolean(formik.errors.description)
                ? "Description is required"
                : ""
            }
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
          />
        </div>
        <div className="col-span-12 md:col-span-4 flex flex-col my-auto gap-2 md:gap-4 lg:gap-7 xl:gap-8">
          <TextField
            fullWidth
            id="type"
            label="Type"
            name="type"
            select
            value={formik.values.type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.type && Boolean(formik.errors.type)
                ? "Type is required"
                : ""
            }
            error={formik.touched.type && Boolean(formik.errors.type)}
          >
            <MenuItem value="Percentage Based">Percentage Based</MenuItem>
            <MenuItem value="Flat rate">Flat rate</MenuItem>
          </TextField>
          <TextField
            fullWidth
            variant="outlined"
            id="maxDiscount"
            name="maxDiscount"
            label="Discount"
            value={formik.values.maxDiscount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.maxDiscount && Boolean(formik.errors.maxDiscount)
                ? "Discount is required"
                : ""
            }
            error={
              formik.touched.maxDiscount && Boolean(formik.errors.maxDiscount)
            }
          />
        </div>
        <div className="col-span-12 flex justify-end">
          <Filledbluebutton
            cname="w-42"
            btnType="submit"
            disabledBtn={!formik.isValid}
          >
            Add New Promo
          </Filledbluebutton>
        </div>
      </form>
    </Card>
  );
};

export default Couponform;

import React, { useContext, useState, useEffect, useRef } from "react";
import Card from "../../../../shared/components/Card/Card";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { UserContext } from "../../../../App";
import { toast } from "react-toastify";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
// import { insuranceRegistrationValidation } from "../../../../core/Validationschemas/insuranceValidation";
import insuranceService from "../../../../core/services/Data/insuranceService";
import CloseIcon from "@mui/icons-material/Close";
import symptomsService from "../../../../core/services/Data/symptomsService";
import * as yup from "yup";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import { AgGridReact } from "ag-grid-react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  getSingleSpecialist,
  unassigndoctor,
} from "../../../../core/services/Data/speciality";

const DoctorAssign = () => {
  const params = useParams();
  const [idToDelete, setIdToDelete] = useState("");
  const { setShowLoader } = useContext(UserContext);
  const [doctorTable, setdoctorTable] = useState();
  const [insurance, setInsurance] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getData = () => {
    getSingleSpecialist(params.id).then((res) => {
      setdoctorTable(res.data?.doctors);
      setInsurance(res.data?.specialityName);
    });
  };
  const Deletebutton = (props) => {
    console.log("props.data", props.data._id);
    return (
      <DeleteOutlineOutlinedIcon
        onClick={() => {
          props.deleteClicked(props.data._id);
        }}
        className={`text-red-500 ${props.cname} border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`}
      />
    );
  };

  const [columnDefs] = useState([
    // { headerName: "Image", field: "icon", resizable: true, cellRenderer: Imagerenderer, },
    {
      headerName: "Doctor Name",
      field: "name",
      resizable: true,
      valueGetter: (e) => {
        return (
        e.data.name.first + " " + e.data.name.last
        );
      },
    },

    {
      headerName: "Action",
      cellRenderer: Deletebutton,
      sortable: false,
      filter: false,
      resizable: true,
      cellRendererParams: {
        data: gridRef && gridRef,

        deleteClicked: (id) => {
          handleOpen();
          setIdToDelete((prevState) => {
            return id;
          });
          // console.log('id', id)
        },
      },
    },
    // { headerName: "Description", field: "description", resizable: true },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  const deleteAssignee = () => {
    console.log(idToDelete);
    setShowLoader(true);
    unassigndoctor(idToDelete,params.id).then((res) => {
      setShowLoader(false);
      toast.success(res.message);
      getData();
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Card>
        <Deletepopup
          handleCloseModel={handleClose}
          isOpen={open}
          id={idToDelete}
          handleDelete={deleteAssignee}
        />

        <div className="flex justify-between mb-3">
          <div className="order-first">
            <p className="text-2xl font-semibold">
              {insurance ? insurance : null}
            </p>
          </div>
          <div className="order-last">
            <Outlinedbluebutton />
            <Filledbluebutton
              cname="ml-4"
              clicked={() => {
                navigate(
                  `/dashboard/speciality/assign-speciality/adddoctor/${params.id}`
                );
              }}
            />
          </div>
        </div>
        <div className="mt-5">
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={doctorTable}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowSelection="multiple"
            ></AgGridReact>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DoctorAssign;

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Doctorshome from "./Doctorshome/Doctorshome";
import Doctorsregistration from "./Doctorsregistration/Doctorsregistration";

const Doctors = () => {
    return (
        <Routes>
            <Route path="" element={<Doctorshome/>}/>
            <Route path="/registration" element={<Doctorsregistration/>}/>
            <Route path="/registration/:id" element={<Doctorsregistration/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
};

export default Doctors;

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Holidayhome from "./Holidayhome/Holidayhome";
import Addholidayform from "./Addholidayform/Addholidayform";

const Holidays = () => {
    return (
        <Routes>
            <Route path="" element={<Holidayhome/>}/>
            <Route path="/add-form" element={<Addholidayform/>}/>
            <Route path="/add-form/:id" element={<Addholidayform/>}/>
            <Route path="" element={<Navigate to="/login"/>}/>
            <Route path="*" element={<Navigate to="/login"/>}/>
        </Routes>
    );
};

export default Holidays;

import React, {useState, useEffect,useContext,useRef,useCallback} from 'react';
import Card from "../../../shared/components/Card/Card";
import Calendar from "react-calendar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Doughnutchart from "../../../shared/components/Doughnutchart/Doughnutchart";
import {useNavigate} from "react-router-dom";
import {leavesDetailsTableData} from "../../../core/constants/appconstants";
import Filterbutton from "../../../shared/components/Filterbutton/Filterbutton";
import {analytics,leaveDetails,updateLeaves} from "../../../core/services/Data/leaveService";
import {UserContext} from "../../../App";
import {AgGridReact} from "ag-grid-react";
import Outlinedbluebutton from "../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import {BasicLeaves} from "../../../shared/components/statusButton/StatusButton";
import {toast} from "react-toastify";
import Imagerenderer from "../../../shared/components/AgGridRendererComponents/Imagerenderer";

const Leaves = () => {
    const navigate = useNavigate();
    const leavesTableData = leavesDetailsTableData;
    const [date, setDate] = useState(new Date());
    const {setShowLoader} = useContext(UserContext);
    const [analyticData, setAnalyticData] = useState(null);
    const [leaveData, setLeaveData] = useState(null);
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);


    function fullNameGetter(params) {
        if(params.data.user.name.first === undefined) return "Akash "
        return params.data.user.name.first + ' ' + params.data.user.name.last ;
      }
        const GetingDate = (type) => (params) => {
            if(type === "startDate"){
                return  params.data.startDate.split("T")[0]
            }else{
                return params.data.endDate.split("T")[0]
            }
        }
    


    const [columnDefs] = useState([
        {headerName: "Image", field: "user.picture", cellRenderer: Imagerenderer},
        {headerName: "Employee Name", field: "first & last",valueGetter: fullNameGetter },
        {headerName: "Hospital name", field: "user.hospital.hospitalName"},
        {headerName: "Branch name", field: "user.branch.name"},
        {headerName: "From Date", field: "startDate", valueGetter : GetingDate("startDate")},
        {headerName: "To Date", field: "endDate", valueGetter : GetingDate("endDate")},
        {headerName: "Leave type", field: "leaveType"},
        {
            headerName: "Status",
            cellRenderer: BasicLeaves ,
            sortable: false,
            filter: false,
            cellRendererParams: {
              data: gridRef && gridRef,
              statusChanged: (data) => {
                console.log('id', data)
                setShowLoader(true);
                const elf = data.status == "Pending" || data.status == "Rejected" ? "Approved" : "Rejected"
                updateLeaves({ status: elf },data._id).then((res) => {
                  if (res) {
                    toast.success("Status Updated Successfully");
                    setShowLoader(false);
                  } else {
                    setShowLoader(false);
                    toast.error("Something Goes wrong");
                  }
                });
              },
            },
          },
       
        // {
            // headerName: 'Action',  sortable: false, filter: false, cellRendererParams: {
            //     data: gridRef && gridRef,
            // }
        // }
    ]);
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const handleDateChange = (event) => {
        const payload = event.getFullYear() + '-' + (event.getMonth() + 1) + '-' + event.getDate();
        setShowLoader(true)
        analytics(payload).then(async (res) => {
            if (res) {
                await setAnalyticData(res);
                setShowLoader(false);
            }
        })
    }

    const dashboardAPIcalls = (datePayload) => {
        setShowLoader(true)
        Promise.all([
            analytics(datePayload),
            leaveDetails(),
            // requestService.requests(2),
            // coupons(2),
            // getPerformanceData()
        ]).then(async ([res1, res2]) => {
            await setAnalyticData(res1);
            await setLeaveData(res2.data)
            // await setDoctorsArray(res2);
            // await setReceivedRequests(res3.data);
            // await setCouponsData(res4);
            setShowLoader(false);
        })
    }
    console.log("UPDATED ONE+=====>Leaves ===>", leaveData);
   
    useEffect(() => {
        const payload = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        dashboardAPIcalls(payload);
       
    }, []);
    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    return (
        <div>
            <div className="flex justify-between mb-3">
                <div className="order-first">
                    <h1 className="text-2xl font-bold tracking-wider">Leaves</h1>
                </div>
                 <div className="order-last">
                <Outlinedbluebutton download={onBtnExport}/>
                    {/* <Filterbutton text="Filter by date"/>
                    <Filterbutton text="Filter by type" cname="mx-4"></Filterbutton> */}
                </div> 
            </div>

            
            <div 
            // className="w-full  grid grid-cols-1 h-fit gap-2 md:gap-5 lg:gap-8 xl:gap-10"
            >
                {/* <Card>
                    <div className="grid  grid-cols-12 2xl:place-items-stretch my-auto place-items-center gap-5 my-auto">
                        <div className="col-span-12 w-full  2xl:col-span-6 my-auto">
                            <Calendar  className = "w-96"
                                onChange={handleDateChange}
                                value={date}
                            />
                        </div>
                        <div className="col-span-10 2xl:col-span-6 my-auto">
                        {analyticData && (
                            <div className="grid grid-cols-2 gap-5">
                                <div className="shadow pt-12 h-32 flex flex-col bg-sky-400 rounded-xl text-white p-3">
                                    <h1 className="text-xl font-bold">{analyticData.data.totalLeaves}</h1>
                                    <p className="text-sm">Total Leaves</p>
                                </div>
                                <div className="shadow pt-12 h-32 flex flex-col bg-orange-400 rounded-xl text-white p-3">
                                    <h1 className="text-xl font-bold">{analyticData.data.vacation}</h1>
                                    <p className="text-sm">Vacation</p>
                                </div>
                                <div className="shadow pt-12 h-32 flex flex-col bg-green-400 rounded-xl text-white p-3">
                                    <h1 className="text-xl font-bold">{analyticData.data.sick}</h1>
                                    <p className="text-sm">Sick</p>
                                </div>
                                <div className="shadow pt-12 h-32 flex flex-col bg-yellow-400 rounded-xl text-white p-3">
                                    <h1 className="text-xl font-bold">{analyticData.data.casual}</h1>
                                    <p className="text-sm">Casual</p>
                                </div>
                            </div>
                             )}
                        </div>
                    </div>
                </Card> */}
                {/* <Card> */}
                    {/* <div className="flex justify-between mt-3">
                        <div className="order-first">
                                <span className="font-bold text-xl tracking-wider">
                                    Present today
                                </span>
                        </div>
                        <div className="order-last cursor-pointer">
                            <div
                                className="tracking-wider font-bold hover:underline text-sky-800 flex justify-center items-center">
                                <ArrowBackIosIcon/>
                                <span className="mx-6 bg-sky-200 px-6 py-1">
                                May
                            </span>
                                <ArrowForwardIosIcon/>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="mt-5">
                        <div className="grid grid-cols-12 gap-10">
                            <div className="col-span-6">
                                <Doughnutchart/>
                            </div>
                            <div className="col-span-6 flex flex-col justify-center my-auto">
                                <div>
                                    <div className="flex flex-row items-center">
                                        <div className="bg-[#1247FF] rounded-full h-2 w-2">&nbsp;</div>
                                        <h1 className="text-sm ml-5">No. of patients</h1>
                                    </div>
                                    <div className="flex flex-row items-center ml-7 text-xs text-gray-500">
                                        90%
                                    </div>
                                </div>
                                <div className="my-4">
                                    <div className="flex flex-row items-center">
                                        <div className="bg-[#F54484] rounded-full h-2 w-2">&nbsp;</div>
                                        <h1 className="text-sm ml-5">Pending reports</h1>
                                    </div>
                                    <div className="flex flex-row items-center ml-7 text-xs text-gray-500">
                                        90%
                                    </div>
                                </div>
                                <div>
                                    <div className="flex flex-row items-center">
                                        <div className="bg-[#37C93D] rounded-full h-2 w-2">&nbsp;</div>
                                        <h1 className="text-sm ml-5">Completed reports</h1>
                                    </div>
                                    <div className="flex flex-row items-center ml-7 text-xs text-gray-500">
                                        90%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </Card> */}
            </div>

            
            <div className="grid grid-cols-1 gap-2 mt-5">
                <Card>
                    <div className="mt-5">

                    <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                    <AgGridReact 
                        ref={gridRef}
                        rowData={leaveData}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        pagination={true} 
                        paginationPageSize= {10 } 
                        rowSelection="multiple">
                    </AgGridReact>
                </div>

                        {/* <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full text-center">
                                            <thead className="border-b bg-gray-50 font-bold">
                                            <tr>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    Image
                                                </th>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    Employee Name
                                                </th>
                                                <th scope="col" className="text-sm text-gray-900 px-6 py-4">
                                                    Hospital name
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                                    Branch name
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                                    From Date
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                                    To Date
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                                    Leave type
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                                    Status
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {leaveData && leaveData.map((ele) => {
                                                return (
                                                    <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900 flex justify-center">
                                                            <img src={ele.imageUrl}
                                                                 className="bg-white shadow rounded-full" alt=""/>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.user.name.first} {ele.user.name.last}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.user.hospital.hospitalName}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.user.branch.name}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.startDate.split("T")[0]}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.endDate.split("T")[0]}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.leaveType}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.status}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Leaves;

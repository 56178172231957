import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ScheduleCalender from "./ScheduleHome/ScheduleHomeCalender-Copy";
import ScheduleHome from "./ScheduleHome/ScheduleHome-copy";
// import ScheduleHome from "./ScheduleHome/ScheduleHome";
import Scheduledocview from "./Scheduledocview/Scheduledocview";
import CreateSchedule from "./Scheduledocview/createSchedule";

const Schedules = ({refresh,setRefresh,setSidebarOpen,currentUserRole}) => {
  const [state, setState] = useState();
  const [datalist, setDatalist] = useState();
  const pull_data = (data) => {
    setState(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  const cons = (data) => {
    setDatalist(data);
  };
  return (
    <Routes>
      <Route path="" element={<ScheduleHome func={pull_data} cops={cons} />} />
      <Route path="/doctor/:id" element={<ScheduleCalender currentUserRole={currentUserRole} setSidebarOpen={setSidebarOpen} refresh={refresh} setRefresh={setRefresh} func={pull_data} cons={cons} />} />
      <Route path="/:id" element={<Scheduledocview cons={cons} />} />
      <Route path="/create/:id" element={<CreateSchedule state={state} />} />
    </Routes>
  );
};

export default Schedules;

import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { UserContext } from "../../../../App";
import { toast } from "react-toastify";
import {
  createholiday,
  updateHoliday,
  getHolidaysById,
} from "../../../../core/services/Data/holidayService";

const Addholidayform = () => {
  const { setShowLoader } = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const [formPrefillData, setFormPrefillData] = useState();
  const validation = yup.object({
    holidayName: yup.string("").required("Holiday Name is required"),
    date: yup.string("").required("Date is required"),
  });

  const addHolidayForm = useFormik({
    initialValues: {
      holidayName: formPrefillData ? formPrefillData.name : "",
      date: formPrefillData ? formPrefillData.date : null,
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      setShowLoader(true);
      const payload = {
        ...values,
      };
      if (params.id) {
        updateHoliday(params.id, payload).then((res) => {
          // toast.success('Hospital details updated successfully');
          navigate("/dashboard/holidays")
          console.log(res);
          setShowLoader(false);
        });
      } else {
        createholiday(payload).then((res) => {
          if (res) {
            toast.success(res.message);
            navigate("/dashboard/holidays");
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (params.id) {  
      setShowLoader(true);
      getHolidaysById(params.id).then((res) => {
        console.log("First By Akash", res.data);
        setFormPrefillData(res.data)
      });
      setShowLoader(false);

    }
  }, []);
  return (
    <Card cname="max-w-2xl mx-auto">
      <h1 className="text-center text-2xl my-6 font-semibold">
        {params.id ? "Update holidayName" : "Add holidayName"}
      </h1>
      <form onSubmit={addHolidayForm.handleSubmit}>
        <TextField
          fullWidth
          id="hospitalInput"
          label="Holiday Name"
          name="holidayName"
          value={addHolidayForm.values.holidayName}
          onChange={addHolidayForm.handleChange}
          onBlur={addHolidayForm.handleBlur}
          helperText={
            addHolidayForm.touched.holidayName &&
            Boolean(addHolidayForm.errors.holidayName)
              ? "Holiday name is required"
              : ""
          }
          error={
            addHolidayForm.touched.holidayName &&
            Boolean(addHolidayForm.errors.holidayName)
          }
        ></TextField>
        <div className="my-5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              name="date"
              value={addHolidayForm.values.date}
              onChange={(value) => {
                  addHolidayForm.setFieldValue("date", Date.parse(value));         
              }}
              renderInput={(params) => (
                <TextField style={{ width: "100%" }} {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="my-5">
          <Filledbluebutton cname="w-full" btnType="submit">
            {" "}
            {params.id ? "Update" : "Add"}
          </Filledbluebutton>
        </div>
      </form>
    </Card>
  );
};

export default Addholidayform;

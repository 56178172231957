import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Languagehome from "./Languagehome/Languagehome";
import Addlanguageform from "./Addlanguageform/Addlanguageform";
import Assigndoctorform from "./Assigndoctorform/Assigndoctorform";
import  DoctorAssign  from "./Assigndoctorform/DoctorAssign";

const Languagepage = () => {
    return (
        <Routes>
            <Route path="" element={<Languagehome/>}/>
            <Route path="/add-language" element={<Addlanguageform/>}/>
            <Route path="/add-language/:id" element={<Addlanguageform/>}/>
            <Route path="/assign-doctor" element={<Assigndoctorform/>}/>
            <Route path="/assign-doctor/:id" element={<DoctorAssign/>}/>
            <Route path="/assign-doctor/adddoctor/:id" element={<Assigndoctorform/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
};

export default Languagepage;

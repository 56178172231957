import React from 'react';
import DoneIcon from '@mui/icons-material/Done';

const Rightbutton = (props) => {
    return (
        <button
            onClick={props.clicked}
            className={`bg-green-500 hover:bg-green-700 text-white p-2 flex justify-evenly items-center ${props.big ? 'rounded-lg w-32' : 'h-7 w-7 rounded-full'}`}>
            {props.big ? (<span>Approve</span>) : (<span></span>)}
            <DoneIcon/>
        </button>
    );
};

export default Rightbutton;

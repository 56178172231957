import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Bloghome from "./Bloghome/Bloghome";
import Blogview from "./Blogview/Blogview";

const Blog = () => {
    return (
        <Routes>
            <Route path="" element={<Bloghome/>}/>
            <Route path="/:id" element={<Blogview/>}/>
            <Route path="*" element={<Navigate to="/dashboard/blog"/>}/>
        </Routes>
    );
};

export default Blog;

import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

async function hospitalRegister(payload) {
    try {
        const {data: response} = await httpservice.post('hospitals/register', payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message || (ex.response.data))
        }
    }
}

async function hospitalUpdate(payload, id) {
    try {
        const {data: response} = await httpservice.put('hospitals/edit/' + id, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export default {
    hospitalRegister,
    hospitalUpdate
}

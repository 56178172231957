import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Insurancehome from "./Insurancehome/Insurancehome";
import Addinsuranceform from "./Addinsuranceform/Addinsuranceform";
import Assigndoctorform from "./Assigndoctorform/Assigndoctorform";
import DoctorAssign from "./Assigndoctorform/DoctorAssign";

const Insurance = () => {
    return (
        <Routes>
            <Route path="" element={<Insurancehome/>}/>
            <Route path="/add-insurance" element={<Addinsuranceform/>}/>
            <Route path="/add-insurance/:id" element={<Addinsuranceform/>}/>
            <Route path="/assign-doctor" element={<Assigndoctorform/>}/>
            <Route path="/assign-doctor/:id" element={<DoctorAssign/>}/>
            <Route path="/assign-doctor/adddoctor/:id" element={<Assigndoctorform/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

export default Insurance;

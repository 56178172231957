import React, {useContext, useState, useEffect} from 'react';
import {UserContext} from "../../../App";
import Card from "../../../shared/components/Card/Card";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {profileFormValidationSchema} from "../../../core/Validationschemas/Schedule";
import TextField from "@mui/material/TextField";
import Filledbluebutton from "../../../shared/components/Filledbluebutton/Filledbluebutton";
import Outlinedbluebutton from "../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import CloseIcon from "@mui/icons-material/Close";
import uploadFileService from "../../../core/services/Data/uploadFileService";
import updateUserProfile from "../../../core/services/Data/updateUserProfileService";
import updateUserProfileService from "../../../core/services/Data/updateUserProfileService";
import {toast} from "react-toastify";
import hospitalRegistrationService from "../../../core/services/Data/hospitalRegistrationService";

const Userprofile = () => {
    const {userDetails} = useContext(UserContext);
    const {setShowLoader} = useContext(UserContext);
    console.log('userDetails', userDetails);
    const [profileImagUrl, setHospitalImageUrl] = useState('');
    const [pfcData, setPfcData] = useState()
    const navigate = useNavigate();
    const profileForm = useFormik({
        initialValues: {
            firstName: pfcData ? pfcData.name.split(" ")[0] :  '',
            lastName:  pfcData ? pfcData.name.split(" ")[1] :'',
            email: userDetails ? userDetails.email : '',
            phoneNo: pfcData ? pfcData.phone :  '',
            profilePhoto: pfcData ? pfcData.picture : ''
        },
        validationSchema: profileFormValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setShowLoader(true);
            Promise.all([
                new Promise((resolve, reject) => {
                    if (typeof values.profilePhoto !== "string") {
                        const response = uploadFileService.fileUpload(values.profilePhoto)
                        if (response) {
                            resolve(response)
                        } else {
                            reject()
                        }
                    } else {
                        resolve()
                    } 
                })  
            ]).then(async ([res1]) => {
                const payload = {
                    name : {
                        first: values.firstName,
                        last: values.lastName
                    },
                    phone: values.phoneNo,
                    profilePhoto: res1 ? res1.fileUrl : values.profilePhoto
                }
                console.log('payload', payload);
                updateUserProfileService.updateUserProfile(payload).then((res) =>{
                    if(res){
                        setShowLoader(false);
                        toast.success('Profile Updated Successfully');
                        window.location.reload()
                        navigate('/dashboard/home');
                    }
                })
            })
        }
    });
    const getUserProfile=()=>{
        updateUserProfileService.getUserProfileService().then((res)=>{
            console.log("AKASh",res.data);
            setPfcData(res.data);
            setHospitalImageUrl(res.data.picture)
        })
    }
    useEffect(()=>{
        getUserProfile()
    },[])

    const profileImageInputChangeHandler = (event) => {
        if (event.currentTarget.files.length !== 0) {
            profileForm.handleChange(event);
            profileForm.setFieldValue("profilePhoto", event.currentTarget.files[0] ? event.currentTarget.files[0] : '')
            setHospitalImageUrl((prevState) => {
                return URL.createObjectURL(event.target.files[0])
            });
        }
    }

    const profileImageReset = () => {
        profileForm.setFieldValue('profilePhoto', '');
        setHospitalImageUrl((prevState) => {
            return ''
        });
    }

    return (
        <>
            <div className="flex justify-between mb-3">
                <div className="order-first">
                    <h1 className="text-2xl font-bold tracking-wider">Profile</h1>
                </div>
            </div>
            <Card>
                <form onSubmit={profileForm.handleSubmit} className={`grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10`}>
                    <div className=" col-span-12"> {/* md:col-span-9 */}
                        <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10">
                            <div className="col-span-6">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    value={profileForm.values.firstName}
                                    onChange={profileForm.handleChange}
                                    onBlur={profileForm.handleBlur}
                                    helperText={profileForm.touched.firstName && Boolean(profileForm.errors.firstName) ? profileForm.errors.firstName : ''}
                                    error={profileForm.touched.firstName && Boolean(profileForm.errors.firstName)}
                                />
                            </div>
                            <div className="col-span-6">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    value={profileForm.values.lastName}
                                    onChange={profileForm.handleChange}
                                    onBlur={profileForm.handleBlur}
                                    helperText={profileForm.touched.lastName && Boolean(profileForm.errors.lastName) ? profileForm.errors.lastName : ''}
                                    error={profileForm.touched.lastName && Boolean(profileForm.errors.lastName)}
                                />
                            </div>
                            <div className="col-span-6">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    className={`cursor-not-allowed`}
                                    disabled
                                    label="Email"
                                    value={profileForm.values.email}
                                    onChange={profileForm.handleChange}
                                    onBlur={profileForm.handleBlur}
                                    helperText={profileForm.touched.email && Boolean(profileForm.errors.email) ? profileForm.errors.email : ''}
                                    error={profileForm.touched.email && Boolean(profileForm.errors.email)}
                                />
                            </div>
                            <div className="col-span-6">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="phoneNo"
                                    name="phoneNo"
                                    label="Phone number"
                                    value={profileForm.values.phoneNo}
                                    onChange={profileForm.handleChange}
                                    onBlur={profileForm.handleBlur}
                                    helperText={profileForm.touched.phoneNo && Boolean(profileForm.errors.phoneNo) ? profileForm.errors.email : ''}
                                    error={profileForm.touched.phoneNo && Boolean(profileForm.errors.phoneNo)}
                                />
                            </div>
                        </div>
                        {/*Submit button*/}
                        <Filledbluebutton cname="w-32 mt-2" btnType="submit" disabledBtn={!profileForm.isValid}>
                            Save
                        </Filledbluebutton>
                    </div>
                    {/* <div className="md:col-span-3 col-span-12">
                        <div className="flex flex-col justify-center items-center max-w-full">
                            <label className={`flex flex-col justify-center items-center w-full h-40 w-40 bg-gray-50 rounded-full border
                                 ${profileForm.touched.profilePhoto && Boolean(profileForm.errors.profilePhoto) && profileForm.errors.profilePhoto
                                ? 'border-[#d32f2f]' : 'border-gray-300'}
                         border-dashed cursor-pointer`}>
                                <input
                                    id="dropzone-file"
                                    name="profilePhoto"
                                    disabled={profileImagUrl !== ''}
                                    accept="image/png, image/jpeg, image/jpg, image/webp"
                                    onBlur={profileForm.handleBlur}
                                    onChange={profileImageInputChangeHandler}
                                    type="file"
                                    className="hidden"/>
                                {profileImagUrl !== '' ? (
                                    <div className="relative">
                                        <div className="relative h-40 w-40 rounded-full overflow-hidden">
                                            <img id="blah" src={profileImagUrl}
                                                 className="shadow w-full object-fill h-full rounded-full"
                                                 alt="your"/>
                                        </div>
                                        <CloseIcon onClick={profileImageReset}
                                                   className="absolute top-2 right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"/>
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                        <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400"
                                             fill="none"
                                             stroke="currentColor" viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Profile Photo</p>
                                    </div>
                                )}
                            </label>
                        </div>
                    </div> */}
                </form>
            </Card>
        </>
    );
};

export default Userprofile;

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

async function addLocation(payload) {
    try {
        const {data: response} = await httpservice.post('locations/create', payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function getLocations(country,state) {
    try {
        const {data: response} = await httpservice.get(`locations/new?country=${country || ""}&state=${state || ""}`);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
async function getAllLocations(country,state) {
    try {
        const {data: response} = await httpservice.get(`locations`);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function getLocationById(id) {
    try {
        const {data: response} = await httpservice.get('locations/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}


async function deleteLocation(id){
    try {
        const {data : location} = await httpservice.delete('locations/delete/' + id)
        return location
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
async function updateLocation(id,payload){
    try {
        const url = "locations/edit/"
        const {data : location} = await httpservice.put(url +id, payload)
        return location
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export default {
    addLocation,
    getLocations,
    getLocationById,
    deleteLocation,
    updateLocation,
    getAllLocations
}

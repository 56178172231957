import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

export async function doctors(max) {
    const url = max ? ('dashboard/doctors?max=' + max) : 'dashboard/doctors';
    try {
        const {data: doctors} = await httpservice.get(url);
        return doctors
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function GetAllDoctorsOfParticularHospital (id) {
    const url =('dashboard/doctors/hospital/' + id) 

    try{
        const {data: doctors} = await httpservice.get(url);
        return doctors
    }catch(ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function scheduleDoctors(max) {
    const url = max ? ('dashboard/doctors?max=' + max) : 'dashboard/doctors';
    try {
        const {data: doctors} = await httpservice.get(url);
        return doctors
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function doctorbyBranch(payload){
   
    if(payload){

        const url = "dashboard/doctors/branch/"
        try {
            const {data : doctors} = await httpservice.get(url + payload)
            return doctors
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data)
            }
        }
    }else{
        return
    }
}


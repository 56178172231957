import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Usercontentcontainer from "./layout/user/Usercontentcontainer";
import Dashboard from "./layout/Dashboard/pages/Dashboard";
import React, {useState} from "react";
import LoginGuard from "./core/Guards/LoginGuard/LoginGuard";
import {toast, ToastContainer} from "react-toastify";
import {logout} from "./core/services/User/Login/login";
import jwtDecode from "jwt-decode";
import NonLoggedGuard from "./core/Guards/NonLoggedGuard/NonLoggedGuard";
import Pageloader from "./shared/components/Pageloader/Pageloader";

export const UserContext = React.createContext({});

function App() {
    const [userDetails, setUserDetails] = useState(localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : {});
    const [showLoader, setShowLoader] = useState(false);

    
    const handleLogin = (data) => {
        console.log('data in app.js', data);
        setUserDetails(jwtDecode(localStorage.getItem('token')));
    }

    const handleLogout = () => {
        logout();
        toast.success('Successfully logged out!');
        setUserDetails({});
    }
    return (
        <UserContext.Provider value={{
            userDetails,
            setShowLoader
        }}>
            <ToastContainer/>
            <div className={showLoader ? '' : 'hidden'}>
                <Pageloader/>
            </div>
            <div className={showLoader ? 'hidden' : ''}>
                <Routes>
                    <Route element={<NonLoggedGuard/>}>
                        <Route path="login/*" element={<Usercontentcontainer handleLoginOfUser={handleLogin}/>}/>
                    </Route>
                    <Route element={<LoginGuard/>}>
                        <Route path="dashboard/*" element={<Dashboard handleLogoutOfUser={handleLogout}/>}/>
                    </Route>
                    <Route path="" element={<Navigate to="/login"/>}/>
                    <Route path="*" element={<Navigate to="/login"/>}/>
                </Routes>
            </div>
        </UserContext.Provider>
    );
}

export default App;

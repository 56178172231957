import React, { useState, useContext } from "react";
import Card from "../../../shared/components/Card/Card";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel } from "@mui/material";
import Filledbluebutton from "../../../shared/components/Filledbluebutton/Filledbluebutton";
import { MultiSelect } from "react-multi-select-component";
import { createpushNotification } from "../../../core/services/Data/pushNotificationService";
import { UserContext } from "../../../App";
import * as yup from "yup";
import { toast } from "react-toastify";

const Pushnotifications = () => {
  const { setShowLoader } = useContext(UserContext);
  const [role, setRole] = useState([]);
  const validation = yup.object({
    notificationTitle: yup
      .string("")
      .required("Notification Title is required"),
    notificationMessage: yup
      .string("")
      .required("Notification Description is required"),
  });
  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
  ];
  const [selected, setSelected] = useState([]);
  const pushNotificationsForm = useFormik({
    initialValues: {
      notificationTitle: "",
      notificationMessage: "",
      addUrl: "",
      toRoles: role ? role : null,
      customUser: "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("VAlues", values);
      // setShowLoader(true)
      const payload = {
        ...values,
        title: values.notificationTitle,
        message: values.notificationMessage,
        link: values.addUrl,
        preference: values.toRoles,
      };
      createpushNotification(payload).then((res) => {
        toast.success(res.message);
        setShowLoader(false);
      });
    },
  });
  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="order-first">
          <h1 className="text-3xl font-semibold">Push Notifications</h1>
        </div>
      </div>
      <Card>
        <div className="flex justify-between">
          <div className="order-first">
            <h1 className="text-2xl font-semibold">Send Push Notifications</h1>
          </div>
        </div>
        <form onSubmit={pushNotificationsForm.handleSubmit}>
          <div className="grid grid-cols-4 md:grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 my-5">
            <div className="col-span-4">
              <TextField
                className="w-full"
                variant="outlined"
                name="notificationTitle"
                id="notificationTitle"
                type="text"
                label="Notification Title"
                value={pushNotificationsForm.values.notificationTitle}
                onChange={pushNotificationsForm.handleChange}
                onBlur={pushNotificationsForm.handleBlur}
                helperText={
                  pushNotificationsForm.touched.notificationTitle &&
                  Boolean(pushNotificationsForm.errors.notificationTitle)
                    ? "Notification title is required"
                    : ""
                }
                error={
                  pushNotificationsForm.touched.notificationTitle &&
                  Boolean(pushNotificationsForm.errors.notificationTitle)
                }
              />
            </div>
            <div className="col-span-8">
              <TextField
                fullWidth
                id="notificationMessage"
                label="Notification Description"
                name="notificationMessage"
                value={pushNotificationsForm.values.notificationMessage}
                onChange={pushNotificationsForm.handleChange}
                onBlur={pushNotificationsForm.handleBlur}
                helperText={
                  pushNotificationsForm.touched.notificationMessage &&
                  Boolean(pushNotificationsForm.errors.notificationMessage)
                    ? "Notification Description is required"
                    : ""
                }
                error={
                  pushNotificationsForm.touched.notificationMessage &&
                  Boolean(pushNotificationsForm.errors.notificationMessage)
                }
                multiline
                rows={1}
              />
            </div>
            <div className="col-span-4">
              <TextField
                className="w-full"
                variant="outlined"
                name="addUrl"
                id="addUrl"
                type="text"
                label="Add URL"
                value={pushNotificationsForm.values.addUrl}
                onChange={pushNotificationsForm.handleChange}
                onBlur={pushNotificationsForm.handleBlur}
                helperText={
                  pushNotificationsForm.touched.addUrl &&
                  Boolean(pushNotificationsForm.errors.addUrl)
                    ? "Notification URL is required"
                    : ""
                }
                error={
                  pushNotificationsForm.touched.addUrl &&
                  Boolean(pushNotificationsForm.errors.addUrl)
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 my-5">
            <div className="col-span-12">
              <h1 className="text-xl font-semibold">
                Notification Preferences
              </h1>
            </div>
            <div className="col-span-12">
              <div className="md:grid grid-cols-12 gap-2">
                <div className="col-span-2">
                  <h1 className="text-sm md:text-xl font-semibold">
                    To Roles :
                  </h1>
                </div>
                <div
                  className="col-span-10 md:flex flex-wrap justify-between"
                  onChange={(e) => {
                    const { value: input } = e.target;
                    const roleArray = [...role];
                    // const childNodes = e.currentTarget.childNodes;
                    // childNodes.forEach(element => {
                    //     const subChildNodes = element.childNodes;
                    //     subChildNodes.forEach(element=>{
                    //         const input = element.children;
                    //         // console.log(input[0].checked=false)
                    //     })
                    // });
                    if (roleArray.includes(input)) {
                      if (input === "All") {
                      }
                      roleArray.splice(roleArray.indexOf(input), 1);
                    } else {
                      if (roleArray.includes("All")) {
                      } else if (e.target.checked) {
                        roleArray.push(input);
                      }
                    }
                    console.log(roleArray);
                    setRole([...roleArray]);
                  }}
                >
                  <FormControlLabel
                    value="All"
                    control={<Checkbox />}
                    label="All"
                  />
                  <FormControlLabel
                    value="Patient"
                    control={<Checkbox />}
                    label="Patient"
                  />
                  <FormControlLabel
                    value="Doctor"
                    control={<Checkbox />}
                    label="Doctor"
                  />
                  <FormControlLabel
                    value="Laboratory"
                    control={<Checkbox />}
                    label="Lab"
                  />
                  <FormControlLabel
                    value="Pharmacy"
                    control={<Checkbox />}
                    label="Pharmacy"
                  />
                  <FormControlLabel
                    value="Hospital"
                    control={<Checkbox />}
                    label="hospital"
                  />
                  <FormControlLabel
                    value="Receptionist"
                    control={<Checkbox />}
                    label="Receptionist"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="md:grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 my-5">
                    <div className="col-span-2">
                        <h1 className="text-xl font-semibold">Custom : </h1>
                    </div>
                    <div className="col-span-4">
                        <MultiSelect
                            options={options}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Select"
                        />
                    </div>
                </div> */}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 my-5">
            <div className="col-span-3">
              <Filledbluebutton cname="w-32" btnType="submit">
                Send
              </Filledbluebutton>
            </div>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Pushnotifications;

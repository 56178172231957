import React, {useEffect} from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {toast} from "react-toastify";

const HospitalGuard = ({currentUserRole, compareToRole}) => {
    let allow = compareToRole.filter((ele) => ele === currentUserRole).length !== 0;
    useEffect(() => {
        if (!allow) {
            toast.error('Unauthorized access', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [allow]);


    return (
        allow ? <Outlet/> : <Navigate to='/dashboard/home'/>
    )
};

export default HospitalGuard;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PinDropIcon from '@mui/icons-material/PinDrop';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ScheduleIcon from '@mui/icons-material/Schedule';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonIcon from '@mui/icons-material/Person';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import ScienceIcon from '@mui/icons-material/Science';
import NearMeIcon from '@mui/icons-material/NearMe';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import BookIcon from '@mui/icons-material/Book';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MapIcon from '@mui/icons-material/Map';
import SecurityIcon from '@mui/icons-material/Security';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { UserContext } from "../../../App";

function Sidebar(props) {
    const location = useLocation();
    const { pathname } = location;
    const pathnameArray = pathname.split('/');
    const { userDetails } = useContext(UserContext);
    console.log('userDetails', userDetails);
    const [showSidebar, setShowSidebar] = useState(true);
    const trigger = useRef(null);
    const sidebar = useRef(null);
    const [refresh, setRefresh] = useState(false);
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(true);

    // close on click outside
    // useEffect(() => {
    //     const clickHandler = ({ target }) => {
    //         if (!sidebar.current || !trigger.current) return;
    //         if (!props.sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
    //         props.setSidebarOpen(false);
    //     };
    //     document.addEventListener('click', clickHandler);
    //     return () => document.removeEventListener('click', clickHandler);
    // });

    // // close if the esc key is pressed
    // useEffect(() => {
    //     const keyHandler = ({ keyCode }) => {
    //         if (!props.sidebarOpen || keyCode !== 27) return;
    //         props.setSidebarOpen(false);
    //     };
    //     document.addEventListener('keydown', keyHandler);
    //     return () => document.removeEventListener('keydown', keyHandler);
    // });

    // useEffect(() => {
    //     localStorage.setItem('sidebar-expanded', sidebarExpanded);
        
    //     if (sidebarExpanded) {
    //         document.querySelector('body').classList.add('sidebar-expanded');
    //     } else {
    //         document.querySelector('body').classList.remove('sidebar-expanded');
    //     }
    // }, [sidebarExpanded]);
    // const params = useLocation();
    // useEffect(() => {
    //     if (params.pathname.includes("/dashboard/schedules/doctor/")) {
    //         console.log("WORKING", params)
    //         setShowSidebar(false)
    //         setSidebarExpanded(false)
    //     }
    // }, [params.pathname])
    return (
    <div>
        {/* Sidebar backdrop (mobile only) */}
        <div
            className={`fixed inset-0' bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity
             duration-200 opacity-100 `}
            aria-hidden="true"
        ></div>

        {/* Sidebar */}

        <div
            id="sidebar"
            ref={sidebar}
            className={`flex flex-col absolute z-40 left-0 top-0 lg:static  lg:left-auto 
             lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto  scrollbar-thin scrollbar-thumb-sky-200 scrollbar-track-sky-100 w-64 
              2xl:!w-64 shrink-0 bg-white shadow p-4 transition-all duration-200 ease-in-out ${props.sidebarOpen ? '!translate-x-0 ' : '!-translate-x-64 lg:!absolute'}`}
        >
            {/* Sidebar header back arrow icon and logo */}
            <div className="flex justify-start mb-10 pr-3 sm:px-2">
                {/* Close button */}
                <button
                    ref={trigger}
                    className=" text-slate-500 hover:text-slate-400"
                    onClick={props.setSidebarOpen}
                    aria-controls="sidebar"
                    aria-expanded={props.sidebarOpen}
                >
                    <ArrowBackIcon />
                </button>
                {/* Logo */}
               <div className='w-full flex justify-center items-center'>
               <NavLink end to="/" className="block">
                    {/* <img src="https://i.postimg.cc/y6p5vXtQ/logo-Hospital.png" style={{ height: '70px', width: "auto" }}
                        alt="" /> */}
                        <img src="/logo192.jpg" style={{ height: '70px', width: "auto" }} className="rounded-lg"
                        alt="" />
                </NavLink>
               </div>
            </div>

            {/* Links */}
            <div className="space-y-8">
                {/* Pages group */}
                <div>
                    <ul className="mt-3">
                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('home') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/home"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <DashboardIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Dashboard
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('requests') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}
                         ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}`}>
                            <NavLink end to="/dashboard/requests"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <PinDropIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Requests
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('hospitals') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/hospitals"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <DomainAddIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Hospitals
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' ? 'hidden' : ''}
                        ${pathnameArray.includes('branches') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/branches"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <AccountTreeIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Branches
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0
                         ${pathnameArray.includes('doctors') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/doctors"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <GroupAddIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Doctors
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('schedules') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/schedules"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <ScheduleIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Schedule
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('receptionist') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/receptionist"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <PersonIcon className="-ml-0.5" />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Receptionist
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('Symptoms') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/Symptoms"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <AssignmentIndIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Symptoms
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('speciality') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/speciality"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <FolderSpecialIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Speciality
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                            ${userDetails.role === 'Hospital' && userDetails.isMainUser ? '' : 'hidden'}
                        ${pathnameArray.includes('addHospitalUser') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/addHospitalUser"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <PersonAddAltOutlinedIcon className="-ml-0.5" />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Hospital User
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <SidebarLinkGroup activecondition={pathnameArray.includes('pharmacy')}>
                            {(handleClick, open) => {
                                return (<React.Fragment>
                                    <a
                                        href="#0"
                                        className={`py-2 ${pathnameArray.includes('pharmacy') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'} block truncate transition duration-150`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                        }}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <LocalPharmacyIcon />
                                                <span
                                                    className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                    Pharmacy
                                                </span>
                                            </div>
                                            {/* Icon */}
                                            <div className="flex shrink-0 ml-2">
                                                <svg
                                                    className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && 'rotate-180'}`}
                                                    viewBox="0 0 12 12">
                                                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                        <ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
                                            <li className="mb-1 last:mb-0">
                                                <NavLink end to="/dashboard/pharmacy/in-house"
                                                    className="block text-slate-400 hover:text-black transition duration-150 truncate">
                                                    <span
                                                        className="text-sm font-medium  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                        In-house
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li className={`mb-1 last:mb-0 ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}`}>
                                                <NavLink end to="/dashboard/pharmacy/out-house"
                                                    className="block text-slate-400 hover:text-black transition duration-150 truncate">
                                                    <span
                                                        className="text-sm font-medium  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                        Out-house
                                                    </span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </React.Fragment>);
                            }}
                        </SidebarLinkGroup>

                        <SidebarLinkGroup activecondition={pathnameArray.includes('laboratory')}>
                            {(handleClick, open) => {
                                return (<React.Fragment>
                                    <a
                                        href="#0"
                                        className={`py-2 ${pathnameArray.includes('laboratory') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'} block truncate transition duration-150`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                        }}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <ScienceIcon />
                                                <span
                                                    className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                    Laboratory
                                                </span>
                                            </div>
                                            {/* Icon */}
                                            <div className="flex shrink-0 ml-2">
                                                <svg
                                                    className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && 'rotate-180'}`}
                                                    viewBox="0 0 12 12">
                                                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                        <ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
                                            <li className="mb-1 last:mb-0">
                                                <NavLink end to="/dashboard/laboratory/in-house"
                                                    className="block text-slate-400 hover:text-black transition duration-150 truncate">
                                                    <span
                                                        className="text-sm font-medium  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                        In-house
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li className={`mb-1 last:mb-0 ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}`}>
                                                <NavLink end to="/dashboard/laboratory/out-house"
                                                    className="block text-slate-400 hover:text-black transition duration-150 truncate">
                                                    <span
                                                        className="text-sm font-medium  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                        Out-house
                                                    </span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </React.Fragment>);
                            }}
                        </SidebarLinkGroup>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('locations') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/locations"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <MapIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Locations
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('medicines') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/medicines"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <PersonIcon className="-ml-0.5" />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Medicines
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('promotions') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/promotions"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <NearMeIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Promotions
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('coupons') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/coupons"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <CardGiftcardIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Coupons
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' ? 'hidden' : ''}
                        ${pathnameArray.includes('leaves') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/leaves"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <HolidayVillageIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leaves
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}

                        ${pathnameArray.includes('blog') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/blog"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <BookIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Blogs
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('insurance') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/insurance"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <SecurityIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Insurance
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('language') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/language"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <GTranslateIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Language
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('subscription') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/subscription"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <SubscriptionsIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Subscription
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('sponsors') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/sponsors"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <AddToPhotosIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Sponsors
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 
                        ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                        ${pathnameArray.includes('holidays') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/holidays"
                                className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <EventBusyIcon />
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Holidays
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>

                        {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0
                         ${userDetails.role === 'Branch' || userDetails.role === 'Hospital' ? 'hidden' : ''}
                         ${pathnameArray.includes('push-notifications') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>
                            <NavLink end to="/dashboard/push-notifications"
                                     className={`block hover:text-sky-600 truncate transition duration-150`}>
                                <div className="flex items-center justify-between">
                                    <div className="grow flex items-center">
                                        <NotificationsIcon/>
                                        <span
                                            className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                 Push Notifications
                                            </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li> */}

                        {/*<li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathnameArray.includes('settings') ? 'text-sky-600 bg-sky-50' : 'text-gray-600 hover:bg-sky-50'}`}>*/}
                        {/*    <NavLink end to="/dashboard/settings"*/}
                        {/*             className={`block hover:text-sky-600 truncate transition duration-150`}>*/}
                        {/*        <div className="flex items-center justify-between">*/}
                        {/*            <div className="grow flex items-center">*/}
                        {/*                <SettingsIcon/>*/}
                        {/*                <span*/}
                        {/*                    className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">*/}
                        {/*                         Settings*/}
                        {/*                    </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>

            {/* Expand / collapse button */}
            <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                <div className="px-3 py-2">
                    <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                        <span className="sr-only">Expand / collapse sidebar</span>
                        <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                            <path className="text-slate-400"
                                d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                            <path className="text-slate-600" d="M3 23H1V1h2z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>);
}

export default Sidebar;

import React, { useEffect, useContext, useRef, useState } from "react";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import { useNavigate } from "react-router-dom";
import { pharmacyDetailsTableData } from "../../../../core/constants/appconstants";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import { AgGridReact } from "ag-grid-react";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import { UserContext } from "../../../../App";
import laboratoryService from "../../../../core/services/Data/laboratoryService";
import { toast } from "react-toastify";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";

const Laboratoryinhouse = () => {
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const { setShowLoader } = useContext(UserContext);
  const [LaboratoryTableData, setLaboratoryTableData] = useState();
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const {userDetails} = useContext(UserContext);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getLaboratoryData = () => {
    setShowLoader(true);
    laboratoryService.getAllLaboratory().then((res) => {
      if (res) {
        setLaboratoryTableData(res.data);
      }
      setShowLoader(false);
    });
  };
  useEffect(() => {
    getLaboratoryData();
  }, []);

  const [columnDefs] = useState([
    { headerName: "Image", field: "picture", cellRenderer: Imagerenderer },
    { headerName: "Lab Assistant", field: "name" },
    { headerName: "Email", field: "email" },
    // { headerName: "Password", field: "password" },
    { headerName: "Hospital", field: "hospital.hospitalName" },
    { headerName: "Branch", field: "branch.branchName" },
    {
      headerName: "Action",
      cellRenderer: Editdeletebutton,
      sortable: false,
      filter: false,
      cellRendererParams: {
        data: gridRef && gridRef,
        editClicked: (id) => {
          navigate("/dashboard/laboratory/in-house/add-form/" + id);
          console.log("id", id);
        },
        deleteClicked: (id) => {
          handleOpen();
          setIdToDelete((prevState) => {
            return id;
          });
          // console.log('id', id)
        },
      },
    },
  ]);
  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };

  const deleteInLab = () => {
    laboratoryService.deleteLaboratory(idToDelete).then((res) => {
      if (res) {
        toast.success(res.message);
        getLaboratoryData();
      }
    });
  };
  const pharmacyTableDate = pharmacyDetailsTableData;
  return (
    <>
      <div className="flex justify-end mb-3">
        <div className="order-last">
          {/* <Filterbutton text="Filter by branch"/>
                    <Filterbutton text="Filter by lab assistant" cname="mx-4"/> */}
        </div>
      </div>
      <div className="mt-10">
        <Card>
          <Deletepopup
            handleCloseModel={handleClose}
            isOpen={open}
            id={idToDelete}
            handleDelete={deleteInLab}
          />
          <div className="flex justify-between mb-3">
            <div className="order-first">
              <p className="text-2xl font-semibold">Laboratory Inhouse</p>
            </div>

            <div className="order-last">
              {userDetails.role === "SuperAdmin" && <Filledbluebutton
                cname="mx-4"
                children="Add Lab Test"
                clicked={() => {
                  navigate("/dashboard/laboratory/in-house/test/add");
                }}
              />}
              <Outlinedbluebutton />
              <Filledbluebutton
                cname="ml-4"
                clicked={() => {
                  navigate("/dashboard/laboratory/in-house/add-form");
                }}
              />
            </div>
          </div>
          <div className="mt-5">
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={LaboratoryTableData}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowSelection="multiple"
              ></AgGridReact>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Laboratoryinhouse;

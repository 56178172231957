import React, {useContext, useEffect, useRef, useState, useCallback} from 'react';
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {useNavigate} from "react-router-dom";
import {doctors} from "../../../../core/services/Data/doctorsService";
import {UserContext} from "../../../../App";
import {allHospitalAdmin, getAllHospitalAdmin,deleteallHospitalAdmin} from "../../../../core/services/Data/hospitalService";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {AgGridReact} from "ag-grid-react";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from 'react-toastify'

const Mapuserhospitalhome = () => {
    const {setShowLoader} = useContext(UserContext);
    const gridRef = useRef(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const [hospitalAdminArray, setHospitalAdminArray] = useState([]);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [columnDefs] = useState([
        // {headerName: "Image", field: "picture", cellRenderer: Imagerenderer},
        {headerName: "Name", field: "name"},
      
       {headerName : "Email", field : "email"},
        {
            headerName: 'Action', cellRenderer: Deletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                // editClicked: (id) => {
                //     navigate('/dashboard/addHospitalUser/registration/' + id);
                //     console.log('id', id)
                // },
                deleteClicked: (id) => {
                    handleOpen()
                    // console.log('first', id)
                    setIdToDelete((prevState) => {
                        return id
                    })
                }
            }
        }
    ]);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);
    const getAllHospitalAdmin = async () => {
        setShowLoader(true)
        const res = await allHospitalAdmin()
        console.log('res for hospital adminn aarray', res);
        setHospitalAdminArray(res.data || []);
        setShowLoader(false)
    
    }
    const deleteuserAdmin = ()=>{
        deleteallHospitalAdmin(idToDelete).then((res)=>{
            if(res){
                toast.success(res.message)
                getAllHospitalAdmin()
                // const userIndex = hospitalAdminArray.findIndex(ele => ele._id.toString() === idToDelete);
                // if(userIndex){
                //     const data = [...hospitalAdminArray];
                //     data.splice(userIndex,1);
                //     setHospitalAdminArray([])
                // }
            }
        })
        console.log("id",idToDelete);
    }

    useEffect(() => {
        getAllHospitalAdmin();
    }, [])

    return (
        <div className="mt-10">
            <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteuserAdmin}/>

                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            Hospital Admin list
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport} />
                        <Filledbluebutton cname="ml-4" clicked={() => {
                            navigate('/dashboard/addHospitalUser/registration')
                        }}/>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={hospitalAdminArray || []}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            rowSelection="multiple"
                            >
                        </AgGridReact>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default Mapuserhospitalhome;

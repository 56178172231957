import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";


export async function getSponsors (){
    try {
        const url = "sponsors"
        const {data : sponsors} = await httpservice.get(url)
        return sponsors
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}


export async function createSponsors(payload){
    try {
        const url = "sponsors/create"
        const {data : sponsors}  = await httpservice.post(url,payload)
        return sponsors
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function deleteSponsors(id){
    try {
        const url = "sponsors/delete/"
        const {data : response} = await httpservice.delete(url+id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function getSingleSponsors(id){
    try {
        const url = "sponsors/"
        const {data : response} = await httpservice.get(url+id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function updateSponsors(id,payload){
    try {
        const url = "sponsors/edit/"
        const {data : response} = await httpservice.put(url+id,payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

async function requests(max) {
    const url = max ? ('dashboard/requests?max=' + max) : 'dashboard/requests';
    try {
        const {data: requests} = await httpservice.get(url);
        return requests
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function singleRequest(id) {
    try {
        const {data: singleRequestData} = await httpservice.get(`dashboard/requests/${id}`);
        return singleRequestData
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function respondRequest(id, status) {
    try {
        const {data: singleRequestData} = await httpservice.patch(`dashboard/requests/${id}?status=${status}`);
        return singleRequestData
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export default {
    requests,
    singleRequest,
    respondRequest
}

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function resetPassword(id, password, confirmPassword) {
    try {
        const {data: response} = await httpservice.put("login/password-reset/" + id, {
            password,
            confirmPassword
        });
        return response;
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

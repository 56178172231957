import React from 'react';
import {ThreeDots} from "react-loader-spinner";

const Pageloader = ({isLoading}) => {
    return (
        <div className="-px-4 sm:-px-6 lg:-px-8 -py-8 bg-white"
             style={{width: '100vw', position: 'fixed', left: '0px', top: '0px', zIndex: '100'}}>
            <div className="flex justify-center flex-col items-center h-screen">
                <img src="/logo192.jpg" style={{height: '120px', width: "auto"}} className="rounded-lg"
                     alt=""/>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#42a5f5"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        </div>
    );
};

export default Pageloader;

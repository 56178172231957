import * as yup from "yup";
import {phoneNumberRegex} from "../constants/appconstants";

export const validationSchemaSchedule = yup.object({
  hospital: yup.string("Enter your email").required("Email is required"),
  doctor: yup.string("Enter your email").required("Email is required"),
  branch: yup.string("Enter your password").required("Password is required"),
  startDate: yup.string("Enter your Start Date").required("Start Date is required"),
  endDate: yup
    .string("Enter your End Date")
    .required("Expiry Date is required"),
});

export const validationCreateSchemaSchedule = yup.object({

  startDate: yup.string("Enter your Start Date").required("Start Date is required"),
  endDate: yup
    .string("Enter your End Date")
    .required("Expiry Date is required"),
startTime: yup.string("Enter your Start Time").required("Start Time is required"),
endTime: yup
    .string("Enter your End Time")
    .required("Expiry Time is required"),
});



export const loginValidationSchema = yup.object({
  email: yup.string("Email is required").required("Email is required"),
  password: yup.string("password is required").required("password is required")
    .regex,
});

export const mapHospitalFormSchema = yup.object({
  name: yup.string("Name is required").required("Name is required"),
  email: yup.string("Email is required").required("Email is required"),
});

export const profileFormValidationSchema = yup.object({
  firstName: yup
    .string("First Name is required")
    .required("First Name is required"),
    lastName:yup.string(),
  email: yup.string("Email is required").required("Email is required"),
  phoneNo: yup.string('Enter your Email').required('Phone number is required').matches(phoneNumberRegex, 'Please enter a valid phone number'),

});

import React, { useRef, useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Card from "../../../../../shared/components/Card/Card";
import { Button, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../../../../App";
import { laboratoryRegistrationValidation } from "../../../../../core/Validationschemas/laboratoryValidation";
import laboratoryService from "../../../../../core/services/Data/laboratoryService";
import uploadFileService from "../../../../../core/services/Data/uploadFileService";
import commonAPICallService from "../../../../../core/services/Data/commonAPICallService";
import { toast } from "react-toastify";
import { LabTestValidationForm } from "../../../../../core/Validationschemas/labTestValidation";

const LabTestForm = () => {
  const navigate = useNavigate();
  //   const imageInputref = useRef();
  //   const [imageUrl, setImageUrl] = useState("");
  //   const [branchList, setbranchList] = useState(null);
  const { setShowLoader } = useContext(UserContext);
  const [formPrefillData, setFormPrefillData] = useState();
  //   const [hospitalsList, setHospitalsList] = useState(null);
  //   const params = useParams();
  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
    },
    validationSchema: LabTestValidationForm,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log("shivam",values)
      setShowLoader((prevState) => {
        return true;
      });
      console.log(values);
      try {
        await laboratoryService.createLabTest({ ...values });
        navigate("/dashboard/laboratory/in-house");
        toast.success("Successfully LabTest Created");
      } catch (ex) {
        console.log(ex);
        toast.error(ex.response.data.error);
      }
      setShowLoader(false);
    },
  });

  // console.log('input files', input.target.files);
  // setImageUrl((prevState) => {
  //     return 'https://i.postimg.cc/d1BM8pWg/map.png'
  // })

  return (
    <Card cname="max-w-2xl mx-auto">
      <h1 className="text-xl font-bold text-center">{"Add Lab Test"}</h1>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        <div className="col-span-12 mt-2">
          <TextField
            id="title"
            fullWidth
            helperText={
              formik.touched.title && Boolean(formik.errors.title)
                ? "Test name is required"
                : ""
            }
            value={formik.values.title}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            label="Test Name"
            name="title"
            variant="outlined"
          />
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            id="body"
            fullWidth
            helperText={
              formik.touched.body && Boolean(formik.errors.body)
                ? "Description is required"
                : ""
            }
            value={formik.values.body}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.body && Boolean(formik.errors.body)}
            label="body"
            name="body"
            variant="outlined"
          />
        </div>

        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </form>
    </Card>
  );
};
export default LabTestForm;

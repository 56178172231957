import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function coupons(max) {
    const url = max ? ('dashboard/coupons?max=' + max) : 'dashboard/coupons';
    try {
        const {data: couponsData} = await httpservice.get(url);
        return couponsData
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function getAllCoupons(max) {
    const url = max ? ('dashboard/coupons/admin?max=' + max) : 'dashboard/coupons/admin';
    try {
        const {data: couponsData} = await httpservice.get(url);
        return couponsData
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}



export async function getCouponsData(){
    const url = "dashboard/coupons"
    try {
        const {data : coupons} = await httpservice.get(url)
        return coupons
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
} 

export async function createCoupons(payload){
    const url = 'dashboard/coupons/create'
    try {
        const {data : coupons} = await httpservice.post(url, payload)
            return coupons
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function coupDelete(id){
    const url = "dashboard/coupons/delete/"
    try {
        const {data : response} = await httpservice.delete(url +id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function updateStatusCoupon(id, payload){
    const url = "dashboard/coupons/edit/"
    try {
        const {data : response} = await httpservice.put(url +id, payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}





import httpservice from "../Httpservice/httpservice,";
import {toast} from "react-toastify";

export async function getPermotions(){
    try {
        const {data: permotion} = await httpservice.get('/promotions');
        return permotion
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function createPermotions(payload){
    try {
        const {data : permotion} = await httpservice.post('/promotions/create', payload)
        return permotion
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function deleteData(id){
    try {
        const {data : permotion} = await httpservice.delete('/promotions/delete/' +id)
        return permotion
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function updatePermotions(id,payload){
    try {
        const {data : permotion} = await httpservice.put('/promotions/edit/' +id, payload)
        return permotion
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function SinglegetPromotions(id){
    try {
        const {data : response} = await httpservice.get('/promotions/' + id)
        return response;
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function updateAdvertiseng(id,paylaod){
    try {
        const url = "/advertise/edit/"
        const {data : response} = await httpservice.put(url +id, paylaod)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}


export async function getAdvertising(){
    try {
        const {data : response} = await httpservice.get('/advertise')
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function createAdvertise(payload){
    try {
      
        const {data : response} = await httpservice.post("/advertise/create", payload)
        return response
    } catch (ex) {
        // console.log(ex.message);
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function singlegetAdvertise(id){
    try {
      
        const {data : response} = await httpservice.get("/advertise/"+ id)
        return response
    } catch (ex) {
        // console.log(ex.message);
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function deleteAdvertisement(id){
        try {
            const {data : response} = await httpservice.delete("/advertise/delete/" + id)
            return response
        } catch (ex) { 
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data)
            }
        }
}
export async function getbannerTable(){
        try {
            const {data : response} = await httpservice.get("/banners")
            return response
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data)
            }
        }
}

export async function createBanner(payload){
    try {
      const {data : response} = await httpservice.post("/banners/create", payload)  
      return response;
     
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function SinglegetBanner(id){
    try {
        const {data : response} = await httpservice.get("/banners/" + id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
} 

export async function UpdateBanner(id,payload){
    try {
        
        const {data : response} = await httpservice.put("/banners/edit/" + id, payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}


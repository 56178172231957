import React, {useContext, useEffect, useState} from 'react';
import Card from "../../../shared/components/Card/Card";
import Rightbutton from "../../../shared/components/Rightbutton/Rightbutton";
import Leftbutton from "../../../shared/components/Leftbutton/Leftbutton";
import {useNavigate} from "react-router-dom";
import requestService, {requests} from "../../../core/services/Data/requestService";
import {UserContext} from "../../../App";

const Requests = () => {
    const navigate = useNavigate();
    const [receivedRequests, setReceivedRequests] = useState(null);
    const {setShowLoader} = useContext(UserContext);
    const getRequests = () => {
        setShowLoader(true);
        requestService.requests(false).then(async (res) => {
            await setReceivedRequests(res.data);
            setShowLoader(false);
        })
    };

    const handleRespondToRequest = (id, status) => {
        requestService.respondRequest(id, status).then((res) => {
            if (res) {
                console.log('response', res);
                getRequests();
            }
        })
    }


    useEffect(() => {
        getRequests();
    }, []);
    return (
        <>

            <div className="flex justify-end mb-3">
                {/* <button type="button"
                        className="inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by entities
                </button> */}
            </div>

            {/* <div className="flex justify-end mb-3">
                <button type="button"
                        className="inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by entities
                </button>
            </div> */}

            <Card>
                <div className="flex flex-col justify-start mb-4">
                    <h1 className="text-2xl font-bold">Requests Panel</h1>
                    <p className="text-sm text-gray-500">You can approve/deny requests of hospitals, pharmacies &
                        more.</p>
                </div>
                {receivedRequests && receivedRequests.map((request) => {
                    return (
                        <>
                            <div className="grid grid-cols-12 gap-6 my-4"
                                 key={request._id}>
                                <div className="col-span-12 lg:col-span-1 flex items-center cursor-pointer" onClick={() => {
                                    navigate(`/dashboard/requests/${request._id}`)
                                }}>
                                    <img src={request.requestedUser.picture}
                                         className="border border-sky-400 p-1 rounded-full h-16 w-16" alt=""/>
                                </div>
                                <div className="col-span-12 lg:col-span-7 flex flex-col my-auto cursor-pointer" onClick={() => {
                                    navigate(`/dashboard/requests/${request._id}`)
                                }}>
                                    <h3 className="text-sky-500 text-sm font-semibold">{request.requestedUser.name}</h3>
                                    <p className="text-xs text-gray-500">
                                        Approval for adding new {request.requestType}
                                    </p>
                                </div>
                                <div 
                                    className="col-span-12 my-auto lg:col-span-4 flex flex-col justify-center items-start lg:items-center">
                                    <div className="flex flex-row">
                                        {request.status === 'rejected' || request.status === 'approved' ? (
                                            <div
                                                className={`font-semibold rounded-md px-2 py-1 ${request.status === 'rejected' ? 'text-red-500 bg-red-200' : 'text-green-500 bg-green-200'}`}>
                                                {request.status.toUpperCase()}
                                            </div>
                                        ) : (
                                            <>
                                                <Rightbutton big={true} clicked={() => {
                                                    handleRespondToRequest(request._id, 'approved')
                                                }}/>
                                                <div className="ml-2">
                                                    <Leftbutton big={true} clicked={() => {
                                                        handleRespondToRequest(request._id, 'rejected')
                                                    }}/>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {request.status === 'rejected' && request.status === 'approved' && <div className="text-center">
                                        <span className="text-xs text-gray-500 font-semibold">
                                            Today, {request.requestedAt}
                                        </span>
                                    </div>}
                                </div>
                            </div>
                            <hr/>
                        </>
                    )
                })}
            </Card>
        </>
    );
};

export default Requests;

import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../App";
import { blogDetailsTableData } from "../../../../core/constants/appconstants";
import Card from "../../../../shared/components/Card/Card";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {
  getBlogById,
  deleteBlog,
  updateBlog,
} from "../../../../core/services/Data/blogService";
import { toast } from "react-toastify";
import { Button, Modal, TextField } from "@mui/material";
import { ClosedCaption } from "@mui/icons-material";

const Blogview = () => {
  const { id } = useParams();
  const [editView, setEditView] = useState(false);
  const [singleData, setSingleData] = useState();
  const singleBlogData = {
    ...blogDetailsTableData.filter((ele) => ele.id === id)[0],
  };
  const navigate = useNavigate()

  const { setShowLoader } = useContext(UserContext);
  const getData = () => {
    setShowLoader(true);
    console.log(11);
    getBlogById(id).then((res) => {
      setShowLoader(false);
      setSingleData(res.data);
      // console.log("mvlgdlmlfl", res.data);
    });
  };
  const handleDelete = (id) => {
    console.log("id======>", id);
    deleteBlog(id).then((res) => {
        navigate("/dashboard/blog")
      toast.success("Blog Sent to Trash");
      
    });
  };
  const hanldeUpdate = (id) => {
    console.log("id edit =====>", id);
    setEditView(true);
  };
  const handleChange = (e) => {
    const { currentTarget: input } = e;
    const states = { ...singleData };
    states[input.name] = input.value;
    setSingleData({ ...states });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Card>
      <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10">
        <Modal
          open={editView}
          onClose={setEditView}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            className="absolute w-[50%] top-1/2 left-1/2"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <div className="p-10 bg-white flex flex-col gap-4 rounded-lg relative">
              {/* <ClosedCaption
                         className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold cursor-pointer"
                         onClick={()=>setEditView(false)}/> */}
              <h1 className="text-lg">Edit Blog</h1>
              <div className="w-full flex flex-col gap-4">
                  <TextField
                    name="title"
                    value={singleData ? singleData.title : ""}
                    label="Title"
                    onChange={handleChange}
                  ></TextField>
                   <TextField
                    name="blog"
                    value={singleData ? singleData.blog : ""}
                    label="Blog"
                    onChange={handleChange}
                  ></TextField>
              </div>
              <div className="flex justify-evenly w-full gap-8 text-white mt-6">
                <Button
                 className="w-full"
                 variant="outlined"
                 color="success"
                 onClick={async ()=>{
                    await updateBlog(singleData._id,{
                        title:singleData.title,
                        blog:singleData.blog
                    });
                    setEditView(false)

                 }}
                >
                    Save
                </Button>
                <Button
                variant="contained"
                color="error"
                className="w-full bg-red-400"
                onClick={()=>{
                    setEditView(false)
                }}
                >
                    Cancel
                </Button>
                </div>
            </div>
          </div>
        </Modal>
        <div className="col-span-12 md:col-span-6">
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10">
            <div className="col-span-12 sm:col-span-2 flex justify-start md:justify-end">
              <img src={singleData ? singleData.picture : null} alt="" />
            </div>
            <div className="col-span-12 sm:col-span-10 flex flex-col">
              <h1 className="text-xl font-semibold">
                {singleData ? singleData.title : null}
              </h1>
              <h3 className="text-sm">
                {singleData
                  ? singleData.author?.name?.title +
                    " " +
                    singleData.author?.name?.first +
                    " " +
                    singleData.author?.name?.last
                  : null}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 flex justify-start md:justify-end">
          <div className="flex flex-col text-gray-500">
            <h1 className="tracking-widest text-sm font-bold">
              {singleData ? singleBlogData.postDate : null}
            </h1>
            <h1 className="tracking-widest text-xs flex justify-start md:justify-end">
              {singleData ? singleData.comments : null}
              <span className="font-semibold">&nbsp;comments</span>
            </h1>
            <div className="flex flex-row justify-start md:justify-end mt-4">
              <div onClick={() => hanldeUpdate(singleData._id)}>
                {" "}
                <EditButton />{" "}
              </div>
              <div onClick={() => handleDelete(singleData._id)}>
                {" "}
                <Deletebutton cname="ml-3" />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 max-w-7xl mx-auto px-2 mt-4">
        <div className="col-span-12">
          {singleData ? singleData.blog : null} <br />
        </div>
      </div>
    </Card>
  );
};

export default Blogview;

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function createpushNotification(payload){
    const url = "/notifications"
    try {
        const {data : response} = await httpservice.post(url, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function getNotification(){
    const url = "/notifications"
    try {
        const {data : response} = await httpservice.get(url)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}

export async function UpdateNotification(id, paylaod){
    const url = "/notifications/read/"
    try {
        const {data : response} = await httpservice.patch(url + id, paylaod)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}
import React, {useRef,useState, useContext, useEffect, useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import {medicineDetailsTableData} from "../../../../core/constants/appconstants";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import {AgGridReact} from "ag-grid-react";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import mediceService from  "../../../../core/services/Data/mediceService";
import {UserContext} from "../../../../App";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from 'react-toastify'


const Medicinehome = () => {
    const navigate = useNavigate();
    const medicineTableData = medicineDetailsTableData;
    const [medicineTableDate, setMedicineTableDate] = useState(null);
    const gridRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const {setShowLoader} = useContext(UserContext);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getMedicine = ()=>{
            setShowLoader(true)
            mediceService.getAllMedicine().then((res) => {
                if (res) {
                    setMedicineTableDate(res.data);
                    console.log('res for medicine table data=====>', res);
                }
                setShowLoader(false);
            });   
    }
    useEffect(()=>{
        getMedicine()
    },[])

    const [columnDefs] = useState([
        {headerName: "Image", field: "picture", cellRenderer: Imagerenderer},
        {headerName: "Medicine Name", field: "name"},
        {headerName: "Company", field: "company"},
        // {headerName: "Email", field: "email"},
        // {headerName: "Branch", field: "branchName"},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/medicines/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    console.log('first', id)
                    setIdToDelete((prevState) => {
                        return id
                    })
                    // console.log('id', id)
                }
            }
        }
    ]);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }


    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const deleteMedicine = ()=>{
        mediceService.deleteMedicineDetails(idToDelete).then((res) => {
            if(res){
                toast.success(res.message);
                getMedicine()
            }
        })
        // console.log('working delete',idToDelete)
    }
    return (
        <Card>
                    <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteMedicine}/>

            <div className="flex justify-between mb-3">
                <div className="order-first">
                    <p className="text-2xl font-semibold">
                        Medicine list
                    </p>
                </div>
                <div className="order-last">
                    <Outlinedbluebutton download={onBtnExport} />
                    <Filledbluebutton cname="ml-4" clicked={() => {
                        navigate('/dashboard/medicines/add-form')
                    }}>
                        Add Medicine
                    </Filledbluebutton>
                </div>
            </div>
            <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={medicineTableDate}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
        </Card>
    );
};

export default Medicinehome;

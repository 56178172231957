import * as yup from "yup";
import {phoneNumberRegex} from "../constants/appconstants";

export const doctorRegistrationValidationSchema = yup.object({
    firstName: yup.string().required('First Name is required').trim(),
    lastName: yup.string().required('Last Name is required').trim(),
    email: yup.string('Enter your Email').required('Email is required').email('Please enter a valid email').trim(),
    hospitalName: yup.string().required('Hospital Name is required').trim(),
    branchName: yup.string().required('Hospital Name is required').trim(),
    educationDegree: yup.string().required('Education Degree is required').trim(),
    yearsExperience: yup.string().required('Years of experience is required').trim(),
    specialist: yup.array().min(1,"Atleast one is required"),
    symptoms:yup.array().required("Symptoms is required").min(1,"Symptom is Required"),
    price:yup.number().required("Price is required"),
    language: yup.array().min(1,"Ateast one is required"),
    insurance: yup.string().trim(),
    doctorCertificate: yup.string().required('Doctor Certificate is required').trim(),
    services: yup.array().min(1,"Atleaset one is required"),
    phoneNumber: yup.string('Enter your Email').required('Phone number is required').trim(),
    alternatePhoneNumber: yup.string('Enter your Email').trim().nullable(),
    address: yup.string('Enter your Email').required('Address is required').trim(),
    city: yup.string('Enter your Email').required('City is required').trim(),
    state: yup.string('Enter your Email').required('State is required').trim(),
    country: yup.string('Enter your Email').required('Country is required').trim(),
    startTime: yup.string('Enter your Email').required('Start Time is required').trim(),
    endTime: yup.string('Enter your Email').required('Expiry Time is required').trim(),
});

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {locationDetailsTableData} from "../../../../core/constants/appconstants";
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {AgGridReact} from "ag-grid-react";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import {hospitalService} from "../../../../core/services/Data/hospitalService";
import {UserContext} from "../../../../App";
import locations from "../Locations.";
import locationService from "../../../../core/services/Data/LocationService";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from "react-toastify";

const Locationhome = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const gridRef = useRef(null);
    const navigate = useNavigate();
  
    const {setShowLoader} = useContext(UserContext);
    const [locationDetailsData, setLocationDetailsData] = useState([]);
    const [idToDelete, setIdToDelete] = useState('');
    const [columnDefs] = useState([
        {headerName: "State", field: "state"},
        {headerName: "City", field: "city"},
        {headerName: "Country", field: "country"},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/locations/add-location/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen();
                    setIdToDelete(id)
                }
            }
        }
    ]);

    const getAllLocationsData = () => {
        setShowLoader(true);
        locationService.getAllLocations().then(async (res) => {
            await setLocationDetailsData((prevState) => {
                return res.data
            });
            setShowLoader(false)
        })
    }

    useEffect(() => {
        getAllLocationsData();
    }, []);



    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }
    const deleteLocation = ()=>{
        locationService.deleteLocation(idToDelete).then((res)=>{
            if(res){
              toast.success(res.message)  
              getAllLocationsData()
            }
        })
        // console.log("id", idToDelete);
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);


    return (
        <>
            <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteLocation}/>
                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            Location table
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport} />
                        <Filledbluebutton cname="mx-4" clicked={() => {
                            navigate('/dashboard/locations/add-location')
                        }}>
                            Add location
                        </Filledbluebutton>
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={locationDetailsData}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowSelection="multiple">
                    </AgGridReact>
                </div>
            </Card>
        </>
    );
};

export default Locationhome;

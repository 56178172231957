import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

async function getInsuranceData(){
    try {
        const url = "/insurances"
        const {data : insurance} = await httpservice.get(url)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function createInsurance(payload){
    try {
        const url = "/insurances/create"
        const {data : insurance} = await httpservice.post(url,payload)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function deleteInsurance(id){
    try {
        const url = "/insurances/delete/"
        const {data : insurance} = await httpservice.delete(url + id)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }  
    }
}
async function getSingleInsurance(id){
    try {
        const url = "/insurances/"
        const {data : insurance} = await httpservice.get(url +id)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}

async function updateInsurance(id,payload){
    try {
        const url = "/insurances/edit/"
        const {data : insurance} = await httpservice.put(url + id, payload)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}
async function createassignInsurance(payload){
    const url = "insurances/assign/doctor"
    try {
        const {data : insurance} = await httpservice.put(url, payload)
        return insurance
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}
async function getSingleAssigninsurance(id){
    try {
        const url = "insurances/assign/doctor/"
        const {data : response} = await httpservice.get(url+id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}
async function updateAssigninsurance(id,payload){
    try {
        const url = "insurances/assign/doctor/edit/";
        const {data : response} = await httpservice.put(url+id, payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }   
    }
}
async function unassignDelete(id){
    try {
        const url = "insurances/unassign/doctor/"
        const {data : response} = await httpservice.delete(url +id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }   
    }
}

export default {
    getInsuranceData,
    createInsurance,
    deleteInsurance,
    getSingleInsurance,
    updateInsurance,
    createassignInsurance,
    getSingleAssigninsurance,
    updateAssigninsurance,
    unassignDelete
}
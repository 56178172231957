import React from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const EditButton = (props) => {
    return (
        <ModeEditIcon
            className={`text-[#0098C4] border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`}/>
    );
};

export default EditButton;

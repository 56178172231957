import React, {useContext,useState,useEffect} from 'react';
import {useFormik} from "formik";
import Card from "../../../../shared/components/Card/Card";
import {useNavigate, useParams} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import * as yup from 'yup';
import {UserContext} from "../../../../App";
import {createSponsors,getSingleSponsors,updateSponsors}  from "../../../../core/services/Data/sponsorsService"
import {toast} from "react-toastify";


const SponsorAddForm = () => {
    const {setShowLoader} = useContext(UserContext);
    const navigate = useNavigate();
    const params = useParams()
    const [formPrefillData, setFormPrefillData] = useState();
    const validation = yup.object({
        name : yup.string("").required("Speciality is required"),
        entityName : yup.string("").required("Entity Name is required")

    })
    const assSpeciality = useFormik({
        initialValues: {
            name: formPrefillData ? formPrefillData.name :"",
            entityName : formPrefillData ? formPrefillData.entityName : ''
        },
        validationSchema : validation,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("Values", values);
            setShowLoader((prevState) => {
                return true
            });
            const payload = {
                ...values,
                // specialityName : values.Speciality
            }
            if(params.id){
                updateSponsors(params.id,payload).then((res)=>{
                    if(res){
                        toast.success(res.message)
                        navigate('/dashboard/sponsors')
                    }else{
                        setShowLoader(false)
                    }
                })
            }
            else{
                createSponsors(payload).then((res)=>{
                    toast.success(res.message)
                    navigate('/dashboard/sponsors');
                    setShowLoader(false)
                })
            }
           
        }
    });
    useEffect(()=>{
        if(params.id){
            setShowLoader(true)
            getSingleSponsors(params.id).then((res)=>{
                setFormPrefillData(res.data);
                setShowLoader(false)
            })
        }
    },[])
    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">{params.id ? "Update" : "Add"} Sponsor</h1>
            <form onSubmit={assSpeciality.handleSubmit}>
                <TextField
                    fullWidth
                    id="name"
                    label="Sponsor"
                    name="name"
                    // select
                    value={assSpeciality.values.name}
                    onChange={assSpeciality.handleChange}
                    onBlur={assSpeciality.handleBlur}
                    helperText={assSpeciality.touched.name && Boolean(assSpeciality.errors.name) ? 'Sponsor name is required' : ''}
                    error={assSpeciality.touched.name && Boolean(assSpeciality.errors.name)}>
                    {/* <MenuItem value="Health Insurance">Health Insurance</MenuItem>
                    <MenuItem value="Cipla">Cipla</MenuItem> */}
                </TextField>
                <div className="my-5">
                <TextField
                    fullWidth
                    id="hospitalInput"
                    label="Entity Name"
                    name="entityName"
                    // select
                    value={assSpeciality.values.entityName}
                    onChange={assSpeciality.handleChange}
                    onBlur={assSpeciality.handleBlur}
                    helperText={assSpeciality.touched.entityName && Boolean(assSpeciality.errors.entityName) ? 'Entity Name is required' : ''}
                    error={assSpeciality.touched.entityName && Boolean(assSpeciality.errors.entityName)}>
                    {/* <MenuItem value="Health Insurance">Health Insurance</MenuItem>
                    <MenuItem value="Cipla">Cipla</MenuItem> */}
                </TextField>
                </div>
                <div className="my-5">
                    <Filledbluebutton cname="w-full" btnType="submit">{params.id ?  "Update": 'Add'}</Filledbluebutton>
                </div>
            </form>
        </Card>
    );
};

export default SponsorAddForm;

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Hospitalhome from "./Hospitalhome/Hospitalhome";
import Hospitaldetails from "./Hospitaldetails/Hospitaldetails";
import Hospitalregistration from "./Hospitalregistration/Hospitalregistration";

const Hospitals = () => {
    return (
        <Routes>
            <Route path="" element={<Hospitalhome/>}/>
            <Route path="/registration" element={<Hospitalregistration/>}/>
            <Route path="/registration/:id" element={<Hospitalregistration/>}/>
            <Route path="/:id" element={<Hospitaldetails/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
};

export default Hospitals;

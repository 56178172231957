import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from "formik";
import Card from "../../../../shared/components/Card/Card";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import * as yup from 'yup';
import { UserContext } from "../../../../App";
import { createSpecialist } from "../../../../core/services/Data/speciality"
import { toast } from "react-toastify";
import { getSingleSpecialist, updateSpecialist } from "../../../../core/services/Data/speciality"
import CloseIcon from "@mui/icons-material/Close";
import uploadFileService from '../../../../core/services/Data/uploadFileService';


const Specialityaddform = () => {
    const { setShowLoader } = useContext(UserContext);
    const [formPrefillData, setFormPrefillData] = useState();
    const params = useParams()
    const [picture, setPicture] = useState()
    const navigate = useNavigate();
    const validation = yup.object({
        Speciality: yup.string("").required("Speciality is required")
    })
    const assSpeciality = useFormik({
        initialValues: {
            Speciality: formPrefillData ? formPrefillData.specialityName : '',
            picture: formPrefillData ? formPrefillData.picture : ''

        },
        validationSchema: validation,
        enableReinitialize: true,
        onSubmit: (values) => {
            setShowLoader((prevState) => {
                return true
            });
            new Promise((resolve, reject) => {
                if (typeof values.picture !== "string") {
                  const response = uploadFileService.fileUpload(
                    values.picture
                  );
                  if (response) {
                    resolve(response);
                  } else {
                    reject();
                  }
                } else {
                  resolve();
                }
              }).then(url => {
                const payload = {
                    ...values,
                    specialityName: values.Speciality,
                    icon:url ? url.fileUrl : values.picture
                }
                if (params.id) {
                    updateSpecialist(params.id, payload).then((res) => {
                        toast.success(res.message)
                        navigate('/dashboard/speciality');
                        setShowLoader(false)
                    })
                }
                else {
                    createSpecialist(payload).then((res) => {
                        toast.success(res.message)
                        navigate('/dashboard/speciality');
                        setShowLoader(false)
                    })
                }
              })
            
        }
    });
    const pictureInputHandler = (event) => {
        if (event.currentTarget.files.length !== 0) {
            const file = event.currentTarget.files[0];
            console.log({ file });
            if (file.size > 1024000) {
                assSpeciality.setErrors({
                    picture: "File size is exceeding the limit 1mb ",
                });
                // }
            } else {
                assSpeciality.handleChange(event);
                assSpeciality.setFieldValue(
                    "picture",
                    event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
                );
                setPicture((prevState) => {
                    return URL.createObjectURL(event.target.files[0]);
                });
            }
        } else {
            assSpeciality.setErrors({
                picture: "Doctor Certificate is Required!",
            });
        }
    };

    const pictureResetInput = () => {
        assSpeciality.setFieldValue("picture", "");
        setPicture((prevState) => {
            return "";
        });
    };
    useEffect(() => {
        if (params.id) {
            setShowLoader(true)
            getSingleSpecialist(params.id).then((res) => {
                setShowLoader(false)
                setFormPrefillData(res.data);
            })
        }
    }, [])
    return (
        <Card cname="max-w-2xl mx-auto">
            <h1 className="text-center text-2xl my-6 font-semibold">{params.id ? "Update" : "Add"} Speciality</h1>
            <form onSubmit={assSpeciality.handleSubmit}>
                <TextField
                    fullWidth
                    id="hospitalInput"
                    label="Speciality"
                    name="Speciality"
                    // select
                    value={assSpeciality.values.Speciality}
                    onChange={assSpeciality.handleChange}
                    onBlur={assSpeciality.handleBlur}
                    helperText={assSpeciality.touched.Speciality && Boolean(assSpeciality.errors.Speciality) ? 'Speciality name is required' : ''}
                    error={assSpeciality.touched.Speciality && Boolean(assSpeciality.errors.Speciality)}>
                    {/* <MenuItem value="Health Insurance">Health Insurance</MenuItem>
                    <MenuItem value="Cipla">Cipla</MenuItem> */}
                </TextField>
                <div className="col-span-12 md:col-span-6 mt-4">
                    {/*Image upload hospital logo*/}
                    <div className="flex flex-col justify-center items-center max-w-full">
                        <label
                            className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${assSpeciality.errors
                                    .picture
                                    ? "border-[#d32f2f]"
                                    : "border-gray-300"
                                }
                         border-dashed cursor-pointer`}
                        >
                            <input
                                id="dropzone-file"
                                name="picture"
                                disabled={picture !== ""}
                                accept="image/png, image/jpeg, image/jpg, image/webp"
                                onBlur={assSpeciality.handleBlur}
                                onChange={pictureInputHandler}
                                type="file"
                                className="hidden"
                            />
                            {picture !== "" ? (
                                <div className="relative w-24 h-24">
                                    <img
                                        id="blah"
                                        src={picture}
                                        className="shadow w-full object-fill"
                                        alt="your"
                                    />
                                    <CloseIcon
                                        onClick={pictureResetInput}
                                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                                    />
                                </div>
                            ) : (
                                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                    <svg
                                        aria-hidden="true"
                                        className="mb-3 w-10 h-10 text-gray-400"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">
                                            Click to upload Doctor Certificate
                                        </span>{" "}
                                        or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                </div>
                            )}
                        </label>
                    </div>
                </div>
                <div className="my-5">
                    <Filledbluebutton cname="w-full" btnType="submit">{params.id ? "Update" : "add"}</Filledbluebutton>
                </div>
            </form>
        </Card>
    );
};

export default Specialityaddform;

import React, { useEffect, useState } from "react";
import Rightbutton from "../../../../shared/components/Rightbutton/Rightbutton";
import Leftbutton from "../../../../shared/components/Leftbutton/Leftbutton";
import { useLocation } from "react-router-dom";
import { requestsReceived } from "../../../../core/constants/appconstants";
import Card from "../../../../shared/components/Card/Card";
import requestService, {
  singleRequest,
} from "../../../../core/services/Data/requestService";
import UtilService from "../../../../core/services/Data/utilsService";

const RequestDetail = () => {
  const location = useLocation();
  const { pathname } = location;
  const id = pathname.split("/")[3];
  const [receivedRequests, setReceivedRequests] = useState();

  const getRequestDetails = () => {
    requestService.singleRequest(id).then((res) => {
      console.log("single request Data", res);
      res && setReceivedRequests(res.data);
    });
  };
  const handleRespondToRequest = (id, status) => {
    const handleRespondToRequest = (id, status) => {
      requestService.respondRequest(id, status).then((res) => {
        if (res) {
          console.log("response", res);
          getRequestDetails();
        }
      });
    };
  };

  useEffect(() => {
    getRequestDetails();
  }, []);
  return (
    <Card>
      {receivedRequests && (
        <>
          <div className="grid grid-cols-12 gap-1 mt-4">
            <div className="col-span-12 lg:col-span-2 my-auto">
              <img
                src={receivedRequests.requestedUser.picture}
                className="border border-sky-400 p-1 h-24 w-24 rounded-full"
                alt=""
              />
            </div>
            <div className="col-span-12 lg:col-span-6 my-auto flex flex-col">
              <h3 className="text-sky-500 text-lg font-semibold">
                {receivedRequests.requestedUser.name}
              </h3>
              <p className="text-md text-gray-500">
                Approval for adding new {requestsReceived.requestType}
              </p>
            </div>
            <div className="col-span-12 lg:col-span-4 my-auto flex flex-col justify-center items-center">
              {/* <div className="flex flex-row">
                                <Rightbutton big={true}/>
                                <div className="ml-2">
                                    <Leftbutton big={true}/>
                                </div>
                            </div> */}
              <div className="flex flex-row">
                {receivedRequests.status === "rejected" ||
                receivedRequests.status === "approved" ? (
                  <div
                    className={`font-semibold rounded-md px-2 py-1 ${
                      receivedRequests.status === "rejected"
                        ? "text-red-500 bg-red-200"
                        : "text-green-500 bg-green-200"
                    }`}
                  >
                    {receivedRequests.status.toUpperCase()}
                  </div>
                ) : (
                  <>
                    <Rightbutton
                      big={true}
                      clicked={() => {
                        handleRespondToRequest(
                          receivedRequests._id,
                          "approved"
                        );
                      }}
                    />
                    <div className="ml-2">
                      <Leftbutton
                        big={true}
                        clicked={() => {
                          handleRespondToRequest(
                            receivedRequests._id,
                            "rejected"
                          );
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="text-center">
                <span className="text-xs text-gray-500 font-semibold">
                 
                </span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 mt-4">
            <div className="col-span-12">
              <img
                src={receivedRequests.requestedUser.banner}
                className="object-fill w-full"
                alt=""
              />
            </div>
          </div>
          <h1 className="text-2xl font-bold text-gray-600 my-7">
            Contact Details
          </h1>
          <div className="grid grid-cols-12 mt-3">
            <div className="col-span-12 md:col-span-3 flex flex-col">
              <h2>
                Phone No. <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {receivedRequests.requestedUser.phone}
              </p>
            </div>
            <div className="col-span-12 md:col-span-5 flex flex-col">
              <h2>
                Address<sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {receivedRequests.requestedUser.address}
              </p>
            </div>
            <div className="col-span-12 md:col-span-2 flex flex-col">
              <h2>
                City <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {receivedRequests.requestedUser.city}
              </p>
            </div>
            <div className="col-span-12 md:col-span-2 flex flex-col">
              <h2>
                Country <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {receivedRequests.requestedUser.country}
              </p>
            </div>
            <div className="col-span-12 md:col-span-3 flex flex-col mt-4">
              <h2>
                Working days <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 gap-2 flex tracking-wider font-semibold text-sm mt-3">
                {UtilService.getDaysWithDaysIndex(receivedRequests.requestedUser.workingDays).map((ele) => {
                  return <div>{ele}</div>;
                })}
              </p>
            </div>
            <div className="col-span-12 md:col-span-3 flex flex-col mt-4">
              <h2>
                Working hours <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {receivedRequests.requestedUser.workingHours} hrs
              </p>
            </div>
          </div>

          <h1 className="text-2xl font-bold text-gray-600 my-7">
            Active Plan Details
          </h1>
          <div className="grid grid-cols-12 mt-3">
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <h2>
                Subscription Name <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {receivedRequests.requestedUser.subscription.name}
              </p>
            </div>
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <h2>
                Start Date <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {
                  receivedRequests.requestedUser.subscription.startDate.split(
                    "T"
                  )[0]
                }
              </p>
            </div>
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <h2>
                End Date <sup className="text-red-600 text-md">*</sup>
              </h2>
              <p className="text-gray-500 tracking-wider font-semibold text-sm mt-3">
                {
                  receivedRequests.requestedUser.subscription.expiryDate.split(
                    "T"
                  )[0]
                }
              </p>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default RequestDetail;

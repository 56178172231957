import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function branchService(max) {
    try {
        const {data: response} = await httpservice.get('branches');
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function branchRegistration(payload) {
    console.log('API call wale function k andar aagya hu');
    try {
        console.log('Try block k andar aagya hu')
        const {data: response} = await httpservice.post('branches/create', payload);
        console.log('resppinse jo register wali api ne return kara', response);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function getBranchById(id) {
    try {
        const {data: response} = await httpservice.get('branches/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function branchUpdate(payload, id) {
    try {
        const {data: response} = await httpservice.put('branches/edit/' + id, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function deleteBranchById(id) {
    try {
        const {data: response} = await httpservice.patch('branches/deactivate/' + id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

async function getAllChemist() {
    const url = '/pharmacy/inhouse';
    try {
        const {data: response} = await httpservice.get(url);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function addChemistInhouseHospital(payload) {
    const url = '/pharmacy/inhouse/create';
    try {
        const {data: response} = await httpservice.post(url, payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}
async function getsingleInhouse(id){
    const url = "/pharmacy/inhouse/"
    try {
        const {data : response} = await httpservice.get(url +id)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}
async function updatesingleInhouse(id, payload){
    const url = "/pharmacy/inhouse/edit/"
    try {
        const {data : response} = await httpservice.put(url + id, payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}

async function getOuthousePharmacy(){
    const url = '/pharmacy/outhouse'
    try {
        const {data : response} = await httpservice.get(url)
        return response;
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}
async function deleteInpharmacy(id){
    try {
        const {data : response} = await httpservice.delete('/pharmacy/inhouse/delete/' +id );
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}
async function deleteOutPharmacy (id){
   
    try {
        const {data : response} = await httpservice.delete('/pharmacy/outhouse/delete/' +id );
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}

async function addOuthousePharmacy(payload){
    const url = 'pharmacy/outhouse/create'
    try {
        const {data : response} = await httpservice.post(url,payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}
async function getSingleOuthousePharmacy(id){
    const url = "pharmacy/outhouse/"
    try {
        const {data : response} = await httpservice.get(url +id);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        } 
    }
}
async function updateSingleOuthouse(id,payload){
    const url = "pharmacy/outhouse/edit/"
    try {
        const {data : response} = await httpservice.put(url + id,payload)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}

export default {
    addChemistInhouseHospital,
    getAllChemist,
    addOuthousePharmacy,
    getOuthousePharmacy,
    deleteInpharmacy,
    deleteOutPharmacy,
    getsingleInhouse,
    updatesingleInhouse,
    getSingleOuthousePharmacy,
    updateSingleOuthouse
}

import React from 'react';

const Filledbluebutton = (props) => {
    return (
        <button type={props.btnType ? props.btnType : 'button'}
                onClick={props.clicked}
                disabled={props.disabledBtn ? props.disabledBtn : false}
                className={`inline-block px-6 py-2.5 bg-sky-500 font-medium text-sm
                             leading-tight text-white uppercase rounded-md hover:bg-sky-600 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out ${props.cname} ${!props.disabledBtn ? '' : 'cursor-not-allowed'}`}>
            {props.children ? props.children : 'Add'}
        </button>
    )
};

export default Filledbluebutton;

import httpservice from "../Httpservice/httpservice,";
import { toast } from "react-toastify";

async function getAllLaboratory() {
  const url = "labs/inhouse";
  try {
    const { data: laboratory } = await httpservice.get(url);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}
async function createLaboratory(payload) {
  const url = "labs/inhouse/create";
  try {
    const { data: laboratory } = await httpservice.post(url, payload);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data.message);
    }
  }
}

async function deleteLaboratory(id) {
  try {
    const { data: laboratory } = await httpservice.delete(
      "labs/inhouse/delete/" + id
    );
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}
async function getLaboratoryById(id) {
  try {
    const url = "labs/inhouse/";
    const { data: laboratory } = await httpservice.get(url + id);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}
async function updateLaboratory(id, payload) {
  try {
    const url = "labs/inhouse/edit/";
    const { data: laboratory } = await httpservice.put(url + id, payload);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}

async function updateLaboratoryOut(id, payload) {
  try {
    const url = "labs/outhouse/edit/";
    const { data: laboratory } = await httpservice.put(url + id, payload);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}
async function getAllLaboratoryOuthouse() {
  const url = "labs/outhouse";
  try {
    const { data: laboratory } = await httpservice.get(url);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}

async function createLaboratoryOuthouse(payload) {
  const url = "labs/outhouse/create";
  try {
    const { data: laboratory } = await httpservice.post(url, payload);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data.message);
    }
  }
}

async function deleteOuthouseLaboratory(id) {
  try {
    const { data: laboratory } = await httpservice.delete(
      "labs/outhouse/delete/" + id
    );
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}
async function getLaboratoryOutById(id) {
  try {
    const url = "labs/outhouse/";
    const { data: laboratory } = await httpservice.get(url + id);
    return laboratory;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
}

function createLabTest({ title, body }) {
  return httpservice.post("labs/tests", { title, body });
}

export default {
  getAllLaboratory,
  createLaboratory,
  deleteLaboratory,
  getLaboratoryById,
  updateLaboratory,
  getAllLaboratoryOuthouse,
  createLaboratoryOuthouse,
  deleteOuthouseLaboratory,
  getLaboratoryOutById,
  createLabTest,
  updateLaboratoryOut,
};

import * as yup from 'yup';

const inhouse = yup.object({
    hospitalName: yup.string('Enter your password').required('Hospital name is required').trim(),
    branch: yup.string('Enter your password').required('Branch name is required').trim(),
    chemistName: yup.string('Email is required').required('Chemist name is required').trim(),
    email: yup.string('Email is required').required('Email is required').trim(),
    image: yup.string('Email is required').required('Image is required').trim(),
});

export default {
    inhouse
}

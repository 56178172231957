import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../App";
import { useFormik } from "formik";
import { receptionalistRegistrationValidationSchema } from "../../../../core/Validationschemas/receptionalistRegistrationValidationSchema";
import LocationPicker from "react-location-picker";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
import commonAPICallService from "../../../../core/services/Data/commonAPICallService";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import Toggledays from "../../../../shared/components/Toggledays/Toggledays";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import receptionistService from "../../../../core/services/Data/receptionistService";
// import {createReceptionist} from "../../../../core/services/Data/receptionistService";
import { toast } from "react-toastify";
import LocationService from "../../../../core/services/Data/LocationService";
import { getSpecialist } from "../../../../core/services/Data/speciality";
import SearchBox from "./googleSearch";
const Receptionistaddform = () => {
  const params = useParams();
  const [formPrefillData, setFormPrefillData] = useState();
  const [workingDays, setWorkingDays] = useState(!params.id && []);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  const defaultPosition = {
    lat: 29.378586,
    lng: 47.990341
  };
  const { setShowLoader } = useContext(UserContext);
  const [hospitalsList, setHospitalsList] = useState(null);
  const [speciality, setSpeciality] = useState([]);
  const [branchList, setbranchList] = useState(null);
  const [receptionistOtherCertificateUrlError, setOtherCertificateUrlError] =
    useState(false);
  const navigate = useNavigate();
  const receptionistOtherInputRef = useRef();
  const [receptionistCertificateUrl, setDoctorCertificateUrl] = useState("");
  const [receptionistOtherCertificateUrl, setOtherCertificateUrl] =
    useState("");
  const [receptionistAwardsCertificateUrl, setAwardsCertificateUrl] =
    useState("");

  const [position, setPosition] = useState({ ...defaultPosition });
  useEffect(() => {
    const currentLocation = async () => {
      await navigator.geolocation.getCurrentPosition(
        (position) =>
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (err) => console.log(err)
      );
      // console.log(this.state.latitude)
    };
    currentLocation();
  }, []);
  const receptionistRegistrationForm = useFormik({
    initialValues: {
      firstName: formPrefillData ? formPrefillData.name.first : "",
      lastName: formPrefillData ? formPrefillData.name.last : "",
      email: formPrefillData ? formPrefillData.email : "",
      hospitalName: formPrefillData
        ? formPrefillData.professional.hospital._id
        : "",
      branchName: formPrefillData
        ? formPrefillData.professional.branch._id
        : "",
      educationDegree: formPrefillData
        ? formPrefillData.professional.educationDegree
        : "",
      yearsExperience: formPrefillData
        ? formPrefillData.professional.yearsOfExp
        : "",
      // specialist: formPrefillData
      //   ? formPrefillData.professional.speciality.split(",")
      //   : [],
      // language: formPrefillData ? formPrefillData.professional.language : '',
      // insurance: formPrefillData ? formPrefillData.professional.insurance : '',
      // receptionistCertificate: formPrefillData ? formPrefillData.professional.certificate : '',
      otherCertificate: formPrefillData
        ? formPrefillData.professional.otherDocs
        : "",
      // awards: '',
      // services: formPrefillData ? formPrefillData.professional.services : '',
      phoneNumber: formPrefillData ? formPrefillData.phone : "",
      block:formPrefillData ? formPrefillData?.addressBlock?.block || "" : 'B-',
      blockNumber:formPrefillData ? formPrefillData?.addressBlock?.number || "" : '',
      alternatePhoneNumber: formPrefillData
        ? formPrefillData.alternatePhone
        : "",
      alternateEmail: formPrefillData ? formPrefillData.alternateEmail : "",
      address: formPrefillData ? formPrefillData.address : "",
      city: formPrefillData ? formPrefillData.city : "",
      state: formPrefillData ? formPrefillData.state : "",

      country: formPrefillData ? formPrefillData.country : "",
      startTime: formPrefillData
        ? new Date(parseInt(formPrefillData.shift.startTime))
        : null,
      endTime: formPrefillData
        ? new Date(parseInt(formPrefillData.shift.endTime))
        : null,
    },
    enableReinitialize: true,
    validationSchema: receptionalistRegistrationValidationSchema,

    
    onSubmit: (values) => {
      setShowLoader((prevState) => {
        return true;
      });
      if (!workingDaysError) {
        Promise.all([
          new Promise((resolve, reject) => {
            if (typeof values.otherCertificate !== "string") {
              const response = uploadFileService.fileUpload(
                values.otherCertificate
              );
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            } else {
              resolve({ fileUrl: values.otherCertificate });
            }
          }),
        ]).then(async ([res2]) => {
          console.log("WORKING")
          if (params.id) {
            console.log("Update One", res2);
            const payload = {
              ...values,
              branchPermit: res2.fileUrl,
              workingDays: workingDays,
              addressBlock:{
                block:"B-",
                number:values.blockNumber
            },
              location: {
                coordinates: [position.lat, position.lng],
              },
            };
            console.log("Update Two");
            receptionistService
              .updateReceptionistDetails(payload, params.id)
              .then((res) => {
                if (res) {
                  console.log("response of branch registration is", res);
                  navigate("/dashboard/receptionist");

                  toast.success("Receptionalist details updated successfully");
                } else {
                  setShowLoader((prevState) => {
                    return false;
                  });
                }
              });
            // console.log("first", payload)
          } else {
            const payload = {
              ...values,
              addressBlock:{
                block:"B-",
                number:values.blockNumber
            },
              otherCertificate: res2.fileUrl,
              location: {
                coordinates: [position.lat, position.lng],
              },
              workingDays: workingDays,
            };
            receptionistService.createReceptionist(payload).then((res) => {
              if (res) {
                navigate("/dashboard/receptionist");
                toast.success("Receptionist registered successfully");
              }
              setShowLoader((prevState) => {
                return false;
              });
            });
          }
        });
      } else {
        setShowLoader((prevState) => {
          return false;
        });
      }
    },
  });
  const receptionistCertificateInputHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file })
      if (file.size > 1024000) {
        receptionistRegistrationForm.setErrors({
          otherCertificate: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        receptionistRegistrationForm.handleChange(event);
        receptionistRegistrationForm.setFieldValue(
          "receptionistCertificate",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setDoctorCertificateUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      receptionistRegistrationForm.setErrors({
        otherCertificate: "Other Certificate is Require!",
      });
    }
  };

  const DoctorCertificateResetInput = () => {
    receptionistRegistrationForm.setFieldValue("receptionistCertificate", "");
    setDoctorCertificateUrl((prevState) => {
      return "";
    });
  };

  const DoctorOtherInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file })
      if (file.size > 1024000) {
        receptionistRegistrationForm.setErrors({
          otherCertificate: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {

        receptionistRegistrationForm.setFieldValue(
          "otherCertificate",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setOtherCertificateUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      receptionistRegistrationForm.setErrors({
        otherCertificate: "Other Certificate is required!",
      });
    }
  };

  const DoctorResetOtherInput = () => {
    receptionistRegistrationForm.setFieldValue("otherCertificate", "");
    setOtherCertificateUrl((prevState) => {
      return "";
    });
  };

  const DoctorAwardsInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      receptionistRegistrationForm.setFieldValue(
        "awards",
        event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
      );
      setAwardsCertificateUrl((prevState) => {
        return URL.createObjectURL(event.target.files[0]);
      });
    } else {
      receptionistRegistrationForm.setErrors({
        awards: "Feature Image is required",
      });
    }
  };

  const DoctorResetAwardsInput = () => {
    receptionistRegistrationForm.setFieldValue("awards", "");
    setAwardsCertificateUrl((prevState) => {
      return "";
    });
  };

  useEffect(() => {
    getSpecialist().then(res => {
      console.log({ res })
      setSpeciality(res.data)
    })
  }, [])
  const handleLocationChange = ({ position, address, places }) => {
    // Set new location
    setPosition({
      lat: position.lat,
      lng: position.lng,
    });
  };

  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };

  const [locatons, setLocations] = useState({
    country: [],
    city: [],
    state: [],
  });
  useEffect(() => {
    const apiData = async () => {
      const { data } = await LocationService.getLocations(receptionistRegistrationForm.values.country, receptionistRegistrationForm.values.state);
      // console.log({data})
      let cities = [...data[0].city];
      let states = [...data[0].state];
      let country = [...data[0].country];
      // for await (let element of data) {
      //   if (!cities.includes(element.city)) {
      //     cities.push(element.city);
      //   }
      //   if (!states.includes(element.state)) {
      //     states.push(element.state);
      //   }
      //   if (!country.includes(element.country)) {
      //     country.push(element.country);
      //   }
      // }
      const locations = { ...locatons };
      locations.city = cities;
      locations.state = states;
      locations.country = country;
      setLocations(locations);
    };
    apiData();
  }, [receptionistRegistrationForm.values.country, receptionistRegistrationForm.values.state]);
  useEffect( () => {

    if (params.id) {
      const apiData = async () => {
        setShowLoader(true);

        const hos = await commonAPICallService.getHospitalsList();
        await setHospitalsList(hos);
        const res = await receptionistService.getReceptionistById(params.id);
        if(res){
          setShowLoader(false);
          console.log("receptionist by id", res.data);
          setOtherCertificateUrl((prevState) => {
            return res.data.professional.otherDocs;
          });
          setWorkingDays((prevState) => {
            console.log("res.data.workingDays", res.data.workingDays);
            return res.data.workingDays;
          });
          setFormPrefillData((prevState) => {
            setShowLoader(false);
            return res.data;
          });
          setbranchList((prevState) => {
            return hos.filter((ele) => ele.hospital === res.data.professional.hospital.hospitalName)[0].branch
          })
          if (res?.data?.location?.coordinates) {
            setPosition((prevState) => {
              return {
                lng: res?.data?.location?.coordinates[1] || 94,
                lat: res?.data?.location?.coordinates[0] || 32,
              };
            });
          }
        }
       
          
  
          setShowLoader(false);
      }
     apiData()
    } else {
      setShowLoader(true);
      commonAPICallService.getHospitalsList().then(async (res) => {
        console.log("res for hospital list", res);
        if (res) {
          await setHospitalsList(res);
        }
      });
      setShowLoader(false);
    }
  }, []);

  const hospitalChange = (event) => {
    const ele = hospitalsList.filter((ele) => {
      return ele.id === event.target.value;
    })[0];
    console.log({ele})
    if (ele) {
      receptionistRegistrationForm.setFieldValue("city", ele.city);
      receptionistRegistrationForm.setFieldValue("country", ele.country);
      receptionistRegistrationForm.setFieldValue("state", ele.state);
      receptionistRegistrationForm.setFieldValue("address", ele.address);
    }
    setbranchList((prevState) => {
      return hospitalsList.filter((ele) => {
        return ele.id === event.target.value;
      })[0].branch;
    });
  };
  const branchChange = (event) => {
    const ele = branchList.filter((ele) => {
      return ele._id === event.target.value;
    })[0];
    if (ele) {
      receptionistRegistrationForm.setFieldValue("city", ele.city);
      receptionistRegistrationForm.setFieldValue("country", ele.country);
      receptionistRegistrationForm.setFieldValue("state", ele.state);
      receptionistRegistrationForm.setFieldValue("address", ele.address);
    }

  }
  return (
    <Card cname="px-10">
      <div className="my-10">
        <h1 className="text-3xl font-bold">
          {params.id ? "Update" : "Register"}
        </h1>
        <p className="text-xl font-semibold text-gray-500 mt-2">
          You have to fill the necessary details here.
        </p>
      </div>
      <form
        onSubmit={receptionistRegistrationForm.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        {/*Registration Type input field*/}
        <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
          <TextField
            fullWidth
            id="registerType"
            label="Register As"
            name="registerType"
            value="Receptionist"
            disabled
            // select
          >
            <MenuItem value="Receptionist">Receptionist</MenuItem>
          </TextField>
        </div>

        {/*Basic Details Input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Basic Details</h1>
          {/*Hospital name input field*/}
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="firstName"
                name="firstName"
                label="Enter first name"
                helperText={
                  receptionistRegistrationForm.touched.firstName &&
                    Boolean(receptionistRegistrationForm.errors.firstName)
                    ? receptionistRegistrationForm.errors.firstName
                    : ""
                }
                value={receptionistRegistrationForm.values.firstName}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.firstName &&
                  Boolean(receptionistRegistrationForm.errors.firstName)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="lastName"
                name="lastName"
                label="Enter Last Name"
                helperText={
                  receptionistRegistrationForm.touched.lastName &&
                    Boolean(receptionistRegistrationForm.errors.lastName)
                    ? receptionistRegistrationForm.errors.lastName
                    : ""
                }
                value={receptionistRegistrationForm.values.lastName}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.lastName &&
                  Boolean(receptionistRegistrationForm.errors.lastName)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="email"
                label="Enter your email"
                helperText={
                  receptionistRegistrationForm.touched.email &&
                    Boolean(receptionistRegistrationForm.errors.email)
                    ? receptionistRegistrationForm.errors.email
                    : ""
                }
                value={receptionistRegistrationForm.values.email}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.email &&
                  Boolean(receptionistRegistrationForm.errors.email)
                }
              />
            </div>
          </div>
        </div>

        {/*Professional details*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Professional Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                id="hospitalName"
                label="Hospital name"
                name="hospitalName"
                select
                helperText={
                  receptionistRegistrationForm.touched.hospitalName &&
                    Boolean(receptionistRegistrationForm.errors.hospitalName)
                    ? receptionistRegistrationForm.errors.hospitalName
                    : ""
                }
                value={receptionistRegistrationForm.values.hospitalName}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={(e) => {
                  hospitalChange(e);
                  receptionistRegistrationForm.handleChange(e);
                }}
                error={
                  receptionistRegistrationForm.touched.hospitalName &&
                  Boolean(receptionistRegistrationForm.errors.hospitalName)
                }
              >
                {hospitalsList &&
                  hospitalsList.map((ele) => {
                    return (
                      <MenuItem value={ele.id} key={ele.id}>
                        {ele.hospital}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                id="branchName"
                label="Branch name"
                name="branchName"
                select
                helperText={
                  receptionistRegistrationForm.touched.branchName &&
                    Boolean(receptionistRegistrationForm.errors.branchName)
                    ? receptionistRegistrationForm.errors.branchName
                    : ""
                }
                value={receptionistRegistrationForm.values.branchName}
                onBlur={receptionistRegistrationForm.handleBlur}

                onChange={(e) => {
                  branchChange(e);
                  receptionistRegistrationForm.handleChange(e);
                }} error={
                  receptionistRegistrationForm.touched.branchName &&
                  Boolean(receptionistRegistrationForm.errors.branchName)
                }
              >
                {branchList &&
                  branchList.map((ele) => {
                    return (
                      <MenuItem value={ele._id} key={ele._id}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="educationDegree"
                name="educationDegree"
                label="Enter your Education Degree"
                helperText={
                  receptionistRegistrationForm.touched.educationDegree &&
                    Boolean(receptionistRegistrationForm.errors.educationDegree)
                    ? receptionistRegistrationForm.errors.educationDegree
                    : ""
                }
                value={receptionistRegistrationForm.values.educationDegree}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.educationDegree &&
                  Boolean(receptionistRegistrationForm.errors.educationDegree)
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="yearsExperience"
                name="yearsExperience"
                label="Enter years of experience"
                helperText={
                  receptionistRegistrationForm.touched.yearsExperience &&
                    Boolean(receptionistRegistrationForm.errors.yearsExperience)
                    ? receptionistRegistrationForm.errors.yearsExperience
                    : ""
                }
                value={receptionistRegistrationForm.values.yearsExperience}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.yearsExperience &&
                  Boolean(receptionistRegistrationForm.errors.yearsExperience)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              {/*Hospital permit image upload*/}
              <div className="flex flex-col justify-center items-center max-w-full">
                <label
                  className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${receptionistRegistrationForm.errors
                      .otherCertificate
                      ? "border-[#d32f2f]"
                      : "border-gray-300"
                    }
                                        border-dashed cursor-pointer`}
                >
                  <input
                    ref={receptionistOtherInputRef}
                    id="dropzone-file"
                    name="otherCertificate"
                    disabled={receptionistOtherCertificateUrl !== ""}
                    accept="image/png, image/jpeg, image/jpg"
                    onBlur={receptionistRegistrationForm.handleBlur}
                    onChange={DoctorOtherInputChangeHandler}
                    type="file"
                    className="hidden"
                  />
                  {receptionistOtherCertificateUrl !== "" ? (
                    <div className="relative w-24 h-24">
                      <img
                        id="blah"
                        src={receptionistOtherCertificateUrl}
                        className="shadow w-full object-fill"
                        alt="your"
                      />
                      <CloseIcon
                        onClick={DoctorResetOtherInput}
                        className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 w-10 h-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Click to upload Other Certificates
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`text-[#d32f2f] my-2 text-sm ml-4 ${receptionistRegistrationForm.errors.otherCertificate
                  ? ""
                  : "hidden"
                  }`}
              >
                {receptionistRegistrationForm.errors.otherCertificate}
              </div>
            </div>
            {/* <div className="col-span-12 md:col-span-4">
              <TextField
                fullWidth
                variant="outlined"
                id="specialist"
                name="specialist"
                label="Enter Speciality"
                helperText={
                  receptionistRegistrationForm.touched.specialist &&
                  Boolean(receptionistRegistrationForm.errors.specialist)
                    ? receptionistRegistrationForm.errors.specialist
                    : ""
                }
                value={receptionistRegistrationForm.values.specialist}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.specialist &&
                  Boolean(receptionistRegistrationForm.errors.specialist)
                }
              />
            </div> */}
            {/* {<div className="col-span-12 md:col-span-4">
              <FormControl className="w-full">
                <InputLabel id="speciality" className="bg-white">
                  Speciality
                </InputLabel>

                <Select
                  fullWidth
                  labelId="speciality"
                  id="specialist"
                  className="w-full"
                  multiple
                  label="Enter Speciality"
                  name="specialist"
                  helperText={
                    receptionistRegistrationForm.touched.specialist &&
                    Boolean(receptionistRegistrationForm.errors.specialist)
                      ? receptionistRegistrationForm.errors.specialist
                      : ""
                  }
                  value={receptionistRegistrationForm.values.specialist}
                  onBlur={receptionistRegistrationForm.handleBlur}
                  onChange={receptionistRegistrationForm.handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  
                  
                  {speciality.map(ele => <MenuItem key={ele._id} value={ele.specialityName}>
                    {ele.specialityName}
                  </MenuItem>)}
                </Select>
              </FormControl>
            </div>} */}
            {/* <div className="col-span-12 md:col-span-4">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="language"
                                name="language"
                                label="Enter language"
                                helperText={receptionistRegistrationForm.touched.language && Boolean(receptionistRegistrationForm.errors.language) ? receptionistRegistrationForm.errors.language : ''}
                                value={receptionistRegistrationForm.values.language}
                                onBlur={receptionistRegistrationForm.handleBlur}
                                onChange={receptionistRegistrationForm.handleChange}
                                error={receptionistRegistrationForm.touched.language && Boolean(receptionistRegistrationForm.errors.language)}
                            />
                        </div> */}
            {/* <div className="col-span-12 md:col-span-4"> */}
            {/* <TextField
                                fullWidth
                                variant="outlined"
                                id="insurance"
                                name="insurance"
                                label="Enter Insurance"
                                helperText={receptionistRegistrationForm.touched.insurance && Boolean(receptionistRegistrationForm.errors.insurance) ? receptionistRegistrationForm.errors.insurance : ''}
                                value={receptionistRegistrationForm.values.insurance}
                                onBlur={receptionistRegistrationForm.handleBlur}
                                onChange={receptionistRegistrationForm.handleChange}
                                error={receptionistRegistrationForm.touched.insurance && Boolean(receptionistRegistrationForm.errors.insurance)}
                            /> */}
            {/* </div> */}
            <div className="col-span-12 md:col-span-6">
              <div className="col-span-12">
                {/*Hospital permit image upload*/}

                <div
                  className={`text-[#d32f2f] my-2 text-sm ml-4 ${receptionistRegistrationForm.errors.awards ? "" : "hidden"
                    }`}
                >
                  {receptionistRegistrationForm.errors.awards}
                </div>
              </div>
            </div>
            {/* <div className="col-span-12 md:col-span-6">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="services"
                                name="services"
                                label="Enter Services"
                                helperText={receptionistRegistrationForm.touched.services && Boolean(receptionistRegistrationForm.errors.services) ? receptionistRegistrationForm.errors.services : ''}
                                value={receptionistRegistrationForm.values.services}
                                onBlur={receptionistRegistrationForm.handleBlur}
                                onChange={receptionistRegistrationForm.handleChange}
                                error={receptionistRegistrationForm.touched.services && Boolean(receptionistRegistrationForm.errors.services)}
                            />
                        </div> */}
          </div>
        </div>

        {/*Contact Details input field*/}
        <div className="col-span-12 mt-4">
          <h1 className="text-xl font-semibold">Contact Details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="phoneNumber"
                name="phoneNumber"
                label="Enter phone number"
                helperText={
                  receptionistRegistrationForm.touched.phoneNumber &&
                    Boolean(receptionistRegistrationForm.errors.phoneNumber)
                    ? receptionistRegistrationForm.errors.phoneNumber
                    : ""
                }
                value={receptionistRegistrationForm.values.phoneNumber}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.phoneNumber &&
                  Boolean(receptionistRegistrationForm.errors.phoneNumber)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="alternatePhoneNumber"
                name="alternatePhoneNumber"
                label="Enter alternate phone number"
                helperText={
                  receptionistRegistrationForm.touched.alternatePhoneNumber &&
                    Boolean(
                      receptionistRegistrationForm.errors.alternatePhoneNumber
                    )
                    ? receptionistRegistrationForm.errors.alternatePhoneNumber
                    : ""
                }
                value={receptionistRegistrationForm.values.alternatePhoneNumber}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.alternatePhoneNumber &&
                  Boolean(
                    receptionistRegistrationForm.errors.alternatePhoneNumber
                  )
                }
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="alternateEmail"
                name="alternateEmail"
                label="Enter your Alternate  email"
                helperText={
                  receptionistRegistrationForm.touched.alternateEmail &&
                    Boolean(receptionistRegistrationForm.errors.alternateEmail)
                    ? receptionistRegistrationForm.errors.alternateEmail
                    : ""
                }
                value={receptionistRegistrationForm.values.alternateEmail}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.alternateEmail &&
                  Boolean(receptionistRegistrationForm.errors.alternateEmail)
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-2 md:mt-8">
            <div className="col-span-12 md:col-span-6">
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                name="address"
                
                label="Enter address"
                helperText={
                  receptionistRegistrationForm.touched.address &&
                    Boolean(receptionistRegistrationForm.errors.address)
                    ? receptionistRegistrationForm.errors.address
                    : ""
                }
                value={receptionistRegistrationForm.values.address}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.address &&
                  Boolean(receptionistRegistrationForm.errors.address)
                }
              />
            </div>
            <div className="col-span-6 md:col-span-3 ">
                            {/* <InputLabel>B-</InputLabel> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="blockNumber"
                                name="blockNumber"
                                label="Enter Block Number"
                                helperText={receptionistRegistrationForm.touched.blockNumber && Boolean(receptionistRegistrationForm.errors.blockNumber) ? receptionistRegistrationForm.errors.blockNumber : ''}
                                value={`${receptionistRegistrationForm.values.block || "B-"}${receptionistRegistrationForm.values.blockNumber}`}
                                onBlur={receptionistRegistrationForm.handleBlur}
                                onChange={(e)=>{
                                    const values = e.target.value.split("-");
                                    e.target.value = values[1] || "";
                                    receptionistRegistrationForm.handleChange(e)
                                }}
                                // InputProps={{
                                //     inputComponent: blockElement,
                                // }}
                                error={receptionistRegistrationForm.touched.blockNumber && Boolean(receptionistRegistrationForm.errors.blockNumber)}
                            />
                        </div>
            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                id="country"
                disabled
                label="Enter Country"
                name="country"
                helperText={
                  receptionistRegistrationForm.touched.country &&
                    Boolean(receptionistRegistrationForm.errors.country)
                    ? receptionistRegistrationForm.errors.country
                    : ""
                }
                value={receptionistRegistrationForm.values.country}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.country &&
                  Boolean(receptionistRegistrationForm.errors.country)
                }
                select
              >
                {locatons.country.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                disabled
                id="state"
                label="Enter State"
                name="state"
                helperText={
                  receptionistRegistrationForm.touched.state &&
                    Boolean(receptionistRegistrationForm.errors.state)
                    ? receptionistRegistrationForm.errors.state
                    : ""
                }
                value={receptionistRegistrationForm.values.state}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.state &&
                  Boolean(receptionistRegistrationForm.errors.state)
                }
                select
              >
                {locatons.state.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                disabled
                id="city"
                label="Enter City"
                name="city"
                helperText={
                  receptionistRegistrationForm.touched.city &&
                    Boolean(receptionistRegistrationForm.errors.city)
                    ? receptionistRegistrationForm.errors.city
                    : ""
                }
                value={receptionistRegistrationForm.values.city}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.city &&
                  Boolean(receptionistRegistrationForm.errors.city)
                }
                select
              >
                {locatons.city.map((ele) => {
                  return <MenuItem value={ele}>{ele}</MenuItem>;
                })}
                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
              </TextField>
            </div>

            {/* <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="city"
                name="city"
                label="Enter City"
                helperText={
                  receptionistRegistrationForm.touched.city &&
                  Boolean(receptionistRegistrationForm.errors.city)
                    ? receptionistRegistrationForm.errors.city
                    : ""
                }
                value={receptionistRegistrationForm.values.city}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.city &&
                  Boolean(receptionistRegistrationForm.errors.city)
                }
              />
            </div>

            <div className="col-span-12 md:col-span-3">
              <TextField
                fullWidth
                variant="outlined"
                id="country"
                name="country"
                label="Enter Country"
                helperText={
                  receptionistRegistrationForm.touched.country &&
                  Boolean(receptionistRegistrationForm.errors.country)
                    ? receptionistRegistrationForm.errors.country
                    : ""
                }
                value={receptionistRegistrationForm.values.country}
                onBlur={receptionistRegistrationForm.handleBlur}
                onChange={receptionistRegistrationForm.handleChange}
                error={
                  receptionistRegistrationForm.touched.country &&
                  Boolean(receptionistRegistrationForm.errors.country)
                }
              />
            </div> */}
          </div>
        </div>

        {/*Hospital Location Details Input map field*/}
        <div className="col-span-12 mt-2">
          <h1 className="text-xl font-semibold">Hospital location</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 relative">
              <div className='absolute left-56 top-[10px] z-50'>

                <SearchBox
                  setPosition={setPosition}
                />
              </div>
              {/*<img src="https://i.postimg.cc/qRF5QCjR/map-register.png" alt="map"*/}
              {/*     className="w-full-object-fill"/>*/}
              <LocationPicker
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "400px" }} />}
                defaultPosition={position}
                onChange={handleLocationChange}
              />
            </div>
          </div>
        </div>

        {/*Shift details*/}
        <div className="col-span-12 mt-2">
          <h1 className="text-xl font-semibold my-4">Shift details</h1>
          <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  name="startTime"
                  helperText={
                    receptionistRegistrationForm.touched.startTime &&
                      Boolean(receptionistRegistrationForm.errors.startTime)
                      ? receptionistRegistrationForm.errors.startTime
                      : ""
                  }
                  value={receptionistRegistrationForm.values.startTime}
                  onBlur={receptionistRegistrationForm.handleBlur}
                  error={
                    receptionistRegistrationForm.touched.startTime &&
                    Boolean(receptionistRegistrationForm.errors.startTime)
                  }
                  onChange={(value) => {
                    receptionistRegistrationForm.setFieldValue(
                      "startTime",
                      Date.parse(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        receptionistRegistrationForm.touched.startTime &&
                          Boolean(receptionistRegistrationForm.errors.startTime)
                          ? "Start Time is required"
                          : ""
                      }
                      value={receptionistRegistrationForm.values.startTime}
                      onBlur={receptionistRegistrationForm.handleBlur}
                      error={
                        receptionistRegistrationForm.touched.startTime &&
                        Boolean(receptionistRegistrationForm.errors.startTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  name="endTime"
                  helperText={
                    receptionistRegistrationForm.touched.endTime &&
                      Boolean(receptionistRegistrationForm.errors.endTime)
                      ? receptionistRegistrationForm.errors.endTime
                      : ""
                  }
                  value={receptionistRegistrationForm.values.endTime}
                  onBlur={receptionistRegistrationForm.handleBlur}
                  error={
                    receptionistRegistrationForm.touched.endTime &&
                    Boolean(receptionistRegistrationForm.errors.endTime)
                  }
                  onChange={(value) => {
                    receptionistRegistrationForm.setFieldValue(
                      "endTime",
                      Date.parse(value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%" }}
                      helperText={
                        receptionistRegistrationForm.touched.endTime &&
                          Boolean(receptionistRegistrationForm.errors.endTime)
                          ? "End Time is required"
                          : ""
                      }
                      value={receptionistRegistrationForm.values.endTime}
                      onBlur={receptionistRegistrationForm.handleBlur}
                      error={
                        receptionistRegistrationForm.touched.endTime &&
                        Boolean(receptionistRegistrationForm.errors.endTime)
                      }
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-span-12 md:col-span-6">
              {workingDays && (
                <Toggledays
                  handleWorkingDays={getWorkingDays}
                  workingDays={workingDays}
                />
              )}
              {workingDaysError && (
                <div className="text-red-500">Working days is required</div>
              )}
            </div>
          </div>
        </div>

        {/*Submit button*/}

        <div className="flex gap-2">
          <Filledbluebutton
            cname="w-32"
            btnType="submit"
            disabledBtn={!receptionistRegistrationForm.isValid}
          >
            {params.id ? "Update" : "Submit"}
          </Filledbluebutton>
          {params.id && (
            <Button
              color="warning"
              variant=""
              fullWidth
              type="submit"
              onClick={() => {
                navigate("/dashboard/receptionist");
              }}
            >
              {params.id ? "Cancel" : ""}
            </Button>
          )}
        </div>
      </form>
    </Card>
  );
};

export default Receptionistaddform;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from "formik";
import Card from "../../../../shared/components/Card/Card";
import TextField from "@mui/material/TextField";
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { useNavigate, useParams } from "react-router-dom";
import LocationPicker from 'react-location-picker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import Toggledays from "../../../../shared/components/Toggledays/Toggledays";
import { hospitalRegistrationValidationSchema } from "../../../../core/Validationschemas/hospitalRegistrationValidation";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
import hospitalRegistrationService from "../../../../core/services/Data/hospitalRegistrationService";
import { toast } from "react-toastify";
import { getHospitalById } from "../../../../core/services/Data/hospitalService";
import { UserContext } from "../../../../App";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import LocationService from '../../../../core/services/Data/LocationService';
import { getSpecialist } from '../../../../core/services/Data/speciality';
import SearchBox from './googleSearch';
import { Label } from '@mui/icons-material';

const Hospitalregistration = () => {
    const params = useParams();
    const [formPrefillData, setFormPrefillData] = useState();
    const [workingDays, setWorkingDays] = useState(!params.id && []);
    const [inputValue, setInputValue] = useState("");

    const [workingDaysError, setWorkingDaysError] = useState(false);
    const defaultPosition = {
        lat: 29.378586,
        lng: 47.990341
    };
    const [speciality, setSpeciality] = useState([])
    const { setShowLoader } = useContext(UserContext);
    const [hospitalPermitUrlError, setHospitalPermitUrlError] = useState(false);
    const navigate = useNavigate();
    const hospitalPermitInputref = useRef();
    const [hospitalImageUrl, setHospitalImageUrl] = useState('');
    const [hospitalPermitUrl, setHospitalPermitUrl] = useState('');
    const [position, setPosition] = useState({ ...defaultPosition })
    useEffect(() => {
        const currentLocation = async () => {
            await navigator.geolocation.getCurrentPosition(
                position => setPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }),
                err => console.log(err)
            );
            // console.log(this.state.latitude)
        }
        currentLocation()
    }, [])
    const hospitalRegistrationForm = useFormik({
        initialValues: {
            hospitalName: formPrefillData ? formPrefillData.name : '',
            hospitalType: formPrefillData
                ? formPrefillData.type.split(",")
                : [],
            hospitalLogo: formPrefillData ? formPrefillData.picture : '',
            phoneNumber: formPrefillData ? formPrefillData.phone : '',
            alternatePhoneNumber: formPrefillData ? formPrefillData.alternatePhone : '',
            email: formPrefillData ? formPrefillData.email : '',
            address: formPrefillData ? formPrefillData.address : '',
            city: formPrefillData ? formPrefillData.city : '',
            state: formPrefillData ? formPrefillData.state : '',
            country: formPrefillData ? formPrefillData.country : '',
            block:formPrefillData ? formPrefillData?.addressBlock?.block || "" : 'B-',
            blockNumber:formPrefillData ? formPrefillData?.addressBlock?.number || "" : '',
            services: formPrefillData?.services ? formPrefillData.services : [],

            // description: formPrefillData ? formPrefillData.description : '',
            subscriptionPlans: formPrefillData ? formPrefillData.subscription?.name : '',
            startDate: formPrefillData ? new Date(formPrefillData.subscription?.startDate) : null,
            expiryDate: formPrefillData ? new Date(formPrefillData.subscription?.expiryDate) : null,
            hospitalPermit: formPrefillData ? formPrefillData.permit : '',
            startTime: formPrefillData ? new Date(formPrefillData.shift.startTime) : null,
            endTime: formPrefillData ? new Date(formPrefillData.shift.endTime) : null
        },
        enableReinitialize: true,
        validationSchema: hospitalRegistrationValidationSchema,
        onSubmit: (values) => {
            console.log({values})
            setShowLoader((prevState) => {
                return true
            });
            console.log({ values })
            if (!workingDaysError) {
                Promise.all([
                    new Promise((resolve, reject) => {
                        if (typeof values.hospitalLogo !== "string") {
                            const response = uploadFileService.fileUpload(values.hospitalLogo)
                            if (response) {
                                resolve(response)
                            } else {
                                reject()
                            }
                        } else {
                            resolve()
                        }
                    }),
                    new Promise((resolve, reject) => {
                        if (typeof values.hospitalPermit !== "string") {
                            console.log({ permit: values.hospitalPermit })
                            const response = uploadFileService.fileUpload(values.hospitalPermit)
                            if (response) {
                                resolve(response)
                            } else {
                                reject()
                            }
                        } else {
                            resolve()
                        }
                    })
                ]).then(async ([res1, res2]) => {
                    if (res1 || res2) {
                        console.log({ res1, res2 })
                        const payload = {
                            ...values,
                            hospitalType: values.hospitalType.join(","),
                            hospitalLogo: res1 ? res1.fileUrl : values.hospitalLogo,
                            hospitalPermit: res2 ? res2.fileUrl : values.hospitalPermit,
                            workingDays: workingDays,
                            addressBlock:{
                                block:"B-",
                                number:values.blockNumber
                            },
                            location: {
                                coordinates: [position.lat, position.lng]
                            }

                        };
                        console.log({ params })
                        if (params.id) {
                            hospitalRegistrationService.hospitalUpdate(payload, params.id).then((res) => {
                                if (res) {
                                    console.log('response of hospital registration is', res);
                                    navigate('/dashboard/hospitals');
                                    toast.success('Hospital details updated successfully.');
                                }
                                setShowLoader((prevState) => {
                                    return false
                                });
                            })
                        } else {
                            hospitalRegistrationService.hospitalRegister(payload).then((res) => {
                                if (res) {
                                    console.log('response of hospital registration is', res);
                                    navigate('/dashboard/hospitals');
                                    toast.success('Hospital registered successfully and mail sent');
                                }
                                setShowLoader((prevState) => {
                                    return false
                                });
                            })
                        }
                    } else {
                        const payload = {
                            ...values,
                            hospitalType: values.hospitalType.join(","),
                            hospitalLogo: values.hospitalLogo,
                            hospitalPermit: values.hospitalPermit,
                            workingDays: workingDays,
                            addressBlock:{
                                block:"B-",
                                number:values.blockNumber
                            },
                            location: {
                                coordinates: [position.lat, position.lng]
                            }
                        };
                        if (params.id) {
                            hospitalRegistrationService.hospitalUpdate(payload, params.id).then((res) => {
                                if (res) {
                                    console.log('response of hospital registration is', res);
                                    navigate('/dashboard/hospitals');
                                    toast.success('Hospital details updated successfully.');
                                }
                                setShowLoader((prevState) => {
                                    return false
                                });
                            })
                        }
                    }
                })
            } else {
                setShowLoader((prevState) => {
                    return false
                });
            }
        }
    });
    console.log({err:hospitalRegistrationForm.errors})
    const hospitalImageInputChangeHandler = (event) => {
        console.log(':Image ur;', event.currentTarget.files[0]);
        if (event.currentTarget.files.length !== 0) {
            const file = event.currentTarget.files[0];
            console.log({ file })
            if (file.size > 1024000) {
                hospitalRegistrationForm.setErrors({
                    hospitalLogo: "File size is exceeding the limit 1mb ",
                });
                // }
            } else {
                hospitalRegistrationForm.handleChange(event);
                hospitalRegistrationForm.setFieldValue("hospitalLogo", event.currentTarget.files[0] ? event.currentTarget.files[0] : '')
                setHospitalImageUrl((prevState) => {
                    return URL.createObjectURL(event.target.files[0])
                });
            }
        } else {
            hospitalRegistrationForm.setErrors({
                hospitalLogo: "Hospital Logo is required!",
            });
        }
    }

    const HospitalResetImageInput = () => {
        hospitalRegistrationForm.setFieldValue('hospitalLogo', '');
        setHospitalImageUrl((prevState) => {
            return ''
        });
    }
    // useEffect(()=>{
    //         const places =  new window.google.maps.places.SearchBox("San")
    //         console.log({places:places.getPlaces()})

    // },[])
    const hospitalPermitInputChangeHandler = (event) => {
        console.log("Image given--", event.currentTarget.files[0]);
        if (event.currentTarget.files.length !== 0) {
            const file = event.currentTarget.files[0];
            console.log({ file })
            if (file.size > 1024000) {
                hospitalRegistrationForm.setErrors({
                    hospitalPermit: "File size is exceeding the limit 1mb ",
                });
                // }
            } else {
                hospitalRegistrationForm.setFieldValue("hospitalPermit", event.currentTarget.files[0] ? event.currentTarget.files[0] : '')
                setHospitalPermitUrl((prevState) => {
                    return URL.createObjectURL(event.target.files[0])
                });
            }
        } else {
            hospitalRegistrationForm.setErrors({ hospitalPermit: 'Hospital Permit is required!' });
        }
    }

    const HospitalResetPermitInput = () => {
        hospitalRegistrationForm.setFieldValue('hospitalPermit', '');
        setHospitalPermitUrl((prevState) => {
            return ''
        });
    }

    const handleLocationChange = ({ position, address, places }) => {
        // Set new location
        setPosition({
            lat: position.lat,
            lng: position.lng,
        });
    }

    const getWorkingDays = (days) => {
        setWorkingDays((prevState) => {
            if (days.length === 0) {
                setWorkingDaysError((prevState) => {
                    return true
                })
            } else {
                setWorkingDaysError((prevState) => {
                    return false
                })
            }
            return days
        })
    }
    const [locatons, setLocations] = useState({
        country: [],
        city: [],
        state: []
    });

    useEffect(() => {
        const apiData = async () => {
            const result = await LocationService.getLocations(hospitalRegistrationForm.values.country, hospitalRegistrationForm.values.state);
            console.log({ result })
            const { data } = result;
            let cities = [...data[0].city];
            let states = [...data[0].state];
            let country = [...data[0].country];
            // for await (let element of data){
            //     if(!cities.includes(element.city)){
            //         cities.push(element.city)
            //     }
            //     if(!states.includes(element.state)){
            //         states.push(element.state)
            //     }
            //     if(!country.includes(element.country)){
            //         country.push(element.country)
            //     }


            // }
            const locations = { ...locatons };
            locations.city = cities;
            locations.state = states;
            locations.country = country
            setLocations(locations)
        }
        apiData()
    }, [hospitalRegistrationForm.values.country, hospitalRegistrationForm.values.state])
    useEffect(() => {
        if (params.id) {
            setShowLoader(true);
            getHospitalById(params.id).then((res) => {
                console.log('res by id  by AKash===>', res);
                setHospitalImageUrl((prevState) => {
                    return res.data.picture
                })
                setHospitalPermitUrl((prevState) => {
                    return res.data.permit
                })
                setWorkingDays((prevState) => {
                    return res?.data?.workingDays
                })
                setFormPrefillData((prevState) => {
                    return res?.data
                })
                setPosition((prevState) => {
                    return { lng: res?.data?.location?.coordinates[1], lat: res?.data?.location?.coordinates[0] }
                })
            });
            setShowLoader(false)
        }
    }, []);
    useEffect(() => {
        getSpecialist().then(res => {
            console.log({ res })
            setSpeciality(res.data)
        })
    }, [])

    function handleChange(event) {
        const trimmedValue = event.target.value.trim();

        if (trimmedValue) {
            const newSelectedItem = [...hospitalRegistrationForm.values.services.selectedItem];
            const values = trimmedValue.split(" ");

            values.forEach((value) => {
                if (newSelectedItem.indexOf(value) === -1) {
                    newSelectedItem.push(value);
                }
            });

            hospitalRegistrationForm.setFieldValue("services", newSelectedItem);
        }

        setInputValue("");
    }

    const handleDelete = (item) => () => {
        const newSelectedItem = hospitalRegistrationForm.values.services.filter((service) => service !== item);
        hospitalRegistrationForm.setFieldValue("services", newSelectedItem);
    };

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }
    function handleKeyDown(event) {
        // ;
        // event.stopPropagation()
        const { setFieldValue } = hospitalRegistrationForm
        if (event.key === "Enter") {
            event.preventDefault();
            const newSelectedItem = [...hospitalRegistrationForm.values.services];
            const trimmedValue = event.target.value.trim();

            if (trimmedValue && newSelectedItem.indexOf(trimmedValue) === -1) {
                newSelectedItem.push(trimmedValue);
            }

            setFieldValue("services", newSelectedItem);
            setInputValue("");
        }

        if (
            hospitalRegistrationForm.values.services.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
            const newSelectedItem = hospitalRegistrationForm.values.services.slice(0, hospitalRegistrationForm.values.services.length - 1);
            setFieldValue("services", newSelectedItem);
        }
    }
    return (
        <Card cname="px-10">
            <div className="my-10">
                <h1 className="text-3xl font-bold">{params.id ? 'Update' : 'Register'}</h1>
                <p className="text-xl font-semibold text-gray-500 mt-2">You
                    can {params.id ? 'update' : 'register'} hospitals, branches,
                    clinics</p>
            </div>
            <form onSubmit={hospitalRegistrationForm.handleSubmit} className="grid grid-cols-12 gap-1 sm:gap-5">
                {/*Registration Type input field*/}
                <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5">
                    <TextField
                        fullWidth
                        id="registerType"
                        label="Hospital"
                        name="registerType"
                        value="Hospital"
                        disabled
                        select>
                        <MenuItem value="Hospital">Hospital</MenuItem>
                    </TextField>
                </div>

                {/*Basic Details Input field*/}
                <div className="col-span-12 mt-4">
                    <h1 className="text-xl font-semibold">Basic Details</h1>
                    {/*Hospital name input field*/}
                    <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
                        <div className="col-span-12 md:col-span-6">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="hospitalName"
                                name="hospitalName"
                                label="Enter hospital name"
                                helperText={hospitalRegistrationForm.touched.hospitalName && Boolean(hospitalRegistrationForm.errors.hospitalName) ? hospitalRegistrationForm.errors.hospitalName : ''}
                                value={hospitalRegistrationForm.values.hospitalName}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.hospitalName && Boolean(hospitalRegistrationForm.errors.hospitalName)}
                            />
                        </div>
                        {/* <div className="col-span-12 md:col-span-6">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="hospitalType"
                                name="hospitalType"
                                label="Enter Hospital Speciality"
                                helperText={hospitalRegistrationForm.touched.hospitalType && Boolean(hospitalRegistrationForm.errors.hospitalType) ? hospitalRegistrationForm.errors.hospitalType : ''}
                                value={hospitalRegistrationForm.values.hospitalType}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.hospitalType && Boolean(hospitalRegistrationForm.errors.hospitalType)}
                            />
                        </div> */}
                        <div className="col-span-12 md:col-span-6">
                            <FormControl className="w-full">
                                <InputLabel id="hospitalType" className="bg-white">
                                    Enter Hospital Speciality
                                </InputLabel>

                                <Select
                                    fullWidth
                                    labelId="hospitalType"
                                    id="hospitalType"
                                    multiple
                                    className="w-full"
                                    name="hospitalType"
                                    error={hospitalRegistrationForm.touched.hospitalType && Boolean(hospitalRegistrationForm.errors.hospitalType)}
                                    helperText={
                                        hospitalRegistrationForm.touched.hospitalType &&
                                            Boolean(hospitalRegistrationForm.errors.hospitalType)
                                            ? hospitalRegistrationForm.errors.hospitalType
                                            : ""
                                    }
                                    value={hospitalRegistrationForm.values.hospitalType}
                                    onBlur={hospitalRegistrationForm.handleBlur}
                                    onChange={hospitalRegistrationForm.handleChange}
                                    input={
                                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                                    }

                                    renderValue={(selected) => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {speciality.map(ele => <MenuItem key={ele._id} value={ele.specialityName}>
                                        {ele.specialityName}
                                    </MenuItem>)}


                                </Select>
                                
                            </FormControl>
                        </div>
                        <div className="col-span-12 md:col-span-6">

                            <FormControl className="w-full">
                                <TextField
                                    label="Services"
                                    placeholder='Add Services'
                                    error={hospitalRegistrationForm.touched.services && Boolean(hospitalRegistrationForm.errors.services)}
                                    InputProps={{
                                        // startAdornment: hospitalRegistrationForm.values.services.map((item) => (
                                        //     <Chip
                                        //         key={item}
                                        //         tabIndex={-1}
                                        //         label={item}
                                        //         onDelete={handleDelete(item)}
                                        //     />
                                        // )),

                                        onChange: (event) => {
                                            // event.preventDefault()
                                            handleInputChange(event);
                                            handleChange(event.target.value);
                                        },
                                    }}
                                    onKeyDown={handleKeyDown}
                                    value={inputValue}
                                    fullWidth
                                    variant="outlined"
                                    id="services"
                                    name="services"
                                    helperText={
                                        hospitalRegistrationForm.touched.services && Boolean(hospitalRegistrationForm.errors.services)
                                            ? hospitalRegistrationForm.errors.services
                                            : ""
                                    }
                                    onBlur={hospitalRegistrationForm.handleBlur}
                                />
                                <div className="my-2 flex gap-2 flex-wrap">
                {hospitalRegistrationForm.values.services.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        onDelete={handleDelete(item)}
                      />
                    ))}
                </div>
                            </FormControl>
                        </div>
                    </div>
                    {/* <div className="col-span-12 md:col-span-4">
              <FormControl className="w-full">
                <InputLabel id="speciality" className="bg-white">
                  Speciality
                </InputLabel>

                <Select
                  fullWidth
                  labelId="speciality"
                  id="specialist"
                  className="w-full"
                  multiple
                  label="Enter Speciality"
                  name="specialist"
                  helperText={
                    doctorsRegistrationForm.touched.specialist &&
                    Boolean(doctorsRegistrationForm.errors.specialist)
                      ? doctorsRegistrationForm.errors.specialist
                      : ""
                  }
                  value={doctorsRegistrationForm.values.specialist}
                  onBlur={doctorsRegistrationForm.handleBlur}
                  onChange={doctorsRegistrationForm.handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {speciality.map((ele) => (
                    <MenuItem key={ele._id} value={ele.specialityName}>
                      {ele.specialityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
                    {/*Hospital permit and logo input field*/}
                    <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-5">
                        {/*Hospital logo input field */}
                        <div className="col-span-12">

                            {/*Image upload hospital logo*/}
                            <div className="flex flex-col justify-center items-center max-w-full">
                                <label className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${hospitalRegistrationForm.errors.hospitalLogo
                                        ? 'border-[#d32f2f]' : 'border-gray-300'}
                         border-dashed cursor-pointer`}>
                                    <input
                                        id="dropzone-file"
                                        name="hospitalLogo"
                                        disabled={hospitalImageUrl !== ''}
                                        accept="image/png, image/jpeg, image/jpg, image/webp"
                                        onBlur={hospitalRegistrationForm.handleBlur}
                                        onChange={hospitalImageInputChangeHandler}
                                        type="file"
                                        className="hidden" />
                                    {hospitalImageUrl !== '' ? (
                                        <div className="relative w-24 h-24">
                                            <img id="blah" src={hospitalImageUrl}
                                                className="shadow w-full object-fill"
                                                alt="your" />
                                            <CloseIcon onClick={HospitalResetImageInput}
                                                className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold" />
                                        </div>
                                    ) : (
                                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                            <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400"
                                                fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                className="font-semibold">Click to upload hospital logo</span> or
                                                drag
                                                and drop</p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG,
                                                JPG or GIF
                                                (MAX.
                                                800x400px)</p>
                                        </div>
                                    )}
                                </label>
                            </div>
                            <div
                                className={`text-[#d32f2f] my-2 text-sm ml-4 ${hospitalRegistrationForm.errors.hospitalLogo ? '' : 'hidden'}`}>
                                {hospitalRegistrationForm.errors.hospitalLogo}
                            </div>

                        </div>
                    </div>
                </div>

                {/*Contact Details input field*/}
                <div className="col-span-12 mt-4">
                    <h1 className="text-xl font-semibold">Contact Details</h1>
                    <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
                        <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Enter phone number"
                                helperText={hospitalRegistrationForm.touched.phoneNumber && Boolean(hospitalRegistrationForm.errors.phoneNumber) ? hospitalRegistrationForm.errors.phoneNumber : ''}
                                value={hospitalRegistrationForm.values.phoneNumber}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.phoneNumber && Boolean(hospitalRegistrationForm.errors.phoneNumber)}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="alternatePhoneNumber"
                                name="alternatePhoneNumber"
                                label="Enter alternate phone number"
                                helperText={hospitalRegistrationForm.touched.alternatePhoneNumber && Boolean(hospitalRegistrationForm.errors.alternatePhoneNumber) ? hospitalRegistrationForm.errors.alternatePhoneNumber : ''}
                                value={hospitalRegistrationForm.values.alternatePhoneNumber}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.alternatePhoneNumber && Boolean(hospitalRegistrationForm.errors.alternatePhoneNumber)}
                            />
                        </div>


                        <div className="col-span-12 md:col-span-6">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="email"
                                name="email"
                                label="Enter your email"
                                helperText={hospitalRegistrationForm.touched.email && Boolean(hospitalRegistrationForm.errors.email) ? hospitalRegistrationForm.errors.email : ''}
                                value={hospitalRegistrationForm.values.email}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.email && Boolean(hospitalRegistrationForm.errors.email)}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-2 md:mt-8">
                        <div className="col-span-12 md:col-span-6">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="address"
                                name="address"
                                label="Enter address"
                                helperText={hospitalRegistrationForm.touched.address && Boolean(hospitalRegistrationForm.errors.address) ? hospitalRegistrationForm.errors.address : ''}
                                value={hospitalRegistrationForm.values.address}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.address && Boolean(hospitalRegistrationForm.errors.address)}
                            />
                        </div>
                        <div className="col-span-6 md:col-span-3 ">
                            {/* <InputLabel>B-</InputLabel> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="blockNumber"
                                name="blockNumber"
                                label="Enter Block Number"
                                helperText={hospitalRegistrationForm.touched.blockNumber && Boolean(hospitalRegistrationForm.errors.blockNumber) ? hospitalRegistrationForm.errors.blockNumber : ''}
                                value={`${hospitalRegistrationForm.values.block || "B-"}${hospitalRegistrationForm.values.blockNumber}`}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={(e)=>{
                                    const values = e.target.value.split("-");
                                    e.target.value = values[1] || "";
                                    hospitalRegistrationForm.handleChange(e)
                                }}
                                // InputProps={{
                                //     inputComponent: blockElement,
                                // }}
                                error={hospitalRegistrationForm.touched.blockNumber && Boolean(hospitalRegistrationForm.errors.blockNumber)}
                            />
                        </div>

                        {/* <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="city"
                                name="city"
                                label="Enter City"
                                helperText={hospitalRegistrationForm.touched.city && Boolean(hospitalRegistrationForm.errors.city) ? hospitalRegistrationForm.errors.city : ''}
                                value={hospitalRegistrationForm.values.city}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.city && Boolean(hospitalRegistrationForm.errors.city)}
                            />
                        </div> */}
                        <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                id="country"
                                label="Enter Country"
                                name="country"
                                helperText={hospitalRegistrationForm.touched.country && Boolean(hospitalRegistrationForm.errors.country) ? hospitalRegistrationForm.errors.country : ''}
                                value={hospitalRegistrationForm.values.country}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.country && Boolean(hospitalRegistrationForm.errors.country)}
                                select>
                                {locatons.country.map(ele => {
                                    return (
                                        <MenuItem value={ele}>{ele}</MenuItem>
                                    )
                                })}
                                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
                            </TextField>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                id="state"
                                label="Enter State"
                                name="state"
                                helperText={hospitalRegistrationForm.touched.state && Boolean(hospitalRegistrationForm.errors.state) ? hospitalRegistrationForm.errors.state : ''}
                                value={hospitalRegistrationForm.values.state}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.state && Boolean(hospitalRegistrationForm.errors.state)}
                                select>
                                {locatons.state.map(ele => {
                                    return (
                                        <MenuItem value={ele}>{ele}</MenuItem>
                                    )
                                })}
                                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
                            </TextField>
                        </div>


                        <div className="col-span-12 md:col-span-3">
                            <TextField
                                fullWidth
                                id="city"
                                label="Enter City"
                                name="city"
                                helperText={hospitalRegistrationForm.touched.city && Boolean(hospitalRegistrationForm.errors.city) ? hospitalRegistrationForm.errors.city : ''}
                                value={hospitalRegistrationForm.values.city}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.city && Boolean(hospitalRegistrationForm.errors.city)}
                                select>
                                {locatons.city.map(ele => {
                                    return (
                                        <MenuItem value={ele}>{ele}</MenuItem>
                                    )
                                })}
                                {/* <MenuItem value="Platinum">Platinum</MenuItem> */}
                            </TextField>
                        </div>
                        



                        {/* <div className="col-span-12">
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="description"
                                name="description"
                                label="Description"
                                helperText={hospitalRegistrationForm.touched.description && Boolean(hospitalRegistrationForm.errors.description) ? hospitalRegistrationForm.errors.description : ''}
                                value={hospitalRegistrationForm.values.description}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.description && Boolean(hospitalRegistrationForm.errors.description)}
                                multiline
                                rows={3}
                            />
                        </div> */}
                    </div>
                </div>

                {/*Hospital Location Details Input map field*/}
                <div className="col-span-12 mt-4">
                    <h1 className="text-xl font-semibold">Hospital location</h1>
                    <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
                        <div className="col-span-12 relative">
                            <div className='absolute left-56 top-[10px] z-50'>

                                <SearchBox
                                setPosition={setPosition}
                                  />
                            </div>

                            {/*<img src="https://i.postimg.cc/qRF5QCjR/map-register.png" alt="map"*/}
                            {/*     className="w-full-object-fill"/>*/}
                            <LocationPicker

                                containerElement={<div style={{ height: '100%' }} />}
                                mapElement={<div style={{ height: '400px' }} />}
                                defaultPosition={position}
                                onChange={handleLocationChange}
                            />
                        </div>
                    </div>
                </div>

                {/*Plans & Permits Details Input map field*/}
                <div className="col-span-12 mt-4">
                    <h1 className="text-xl font-semibold">Plans & Permits</h1>
                    <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
                        <div className="col-span-12 md:col-span-6">
                            <TextField
                                fullWidth
                                id="subscriptionPlans"
                                label="Subscription Plans"
                                name="subscriptionPlans"
                                helperText={hospitalRegistrationForm.touched.subscriptionPlans && Boolean(hospitalRegistrationForm.errors.subscriptionPlans) ? hospitalRegistrationForm.errors.subscriptionPlans : ''}
                                value={hospitalRegistrationForm.values.subscriptionPlans}
                                onBlur={hospitalRegistrationForm.handleBlur}
                                onChange={hospitalRegistrationForm.handleChange}
                                error={hospitalRegistrationForm.touched.subscriptionPlans && Boolean(hospitalRegistrationForm.errors.subscriptionPlans)}
                                select>
                                <MenuItem value="Gold">Gold</MenuItem>
                                <MenuItem value="Silver">Silver</MenuItem>
                                <MenuItem value="Platinum">Platinum</MenuItem>
                            </TextField>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    name="startDate"
                                    helperText={hospitalRegistrationForm.touched.startDate && Boolean(hospitalRegistrationForm.errors.startDate) ? hospitalRegistrationForm.errors.startDate : ''}
                                    value={hospitalRegistrationForm.values.startDate}
                                    onBlur={hospitalRegistrationForm.handleBlur}
                                    onChange={(value) => {
                                        hospitalRegistrationForm.setFieldValue('startDate', Date.parse(value))
                                    }}
                                    error={hospitalRegistrationForm.touched.startDate && Boolean(hospitalRegistrationForm.errors.startDate)}
                                    renderInput={(params) => <TextField fullWidth name="startDate"
                                        error={hospitalRegistrationForm.touched.startDate && Boolean(hospitalRegistrationForm.errors.startDate)}
                                        helperText={hospitalRegistrationForm.touched.startDate && Boolean(hospitalRegistrationForm.errors.startDate) ? 'Start Date is required' : ''}
                                        onBlur={hospitalRegistrationForm.handleBlur} {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Expiry Date"
                                    name="expiryDate"
                                    minDate={hospitalRegistrationForm.values.startDate}
                                    helperText={hospitalRegistrationForm.touched.expiryDate && Boolean(hospitalRegistrationForm.errors.expiryDate) ? hospitalRegistrationForm.errors.expiryDate : ''}
                                    value={hospitalRegistrationForm.values.expiryDate}
                                    onBlur={hospitalRegistrationForm.handleBlur}
                                    error={hospitalRegistrationForm.touched.expiryDate && Boolean(hospitalRegistrationForm.errors.expiryDate)}
                                    onChange={(value) => {
                                        hospitalRegistrationForm.setFieldValue('expiryDate', Date.parse(value))
                                    }}
                                    renderInput={(params) => <TextField style={{ width: '100%' }}
                                        helperText={hospitalRegistrationForm.touched.expiryDate && Boolean(hospitalRegistrationForm.errors.expiryDate) ? 'Expiry date is required' : ''}
                                        value={hospitalRegistrationForm.values.expiryDate}
                                        onBlur={hospitalRegistrationForm.handleBlur}
                                        error={hospitalRegistrationForm.touched.expiryDate && Boolean(hospitalRegistrationForm.errors.expiryDate)}
                                        {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-span-12">
                            {/*Hospital permit file upload*/}
                            <div className="col-span-12">

                                {/*Hospital permit image upload*/}
                                <div className="flex flex-col justify-center items-center max-w-full">
                                    <label
                                        className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${hospitalRegistrationForm.errors.hospitalPermit ? 'border-[#d32f2f]' : 'border-gray-300'}
                                        border-dashed cursor-pointer`}>
                                        <input
                                            ref={hospitalPermitInputref}
                                            id="dropzone-file"
                                            name="hospitalPermit"
                                            disabled={hospitalPermitUrl !== ''}
                                            accept="image/png, image/jpeg, image/jpg"
                                            onBlur={hospitalRegistrationForm.handleBlur}
                                            onChange={hospitalPermitInputChangeHandler}
                                            type="file"
                                            className="hidden" />
                                        {hospitalPermitUrl !== '' ? (
                                            <div className="relative w-24 h-24">
                                                <img id="blah" src={hospitalPermitUrl}
                                                    className="shadow w-full object-fill"
                                                    alt="your" />
                                                <CloseIcon onClick={HospitalResetPermitInput}
                                                    className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold" />
                                            </div>
                                        ) : (
                                            <div
                                                className="flex flex-col justify-center items-center pt-5 pb-6">
                                                <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400"
                                                    fill="none"
                                                    stroke="currentColor" viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                                    className="font-semibold">Click to upload hospital permit</span> or
                                                    drag and drop</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG,
                                                    PNG, JPG or
                                                    GIF
                                                    (MAX.
                                                    800x400px)</p>
                                            </div>
                                        )}
                                    </label>
                                </div>
                                <div
                                    className={`text-[#d32f2f] my-2 text-sm ml-4 ${hospitalRegistrationForm.errors.hospitalPermit ? '' : 'hidden'}`}>
                                    {hospitalRegistrationForm.errors.hospitalPermit}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Shift details*/}
                <div className="col-span-12">
                    <h1 className="text-xl font-semibold my-5">Shift details</h1>
                    <div className="grid grid-cols-12 gap-2 md:gap-5 lg:gap-8 xl:gap-10 mt-3">
                        <div className="col-span-12 md:col-span-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label="Start Time"
                                    name="startTime"
                                    helperText={hospitalRegistrationForm.touched.startTime && Boolean(hospitalRegistrationForm.errors.startTime) ? hospitalRegistrationForm.errors.startTime : ''}
                                    value={hospitalRegistrationForm.values.startTime}
                                    onBlur={hospitalRegistrationForm.handleBlur}
                                    error={hospitalRegistrationForm.touched.startTime && Boolean(hospitalRegistrationForm.errors.startTime)}
                                    onChange={(value) => {
                                        hospitalRegistrationForm.setFieldValue('startTime', Date.parse(value))
                                    }}
                                    renderInput={(params) => <TextField style={{ width: '100%' }}
                                        helperText={hospitalRegistrationForm.touched.startTime && Boolean(hospitalRegistrationForm.errors.startTime) ? 'Start Time is required' : ''}
                                        value={hospitalRegistrationForm.values.startTime}
                                        onBlur={hospitalRegistrationForm.handleBlur}
                                        error={hospitalRegistrationForm.touched.startTime && Boolean(hospitalRegistrationForm.errors.startTime)}
                                        {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label="End Time"
                                    name="endTime"
                                    helperText={hospitalRegistrationForm.touched.endTime && Boolean(hospitalRegistrationForm.errors.endTime) ? hospitalRegistrationForm.errors.endTime : ''}
                                    value={hospitalRegistrationForm.values.endTime}
                                    onBlur={hospitalRegistrationForm.handleBlur}
                                    error={hospitalRegistrationForm.touched.endTime && Boolean(hospitalRegistrationForm.errors.endTime)}
                                    onChange={(value) => {
                                        hospitalRegistrationForm.setFieldValue('endTime', Date.parse(value));
                                    }}
                                    renderInput={(params) => <TextField style={{ width: '100%' }}
                                        helperText={hospitalRegistrationForm.touched.endTime && Boolean(hospitalRegistrationForm.errors.endTime) ? 'End Time is required' : ''}
                                        value={hospitalRegistrationForm.values.endTime}
                                        onBlur={hospitalRegistrationForm.handleBlur}
                                        error={hospitalRegistrationForm.touched.endTime && Boolean(hospitalRegistrationForm.errors.endTime)}
                                        {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            {workingDays && <Toggledays handleWorkingDays={getWorkingDays} workingDays={workingDays} />}
                            {workingDaysError && (
                                <div className="text-red-500">
                                    Working days is required
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/*Submit button*/}
                <Filledbluebutton cname="w-32" btnType="submit" disabledBtn={!hospitalRegistrationForm.isValid}>
                    {params.id ? 'Update' : 'Submit'}
                </Filledbluebutton>
            </form>
        </Card>
    );
};

export default Hospitalregistration;

import React,{useState,useContext,useEffect, useRef,useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import {pharmacyOutHouseDetailsTableData} from "../../../../core/constants/appconstants";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {AgGridReact} from "ag-grid-react";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import {UserContext} from "../../../../App";
import pharmacyService from "../../../../core/services/Data/pharmacyService";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from 'react-toastify'

const Pharmacyouthouse = () => {
    const navigate = useNavigate();
    const gridRef = useRef(null);
    const {setShowLoader} = useContext(UserContext);
    const [pharmacyTableDate, setPharmacyTableDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, [])
    const getChemistsData = () => {
        setShowLoader(true)
        pharmacyService.getOuthousePharmacy().then((res) => {
            if (res) {
                setPharmacyTableDate(res.data);
                console.log('res for chemists table data=====>', res);
            }
            setShowLoader(false);
        });
    };

    const [columnDefs] = useState([
        {headerName: "Image", field: "picture", cellRenderer: Imagerenderer},
        {headerName: "Chemist", field: "name"},
        {headerName: "Email", field: "email"},
        // {headerName: "Branch", field: "branchName"},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/pharmacy/out-house/add-form/' + id);
                    console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    setIdToDelete((prevState) => {
                        return id
                    })
                    // console.log('id', id)
                }
            }
        }
    ]);
    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }
    useEffect(() => {
        getChemistsData();
    }, []);
    const deletePharmacy = ()=>{
        pharmacyService.deleteOutPharmacy(idToDelete).then((res) => {
            if(res){
                toast.success(res.message);
                getChemistsData()
            }
        })
        // console.log('working delete')
    }

    const pharmacyOutHouseDetails = pharmacyOutHouseDetailsTableData;
    return (
        <>
            <div className="flex justify-end mb-3">
                <div className="order-last">
                    {/* <button type="button"
                            className="mx-4 inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by branch
                    </button>
                    <button type="button"
                            className="inline-block px-6 py-2 border-2 border-gray-700 text-gray-800 font-medium text-xs
                             leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none
                              focus:ring-0 transition duration-150 ease-in-out">Filter by chemist
                    </button> */}
                </div>
            </div>
            <div className="mt-10">
                <Card>
                <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deletePharmacy}/>
                    <div className="flex justify-between mb-3">
                        <div className="order-first">
                            <p className="text-2xl font-semibold">
                                Pharmacy OutHouse
                            </p>
                        </div>
                        <div className="order-last">
                            <Outlinedbluebutton download={onBtnExport}/>
                            <Filledbluebutton cname="ml-4" clicked={() => {
                                navigate('/dashboard/pharmacy/out-house/add-form')
                            }}>
                                Add Pharmacy
                            </Filledbluebutton>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={pharmacyTableDate}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Pharmacyouthouse;

import * as yup from "yup";
import {phoneNumberRegex} from "../../constants/appconstants";

export const medicineFormSchema = yup.object({
    pharmacyName: yup.string().required('Pharmacy Name is required'),
    pharmacyLogo: yup.string().required('Pharmacy Logo is required'),
    pharmacyPermit: yup.string().required('Pharmacy Permit is required'),
    phoneNumber: yup.string('Enter your Email').required('Phone number is required'),
    alternatePhoneNumber: yup.string('Enter your Email').nullable(""),
    email: yup.string('Enter your Email').required('Email is required').email('Please enter a valid email'),
    address: yup.string('Enter your Email').required('Address is required'),
    city: yup.string('Enter your Email').required('City is required'),
    state: yup.string('Enter your Email').required('State is required'),
    country: yup.string('Enter your Email').required('Country is required'),
});

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Mapuserhospitalhome from "./Mapuserhospitalhome/Mapuserhospitalhome";
import Mapuserhospitalform from "./Mapuserhospitalform/Mapuserhospitalform";

const Mapuserhospital = (props) => {
    return (
        <Routes>
            <Route path="" element={<Mapuserhospitalhome/>}/>
            <Route path="/registration" element={<Mapuserhospitalform/>}/>
            <Route path="/registration/:id" element={<Mapuserhospitalform/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

export default Mapuserhospital;

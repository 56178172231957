import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Promotionhome from "./Promotionhome/Promotionhome";
import Addform from "./Addform/Addform";

const Promotions = () => {
    return (
        <Routes>
            <Route path="" element={<Promotionhome/>}/>
            <Route path="add-promotion" element={<Addform/>}/>
            <Route path="add-promotion/update/:id" element={<Addform/>}/>
            <Route path="add-banner" element={<Addform/>}/>
            <Route path="add-banner/update/:id" element={<Addform/>}/>
            <Route path="add-advertisement" element={<Addform/>}/>
            <Route path="add-advertisement/update/:id" element={<Addform/>}/>
            <Route path="*" element={<Navigate to=""/>}/>
        </Routes>
    );
};

export default Promotions;

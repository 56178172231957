import React from 'react';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const Editdeletebutton = (props) => {
    console.log('props.data', props.data)
    return (
        <>
            <ModeEditIcon
                className={`text-[#0098C4] border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.editClicked(props.data._id)
                    }}/>
            <DeleteOutlineOutlinedIcon
                onClickCapture={(e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                    props.deleteClicked(props.data._id)
                }}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.deleteClicked(props.data._id)
                }}
                className={`text-red-500 ${props.cname} border border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110 ml-2`}/>
        </>
    );
};

export default Editdeletebutton;

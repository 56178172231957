import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Specialityhome from "./Specialityhome/Specialityhome";
import Specialityaddform from "./Specialityaddform/Specialityaddform";
import Specialityassignform from "./Specialityassignform/Specialityassignform";
import DoctorAssign from "./Specialityassignform/DoctorAssign";

const Speciality = () => {
    return (
        <Routes>
            <Route path="" element={<Specialityhome/>}/>
            <Route path="/add-speciality" element={<Specialityaddform/>}/>
            <Route path="/add-speciality/:id" element={<Specialityaddform/>}/>
            <Route path="/assign-speciality" element={<Specialityassignform/>}/>
            <Route path="/assign-speciality/adddoctor/:id" element={<Specialityassignform/>}/>
            <Route path="/assign-speciality/:id" element={<DoctorAssign/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

export default Speciality;

import React, {Fragment} from 'react';
import TextField from "@mui/material/TextField";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {loginValidationSchema} from "../../../core/Validationschemas/Schedule";
import {login} from "../../../core/services/User/Login/login";
import PassTextField from '../../utils/passwordTextfield/passwordTextfield';
import jwtDecode from "jwt-decode";
import {toast} from "react-toastify";

const Login = (props) => {
    const navigate = useNavigate();
    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginValidationSchema,
        onSubmit: (values) => {
            login(loginForm.values.email, loginForm.values.password).then((token) => {
                console.log('token aftersuccessfull login is', token);
                console.log('decoded ===>', jwtDecode(token));
                toast.success('You are successfully logged In');
                navigate('/dashboard');
                props.setUserDetails(jwtDecode(token));
            });
        }
    })
    return (
        <Fragment>
            <div className="py-5">
                <h3 className="text-2xl text-start text-gray-500">Welcome back</h3>
                <h1 className="text-4xl font-bold">Login to your account</h1>
            </div>
            <form onSubmit={loginForm.handleSubmit}>
                <div className="my-5">
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="email"
                        name="email"
                        label="Enter your email"
                        value={loginForm.values.email}
                        onChange={loginForm.handleChange}
                        onBlur={loginForm.handleBlur}
                        helperText={loginForm.touched.email && Boolean(loginForm.errors.email) ? 'Email is required' : ''}
                        error={loginForm.touched.email && Boolean(loginForm.errors.email)}
                    />
                </div>
                <div className="my-5">
                    <PassTextField
                        className="w-full"
                        variant="outlined"
                        name="password" 
                        id="password"
                        type="password"
                        label="Enter your password"
                        value={loginForm.values.password}
                        onChange={loginForm.handleChange}
                        onBlur={loginForm.handleBlur}
                        helperText={loginForm.touched.password && Boolean(loginForm.errors.password) ? 'Password is required' : ''}
                        error={loginForm.touched.password && Boolean(loginForm.errors.password)}
                    />


                    

                </div>
                <Link to="/login/forgot-password"
                      className="flex justify-end text-gray-600 cursor-pointer hover:underline">
                    Forgot Password ?
                </Link>
                <button
                    type="submit"
                    className="text-white rounded w-full my-4 uppercase app-button focus:outline-none focus:ring-4
                 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 mr-2 mb-2">
                    Login
                </button>
            </form>
        </Fragment>
    );
};

export default Login;

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Pharmacyhome from "./Pharmacyhome/Pharmacyhome";
import Pharmacyaddform from "./Pharmacyhome/Pharmacyaddform/Pharmacyaddform";
import Pharmacyouthouse from "./Pharmacyouthouse/Pharmacyouthouse";
import Pharmacyouthouseform from "./Pharmacyouthouse/Pharmacyouthouseform/Pharmacyouthouseform";

const Pharmacy = () => {
    return (
        <Routes>
            <Route path="/in-house" element={<Pharmacyhome/>}/>
            <Route path="/out-house" element={<Pharmacyouthouse/>}/>
            <Route path="/in-house/add-form" element={<Pharmacyaddform/>}/>
            <Route path="/in-house/add-form/:id" element={<Pharmacyaddform/>}/>
            <Route path="/out-house/add-form" element={<Pharmacyouthouseform/>}/>
            <Route path="/out-house/add-form/:id" element={<Pharmacyouthouseform/>}/>
            <Route path="" element={<Navigate to="/dashboard/pharmacy/in-house"/>}/>
        </Routes>
    );
};

export default Pharmacy;

import React, { useContext, useEffect, useRef, useState } from "react";
import Card from "../../../../../shared/components/Card/Card";
import { Button, MenuItem, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import pharmacyValidation from "../../../../../core/Validationschemas/pharmacyValidation";
import commonAPICallService from "../../../../../core/services/Data/commonAPICallService";
import { UserContext } from "../../../../../App";
import uploadFileService from "../../../../../core/services/Data/uploadFileService";
import { toast } from "react-toastify";
import pharmacyService from "../../../../../core/services/Data/pharmacyService";

const Pharmacyaddform = () => {
  const params = useParams();
  const navigate = useNavigate();
  const imageInputref = useRef();
  const [hospitalImageUrl, setHospitalImageUrl] = useState("");
  const [formPrefillData, setFormPrefillData] = useState();
  const [hospitalsList, setHospitalsList] = useState(null);
  const [branchList, setbranchList] = useState(null);
  const { setShowLoader } = useContext(UserContext);
  const pharmacyInhouseForm = useFormik({
    initialValues: {
      hospitalName: formPrefillData
        ? formPrefillData.hospital.hospitalName
        : "",
      branch: formPrefillData ? formPrefillData.branch._id : "",
      chemistName: formPrefillData ? formPrefillData.name : "",
      email: formPrefillData ? formPrefillData.email : "",
      image: formPrefillData ? formPrefillData.picture : "",
    },
    enableReinitialize: true,
    validationSchema: pharmacyValidation.inhouse,
    onSubmit: async (values) => {
      setShowLoader(true);
      let fileUrl ;

      if(typeof values.image !=="string"){
       const result = await uploadFileService.fileUpload(values.image)
       fileUrl = result.fileUrl
      }

    
        if (params.id) {
          const payload = {
            ...values,
            image: fileUrl || values.picture,
          };
          pharmacyService
            .updatesingleInhouse(params.id, payload)
            .then((res) => {
              toast.success(res.message);
              navigate("/dashboard/pharmacy/in-house", { replace: true });
              setShowLoader(false);
            });
        } else {
          const payload = {
            ...values,
            image: fileUrl || values.picture,
          };
          if (fileUrl) {
            pharmacyService.addChemistInhouseHospital(payload).then((res) => {
              if (res) {
                navigate("/dashboard/pharmacy/in-house", { replace: true });
                toast.success("Chemist credentials added successfully");
              }
              setShowLoader(false);
            });
          }
        }
    },
  });

  const hospitalImageInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({file})
      if(file.size > 1024000){
        pharmacyInhouseForm.setErrors({
          image: "File size is exceeding the limit 1mb ",
        });
      // }
      }else{
      pharmacyInhouseForm.handleChange(event);
      pharmacyInhouseForm.setFieldValue(
        "image",
        event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
      );
      setHospitalImageUrl((prevState) => {
        return URL.createObjectURL(event.target.files[0]);
      });
    }
    }else{
      pharmacyInhouseForm.setErrors({
        image: "Image is Required!",
      });
    }
  };

  const HospitalResetImageInput = () => {
    pharmacyInhouseForm.setFieldValue("image", "");
    setHospitalImageUrl((prevState) => {
      return "";
    });
  };

  const hospitalChange = (event) => {
    setbranchList((prevState) => {
      return hospitalsList.filter((ele) => {
        return ele.hospital === event.target.value;
      })[0].branch;
    });
  };

  useEffect(() => {
    setShowLoader(true);

    if (params.id) {
      setShowLoader(true);
      pharmacyService.getsingleInhouse(params.id).then((res) => {
        commonAPICallService.getHospitalsList().then(async (response) => {
          if (response) {
            await setHospitalsList(response);
            console.log({response})
            setbranchList((prevState) => {
                return response.filter((ele) => ele.id === res.data.hospital._id)[0].branch
            })
          }
        });
        setShowLoader(false);
        console.log("AKASH ", res.data);
        setFormPrefillData(res.data);
        setHospitalImageUrl(res.data.picture);
      });
    } else {
      commonAPICallService.getHospitalsList().then(async (res) => {
        if (res) {
          await setHospitalsList(res);
          setShowLoader(false);
        }
      });
    }
  }, []);
  console.log("===========>", formPrefillData);

  return (
    <Card cname="max-w-2xl mx-auto">
      <h1 className="text-xl font-bold text-center">
        {params.id ? "Update" : "Add"} Chemist
      </h1>
      <form
        onSubmit={pharmacyInhouseForm.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        <div className="col-span-12 mt-2">
          <TextField
            fullWidth
            id="hospitalName"
            label="Hospital name"
            name="hospitalName"
            select
            helperText={
              pharmacyInhouseForm.touched.hospitalName &&
              Boolean(pharmacyInhouseForm.errors.hospitalName)
                ? pharmacyInhouseForm.errors.hospitalName
                : ""
            }
            value={pharmacyInhouseForm.values.hospitalName}
            onBlur={pharmacyInhouseForm.handleBlur}
            onChange={(e) => {
              hospitalChange(e);
              pharmacyInhouseForm.handleChange(e);
            }}
            error={
              pharmacyInhouseForm.touched.hospitalName &&
              Boolean(pharmacyInhouseForm.errors.hospitalName)
            }
          >
            {hospitalsList &&
              hospitalsList.map((ele) => {
                return (
                  <MenuItem value={ele.hospital} key={ele.id}>
                    {ele.hospital}
                  </MenuItem>
                );
              })}
          </TextField>
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            fullWidth
            id="branch"
            label="Branch name"
            name="branch"
            select
            helperText={
              pharmacyInhouseForm.touched.branch &&
              Boolean(pharmacyInhouseForm.errors.branch)
                ? pharmacyInhouseForm.errors.branch
                : ""
            }
            value={pharmacyInhouseForm.values.branch}
            onBlur={pharmacyInhouseForm.handleBlur}
            onChange={pharmacyInhouseForm.handleChange}
            error={
              pharmacyInhouseForm.touched.branch &&
              Boolean(pharmacyInhouseForm.errors.branch)
            }
          >
            {branchList &&
              branchList.map((ele) => {
                return (
                  <MenuItem value={ele._id} key={ele._id}>
                    {ele.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            id="chemistName"
            fullWidth
            helperText={
              pharmacyInhouseForm.touched.chemistName &&
              Boolean(pharmacyInhouseForm.errors.chemistName)
                ? pharmacyInhouseForm.errors.chemistName
                : ""
            }
            value={pharmacyInhouseForm.values.chemistName}
            onBlur={pharmacyInhouseForm.handleBlur}
            onChange={pharmacyInhouseForm.handleChange}
            error={
              pharmacyInhouseForm.touched.chemistName &&
              Boolean(pharmacyInhouseForm.errors.chemistName)
            }
            label="Chemist Name"
            name="chemistName"
            variant="outlined"
          />
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            id="email"
            fullWidth
            helperText={
              pharmacyInhouseForm.touched.email &&
              Boolean(pharmacyInhouseForm.errors.email)
                ? pharmacyInhouseForm.errors.chemistName
                : ""
            }
            value={pharmacyInhouseForm.values.email}
            onBlur={pharmacyInhouseForm.handleBlur}
            onChange={pharmacyInhouseForm.handleChange}
            error={
              pharmacyInhouseForm.touched.email &&
              Boolean(pharmacyInhouseForm.errors.email)
            }
            label="Email"
            name="email"
            variant="outlined"
          />
        </div>
        <div className="col-span-12 mt-2">
          <label
            className="block mb-2 text-md font-medium text-gray-600"
            htmlFor="large_size"
          >
            Image
          </label>
          {/*Image upload hospital logo*/}
          <div className="flex flex-col justify-center items-center max-w-full">
            <label
              className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                 ${
                                  
                                   pharmacyInhouseForm.errors.image
                                     ? "border-[#d32f2f]"
                                     : "border-gray-300"
                                 } 
                         border-dashed cursor-pointer`}
            >
              <input
                id="dropzone-file"
                name="image"
                disabled={hospitalImageUrl !== ""}
                accept="image/png, image/jpeg, image/jpg"
                onBlur={pharmacyInhouseForm.handleBlur}
                onChange={hospitalImageInputChangeHandler}
                type="file"
                className="hidden"
              />
              {hospitalImageUrl !== "" ? (
                <div className="relative w-24 h-24">
                  <img
                    id="blah"
                    src={hospitalImageUrl}
                    className="shadow w-full object-fill"
                    alt="your"
                  />
                  <CloseIcon
                    onClick={HospitalResetImageInput}
                    className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">
                      Click to upload Image
                    </span>{" "}
                    or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
              )}
            </label>
          </div>
          <div
            className={`text-[#d32f2f] my-2 text-sm ml-4 ${
           
              pharmacyInhouseForm.errors.image
                ? ""
                : "hidden"
            }`}
          >
            {pharmacyInhouseForm.errors.image}
          </div>
        </div>

        <div className="flex gap-2">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {params.id ? "Update" : "Submit"}
          </Button>
          {params.id && (
            <Button
              color="warning"
              variant=""
              fullWidth
              type="submit"
              onClick={() => {
                navigate("/dashboard/pharmacy/in-house");
              }}
            >
              {params.id ? "Cancel" : ""}
            </Button>
          )}
        </div>
      </form>
    </Card>
  );
};

export default Pharmacyaddform;

import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Card from "../../../../shared/components/Card/Card";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import {
  hospitalDetailsTableData,
  requestsReceived,
} from "../../../../core/constants/appconstants";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Rightbutton from "../../../../shared/components/Rightbutton/Rightbutton";
import Leftbutton from "../../../../shared/components/Leftbutton/Leftbutton";
import Imagecircular from "../../../../shared/components/Imagecircular/Imagecircular";
import { GetAllDoctorsOfParticularHospital } from "../../../../core/services/Data/doctorsService";
import { getHospitalByIdWithServices } from "../../../../core/services/Data/hospitalService";

const Hospitaldetails = () => {
  const [value, setValue] = React.useState("1");
  const [doctors, setDoctors] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [symptom, setSymptom] = React.useState([]);

  const receivedRequests = requestsReceived;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const location = useLocation();
  const { pathname } = location;

  const { id } = useParams();
  console.log("WOKRING", { location });

  //   const id = pathname.split("/")[3];
  const hospitalDetailsTable = hospitalDetailsTableData;
  let [hospitalDetailsTableDataToDisplay, setHospitalToDisplay] =
    React.useState({});

  useEffect(() => {
    setHospitalToDisplay(location.state);
    getHospitalByIdWithServices(id).then(({ data }) =>{
      setSymptom(data.symptom)
      setServices(data.services)
    });
    GetAllDoctorsOfParticularHospital(id).then((res) => {
      console.log(res);
      setDoctors(res.data);
    });
  }, []);
  return (
    <>
      <div className="grid grid-cols-12 rounded-xl shadow bg-white mb-4">
        <div className="col-span-12 md:col-span-5">
          <img
            src="https://i.postimg.cc/qR3TpWb3/hospitalcard.png"
            className="w-full h-full object-fill"
            alt="Laptop on Desk"
          />
        </div>
        {hospitalDetailsTableDataToDisplay &&
          hospitalDetailsTableDataToDisplay.name && (
            <div className="col-span-12 md:col-span-7 p-4">
              <div className="flex flex-col sm:flex-row justify-between">
                <div className="order-first flex flex-col">
                  <p className="text-sm font-bold">Main</p>
                  <p className="text-2xl font-semibold">
                    {hospitalDetailsTableDataToDisplay.name}
                  </p>
                  <div className="flex flex-col sm:flex-row justify-between text-gray-500">
                    <div>
                      <EmailIcon />
                      <span className="underline ml-1">
                        {hospitalDetailsTableDataToDisplay.email}
                      </span>
                    </div>
                    <div className="ml-0 sm:ml-3">
                      <LocalPhoneIcon />
                      <span className="underline ml-1">
                        {hospitalDetailsTableDataToDisplay.phone}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="order-first sm:order-last flex justify-start sm:justify-center mt-2 sm:mt-0 items-center">
                  <button className="bg-[#13C3C3] text-white px-3 py-1 rounded">
                    Platinum
                  </button>
                  <ModeEditIcon
                    className={`text-[#383838] border ml-4 border-gray-200 p-0.5 rounded-lg cursor-pointer hover:scale-110`}
                    style={{ height: "2rem", width: "2rem" }}
                  />
                </div>
              </div>
              <p className="tracking-wider text-gray-600 mt-5">
                {hospitalDetailsTableDataToDisplay.description}
              </p>
            </div>
          )}
      </div>
      <div className="mt-5">
        <TabContext value={value}>
          <div>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="lab API tabs example"
            >
              <Tab label="Treatments" value="1" />
              <Tab label="Services" value="2" />
              <Tab label="Doctors" value="3" />
              <Tab label="Branches" value="4" />
            </TabList>
          </div>
          <Card>
            <TabPanel value="1">
              <div className="grid grid-cols-12 gap-y-10">
                {symptom.map((ele) => {
                                    return (
                                        <div key={ele._id}
                                             className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                                            <img src={ele.icon}
                                                 className="bg-white shadow h-24 w-24 rounded-full" alt=""/>
                                            <h1 className="text-md text-gray-500 font-semibold tracking-wider">{ele.name}</h1>
                                        </div>
                                    )
                                })}
              </div>
            </TabPanel>
            <TabPanel value="2">
              {
                <div>
                  <div className="tracking-wider">
                    <h1 className="text-xl font-bold">Services</h1>
                  </div>

                  <div className="grid grid-cols-12 gap-0 md:gap-10">
                    <div className="col-span-12  md:col-span-6 my-auto">
                      {/* <div className="flex justify-between mt-3">
                          <div className="order-first">
                          <span className="font-bold tracking-wider">Today</span>
                          </div>
                          <div className="order-last cursor-pointer">
                          <span className="tracking-wider font-bold hover:underline text-sky-600">
                          <NavLink to="/dashboard/requests">View all</NavLink>
                          </span>
                          </div>
                        </div> */}
                      <div className="flex flex-col tracking-wider">
                        {/* <h1 className="text-xl font-bold">Requests</h1> */}
                      </div>
                      <div
                        className="p-2 inline-block grid grid-cols-2 gap-4 text-center"
                        // key={request.id}
                      >
                      {services.map((ele) => {
                        return (
                          <div className="p-6 shadow-md border border-soild rounded-lg">
                              {ele}
                          </div>
                        );
                      })}
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6 my-auto mx-auto">
                      <img src="https://i.postimg.cc/d1BM8pWg/map.png" alt="" />
                    </div>
                  </div>
                </div>
              }
            </TabPanel>
            <TabPanel value="3">
              <div className="grid grid-cols-12 gap-y-10">
                {doctors.map((ele) => {
                  return (
                    <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 flex flex-col justify-center items-center">
                      <Imagecircular doctor={ele}></Imagecircular>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel value="4">
              <div className="grid grid-cols-12 gap-0 md:gap-10">
                <div className="col-span-12 md:col-span-6 my-auto">
                  <div className="flex flex-col tracking-wider">
                    <h1 className="text-xl font-bold">Requests</h1>
                    <p className="text-gray-500 text-sm">
                      You can approve/deny requests of hospitals, pharmacies{" "}
                      <br />& more.
                    </p>
                  </div>
                  <div className="flex justify-between mt-3">
                    <div className="order-first">
                      <span className="font-bold tracking-wider">Today</span>
                    </div>
                    <div className="order-last cursor-pointer">
                      <span className="tracking-wider font-bold hover:underline text-sky-600">
                        <NavLink to="/dashboard/requests">View all</NavLink>
                      </span>
                    </div>
                  </div>
                  {receivedRequests.map((request) => {
                    return (
                      <>
                        <div
                          className="grid grid-cols-12 place-content-center gap-6 my-4"
                          key={request.id}
                        >
                          <div className="col-span-3 flex justify-center items-center">
                            <img
                              src={request.imgLink}
                              className="border border-sky-400 p-1 rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="col-span-6 flex flex-col">
                            <h3 className="text-sky-500 text-sm font-semibold">
                              {request.hospitalName}
                            </h3>
                            <p className="text-xs text-gray-500">
                              Approval for adding new branch
                            </p>
                          </div>
                          <div className="col-span-3 flex flex-col justify-center items-center">
                            <div className="flex flex-row">
                              <Rightbutton />
                              <div className="ml-2">
                                <Leftbutton />
                              </div>
                            </div>
                            <div className="text-center">
                              <span className="text-xs text-gray-500 font-semibold">
                                Today, {request.requestedAt}
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                </div>
                <div className="col-span-12 md:col-span-6 my-auto mx-auto">
                  <img src="https://i.postimg.cc/d1BM8pWg/map.png" alt="" />
                </div>
              </div>
            </TabPanel>
          </Card>
        </TabContext>
      </div>
    </>
  );
};

export default Hospitaldetails;

import React, {useState} from 'react';

const DAYS = [
    {
        key: 0,
        label: "S"
    },
    {
        key: 1,
        label: "M"
    },
    {
        key: 2,
        label: "T"
    },
    {
        key: 3,
        label: "W"
    },
    {
        key: 4,
        label: "T"
    },
    {
        key: 5,
        label: "F"
    },
    {
        key: 6,
        label: "S"
    }
];

const Toggledays = (props) => {
    const [days, setDays] = useState(props.workingDays);
    console.log({days})
    const appendDays = (key) => {
        setDays((prevState) => {
            if (!prevState.includes(key) || prevState.length === 0) {
                props.handleWorkingDays([...prevState, key]);
                return [...prevState, key]
            } else {
                props.handleWorkingDays([...prevState].filter((ele) => ele !== key));
                return [...prevState].filter((ele) => ele !== key)
            }
        });
    };
    return (
        <>
            {days && <div className="grid grid-cols-12 gap-0">
                <div className="col-span-12 md:col-span-3 my-auto">
                    <p className="text-sm md:text-md flex flex-col justify-center items-center text-gray-500 font-semibold"><span>{`Select Working`}</span><span>days :</span></p>
                </div>
                <div className="col-span-12 md:col-span-9 flex flex-wrap justify-evenly">
                    {DAYS.map((ele) => {
                        return (
                            <div key={ele.key} className={`my-2 sm:my-0 cursor-pointer font-semibold h-10 w-10 border-2 rounded
                         flex flex-wrap justify-center items-center ${days.includes(ele.key) ? 'text-sky-600 border-sky-400' : 'text-gray-600 border-gray-400'}`}
                                 onClick={() => appendDays(ele.key)}>
                                {ele.label}
                            </div>
                        )
                    })}
                </div>
            </div>}
        </>
    );
}

export default Toggledays;

import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Symptomshome from "./Symtomshome/Symtomshome";
import AddSymptomform from "./Addsymptomsform/AddSymptomform";
import Assigndoctorform from "./Assigndoctorform/Assigndoctorform";
import  DoctorAssign  from "./Assigndoctorform/DoctorAssign";

const Symptoms = () => {
    return (
        <Routes>
            <Route path="" element={<Symptomshome/>}/>
            <Route path="/add-symptom" element={<AddSymptomform/>}/>
            <Route path="/add-symptom/:id" element={<AddSymptomform/>}/>
            <Route path="/assign-doctor" element={<Assigndoctorform/>}/>
            <Route path="/assign-doctor/:id" element={<DoctorAssign/>}/>
            <Route path="/assign-doctor/adddoctor/:id" element={<Assigndoctorform/>}/>

            <Route path="*" element={<Navigate to="/dashboard/Symptoms"/>}/>
        </Routes>);
};

export default Symptoms;
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import {UserContext} from "../../../../App";
import receptionistService from "../../../../core/services/Data/receptionistService";
import Imagerenderer from "../../../../shared/components/AgGridRendererComponents/Imagerenderer";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import {AgGridReact} from "ag-grid-react";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {toast} from "react-toastify";

const Receptionisthome = () => {
    const gridRef = useRef(null);
    const [receptionistArray, setReceptionistArray] = useState(null);
    const {setShowLoader} = useContext(UserContext);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const callGetReceptionist = () => {
        setShowLoader(true)
        receptionistService.getAllReceptionist().then((res) => {
            setShowLoader(false)
            setReceptionistArray(res);
        })
    }
    const [idToDelete, setIdToDelete] = useState('');

    const [columnDefs] = useState([
        {headerName: "Image", field: "picture", cellRenderer: Imagerenderer},
        {
            headerName: "Receptionists name", field: "name.first", valueGetter: (e) => {
                return e.data.name.first + ' ' + e.data.name.last
            }
        },
        {headerName: "Email", field: "email"},
        {headerName: "Hospital", field: "professional.hospital.hospitalName"},
        {headerName: "Branch", field: "professional.branch.name"},
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/receptionist/registration/' + id);
                    // console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen();
                    setIdToDelete((prevState) => {
                        return id
                    })
                }
            }
        }
    ]);
    const deleteReceptionist = () => {
        receptionistService.deleteReceptionistById(idToDelete).then((res) => {
            if(res){
                toast.success('Receptionist is Deleted successfully');
                callGetReceptionist();
            }
        })
    }
    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    useEffect(() => {
        callGetReceptionist();
    }, [])
    return (
        <>
            <div className="mt-10">
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteReceptionist}/>
                <Card>
                    <div className="flex justify-between mb-3">
                        <div className="order-first">
                            <p className="text-2xl font-semibold">
                                Receptionist list 
                            </p>
                        </div>
                        
                        <div className="order-last">
                            <Outlinedbluebutton download={onBtnExport}/>
                            <Filledbluebutton cname="ml-4" clicked={() => {
                                navigate('/dashboard/receptionist/registration')
                            }}/>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact 
                                ref={gridRef}
                                rowData={receptionistArray}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Receptionisthome;

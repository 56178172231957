import React, { useRef, useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Card from "../../../../../shared/components/Card/Card";
import { Button, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../../../../App";
import { laboratoryRegistrationValidation } from "../../../../../core/Validationschemas/laboratoryValidation";
import laboratoryService from "../../../../../core/services/Data/laboratoryService";
import uploadFileService from "../../../../../core/services/Data/uploadFileService";
import commonAPICallService from "../../../../../core/services/Data/commonAPICallService";
import { toast } from "react-toastify";

const Laboratoryinhouseform = () => {
  const navigate = useNavigate();
  const imageInputref = useRef();
  const [imageUrl, setImageUrl] = useState("");
  const [branchList, setbranchList] = useState(null);
  const { setShowLoader } = useContext(UserContext);
  const [formPrefillData, setFormPrefillData] = useState();
  const [hospitalsList, setHospitalsList] = useState(null);
  const params = useParams();
  const formik = useFormik({
    initialValues: {
      hospitalName: formPrefillData
        ? formPrefillData.hospital.hospitalName
        : "",
      branch: formPrefillData ? formPrefillData.branch._id : "",
      labAssistantName: formPrefillData ? formPrefillData.name : "",
      email: formPrefillData ? formPrefillData.email : "",
      shopLocation: formPrefillData ? formPrefillData.shopLocation : "",
      image: formPrefillData ? formPrefillData.image : "",
    },
    validationSchema: laboratoryRegistrationValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log("shivam",values)
      setShowLoader((prevState) => {
        return true;
      });
      new Promise(async (resolve, reject) => {
        if (typeof values.image !== "string") {
          const response = uploadFileService.fileUpload(values.image);
          console.log("First", response);
          if (response) {
            resolve(response);
          } else {
            reject();
          }
        } else {
          resolve();
        }
      }).then(async (res1) => {
        if (params.id) {
          const payload = {
            ...values,
            picture: res1?.fileUrl || values.image,
            name: values.labAssistantName,
            branchName: values.branch,
            shopLocation: values.shopLocation,
          };
          console.log("shivam", payload);

          laboratoryService.updateLaboratory(params.id, payload).then((res) => {
            if (res) {
              console.log("fvld", res.data);
              setShowLoader(false);
              navigate("/dashboard/laboratory/in-house");
              toast.success("Laboratory Updated successfully");
            } else {
              setShowLoader(false);
            }
          });
        } else {
          const payload = {
            ...values,
            image: res1.fileUrl,
          };
          laboratoryService
            .createLaboratory({
              ...payload,
              picture: payload.image,
              name: values.labAssistantName,
              branchName: values.branch,
              shopLocation: values.shopLocation,
            })
            .then((res) => {
              if (res) {
                toast.success(res.message);
                navigate("dashboard/laboratory/in-house");
              }
              setShowLoader((prevState) => {
                return false;
              });
            }).catch(err => {
              console.log({err})
              toast.error(err.response.data.message)
            })
        }
      });
    },
  });
  const imageInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      console.log({ file });
      if (file.size > 1024000) {
        formik.setErrors({
          image: "File size is exceeding the limit 1mb ",
        });
        // }
      } else {
        formik.setFieldValue(
          "image",
          event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
        );
        setImageUrl((prevState) => {
          return URL.createObjectURL(event.target.files[0]);
        });
      }
    } else {
      formik.setErrors({ image: "Image is required" });
    }

    // console.log('input files', input.target.files);
    // setImageUrl((prevState) => {
    //     return 'https://i.postimg.cc/d1BM8pWg/map.png'
    // })
  };

  const hospitalChange = async (event) => {
    setbranchList((prevState) => {
      return hospitalsList.filter((ele) => {
        return ele.hospital === event.target.value;
      })[0].branch;
    });
  };

  const resetImageInput = () => {
    console.log("imageInputref", imageInputref);
    imageInputref.current.value = null;
    setImageUrl("");
  };

  useEffect(() => {
    setShowLoader(true);

    if (params.id) {
      setShowLoader(true);
      laboratoryService.getLaboratoryById(params.id).then((res) => {
        // console.log("res by Id--->", res.data);
        commonAPICallService.getHospitalsList().then(async (hos) => {
          if (hos) {
            await setHospitalsList(hos);
            setShowLoader(false);
            console.log({ hos });

            setbranchList((prevState) => {
              return hos.filter((ele) => ele.id === res.data.hospital._id)[0]
                .branch;
            });
          }
        });

        setImageUrl((prevState) => {
          return res.data.picture;
        });
        setFormPrefillData((prevState) => {
          setShowLoader(false);
          return res.data;
        });
      });
    } else {
      commonAPICallService.getHospitalsList().then(async (res) => {
        if (res) {
          await setHospitalsList(res);
          setShowLoader(false);
        }
      });
    }
  }, []);
  console.log("aara hai kya", formPrefillData);

  return (
    <Card cname="max-w-2xl mx-auto">
      <h1 className="text-xl font-bold text-center">
        {params.id ? "Update Lab Employee" : "Add Lab Employee"}
      </h1>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-12 gap-1 sm:gap-5"
      >
        <div className="col-span-12 mt-2">
          <TextField
            style={{ width: "100%" }}
            id="hospitalName"
            label="Hospital name"
            name="hospitalName"
            select
            helperText={
              formik.touched.hospitalName && Boolean(formik.errors.hospitalName)
                ? "Hospital name is required"
                : ""
            }
            value={formik.values.hospitalName}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              hospitalChange(e);
              formik.handleChange(e);
            }}
            error={
              formik.touched.hospitalName && Boolean(formik.errors.hospitalName)
            }
          >
            {hospitalsList &&
              hospitalsList.map((ele) => {
                return (
                  <MenuItem value={ele.hospital} key={ele.id}>
                    {ele.hospital}
                  </MenuItem>
                );
              })}
          </TextField>
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            style={{ width: "100%" }}
            id="branch"
            label="Branch name"
            name="branch"
            select
            helperText={
              formik.touched.branch && Boolean(formik.errors.branch)
                ? "Branch name is required"
                : ""
            }
            value={formik.values.branch}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.branch && Boolean(formik.errors.branch)}
          >
            {branchList &&
              branchList.map((ele) => {
                return (
                  <MenuItem value={ele._id} key={ele._id}>
                    {ele.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            id="labAssistantName"
            fullWidth
            helperText={
              formik.touched.labAssistantName &&
              Boolean(formik.errors.labAssistantName)
                ? "Laboratory name is required"
                : ""
            }
            value={formik.values.labAssistantName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.labAssistantName &&
              Boolean(formik.errors.labAssistantName)
            }
            label="Lab Assistant Name"
            name="labAssistantName"
            variant="outlined"
          />
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            id="email"
            fullWidth
            helperText={
              formik.touched.email && Boolean(formik.errors.email)
                ? "Email name is required"
                : ""
            }
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            label="Email"
            name="email"
            variant="outlined"
          />
        </div>
        <div className="col-span-12 mt-2">
          <TextField
            id="email"
            fullWidth
            helperText={
              formik.touched.shopLocation && Boolean(formik.errors.shopLocation)
                ? formik.errors.shopLocation
                : ""
            }
            value={formik.values.shopLocation}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.shopLocation && Boolean(formik.errors.shopLocation)
            }
            label="Address"
            name="shopLocation"
            variant="outlined"
          />
        </div>
        <div className="col-span-12 mt-2">
          <label
            className="block mb-2 text-md font-medium text-gray-600"
            htmlFor="large_size"
          >
            Image
          </label>
          <div className="flex flex-col justify-center items-center w-full">
            <label
              className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2
              ${formik.errors.image ? "border-[#d32f2f]" : "border-gray-300"}
                         border-dashed cursor-pointer`}
            >
              <input
                ref={imageInputref}
                id="dropzone-file"
                name="image"
                disabled={imageUrl !== ""}
                accept="image/png, image/jpeg, image/jpg"
                // value={formik.values.image}
                onBlur={formik.handleBlur}
                onChange={imageInputChangeHandler}
                // onInput={imageInputChangeHandler}
                type="file"
                className="hidden"
              />
              {imageUrl !== "" ? (
                <div className="relative w-24 h-24">
                  <img
                    id="blah"
                    src={imageUrl}
                    className="shadow w-full object-fill"
                    alt="your"
                  />
                  <CloseIcon
                    onClick={resetImageInput}
                    className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
              )}
            </label>
          </div>
          <div
            className={`text-[#d32f2f] my-2 text-sm ml-4 ${
              formik.errors.image ? "" : "hidden"
            }`}
          >
            {formik.errors.image}
          </div>
        </div>
        <div className="flex gap-2">
          <Button color="primary" variant="contained" fullWidth type="submit">
            Submit
          </Button>
          {params.id && (
            <Button
              color="warning"
              variant=""
              fullWidth
              type="submit"
              onClick={() => {
                navigate("/dashboard/laboratory/in-house");
              }}
            >
              {params.id ? "Cancel" : ""}
            </Button>
          )}
        </div>
      </form>
    </Card>
  );
};

export default Laboratoryinhouseform;

import React, {Fragment} from 'react';
import {TextField} from "@mui/material";
import {useFormik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import {resetPassword} from "../../../core/services/User/resetPasswordService";
import {toast} from "react-toastify";

const ResetPassword = () => {
    const params = useParams();
    const navigate = useNavigate();
    console.log('params', params.id);
    const resetPasswordForm = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: (values) => {
            resetPassword(params.id, resetPasswordForm.values.password, resetPasswordForm.values.confirmPassword).then((res) => {
                if (res) {
                    navigate('/login');
                    toast.success('Password successfully changed. Please login with your new password.');
                }
            })
        }
    })
    return (
        <Fragment>
            <div className="py-5">
                <h1 className="text-4xl font-bold">Reset Password</h1>
                <h3 className="text-lg my-3 text-start text-gray-400 max-w-sm">
                    Your new password should be different from the old password.
                </h3>
            </div>
            <form onSubmit={resetPasswordForm.handleSubmit}>
                <div className="my-5">
                    <TextField
                        className="w-full"
                        id="outlined-error"
                        label="Password"
                        name="password"
                        value={resetPasswordForm.values.password}
                        onChange={resetPasswordForm.handleChange}
                        onBlur={resetPasswordForm.handleBlur}
                        helperText={resetPasswordForm.touched.password && Boolean(resetPasswordForm.errors.password) ? 'Password is required' : ''}
                        error={resetPasswordForm.touched.password && Boolean(resetPasswordForm.errors.password)}
                    />
                </div>
                <div className="my-5">
                    <TextField
                        className="w-full"
                        id="outlined-error"
                        label="Confirm Password"
                        name="confirmPassword"
                        value={resetPasswordForm.values.confirmPassword}
                        onChange={resetPasswordForm.handleChange}
                        onBlur={resetPasswordForm.handleBlur}
                        helperText={resetPasswordForm.touched.confirmPassword && Boolean(resetPasswordForm.errors.confirmPassword) ? 'Confirm Password is required' : ''}
                        error={resetPasswordForm.touched.confirmPassword && Boolean(resetPasswordForm.errors.confirmPassword)}
                    />
                </div>
                <button
                    type="submit"
                    className="text-white rounded w-full my-4 uppercase app-button focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 mr-2 mb-2">
                    reset password
                </button>
            </form>
        </Fragment>
    );
};

export default ResetPassword;

import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

async function updateUserProfileService(payload) {
    try {
        const {data: response} = await httpservice.put("users/edit/me", payload);
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

async function getUserProfileService(){
    try {
        const {data: response} = await httpservice.get("users/me");
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export default {
    updateUserProfile: updateUserProfileService,
    getUserProfileService : getUserProfileService
}

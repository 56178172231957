import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

async function createMapUserService(name, email) {
    try {
        const {data: response} = await httpservice.post("hospitals/create/user", {
            name,
            email
        });
        return response;
    } catch (ex) {
        console.log({ex})
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.error)
        }
    }
}



export default {
    createmapuser: createMapUserService
}

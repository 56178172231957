import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function getHolidays(){
    try {
        const url = '/holidays';
        const {data : holiday} = await httpservice.get(url)
        return holiday
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}

export async function createholiday(payload){
    try {
        const url = '/holidays/create';
        const {data : holiday} = await httpservice.post(url, payload)
        return holiday
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data.message)
        }
    }
}

export async function deleteHoliday(id){
    
    try {
        const url = '/holidays/delete/'
        const {data : holiday} = await httpservice.delete(url +id)
        return holiday
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}


export async function updateHoliday(id, payload){
    try {
        const url = "holidays/edit/"
        const {data : holiday} = await httpservice.put(url + id, payload)
        return holiday
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
 export async function getHolidaysById(id){
    try {
        const url = "holidays/";
        const {data : holiday} = await httpservice.get(url + id)
        return holiday
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
 }
import {toast} from 'react-toastify'
import httpservice from "../Httpservice/httpservice,";

export async function analytics(date) {
    try {
        const {data: analayticsData} = await httpservice.post("leaves", {
            date,
        });
        return analayticsData
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
export async function leaveDetails(){
    try {
       const url = '/leaves'
       const {data : leaves} = await httpservice.get(url) 
       return leaves
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        } 
    }
}
export async function updateLeaves(payload,id){
    try {
        console.log("PAYLOAD",payload);
        const url = "/leaves/update/"
        const {data : response} = await httpservice.put(url +id + `?status=${payload.status}`)
        return response
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data)
        }
    }
}
import React ,{useRef, useState,useCallback, useContext, useEffect}  from 'react';
import Filterbutton from "../../../../shared/components/Filterbutton/Filterbutton";
import Card from "../../../../shared/components/Card/Card";
import Outlinedbluebutton from "../../../../shared/components/Outlinedbluebutton/Outlinedbluebutton";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import Customtable from "../../../../shared/components/Customtable/Customtable";
import EditButton from "../../../../shared/components/EditButton/EditButton";
import Deletebutton from "../../../../shared/components/Deletebutton/Deletebutton";
import {useNavigate} from "react-router-dom";
import {SponsorsDetailsTableData} from "../../../../core/constants/appconstants";
import {AgGridReact} from "ag-grid-react";
import Editdeletebutton from "../../../../shared/components/Editdeletebutton/Editdeletebutton";
import {UserContext} from "../../../../App";
import Deletepopup from "../../../../shared/components/Deletepopup/Deletepopup";
import {getSponsors, deleteSponsors}  from "../../../../core/services/Data/sponsorsService"
import {toast} from "react-toastify";

const Sponsorshome = () => {
    const navigate = useNavigate();
    const gridRef = useRef(null);
    const sponsorsDetailsData = SponsorsDetailsTableData;
    const [sponsorData, setSponsorData] = useState()
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const {setShowLoader} = useContext(UserContext);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
       
    };
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        floatingFilter: true
    };

    const GettingSponsorsData = ()=>{
        setShowLoader(true)
        getSponsors().then((res)=>{
            setShowLoader(false)
            setSponsorData( res.data);
            console.log("AKASH", res.data);
        })
    }


    const [columnDefsDoctor] = useState([
        {headerName: "Entity Name", field: "entityName",},
        {headerName: "Name", field: "name"},
        
        {
            headerName: 'Action', cellRenderer: Editdeletebutton, sortable: false, filter: false, cellRendererParams: {
                data: gridRef && gridRef,
                editClicked: (id) => {
                    navigate('/dashboard/sponsors/add-sponsor/edit/' + id);
                    // console.log('id', id)
                },
                deleteClicked: (id) => {
                    handleOpen()
                    setIdToDelete((prevState) => {
                        return id
                    })
                    // console.log('id', id)
                }
            }
        }
    ]);

    const deleteSponsor = ()=>{
        setShowLoader(true)
        deleteSponsors(idToDelete).then((res)=>{
            if(res){
                toast.success(res.message)
                GettingSponsorsData()
            }
            setShowLoader(false)
        })
    }
    useEffect(()=>{
        GettingSponsorsData()
    },[])
    return (
        <>
            <div className="flex justify-between mb-4">
                <div className="order-first">
                    <h1 className="text-3xl font-semibold">Sponsors</h1>
                </div>
                {/* <div className="order-last">
                    <Filterbutton text="Filter by entity"/>
                    <Filterbutton text="Filter by name" cname="ml-4"/>
                </div> */}
            </div>
            <Card>
            <Deletepopup handleCloseModel={handleClose} isOpen={open} id={idToDelete} handleDelete={deleteSponsor}/>

                <div className="flex justify-between mb-3">
                    <div className="order-first">
                        <p className="text-2xl font-semibold">
                            List of sponsored entities
                        </p>
                    </div>
                    <div className="order-last">
                        <Outlinedbluebutton download={onBtnExport}/>
                        <Filledbluebutton cname="ml-4" clicked={() => {
                            navigate('/dashboard/sponsors/add-sponsor')
                        }}/>
                    </div>
                </div>
                {/* <Customtable heading={['Entity Name', 'Name', 'Action']}>
                    {sponsorsDetailsData.map((ele) => {
                        return (
                            <tr className="bg-white border-b cursor-pointer" key={ele.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.entityName}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 font-medium text-gray-900">{ele.name}</td>
                                <td>
                                    <EditButton/>
                                    <Deletebutton cname="ml-3"/>
                                </td>
                            </tr>
                        )
                    })}
                </Customtable> */}
                 <div className="mt-5">
                        <div className="ag-theme-alpine" style={{height: 400, width: '100%'}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={sponsorData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefsDoctor}
                                rowSelection="multiple">
                            </AgGridReact>
                        </div>
                    </div>
            </Card>
        </>
    );
};

export default Sponsorshome;

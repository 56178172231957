import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Sponsorshome from "./Sponsorshome/Sponsorshome";
import SponsorAddForm from "./sponsorAddform/sponsorAddform";

const Sponsors = () => {
    return (
        <Routes>
            <Route path="" element={<Sponsorshome/>}/>
            <Route path="/add-sponsor" element={<SponsorAddForm/>}/>
            <Route path="/add-sponsor/edit/:id" element={<SponsorAddForm/>}/>
            <Route path="*" element={<Navigate to={"/"}/>}/>
        </Routes>
    );
};

export default Sponsors;

import React, { useEffect, useState, useRef, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Card from "../../../../shared/components/Card/Card";
import { Button, MenuItem, TextField } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getScheduleData } from "../../../../core/services/Data/scheduleService";
import timeGridPlugin from "@fullcalendar/timegrid";
import {UserContext} from "../../../../App";

const Scheduledocview = ({ cons }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {setShowLoader} = useContext(UserContext);

  const calendarRef = useRef(null);
  const [data, setData] = useState(null);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const params = useParams();

  const handleDateClick = (event) => {
    // console.log("event", calendarRef.current.getApi());
  };

  const handleClicked = () => {
    navigate(`/dashboard/schedules/create/${params?.id}`);
  };

  function renderEventContent(eventInfo) {
    return (
      <div className="text-center hover:bg-red-300">
        {/* <b>{eventInfo.timeText}</b> */}
        {/* <i
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {eventInfo.event.title}
            </i> */}

        {data &&
          data.map((ele) => {
            // console.log(
            //   "checking",
            //   eventInfo.event,
            //   new Date(eventInfo.event.start).getFullYear(),
            //   new Date(eventInfo.event.start).getMonth(),
            //   new Date(eventInfo.event.start).getDate(),
            //   new Date(eventInfo.event.start).getHours(),
            //   new Date(eventInfo.event.start).getMinutes(),

            //   // eventInfo.timeText.slice(0, 5),
            //   new Date(ele.startTime).getFullYear(),
            //   new Date(ele.startTime).getMonth(),
            //   new Date(ele.startTime).getDate(),
            //   new Date(ele.startTime).getHours(),
            //   new Date(ele.startTime).getMinutes()
            // );
            if (
              new Date(eventInfo.event.start).getFullYear() ==
                new Date(ele.startTime).getFullYear() &&
              new Date(eventInfo.event.start).getMonth() ==
                new Date(ele.startTime).getMonth() &&
              new Date(eventInfo.event.start).getDate() ==
                new Date(ele.startTime).getDate() &&
              new Date(eventInfo.event.start).getHours() ==
                new Date(ele.startTime).getHours() &&
              new Date(eventInfo.event.start).getMinutes() ==
                new Date(ele.startTime).getMinutes()
            )
              return <b key={ele} className="">{ele.status?.toUpperCase()}</b>;
          })}
      </div>
    );
  }
  let todayStr = new Date().toISOString().replace(/T.*$/, "");
  useEffect(() => {
    // console.log({ startTime: new Date(state.startDate) });
    const startDate = new Date(state.startDate);
    const endDate = new Date(state.endDate);
    startDate.setDate(new Date(state.endDate).getDate());
    endDate.setDate(new Date(state.endDate).getDate() + 2);
    // console.log(startDate.toISOString());
    // const payload = {
    //   startDate = `${startDate}`
    // }
    // getScheduleData(params.id, {
    //   startDate: startDate.toISOString().slice(0, 10),
    //   endDate: endDate.toISOString().slice(0, 10),
    // }).then((res) => {
    //   console.log("DATA IS HERE", res.data);
    setData([...state.appointments]);
    // });
    // console.log(state);
  }, []);
  return (
    <Card>
      {/* <FullCalendar 
                plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                    left: 'prev, next, today',
                    center: 'title',
                    right: 'dayGridMonth, dayGridWeek, dayGridDay, listWeek'
                }}
                events={[
                    {title: 'Admin panel sync call', date: '2022-09-13'}
                ]}
             
                
                dateClick={handleDateClick}
                initialView="dayGridMonth"
            /> */}
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        headerToolbar={{
          // left: "myCustomButton prev,today,next",
          left: "prev,today,next",
          center: "title",
          right: "",
        }}
        initialView="timeGridWeek"
        // editable={true}
        lazyFetching={true}
        isLoading={true}
        selectable={true}
        selectMirror={true}
        // progressiveEventRendering={false}
        // slotLabelInterval={15}
        slotDuration='00:15:00'
        // slotLabelFormat='h(:mm)a'
        dayMaxEvents={true}
        weekends={weekendsVisible}
        events={
          data &&
          data.map((ele) => ({
            title: ele.status,
            start: ele.startTime,
            end: ele.endTime,
          }))
          
        }
        eventContent={renderEventContent} // custom render function
        dateClick={handleDateClick}
        eventAdd={(e) => {
          console.log("eventAdd", e);
        }}
        eventChange={(e) => {
          console.log("eventChange", e);
        }}
        eventRemove={(e) => {
          console.log("eventRemove", e);
        }}
      />
      <div>
        No slots Yet <br />
        <Button color="primary" variant="contained" onClick={handleClicked}>
          Create Slots
        </Button>
      </div>
    </Card>
  );
};

export default Scheduledocview;

import React, { useRef, useContext, useState, useEffect } from "react";
import Card from "../../../../shared/components/Card/Card";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Filledbluebutton from "../../../../shared/components/Filledbluebutton/Filledbluebutton";
import { MedicineAddFormValidationSchema } from "../../../../core/Validationschemas/MedicineValidationSchema";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../App";
import uploadFileService from "../../../../core/services/Data/uploadFileService";
import mediceService from "../../../../core/services/Data/mediceService";
import { toast } from "react-toastify";

const Medicineform = () => {
  const imageInputref = useRef();
  const params = useParams();
  const [formPrefillData, setFormPrefillData] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const { setShowLoader } = useContext(UserContext);
  const navigate = useNavigate();
  const medicineAddForm = useFormik({
    initialValues: {
      name: formPrefillData ? formPrefillData.name : "",
      company: formPrefillData ? formPrefillData.company : "",
      image: formPrefillData ? formPrefillData.picture : "",
      description: formPrefillData ? formPrefillData.description : "",
    },
    enableReinitialize: true,
    validationSchema: MedicineAddFormValidationSchema,
    onSubmit: async (values) => {
      console.log('values', values);
      //   setShowLoader((prevState) => {
      //     return true;
      //   });
      let fileUrl;
      if (typeof values.image !== "string") {
        const result = await uploadFileService.fileUpload(values.image);
        fileUrl = result.fileUrl;
      }

      // console.log("Akash is Here",res1)
      const payload = {
        ...values,
        image: fileUrl || values.image,
      };

      console.log("values", values);
      if (params.id) {
        mediceService.UpdateMedicine(params.id, payload).then((res) => {
          if (res) {
            navigate("/dashboard/medicines");
            toast.success(res.message);
          } else {
            setShowLoader(false);
          }
        });
      } else {
        mediceService
          .createMedicine({
            ...payload,
            picture: payload.image,
          })
          .then((res) => {
            if (res) {
              toast.success(res.message);
              navigate("/dashboard/medicines");
            }

            setShowLoader((prevState) => {
              return false;
            });
          });
      }
    },
  });

  const imageInputChangeHandler = (event) => {
    if (event.currentTarget.files.length !== 0) {
      medicineAddForm.setFieldValue(
        "image",
        event.currentTarget.files[0] ? event.currentTarget.files[0] : ""
      );
      setImageUrl((prevState) => {
        return URL.createObjectURL(event.target.files[0]);
      });
    } else if (medicineAddForm.values.image) {
    } else {
      // medicineAddForm.setErrors({image: 'Medicine Image is required'});
    }
  };

  const resetImageInput = () => {
    medicineAddForm.setFieldValue("image", "");
    setImageUrl((prevState) => {
      return "";
    });
  };

  useEffect(() => {
    setShowLoader(true);
    if (params.id) {
      mediceService.getMedicineById(params.id).then((res) => {
        if (res) {
          setFormPrefillData((prevState) => {
            setShowLoader(false);
            //   console.log(res.data,"medData");
            return res.data;
          });
          setImageUrl(res.data.picture);
        }
      });
    } else {
      setShowLoader(false);
    }
  }, []);

  return (
    <Card>
      <div className="flex justify-between my-7">
        <div className="order-first">
          <h1 className="font-semibold text-xl tracking-wider">
            Add New Medicine
          </h1>
        </div>
      </div>
      <form
        action=""
        className="grid grid-cols-12 gap-10"
        onSubmit={medicineAddForm.handleSubmit}
      >
        <div className="col-span-6">
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            name="name"
            label="Name"
            value={medicineAddForm.values.name}
            onChange={medicineAddForm.handleChange}
            onBlur={medicineAddForm.handleBlur}
            helperText={
              medicineAddForm.touched.name &&
                Boolean(medicineAddForm.errors.name)
                ? medicineAddForm.errors.name
                : ""
            }
            error={
              medicineAddForm.touched.name &&
              Boolean(medicineAddForm.errors.name)
            }
          />
        </div>
        <div className="col-span-6">
          <TextField
            fullWidth
            id="hospitalInput"
            label="Company"
            name="company"
            value={medicineAddForm.values.company}
            onChange={medicineAddForm.handleChange}
            onBlur={medicineAddForm.handleBlur}
            helperText={
              medicineAddForm.touched.company &&
                Boolean(medicineAddForm.errors.company)
                ? medicineAddForm.errors.company
                : ""
            }
            error={
              medicineAddForm.touched.company &&
              Boolean(medicineAddForm.errors.company)
            }
          ></TextField>
        </div>
        {/* {console.log({error:medicineAddForm.errors})} */}
        <div className="col-span-12">
          <div className="flex flex-col justify-center items-center w-full">
            <label
              className={`flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border
                                         ${medicineAddForm.errors.image
                  ? "border-[#d32f2f]"
                  : "border-gray-300"
                }
                                        border-dashed cursor-pointer`}
            >
              <input
                ref={imageInputref}
                id="dropzone-file"
                name="image"
                disabled={imageUrl !== ""}
                accept="image/png, image/jpeg, image/jpg"
                onBlur={medicineAddForm.handleBlur}
                onChange={imageInputChangeHandler}
                type="file"
                className="hidden"
              />
              {imageUrl !== "" ? (
                <div className="relative w-24 h-24">
                  <img
                    id="blah"
                    src={imageUrl}
                    className="shadow w-full object-fill"
                    alt="your"
                  />
                  <CloseIcon
                    onClick={resetImageInput}
                    className="absolute -top-2 -right-2 rounded-full p-1 bg-red-50 text-red-500 font-bold"
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">
                      Click to upload product image
                    </span>{" "}
                    or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
              )}
            </label>
          </div>
          {console.log({ error: medicineAddForm.errors.image })}
          <div
            className={`text-[#d32f2f] my-2 text-sm ml-4 ${medicineAddForm.errors.image ? "" : "hidden"
              }`}
          >
            Medicine Image is required
          </div>
        </div>
        <div className="col-span-12">
          <TextField
            fullWidth
            id="hospitalInput"
            label="Description"
            name="description"
            value={medicineAddForm.values.description}
            onChange={medicineAddForm.handleChange}
            onBlur={medicineAddForm.handleBlur}
            helperText={
              medicineAddForm.touched.description &&
                Boolean(medicineAddForm.errors.description)
                ? medicineAddForm.errors.description
                : ""
            }
            error={
              medicineAddForm.touched.description &&
              Boolean(medicineAddForm.errors.description)
            }
            multiline
            rows={6}
          />
        </div>
        <div className="col-span-12">
          {/*Submit button*/}
          <Filledbluebutton
            cname="w-32"
            btnType="submit"
            disabledBtn={!medicineAddForm.isValid}
          >
            {params.id ? "Update" : "Submit"}
          </Filledbluebutton>
        </div>
      </form>
    </Card>
  );
};

export default Medicineform;
